import Countdown from 'react-countdown';
import FadeIn from 'react-fade-in';
import { Card, CardText, CardTitle } from 'reactstrap';
import { Banner, BoxInfo } from '../../telas/styles/estilo_telas'
import { getAviso } from './BannerScreen.component'

interface PropAviso {
  id: string,
  ReiniciarTentativa : () => void,
  tentativa: number
}

export const ErroIdentificacao = ({id, ReiniciarTentativa, tentativa} : PropAviso) => 
    {
      const onUpdateCounter = ({seconds}: {seconds: number}) => {
        return(<span>{seconds}</span>)
      }

      const ban = getAviso(id);
      return(<FadeIn delay={50}>
        <BoxInfo>
          <Card
            body
            className="text-center"
          >
            <CardTitle tag="h5">
              {ban.title}
            </CardTitle>
            <CardText>
              {ban.text}
            </CardText>
          </Card>
          
          <Banner alt={id} src={ban.src} />
          
          <p>Você atualmente possui {tentativa} tentativas, a etapa de prova de vida se 
          reiniciará em (<Countdown date={Date.now()+5000} intervalDelay={0} precision={3} renderer={onUpdateCounter} onComplete={ReiniciarTentativa} />) segundos...</p>
        </BoxInfo>
      </FadeIn>)
    };

export default ErroIdentificacao