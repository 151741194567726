import React from 'react'
import {Card, CardTitle, CardBody, Button} from 'reactstrap'
import FadeIn from 'react-fade-in'
import './styles/estilo.css'
import { ButtonsInlineCard } from './styles/estilo_telas'
import { ClearEtapas } from '../components/Upload.component'

import Loading from '../components/Loading'

import { PropUJornada } from '../ts/interfaces/PropTelas.interfaces'
import { getStateJornada } from '../state/actions/jornada_actions'

interface State {
  wait : boolean
}

const {REACT_APP_PERFIL} = process.env

export default class ValidacaoUltimaJornada extends React.Component<PropUJornada, State> {
  constructor(props : PropUJornada) {
    super(props)
    this.Aceitar = this.Aceitar.bind(this);
    this.Rejeitar = this.Rejeitar.bind(this);
  }

  state = {
    wait: false
  }

  Aceitar() {
    const {ultimaJornada} = getStateJornada();

    //this.props.InfoUpdate({fase: status.lgpd_aceita, situacao: situacao.sucesso});
    this.props.AtualizarEventos();
    this.props.changePasso(ultimaJornada.etapa+1);
    this.props.continuarJornada();
  }

  async Rejeitar() {
    const { id } = getStateJornada();
    //this.props.InfoUpdate({fase: status.lgpd_rejeita, situacao: situacao.sucesso});
    //this.props.history.push('/rejeitar/'+this.props.id)
    this.setState({wait: true});
    await ClearEtapas(id);
    this.props.continuarJornada();
  }


  render(){
    const {wait} = this.state;

    if(wait){
      return(
        <div style={{marginTop: 20}}>
          <Loading text={'Carregando...'}/>
        </div>
      );
    }

    return(
      <FadeIn delay={50}>
        
        <div style={{marginTop: '20px'}}>
        <Card
          body
          >
        <CardTitle tag='h5' style={{textAlign: 'left'}}>Jornada em andamento</CardTitle>
        <CardBody>
        <p style={{textAlign: 'left', textJustify: 'inter-word'}}>Identificamos que você já iniciou uma jornada anteriormente. Deseja continuar a formalização de onde parou ?
        </p>
        <ButtonsInlineCard>
          <Button id={REACT_APP_PERFIL == 'BPO'? 'button-bpo': 'button-purple'} onClick={this.Aceitar}> Sim </Button>
          <Button color='danger' onClick={this.Rejeitar}> Nao </Button>
        </ButtonsInlineCard>
        </CardBody>
        </Card>
        </div>
      </FadeIn>
    );

  }


}