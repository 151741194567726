import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap';
import FadeIn from 'react-fade-in'
import Header from '../components/header'
import error from '../images/error.png'
import { isBrowser } from 'react-device-detect'

interface ErrorType {
  title: string,
  message: string | React.ReactNode
}

export const ErrorComponent = ({title, message} : ErrorType) => {
    if(isBrowser){
      return(
        <div>
            <FadeIn delay={150}>
                <Header/>

                <div className='card-style'>
                  <Card
                    body
                    className="text-center">
                      <img src={error} width='110px' height='105px' style={{margin: '20px auto'}} alt='failed'/>
                      <CardTitle tag="h5">
                        {title}
                      </CardTitle>
                      <CardBody>
                        {message}
                      </CardBody>
                  </Card>
                </div>
            </FadeIn>
      </div>
      );
    }else{
      return(
        <div>
          <FadeIn delay={150}>
              <Header/>
              
               <div className='card-style-mobile'>
                 <Card
                   body
                   className="text-center">
                     <img src={error} width='90px' height='80px' style={{margin: '20px auto'}} alt='failed'/>
                     <CardTitle tag="h5">
                        {title}
                     </CardTitle>
                     <CardBody>
                        {message}
                     </CardBody>
                 </Card>
               </div>
            </FadeIn>
      </div>
      );
    } 
}

export default ErrorComponent