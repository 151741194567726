import { useRef, useState } from 'react'
import { Box, Button, ButtonProps, Grid, Icon, MenuItem, Paper, styled } from '@mui/material'
import { Form } from '@unform/web'
import { InputTextField, SelectField } from '../form'
import { FormHandles } from '@unform/core'
import { grey } from '@mui/material/colors'

import { RegistroData } from './FormInterfaces'
import { InputPassowordField } from '../form/InputPasswordField'
import Alert, { IAlert } from '../alerts/alert'
import axios from 'axios'

const {REACT_APP_SERVER} = process.env;

const webs = REACT_APP_SERVER

const ButtonPropSubmit = styled(Button)<ButtonProps>(({ theme }) => ({
  borderColor: theme.palette.getContrastText(grey[300]),
  color: theme.palette.getContrastText(grey[300]),
  '&:click': {
    borderColor:theme.palette.getContrastText(grey[700]),
    color: theme.palette.getContrastText(grey[700]),
  },
  '&:hover': {
    borderColor:theme.palette.getContrastText(grey[200])
  }
}))

export const FormRegistro = () : JSX.Element => {
  const formRef = useRef<FormHandles>(null);
  const [alert, setAlert] = useState<IAlert>({message: 'enviado', visible: false})

  const onSubmit = (data: RegistroData) => {
    if(data.senha != data.confirma_senha){
      formRef.current?.setFieldError('confirma_senha', 'Confirme a senha correta');
      setAlert({message: 'Erro no cadastro', visible: true, color: 'error'})
    }else if(!data.perfil){
      formRef.current?.setFieldError('perfil', 'Tipo Inválido');
      setAlert({message: 'Erro no cadastro', visible: true, color: 'error'})
    }else {
      axios.post(webs+'/registrar', data).then((resp) => {
        if(!resp.data){
          setAlert({message: 'Erro Usuario com login já existente', visible: true, color: 'error'})
        } else {
          setAlert({message: 'Usuario Registrado com sucesso', visible: true, color: 'success'})
        }
      })
    }
  }

  return (
    <>
    <Alert {...alert}/>
    <Form ref={formRef} onSubmit={onSubmit} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <Box margin={2} display='flex' flexDirection='column' component={Paper} variant='outlined'>
        <Grid container direction={'column'} padding={2} spacing={2}>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <h5>Cadastrar</h5>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputTextField fullWidth name='login' label='Usuario' />
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputPassowordField fullWidth name='senha' label='Senha' type='password'/>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputPassowordField fullWidth name='confirma_senha' label='Confirmar Senha' type='password'/>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField fullWidth name='perfil' label='Tipo Perfil'>
                <MenuItem value={1}>Operador TI</MenuItem>
                <MenuItem value={2}>Administrador</MenuItem>
                <MenuItem value={3}>Operador</MenuItem>
              </SelectField>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <ButtonPropSubmit fullWidth type='submit'><Icon>group_add_icon</Icon>Registrar</ButtonPropSubmit>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
    </>
  )
}

export default FormRegistro