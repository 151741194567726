import React from 'react'
import FadeIn from 'react-fade-in';
import { Button, Card, CardBody, CardTitle, Input } from 'reactstrap';
import '../telas/styles/estilo.css'

import {videocons, CardInfo, OpcoesBtn, ResPic, ButtonsInline, Command} from '../telas/styles/estilo_telas'
import Document from '../components/Document'
import Camera from '../components/CameraFluxo'

import {OpcaoText, AnexarText, TirarFotoText} from '../telas/text/contracheque.text'
import {IsFileUpperBound, FiletoBase64, IsValidExtension} from '../util/Utils'

import { PropTelaP, HTMLInputEvent } from '../ts/interfaces/PropTelas.interfaces';
import { Base64Type, Contrato } from '../ts/types/Types';
import Webcam from 'react-webcam';

import Apresentacao from '../components/screen_components/Apresentacao.component';
import { updateInfoBPO, situacao, status } from '../state/actions/Info_actions';
import { documento_id } from '../telas_id';

const tela = {
  apresentacao: 0,
  opcao: 1,
  anexar: 2,
  tirarfoto: 3
}

interface State {
  data: Base64Type,
  datab64: Base64Type,
  dataUrl: string | null,
  step: number,
  refazer: boolean
}

const {REACT_APP_PERFIL} = process.env
export default class ContrachequeTela extends React.Component<PropTelaP, State> {
  webcam: React.RefObject<Webcam>
  contrato!: Contrato

  constructor(props : PropTelaP){
    super(props)
    this.Apresentacao = this.Apresentacao.bind(this)
    this.Opcao = this.Opcao.bind(this)
    this.Anexar = this.Anexar.bind(this)
    this.TirarFoto = this.TirarFoto.bind(this)
    this.Takecapture = this.Takecapture.bind(this)
    this.ProximoPasso = this.ProximoPasso.bind(this)
    this.SeguirAnexo = this.SeguirAnexo.bind(this)
    this.SeguirFoto = this.SeguirFoto.bind(this)
    this.CameraError = this.CameraError.bind(this)

    this.webcam = React.createRef<Webcam>()

    this.handleImage = this.handleImage.bind(this)

    this.state = {
      data: null,
      datab64: null,
      dataUrl: null,
      step: tela.apresentacao,
      refazer: true
    }
  }

  CameraError = () => {
    this.setState({data: null, refazer: true})
    this.props.ShowAlert(TirarFotoText.error)
  }

  componentDidMount(){
    updateInfoBPO({fase: status.tela_contracheque, situacao: situacao.sucesso});
  }

  async handleImage(event : HTMLInputEvent) {
    const file : File | null = event.target.files? event.target.files[0] : null
    if(file) {
      if(IsFileUpperBound(file)){
        this.props.ShowAlert(AnexarText.error)
      }else if(!IsValidExtension(file)){
        this.props.ShowAlert(AnexarText.error2)
      }else{
        this.setState({
          data: file,
          dataUrl: URL.createObjectURL(file)
        })
        const datab64 = await FiletoBase64(file)
        this.setState({datab64: datab64})
      }
    }
  }

  Apresentacao(){
    return(
    <Apresentacao 
      id='Contracheque'
      onClick={()=> this.setState({step: tela.opcao})}
    />);
  }

  Opcao(){
    return(
      <FadeIn>
        <CardInfo>
          <Card 
            body
            className="text-center">
            <CardBody>
              <CardTitle tag="h5">
                {OpcaoText.title}
              </CardTitle>

              <OpcoesBtn>
                <Button color='success' onClick={()=>this.setState({step: tela.anexar})}> Anexar foto</Button>
                <Button color='info' onClick={()=>this.setState({step: tela.tirarfoto})}> Tirar Foto </Button>
              </OpcoesBtn>
            </CardBody>
          </Card>
        </CardInfo>
      </FadeIn>
    );
  }

  SeguirAnexo = () => {
    const{datab64} = this.state
    this.props.InsertScreenShot(datab64, documento_id.contracheque)
    this.ProximoPasso()
  }

  SeguirFoto = () => {
    const{data} = this.state
    this.props.InsertScreenShot(data, documento_id.contracheque)
    this.ProximoPasso()
  }

  ProximoPasso(){
    this.props.changePasso()
  }

  Anexar(){
    return(
      <FadeIn>
        <CardInfo>
          <Card
            body
            className="text-center">
              {this.state.data?
              <CardTitle tag="h5">
                {AnexarText.title2}
              </CardTitle>
              :
              <CardTitle tag="h5">
                {AnexarText.title1}
              </CardTitle>
              }
              <CardBody>
                <Input color='success' type='file' onChange={this.handleImage}/>
                {this.state.data && <Document alt='Anexo Contracheque' src={this.state.data} url={this.state.dataUrl} />}
                {this.state.data && <Button id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} block={true} onClick={this.SeguirAnexo}> Seguir </Button>}
              </CardBody>
          </Card>
        </CardInfo>
      </FadeIn>
    );
  }

  Takecapture = async () => {
    var screenshot = this.webcam?.current?.getScreenshot();
    if(screenshot){
      this.setState({data: screenshot, refazer: false})
    }else{
      this.props.ShowAlert(TirarFotoText.error)
    }
  }

  TirarFoto(){
    const {refazer} = this.state

    return(
      <FadeIn delay={50}>
        <div style={{marginTop: '50px', marginBottom: '100px'}}>
          <Command>
            {refazer?
            <p><b>{TirarFotoText.title1}</b></p>
            :
            <p><b>{TirarFotoText.title2}</b></p>
            }
          </Command>
          
          {refazer? 
              <Camera webcam={this.webcam} videocons={videocons} CameraError={this.CameraError} onClick={this.Takecapture} />
            : <ResPic>
            
            <img alt='Pic contracheque' src={this.state.data as string}/>
            
            {this.state.data && (
              <ButtonsInline>
                <Button color='info' onClick={()=>this.setState({refazer: true})}> Tirar Novamente </Button>
                <Button id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} onClick={this.SeguirFoto}> Seguir </Button>
              </ButtonsInline>
              )
              }
            </ResPic>}

        </div>
      </FadeIn>
    );
  }

  render(){
    const {step} = this.state;

    switch(step){
      case tela.apresentacao:
        return(this.Apresentacao());
      case tela.opcao:
        return(this.Opcao());
      case tela.tirarfoto:
        return(this.TirarFoto());
      case tela.anexar:
        return(this.Anexar());
      default:
        break;
    }
    return null;
  }
  
}