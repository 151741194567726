import { UncontrolledAlert } from 'reactstrap';
import styled from 'styled-components';

const Alert = styled(UncontrolledAlert)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  border-radius: 0px;
`

export interface IAlertError {
  message: string,
  visible: boolean,
  color?: string
}

export default function AlertError({message, visible, color='danger'} : IAlertError)
{
  if(visible) {
    return(
      <Alert isOpen={visible} color={color}>
        {message}
      </Alert>
    );
  }
  return(<></>)
}