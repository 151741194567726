import {isBrowser} from 'react-device-detect';
import styled from 'styled-components'

export const CardInfo = isBrowser? styled.div`
  padding: 20px;
  width: 50%;
  margin: 0 auto;
`:styled.div`
  padding: 20px;
  margin: 0 auto;
  max-width: 'device-width';
`;