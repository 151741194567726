import styled from 'styled-components'
import { isBrowser } from 'react-device-detect'

const ImgDiv = isBrowser? styled.div`
  margin: 20px auto; 
  text-align: center; 
` : styled.div``

const ImgBanner = isBrowser? styled.img`
  margin: 20px auto;
  text-align: center;
  width: auto;
  height: 230px;
` : styled.img`
  margin-top: 20px;
  margin-bottom: 20px; 
  width: 90%; 
  height: 250px;
`

export const Banner = ({alt, src} : {alt: string, src: string}) => {
  return(
    <ImgDiv>
      <ImgBanner alt={alt} src={src}></ImgBanner>
    </ImgDiv>
  )
}

export const ResPic = isBrowser? styled.div`
  text-align: center;

  img {
    border: 15px solid; 
    border-color: #5A8E91; 
    width: 640px; 
    height: 480px;
  }
`: styled.div`
  text-align: center;

  img {
    border: 15px solid; 
    border-color: #5A8E91; 
    width: ${() => window.screen.width}px;
    height: 324px;
  }
`