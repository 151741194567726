import styled from 'styled-components'
import { isBrowser } from 'react-device-detect'

export const PageSection = isBrowser? styled.div`
  width: 500px; 
  height: 550px; 
  background-color: #adb8b3; 
  border: 1px solid #ccc;
  font: 12px/16px Arial, Helvetica, sans-serif;
  overflow: auto
`:styled.div`
  width: ${window.innerWidth * 0.89}px; 
  height: 318px; 
  background-color: #adb8b3; 
  border: 1px solid #ccc;
  font: 12px/16px Arial, Helvetica, sans-serif;
  overflow: auto
`

export const Page = isBrowser? styled.div`
  width: 500px;
  background-color: white;
  height: auto;
  &:nth-child(n+2) {
    margin-top: 20px;
  }
`:styled.div`
  width: ${window.innerWidth * 0.89}px;
  background-color: white;
  height: auto;
  &:nth-child(n+2){
    margin-top: 20px;
  }
`

export const SpanSpaced = styled.span`
  &: nth-child(n+2){
    margin-left: 20px;
  }
`

export const TableFGTS = styled.table`
margin: 35px 3px;
border: 1px solid black;
background-color: #FFF;
th, td, tr {
  border: 1px solid black;
  background-color: #FFF;   
}
td + td {
  border-left: 1px solid black;
}
.mark {
  background-color: #C0C0C0
}
`

export const Table = styled.table`
  border: 1px solid black;
  background-color: #FFF;
  th, td, tr {
    border: 1px solid black;
    background-color: #FFF;    
  }
  tr:last-child td {
    border: 0;
  }
  .mark {
    background-color: #C0C0C0
  }
  .titleID {
    text-align: center;
  }
`;

export const DisplayCheck = styled.div<{type: number}>`
  display:flex;
  flex-wrap: wrap;

  div {
    flex: 1 0 ${props=>props.type == 1?'50':'34'}%;
  }
`;

export const InputFixed = ({checked = false}) => {
  if(!checked){
    return <input type="checkbox" disabled={false}/>
  }
  return <input type="checkbox" disabled={false} checked={true}/>
}