import logoLecca from './imgdoc/logoLecca.png'
import logoBpo from '../../images-bpo/logo-ccb-3.png'
import Cedula from './imgdoc/Cedula.png'
import { PageSection, Page, TableFGTS, SpanSpaced } from './styles/docstyle.component'
import { IDocument } from '../../ts/interfaces/Components.interfaces'
import { Endereco, Telefone, PropostaFGTS } from '../../ts/types/Types'
import { getStateJornada } from '../../state/actions/jornada_actions'

const {REACT_APP_PERFIL, REACT_APP_NAME_LECCA_CAPS} = process.env

const CCBGenFGTS = ({Status} : IDocument) => {
  const { Cliente, Proposta, PropostaParcelas } = getStateJornada().contrato

  const proposta : PropostaFGTS = Proposta as PropostaFGTS;

  const getAdr = (arr : Endereco[], type : number) => {
    return arr.find(element => element.TipoEndereco == type)
  }

  const getTel = (arr : Telefone[], type : number) => {
    return arr.find(element => element.TipoTelefone == type)
  }

  const toStringRS = (value : number) => {
    if(Number.isInteger(value)){
      return 'R$ '+new Intl.NumberFormat('de-DE').format(value) + ',00'
    }
    return 'R$ '+new Intl.NumberFormat('de-DE').format(parseFloat(value.toFixed(2)))
  }

  const FormatData = (str : string) => {
    var sub = str.substring(0,str.lastIndexOf('T'))
    var arr = sub.split('-')
    arr.reverse()
    return arr.join('/')
  }

  const EstadoCivil = {
    Casado: 1,
    Solteiro: 2,
    Divorciado: 3,
    Viuvo: 4,
    Desquitado: 5,
    Companheiro: 6
  }

  const getEstadoCivil = (x: number) => {
    switch(x){
      case EstadoCivil.Casado:
        return 'Casado';
      case EstadoCivil.Solteiro:
        return 'Solteiro';
      case EstadoCivil.Divorciado:
        return 'Divorciado';
      case EstadoCivil.Viuvo:
        return 'Viuvo';
      case EstadoCivil.Desquitado:
        return 'Desquitado';
      case EstadoCivil.Companheiro:
        return 'Companheiro';
      default:
        break;
    }
    return 'Outros';
  }

  const endr = getAdr(Cliente.DadosCliente.Enderecos, 1)
  const celular = getTel(Cliente.DadosCliente.Telefones, 3)
  //const tel = getTel(Cliente.DadosCliente.Telefones, 1)
  //const CodigoEmpregador = Proposta.CodigoEmpregador

  /*var telstr = "Nao possui"
  if(tel){
    telstr = '('+tel.DDD+') '+tel.NumeroTelefone;
  }*/

  var celstr = "Nao possui";
  if(celular){
    celstr = "("+celular.DDD+") "+celular.NumeroTelefone;
  }/*
  var Orgao = "Nao possui"
  if(Proposta.NomeEmpregador){
    Orgao = Proposta.NomeEmpregador
  }*/

  let pgnum = 0;

  const Header = () => {
    return(
      <div>
        <img src={REACT_APP_PERFIL == 'BPO'? logoBpo: logoLecca} width={150} height={45} alt='logo-ccb'/>
        <img src={Cedula} style={{marginLeft: '125px'}} width={200} height={20} alt='cedula' />
      </div>
    );
  }

  const Footer = () => {
    pgnum+=1;
    return(
      <div>
        <p style={{textAlign: 'center'}}>
          Via Não Negociável: Cliente
        </p>
        <p style={{marginLeft:'8px', fontSize:'10px'}}>
          <span>Ouvidoria: 0800 709 9944 | SAC (11) 3075-9565 (WhatsApp)</span>
          <span style={{margin: '0px 10px'}}>Versão: JUR107/2022</span> 
          <span style={{margin: '0px 10px'}}>Página {pgnum} | 6</span>
        </p>
      </div>
    );
  }

  return(
    <PageSection onScroll={Status}>
      <Page>
        <Header />
          <TableFGTS>
            <thead>
              <tr>
                <td colSpan={2}><span style={{margin: '0px 15px'}}><b>EMPRÉSTIMO PESSOAL COM GARANTIA DE SAQUE ANIVERSÁRIO FGTS</b></span></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Cédula de Crédio Bancario: </b>{proposta.NumeroProposta}</td>
                <td><b>Data de Emissão: </b>{FormatData(proposta.DataCadastro)}</td>
              </tr>
              <tr>
                <td className='mark' colSpan={2}><b>Quadro I - CREDOR:</b></td>
              </tr>
              <tr>
                <td colSpan={2}><b>{REACT_APP_NAME_LECCA_CAPS} CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.</b>, instituição financeira devidamente constituída e
existente de acordo com as leis do Brasil, com sede na Cidade de São Paulo, Estado do São Paulo, na Rua José dos Reis 409, sala 51 5º andar, CEP 03.139-040 e inscrita no CNPJ/MF sob o nº 29.871.099/0001-89.</td>
              </tr>
              <tr>
                <td className='mark' colSpan={2}><b>Quadro II - QUALIFICAÇÃO DO EMITENTE:</b></td>
              </tr>
              <tr>
                <td><b>Nome: </b>{proposta.NomeCliente}</td>
                <td><b>Data de Nascimento: </b>{FormatData(Cliente.DadosCliente.DataNascimento)}</td>
              </tr>
              <tr>
                <td><b>RG nro.: </b>{Cliente.DadosCliente.Registro.Numero}</td>
                <td><b>Org. Exp.: </b>{Cliente.DadosCliente.Registro.LocalEmissao}</td>
              </tr>
              <tr>
                <td><b>Naturalidade: </b>{Cliente.DadosCliente.CidadeNatal}/{Cliente.DadosCliente.UFNatal}</td>
                <td><b>Estado Civil: </b>{getEstadoCivil(Cliente.DadosCliente.EstadoCivil)}</td>
              </tr>
              <tr>
                <td colSpan={2}><b>Endereço: </b>{endr?.Logradouro}</td>
              </tr>
              <tr>
                <td><b>Cidade: </b>{Cliente.DadosCliente.CidadeNatal}</td>
                <td><b>UF: </b>{Cliente.DadosCliente.UFNatal}</td>
              </tr>
              <tr>
                <td><b>Celular: </b>{celstr}</td>
                <td><b>Email: </b>{Cliente.DadosCliente.Email}</td>
              </tr>
              <tr>
                <td className='mark' colSpan={2}><b>Quadro III - ESPECIFICAÇÕES DO CRÉDITO:</b></td>
              </tr>
              <tr>
                <td><p><b>1. Valor Liberado:</b></p><p>{toStringRS(Proposta.ValorLiquidoCliente)}</p></td>
                <td><p><b>2. Tarifa de Cadastro:</b></p><p>{toStringRS(Proposta.ValorDespesas)}</p></td>
              </tr>
              <tr>
                <td><p><b>3. Taxa de Juros:</b></p><p>{Proposta.TaxaCLMes.toFixed(2)} % ao mês (30 dias) {Proposta.TaxaCLAno.toFixed(2)} % ao ano (360 dias) </p></td>
                <td><p><b>4. Valor Total do Empréstimo:</b></p><p>{toStringRS(Proposta.ValorFinanciado)}</p></td>
              </tr>
              <tr>
                <td><p><b>5. IOF:</b></p><p>{toStringRS(Proposta.ValorIOF)}</p></td>
                <td><p><b>6. Custo Efetivo Total - CET:</b></p><p>{Proposta.TaxaCETMes.toFixed(2)} % ao mês (30 dias) {Proposta.TaxaCETAno.toFixed(2)} % ao ano (360 dias)</p></td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p><b>7. Quantidade de Parcelas:</b> {Proposta.Prazo}</p>
                  <p><b>7.1. Vencimento das Parcelas:</b></p>
                  <table width='100%'>
                    <thead>
                      <tr>
                        <td><b>Numero Parcela</b></td>
                        <td><b>Data de Vencimento</b></td>
                        <td><b>Valor Parcela</b></td>
                      </tr>
                    </thead>
                    <tbody>
                      {PropostaParcelas?.map((x, i) => {
                        return (
                        <tr key={i}>
                          <td><b>{x.NumeroParcela}.</b></td>
                          <td>{FormatData(x.DataVencimento)}</td>
                          <td>{toStringRS(x.ValorParcela)}</td>
                        </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}><p><b>8. Dados Bancários para a Liberação do Crédito:</b></p>
                <p>
                  <SpanSpaced>Banco: {Proposta.Beneficiario.NumeroBanco}</SpanSpaced> 
                  <SpanSpaced>Agência: {Proposta.Beneficiario.NumeroAgencia} {Proposta.Beneficiario.NumeroDVAgencia? `/${Proposta.Beneficiario.NumeroDVAgencia}` : ''}</SpanSpaced> 
                  <SpanSpaced>Conta: {Proposta.Beneficiario.NumeroConta} / {Proposta.Beneficiario.NumeroDVConta}</SpanSpaced> 
                </p></td>
              </tr>
              <tr>
                <td colSpan={2}><p><b>9. Praça de Pagamento:</b>{' '}São Paulo/SP</p></td>
              </tr>
              <tr>
                <td colSpan={2}><b>10. Forma de Pagamento:</b>{' '}As parcelas serão pagas com os recursos debitados da conta vinculada do FGTS do
EMITENTE, conforme autorização realizada por este junto à Caixa Econômica Federal, ressalvada a hipótese
prevista no item 3.4 do Quadro VII</td>
              </tr>
              <tr>
                <td className='mark' colSpan={2}><b>Quadro IV - ENCARGOS MORATÓRIOS:</b></td>
              </tr>
              <tr>
                <td>11. Multa:</td>
                <td>12. Juros Moratórios:</td>
              </tr>
            </tbody>
          </TableFGTS>
        <Footer />
      </Page>
      <Page>
        <Header />
          <TableFGTS>
            <tbody>
            <tr>
              <td className='mark' colSpan={2}><b>Quadro V - GARANTIA</b></td>
            </tr>
            <tr>
              <td colSpan={2}><b>13.Cessão Fiduciária:</b>{' '}Para garantir o cumprimento do pagamento de todas as obrigações assumidas pelo
EMITENTE neste instrumento, este constitui em favor do CREDOR garantia de cessão fiduciária dos direitos aos
saques-aniversário anuais previstos no §3º do art. 20-D da Lei 8.036/90, conforme art. 66-B da Lei 4.728/65. O saldo
oferecido em garantia será bloqueado em sua conta vinculada do FGTS, cujo valor será suficiente para arcar
integralmente com as parcelas da CCB (“garantia”).</td>
            </tr>
            <tr>
              <td className='mark' colSpan={2}><b>Quadro VI - DADOS DO CORRESPONDENTE BANCÁRIO (“correspondente”)</b></td>
            </tr>
            <tr>
              <td><b>Nome: </b><p>{proposta.DadosPromotora.CodigoEDescricao}</p></td>
              <td><b>CPF/CNPJ: </b><p>{proposta.DadosPromotora.CNPJ? proposta.DadosPromotora.CNPJ : 'Não possui'}</p></td>
            </tr>
            <tr>
              <td colSpan={2}><b>Endereço: </b><p>{proposta.DadosPromotora.EnderecoComercial.Logradouro? `${proposta.DadosPromotora.EnderecoComercial.Logradouro} ${proposta.DadosPromotora.EnderecoComercial.Numero}`: 'Não possui.'}</p></td>
            </tr>
            <tr>
              <td><b>Celular: </b><p>{proposta.DadosPromotora.TelefoneCelular.NumeroTelefone? `(${proposta.DadosPromotora.TelefoneCelular.DDD}) ${proposta.DadosPromotora.TelefoneCelular.NumeroTelefone}`: 'Não possui.'}</p></td>
              <td><b>E-mail: </b><p>{proposta.DadosPromotora.Email.length > 0? proposta.DadosPromotora.Email[0] : 'Não possui.'}</p></td>
            </tr>
            <tr>
              <td className='mark' colSpan={2}><b>Quadro V - CONDIÇÕES GERAIS DA CÉDULA DE CRÉDITO BANCÁRIO</b></td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p><b>1. PROMESSA DE PAGAMENTO</b></p>
                <p>
                  1.1. O EMITENTE pagará por esta Cédula de Crédito Bancário (“CCB”) ao CREDOR, ou a sua ordem, na Praça de
                  Pagamento, dívida em dinheiro, certa, líquida e exigível, correspondente ao Valor Total do Empréstimo, acrescido dos
                  encargos e tributos descritos no Quadro III, nos respectivos vencimentos, observadas as condições desta CCB.
                </p>
                <p>
                  1.2. <u>Emissão da CCB</u>. O EMITENTE reconhece como válida, eficaz e vinculante, a presente CCB emitida de forma
                  eletrônica, assinada por meio digital, seja por dados biométricos e/ou aceite eletrônico em ambiente autenticado, nos
                  termos do parágrafo 2º do artigo 10 da Medida Provisória n.º 2.200/01.
                </p>
                <p>
                  1.3. O EMITENTE declara ter ciência que o desembolso dos recursos pelo CREDOR deste empréstimo está
                  condicionado à (i) entrega de todos os documentos pessoais exigidos de forma legível; (ii) realização de biometria
                  facial; (iii) efetivo bloqueio do saldo da conta vinculada do FGTS, suficiente para compor a garantia. Caso as
                  condições suspensivas aqui previstas não sejam cumpridas, esta CCB será considerada automaticamente cancelada
                  e o CREDOR estará desobrigado de todas e quaisquer obrigações ora assumidas.
                </p>
                <p><b>2. OPERAÇÃO DE CRÉDITO</b></p>
                <p>
                  2.1.Esta CCB representa operação de crédito pessoal com garantia em cessão fiduciária de saldo da conta vinculada
                  do FGTS do EMITENTE, e está sujeita aos termos aqui previstos, bem como pelos artigos 26 a 45 da Lei
                  10.931/2004 e demais normas aplicáveis (“empréstimo” ou “operação”).
                </p>
                <p>
                  2.1.1.O EMITENTE reconhece e concorda que o valor total devido ao CREDOR nos termos desta CCB equivale à
                  somatória do Valor Total do Empréstimo acrescido dos encargos, tarifas e tributos descritos no Quadro III incidentes
                  sobre a operação, bem como os encargos previstos no Quadro IV, em caso de inadimplemento.
                </p>
                <p><u>2.2. CET:</u></p>
                <p>
                  O custo efetivo total da operação está expresso no item 6 do Quadro III, na forma de taxa percentual. O EMITENTE
                  está ciente que para o cálculo do CET são considerados o valor total do empréstimo, o número de parcelas e a data
                  de pagamento, incluindo taxa de juros, tributos e tarifas.
                </p>
                <p>
                  2.3.O EMITENTE declara ter tomado ciência do CET previamente à contratação da operação, bem como os fluxos de
                  pagamento das parcelas.
                </p>
                <p>
                  <u>2.4. Tarifas e despesas</u>: EMITENTE declara ter ciência que arcará com tarifas e despesas, a saber: (i)
                </p>
              </td>
            </tr>
            </tbody>
          </TableFGTS>
        <Footer />
      </Page>
      <Page>
        <Header />
          <TableFGTS>
            <tbody>
            <tr>
            <td><p>Tarifa de Cadastro: Serviço de pesquisa em base de dados e informações cadastrais, bureaus de crédito, cartório,
            assim como tratamento de dados e informações necessárias para cadastro do EMITENTE e respectiva atualização;
            (ii) IOF: Imposto sobre operações financeiras, cujo cálculo segue o previsto na legislação em vigor; (iii) eventuais
            tarifas relativas a serviços que vierem a ser prestados pelo CREDOR, por solicitação do EMITENTE, após a
            assinatura da CCB.2.5.O EMITENTE se compromete a fornecer, a qualquer tempo, informações e documentos
            adicionais a fim de garantir a veracidade das informações cadastrais prestadas.</p>
            <p>3.GARANTIA E BLOQUEIO DE SALDO DO FGTS</p>
            <p>3.1.O EMITENTE tem ciência e concorda que os saldo bloqueado em virtude da garantia desta CCB, permanecerá
            na conta vinculada do FGTS de sua titularidade, e serão destinados única e exclusivamente para quitação das
            obrigações constituídas nesta CCB, não podendo, portanto, tal saldo ser movimentado</p>
            <p>3.2.O bloqueio da garantia prevalecerá até o integral cumprimento das obrigações previstas na CCB, incluindo
            principal e acessórios.</p>
            <p>3.3.O pagamento da CCB será efetuado anualmente, por meio da garantia, seguindo o calendário anual de
            pagamento da Caixa Econômica Federal do saque-aniversário, sendo transferido o valor bloqueado da garantia para
            o CREDOR.</p>
            <p>3.4.Caso, por qualquer motivo, o pagamento não seja realizado, o EMITENTE deverá efetuar o pagamento da(s)
            parcela(s) pendente(s), acrescido de eventuais encargos moratórios, por meio de cobrança a ser enviada pelo
            CREDOR, sem prejuízo de outras providências mencionadas nesta CCB.</p>
            <p>3.5.Na hipótese de alteração legal que ocasione a alteração de valores das faixas, alíquotas, e juros das parcelas das
            contas vinculadas do FGTS, ou na hipótese de diminuição do valor da garantia por qualquer motivo, o EMITENTE
            autoriza, de forma irrevogável e irretratável; (i) a caixa a realizar a elevação do valor bloqueado, caso exista saldo
            suficiente na conta vinculada do FGTS; e (ii) na eventualidade de insuficiência de saldo no ano-base por qualquer
            motivo, a ampliação dos prazos de vencimento da CCB e, consequentemente, da quantidade de saques-aniversário
            cedidos.</p>
            <p>4. ENCARGOS MANDATÓRIOS</p>
            <p>4.1. O atraso no pagamento de qualquer parcela do Empréstimo (i) implicará na constituição automática do
            EMITENTE em mora, independentemente de aviso ou notificação; (ii) sujeitará o EMITENTE aos encargos
            estabelecidos no Quadro IV; e (iii) poderá acarretar a inscrição do EMITENTE em órgãos de restrição ao crédito, bem
            como o protesto da presente CCB e vencimento antecipado da dívida.</p>
            <p>4.2. Sem prejuízo ao disposto no item anterior, o EMITENTE deverá ainda ressarcir ao CREDOR pelas despesas a
            que der causa em decorrência do atraso no pagamento das parcelas do empréstimo, incluindo, mas não se limitando
            a custas para a cobrança, despesas processuais e honorários advocatícios.
            </p>
            <p>4.3. O CREDOR poderá, nos termos do art. 368 do Código Civil, compensar quaisquer créditos que tenha ou venha a
            ter em face do EMITENTE, com quaisquer créditos que o EMITENTE, tenha ou venha a ter perante o CREDOR.</p>
            <p>4.4. <u>Vencimento antecipado:</u> Além das hipóteses previstas na CCB e nos artigos 333 e 1.425 do Código Civil, 
            o CREDOR considerará antecipadamente vencidas as obrigações desta CCB e exigível o</p>
            </td>
            </tr>
            </tbody>
          </TableFGTS>
        <Footer />
      </Page>
      <Page>
        <Header />
          <TableFGTS>
            <tbody>
            <tr>
            <td>
              <p>pagamento da dívida devidamente atualizada, independente de aviso, se ocorrer qualquer circunstância que afete 
                a garantia, ou nas seguintes hipóteses: (i) descumprimento de quaisquer obrigações 
                assumidas pelo EMITENTE na CCB, ou em qualquer outro contrato firmado com 
                o CREDOR e afiliadas; (ii) legítimo protesto de títulos ou ações judiciais em seu nome, 
                ou negativação de dívida que comprometa o pagamento da CCB; (iii) em caso de morte, prisão, 
                declaração de incapacidade ou interdição do EMITENTE; (iv) qualquer das declarações prestadas 
                pelo EMITENTE seja considerada falsa, incorreta, incompleta, desatualizada ou imprecisa; 
                ou (v) se houver perda da garantia total ou parcial, ou qualquer situação que prejudique 
                sua eficácia. </p>
              <p><b>5. CONDIÇÕES GERAIS</b></p>
              <p>5.1.	<u>Tolerância; Independência</u>: O não exercício, pelo CREDOR, de quaisquer direitos assegurados 
                nesta CCB ou na legislação vigente, incluindo, mas não se limitando, ao recebimento de qualquer 
                parcela em atraso sem cobrança de multas ou penalidades, não prejudicará o exercício posterior 
                dos mesmos direitos. Se uma ou mais disposições aqui contidas forem consideradas inválidas, 
                ilegais ou inexequíveis, em qualquer aspecto, a validade, legalidade e exequibilidade das 
                demais disposições aqui incluídas não serão afetadas ou prejudicadas, a qualquer título.</p>
              <p>5.2.	<u>Amortização e Liquidação Antecipada</u>: O EMITENTE poderá amortizar e/ou liquidar 
                antecipadamente o Valor Total do Empréstimo, de forma parcial ou integral, desde que 
                solicitado por meio dos canais disponíveis do CREDOR, ocasião em que lhe será fornecido 
                um desconto proporcional dos juros aplicados, considerando-se a taxa de desconto igual 
                a taxa de juros descrita no Quadro III.</p>
              <p>5.3.	<u>Arrependimento</u>: O EMITENTE poderá se arrepender da presente contratação mediante 
                solicitação realizada diretamente ao CREDOR, no prazo de até 07 (sete) dias a contar 
                da data da liberação do empréstimo. Esta hipótese estará condicionada a restituição 
                integral do Valor Total do Empréstimo pelo EMITENTE em até 48 (quarenta e oito) horas 
                da data da comunicação do arrependimento à CREDORA, após esse prazo, o direito de 
                arrependimento será considerado nulo e ineficaz.</p> 
              <p>5.4.	<u>Portabilidade</u>: O EMITENTE poderá realizar a portabilidade da operação 
                para outra instituição financeira, no entanto, deverá procurar a instituição escolhida 
                para formalizar da solicitação.</p>
              <p>5.5.	<u>Consentimento do uso de dados pessoais</u>: O EMITENTE declara expressamente 
                estar de acordo com o tratamento de seus dados pessoais para finalidade específica, 
                em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD), 
                qual seja: (i) análise de risco de crédito; (ii) cadastro na plataforma do CORRESPONDENTE; 
                (iii) elaboração desta CCB; (iv) realização de eventuais cobranças decorrentes do 
                crédito concedido ao EMITENTE.</p>
              <p>5.5.1.	O EMITENTE consente e concorda que o CREDOR e o CORRESPONDENTE tomem decisões 
                referentes ao tratamento de seus dados pessoais, bem como realize o tratamento de tais 
                dados.</p>
              <p>5.6.	Compartilhamento de Informações: O EMITENTE autoriza expressamente o CREDOR e o 
                CORRESPONDENTE, em caráter irrevogável e irretratável, a divulgar, trocar e compartilhar, 
                para fins de registro, todas as informações fornecidas para concessão do empréstimo, 
                bem como todas as obrigações assumidas pelo EMITENTE nesta CCB, às entidades de proteção 
                ao crédito (SPC, Serasa e outras em que o CREDOR seja cadastrado) e central de risco – SCR,
                assim como autoriza que seus dados sejam compartilhados com terceiros 
                nas seguintes hipóteses: (i) oferta de empréstimos e serviços pelo CREDOR; 
                (ii) para a proteção dos interesses do CREDOR, em caso de demandas judiciais 
                ou extrajudiciais; (iii) em caso de alterações societárias envolvendo o CREDOR </p>
            </td>
            </tr>
            </tbody>
          </TableFGTS>
        <Footer />
      </Page>
      <Page>
        <Header />
          <TableFGTS>
            <tbody>
            <tr>
            <td>
              <p>ou na hipótese de cessão da CCB, sendo, neste caso, a transferência das informações 
                necessárias para a continuidade da CCB; (iv) em caso de decisão judicial ou em virtude 
                de requerimento de autoridades administrativas que tenham competência legal ou 
                autorização para tal requisição.</p>
              <p>5.6.1.	O EMITENTE concorda que, como condição para a concessão do empréstimo, precisará 
                compartilhar diretamente com o CREDOR, CORRESPONDENTE ou consultor de vendas, algumas 
                informações e documentos pessoais, tais como: nome, e-mail, número de documento de 
                identidade e CPF, assim como cópia digitalizada, número de celular, data de nascimento, 
                endereço, sexo, estado civil, profissão, nome da mãe, nacionalidade, selfie (foto do rosto), 
                prova de vida (dados biométricos), dados financeiros como comprovante de renda;
                (“Dados Pessoais”).</p>
              <p>5.6.3.	O EMITENTE poderá exercer direitos sobre seus Dados Pessoais, entrando em contato 
                com a central de atendimento do CREDOR, e reconhece e concorda que seus Dados Pessoais 
                poderão ser armazenados mesmo após o vencimento da CCB, se esse armazenamento decorrer 
                de uma obrigação legal ou se for necessário para garantir o cumprimento das 
                obrigações ora assumidas.</p>
              <p>5.6.4.	O EMITENTE está ciente e concorda que seus Dados Pessoais e a sua biometria facial 
                serão tratados e usados pelo CREDOR e CORRESPONDENTE, para a identificação como medida 
                de prevenção a fraude.</p>
              <p>5.7.	<u>Acesso ao SCR</u>: O EMITENTE autoriza o CREDOR, a qualquer tempo: 
              a (i) efetuar consultas ao Sistema de Informações de Crédito – SCR – do 
              Banco Central do Brasil (“SCR”), nos termos da Resolução nº 3.658, do Conselho 
              Monetário Nacional, de 17.12.2008, conforme alterada e os serviços de proteção 
              ao crédito SPC, Serasa e outras em que o CREDOR seja cadastrado; 
              (ii) fornecer ao Banco Central do Brasil informações sobre esta CCB, 
              para integrar o SCR; (iii) proceder conforme disposições que advierem 
              de novas exigências feitas pelo Banco Central do Brasil ou autoridades 
              públicas competentes.</p>
              <p>5.8.	Cessão: O CREDOR poderá endossar, ceder ou transferir, no todo ou em parte, 
                os direitos, obrigações e garantias desta CCB, a seu exclusivo critério, 
                sem qualquer necessidade de comunicação prévia, bem como utilizar essa 
                CCB na captação de recursos financeiros, conforme estabelece as normas 
                vigentes do Banco Central do Brasil. Em caso de endosso, cessão ou 
                transferência desta CCB, eventual cessionário ou endossatário será responsável 
                pelo atendimento ao EMITENTE, e se assumirá a condição de CREDOR para 
                todos os fins de direito.</p>
              <p>5.9.	Responsabilidade Socioambiental e Coibição ao Trabalho Escravo: 
                O EMITENTE deverá respeitar a legislação e regulamentação ambiental 
                e trabalhista em vigor, especialmente em normas relativas à saúde e 
                segurança ocupacional e coibir todo e qualquer trabalho análogo ao escravo 
                e infantil, devendo informar ao CREDOR quando da ocorrência de qualquer ato 
                ou omissão que possa levar aos órgãos públicos ou autoridades competentes 
                a considerar descumprida as normas aqui mencionadas.</p>
              <p>5.10. <u>Vigência</u>: A presente CCB entrará em vigor a partir de sua emissão, 
                observado o disposto no item 1.3, e permanecerá em pleno vigor e efeito 
                até a data em que o EMITENTE tiver cumprido integralmente as obrigações 
                assumidas nesta CCB. </p>
            </td>
            </tr>
            </tbody>
          </TableFGTS>
        <Footer />
      </Page>
      <Page>
        <Header />
          <TableFGTS>
            <tbody>
            <tr>
            <td>
              <p>5.11. <u>Título Executivo</u>: O EMITENTE declara estar ciente de que a presente CCB 
                consiste em título executivo extrajudicial e representativo de dívida em dinheiro, 
                certa, líquida e exigível, seja pela soma indicada, seja pelo saldo devedor 
                demonstrado em planilha de cálculo emitida pelo CREDOR, nos termos do artigo 28, 
                caput, da Lei 10.931/2004, cuja apresentação pelo CREDOR será suficiente para 
                a exigibilidade de seu crédito. Sendo a via negociável do CREDOR e 
                a não negociável do EMITENTE.</p>
              <p>5.12. <u>Foro</u>: Fica eleito o foro da Comarca da Capital de São Paulo, 
                Estado de São Paulo, como competente para dirimir todas as questões decorrentes 
                desta CCB.</p>
              <p>E por estarem justas e acordadas, o EMITENTE emite a presente CCB em caráter irrevogável e 
                irretratável,  obrigando o EMITENTE, CREDOR e seus eventuais sucessores a qualquer título.</p>
              <hr style={{width: '250px', margin: 'auto'}} />
              <p style={{textAlign: 'center'}}><b>EMITENTE</b></p>
              <p style={{textAlign: 'center'}}>{proposta.NomeCliente}</p>
            </td>
            </tr>
            </tbody>
          </TableFGTS>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Footer />
      </Page>
    </PageSection>
  );
}

export default CCBGenFGTS
