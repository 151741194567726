import axios from "axios";
import { Base64Type } from "../ts/types/Types";
import { getStateJornada } from "../state/actions/jornada_actions";

const webs = process.env.REACT_APP_SERVER

export const IsFileUpperBound = (file : File) : boolean => {
  let s = (file.size/1024)/1024
  if(s >= 4){
    return true
  }
  return false;
}

export const getExtension = ( base64 : Base64Type ) : string => {
  if(base64){
    const value = base64.toString();
    const[,type] = value.split(';')[0].split('/')
    return type;
  }
  return ""
}

export const IsValidExtension = ( file : File ) : boolean => {
  const ext = file.type.split('/')[1]
  const validext = ['jpg','png','bmp','gif','jpeg', 'pdf']
  return validext.includes(ext)
}

export const FiletoBase64 = (file : File) : Promise<Base64Type> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const blobToBase64 = (blob : Blob) : Promise<Base64Type> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const UpdateLogMessage = (...obj: any) => {
	const { id } = getStateJornada();
	try{
		axios.post(`${webs}/update-message`, {id: id, error: obj})
	}catch(e){}
}

export const LogMessage = (id: string, ...obj: any) => {
	try{
		axios.post(`${webs}/update-message`, {id: id, error: obj})
	}catch(e){}
}