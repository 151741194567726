import React from 'react'
import '../App.css';
import FadeIn from 'react-fade-in';
import {tela_fluxo} from '../telas_id';
import {Label} from 'reactstrap'
import TermoGen from '../components/doc/termogen.component'
import {FormBox, Seguir, CheckedBox} from './styles/estilo_telas'
import {status, situacao} from '../components/Info'
import Loading from '../components/Loading'
import './styles/estilo.css'

import { PropAccept } from '../ts/interfaces/PropTelas.interfaces';
import { addInfo, updateInfoBPO } from '../state/actions/Info_actions';
import { getStateJornada } from '../state/actions/jornada_actions';

interface State {
  unlock: boolean,
  checklock: boolean
}

class TermosScreen extends React.Component<PropAccept, State> {
  constructor(props : PropAccept){
    super(props)
    this.Aceitar = this.Aceitar.bind(this);
    this.Rejeitar = this.Rejeitar.bind(this);
    this.Status = this.Status.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      unlock: true,
      checklock: false
    }
  }

  componentDidMount(){
    updateInfoBPO({fase: status.tela_termo});
    addInfo(tela_fluxo.termo_screen)
  }

  Aceitar() {
    updateInfoBPO({fase: status.termo_aceito, situacao: situacao.sucesso});
    this.props.changePasso()
  }

  Rejeitar() {
    const {id} = getStateJornada();
    updateInfoBPO({fase: status.termo_rejeitado, situacao: situacao.sucesso});
    this.props.history.push('/rejeitar/'+id)
  }

  handleChange = (e : React.ChangeEvent<any>) => {
    const{checklock} = this.state;
    if(e.target.checked && !checklock){
      this.props.ShowAlert('É necessário ler o documento antes de assinar', 'warning')
    }
    if(!checklock){
      e.target.checked = false
    }
    this.setState({unlock: e.target.checked && checklock})
  }

  Status(e : React.ChangeEvent<any>) {
    if(e.target.scrollTop >= (30 * e.target.scrollWidth)){
      this.setState({checklock: true})
    }
  }

  render() {
    const {unlock} = this.state
    const message = 'Li e aceito os termos e condições'
    const {enviado} = this.props;
    
    if(!enviado){
      return(
        <div style={{marginTop: 20}}>
          <Loading text={'Carregando...'}/>
        </div>
      );
    }
    
    return(
      <FadeIn delay={150}>
        <FormBox>
            <h5>Termo de Fornecimento do Cartão</h5>
            <div className='termo'>
              <TermoGen Status={this.Status} />
            </div>
            <CheckedBox onChange={this.handleChange}/>{' '}<Label check>{message}</Label>
            <Seguir unlock={unlock} onClick={this.Aceitar} />
        </FormBox>
      </FadeIn>
    );
  }
}

export default TermosScreen;
