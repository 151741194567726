import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap';
import FadeIn from 'react-fade-in'
import Header from '../components/header'
import error from '../images/error.png'
import { isBrowser } from 'react-device-detect'
import axios from 'axios';

import { IPropsId } from '../ts/interfaces/Props.interfaces'

const {REACT_APP_SERVER, REACT_APP_NAME_LECCA} = process.env

const webs = REACT_APP_SERVER

interface State {
  title: string,
  message: string
}

export default class TelaRejeicao extends React.Component<IPropsId, State> {
  constructor(props : IPropsId){
    super(props)
    this.state = {
      title: 'Seu contrato foi rejeitado',
      message: 'Seu contrato foi rejeitado, caso queira realizar um novo realize o cadastro novamente'
    }
  }

  componentDidMount(){
    axios.get<{success: boolean, message: string}>(webs+'/reject?',
      {
        params: {
          id: this.props.match.params.id
        }
    }).then(res =>{
      if(res.data.success == false){
        this.setState({title:'Erro ao rejeitar contrato', message: res.data.message});    
      }
    }).catch(e => {
      this.setState({title:'Erro ao rejeitar contrato', message: 'Não foi possivel realizar a solicitação de rejeição'})
    });
  }

  render(){
    const {title, message} = this.state;
    
    if(isBrowser){
      return(
        <div>
            <FadeIn delay={150}>
                <Header/>


                <div className='card-style'>
                  <Card
                    body
                    className="text-center">
                      <img src={error} width='110px' height='105px' style={{margin: '20px auto'}} alt='rejeicao'/>
                      <CardTitle tag="h5">
                        {title}
                      </CardTitle>
                      <CardBody>
                        <p><em>{message}</em></p>

                        <p><em>Time {REACT_APP_NAME_LECCA}</em></p>
                      </CardBody>
                  </Card>
                </div>
            </FadeIn>
      </div>
      );
    }else{
      return(
        <div>
          <FadeIn delay={150}>
              <Header/>
               <div className='card-style-mobile'>
                 <Card
                   body
                   className="text-center">
                     <img src={error} width='90px' height='80px' style={{margin: '20px auto'}} alt='rejeicao'/>
                     <CardTitle tag="h5">
                        {title}
                     </CardTitle>
                     <CardBody>

                       <p><em>{message}</em></p>
                        
                       <p><em>Time {REACT_APP_NAME_LECCA}</em></p>
                     </CardBody>
                 </Card>
               </div>
            </FadeIn>
      </div>
      );
    }
  } 
}