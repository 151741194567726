import React from 'react'
import './App.css';
import Loading from './components/Loading'
import Fluxo from './fluxos/main.fluxo'
import Login from './routes/Login'
import axios from 'axios';
import { IPropsId } from './ts/interfaces/Props.interfaces';
import { ValidationReturn } from './ts/types/Types';
import FluxoPersonalizado from './fluxos/main_personalizado.fluxo';
import FluxoFGTS from './fluxos/fgts.fluxo';
import { getStateJornada, setStateJornada } from './state/actions/jornada_actions';
import { updateInfoBPO, situacao, getBrowserInfo, getOSInfo } from './state/actions/Info_actions';
import { LogMessage } from './util/Utils';


const {REACT_APP_SERVER} = process.env;

const webs = REACT_APP_SERVER

interface State {
  carregar: boolean,
  login: boolean
}

class App extends React.Component<IPropsId, State> {

  constructor(props: IPropsId){
    super(props)
    this.ValidateId = this.ValidateId.bind(this);
  }

  state = {
    carregar: true,
    login: false
  }

  componentDidMount(){
    this.ValidateId(this.props.match.params.id)
  }

  async ValidateId(id : string){
    try{
      const resp = await axios.post<ValidationReturn>(webs+'/check-id?', {id: id, navegador: getBrowserInfo(), so: getOSInfo()})
      let evalue = resp.data;
      //console.log(evalue);
      if(evalue.success && evalue.situacao == 1){
        setStateJornada({id: id, tipo: evalue.tipojornada, nome: evalue.nome, idJornada: evalue.idjornada, ultimaJornada: evalue.ultimaJornada, fluxo: evalue.fluxo, contrato: evalue.contrato[0]});
        
        updateInfoBPO({idJornada: evalue.idjornada, situacao: situacao.sucesso});
        //this.setState({valid:{...evalue, idJornada: evalue.idjornada, id: id, contrato: evalue.contrato[0]}})
        //this.UpdateInfoBPO({idJornada: evalue.idjornada, situacao: situacao.sucesso});
      }else{

        updateInfoBPO({idJornada: evalue.idjornada, situacao: situacao.falha, mensagemErro: 'ID Inválido'})
        //this.setState({valid: {...evalue, tipojornada: 0, id: '', ultimaJornada: null, idJornada: '', contrato: null}});
        //this.UpdateInfoBPO({idJornada: evalue.idjornada, situacao: situacao.falha, mensagemErro: 'ID Inválido'});
      }
    } catch(error) {
      LogMessage(id, error);
      console.log(error);
      this.props.history.push('/erro/inesperado')
    }
    
    this.setState({carregar: false});
  }

  render() {
    const { carregar, login } = this.state;
    let jornada = getStateJornada();

    if(carregar){
      return(
        <Loading />
      );
    }else if(!jornada.id){
      this.props.history.push('/error/'+this.props.match.params.id)
    }
    
    let urlparams = window.location.href.split('/').slice(-2);

    var tipo = urlparams[0] == 'auth' && jornada.tipo == 2? 2 
    : urlparams[0] == "prova-vida" && jornada.tipo == 3? 3 
    : urlparams[0] == "jornada-personalizada" && jornada.tipo == 4? 4
    : urlparams[0] == "doc-fgts" && jornada.tipo == 5? 5
    : 1;

    setStateJornada({ tipo });

    if((!carregar)&&(!login)){
      return(
        <Login setLogin={(value : boolean)=> this.setState({login: value})} history={this.props.history}/>
      );
    }

    const PropsFluxo = {
      history: this.props.history
    }

    if(tipo == 4){
      return (
        <div className='App'>
          <FluxoPersonalizado {...PropsFluxo} />
        </div>
      );
    }

    if(tipo == 5){
      return(
        <div className='App'>
          <FluxoFGTS {...PropsFluxo} />
        </div>
      );
    }
    
    return(
      <div className="App">
        <Fluxo {...PropsFluxo} />
      </div>
    );
    
  }
}

export default App;
