import styled from 'styled-components'
import Webcam from 'react-webcam'
import { isBrowser } from 'react-device-detect'
import photo from '../images/screenshot.png'


const CameraDiv = isBrowser? styled.div`
  text-align: center;  

  div {
    text-align: center;
    margin-top: 35px;
  }

  video {
    margin: 0 auto; 
    border: 15px solid; 
    border-color: #5A8E91
  }

`:styled.div`
  div {
    text-align: center;
    margin-top: 20px;
  }

  video {
    border: 15px solid; 
    border-color: #5A8E91
  }
`

interface ICam {
  webcam: React.RefObject<Webcam>
  videocons: boolean | MediaTrackConstraints | undefined,
  onClick: React.MouseEventHandler<HTMLImageElement>
}

export const Camera = ({ webcam, videocons, onClick } : ICam) => {
  const Cam = isBrowser?(
    <Webcam
      ref={webcam}
      audio={false}
      height={480}
      width={640}
      videoConstraints={videocons}
      forceScreenshotSourceSize={true}
      screenshotFormat='image/png'
    />
  ):(
    <Webcam
      ref={webcam}
      audio={false}
      width={window.screen.width}
      height={window.screen.height/2}
      screenshotQuality={1}
      imageSmoothing={true}
      forceScreenshotSourceSize={true}
      videoConstraints={{width: window.screen.width, height: window.screen.height/2, facingMode: 'user'}}
      screenshotFormat='image/png'
    />
  )
  
  return(
    <CameraDiv> 
      {Cam}
      <div><img src={photo} alt='Take Pic' onClick={onClick} height={50}/></div>
    </CameraDiv>
  );
  
}

export default Camera;