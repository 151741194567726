
import { Checkbox, CheckboxProps } from '@mui/material';
import { useField } from '@unform/core';
import React, { useEffect, useState } from 'react';

type InputCheckBox = CheckboxProps & {
	name: string,
	defaultChecked?: boolean
}

export const CheckBoxField : React.FC<InputCheckBox> = ({ name, defaultChecked, ...rest }) => {
	const { fieldName, registerField, defaultValue } = useField(name);

	const [value, setValue] = useState(defaultValue || defaultChecked);

	useEffect(()=>{
		registerField({
			name : fieldName,
			getValue : () => value,
			setValue: (_, newValue) => setValue(newValue)
		});
	},[registerField, fieldName, value])

	return(
    <Checkbox name={name} defaultChecked={defaultChecked} {...rest}/>
	)
} 

export default CheckBoxField;