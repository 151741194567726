import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import TelaAgradecimento from './routes/TelaAgradecimento'
import Cadastro from './routes/Cadastro'
import TelaErro from './routes/TelaError'
import TelaRejeicao from './routes/TelaRejeicao'
import TelaErroLogin from './routes/TelaErrorLogin'
import TelaErroInesperado from './routes/TelaErroInesperado'
import Manager from './manager/manager';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { persistor, store } from './state/store';
import { Provider } from 'react-redux';
//import ValidacaoRefin from './telas/refin_validation.component';
import CCBGenEmprestimo from './components/doc/ccbgenemprestimo.component';
import { Resumo } from './telas';
//import ResidenciaNovo from './telas/new_comprovante.component';
// import Refin from './telas/refin.component';
// import { CCBScreen } from './telas';
//import TermoGen from './components/doc/termogen.component'
//import CCBGenFGTS from './components/doc/ccbgenfgts.component'
//<Route path='/termogentest' component={CCBGenFGTS}/>


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route path='/' exact={true} component={Cadastro} />
          <Route path='/doc/:id' component={App} />
          <Route path='/error/:id' component={TelaErro} />
          <Route path='/erro/login' component={TelaErroLogin}/>
          <Route path='/agradecimento/:tipo' component={TelaAgradecimento}/>
          <Route path='/cadastro' component={Cadastro}/>
          <Route path='/rejeitar/:id' component={TelaRejeicao} />
          <Route path='/erro/inesperado' component={TelaErroInesperado} />
          <Route path='/auth/:id' component={App} />
          <Route path='/prova-vida/:id' component={App}/>
          <Route path='/jornada-personalizada/:id' component={App}/>
          <Route path='/doc-fgts/:id' component={App}/>
          <Route path='/manager' component={Manager}/>
          <Route path='/manager/:rota' component={Manager}/>
          <Route path='/teste' component={CCBGenEmprestimo}/>
          <Route path='/resumo' component={Resumo}/>
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
