import { Icon, Button, ButtonProps, styled, useTheme, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useDrawerContext } from '../context/MenuContext';

const ButtonPropMenu = styled(Button)<ButtonProps>(({ theme }) => ({
  marginLeft: -10,
  borderColor: theme.palette.getContrastText(grey[300]),
  color: theme.palette.getContrastText(grey[300]),
  '&:click': {
    borderColor:theme.palette.getContrastText(grey[700]),
    color: theme.palette.getContrastText(grey[700]),
  },
  '&:hover': {
    borderColor:theme.palette.getContrastText(grey[200])
  }
}))

export const ButtonMenu = () => {
	const theme = useTheme();
	const smDawn = useMediaQuery(theme.breakpoints.down('sm'));//breakpoint para telas mobile
	const { toggleDrawerOpen } = useDrawerContext();
	if(smDawn){
		return (
			<ButtonPropMenu variant='outlined' onClick={toggleDrawerOpen}><Icon>menu</Icon></ButtonPropMenu>
		);
	}
	return <></>
}

export default ButtonMenu;