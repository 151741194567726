import React from 'react'
import FormLogin from '../forms/FormLogin'
import FadeIn from 'react-fade-in';
import { Card, CardTitle } from 'reactstrap';
import Header from '../components/header'
import AlertError from './alerts/alert_error'
import '../App.css';
import Loading from '../components/Loading'
import axios from 'axios';
import { LoginForm } from './styles/login-style'
import { updateInfoBPO, status, situacao } from '../state/actions/Info_actions';
import { getStateJornada } from '../state/actions/jornada_actions';

const { REACT_APP_API_LOCATION, REACT_APP_SERVER, REAC } = process.env;

const api_location = REACT_APP_API_LOCATION
const webs = REACT_APP_SERVER

const detectAdBlock = async (proposta: number) => {
  try {
    let bl = await axios(`${api_location}`)
    const res = bl.data

    if(res.ip){
      return "";
    }
    
    await axios(`${webs}/update-error-logs`, {params: {proposta, message: `Erro na API de geolocalização: IP não localizado ${JSON.stringify(res)}`}})
    return `IP não localizado ${JSON.stringify(res)}`;
  }catch(e){
    await axios(`${webs}/update-error-logs`, {params: {proposta, message: `Erro na API de geolocalização: Exceção gerada ${e}`}})
    return `${e}`;
  }
}

const removeMascara = (campo : string) => {
  var formata = campo.trim();
  formata = formata.replaceAll(/[.|-]/g, '');
  return formata;
}

const formatData = (data : string) => {
  if(!data) {
    return ''
  }
  var formata = data.split('-');
  return `${formata[2]}/${formata[1]}/${formata[0]}`
}

interface ILogin {
  setLogin: (v: boolean) => void,
  history: any
}

interface State {
  title: string,
  message: string,
  display: boolean,
  loading: boolean
}

export default class Login extends React.Component<ILogin, State> {
  constructor(props : ILogin){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.ShowAlert = this.ShowAlert.bind(this)
    this.state = {
      title: 'Vamos começar validando alguns dados, ok?',
      message: 'Não foi possível encontrar sua localização, tente novamente mais tarde.',
      display: false,
      loading: false
    }
    updateInfoBPO({fase: status.tela_autenticacao, situacao: situacao.sucesso});
  }

  handleSubmit = (cpf : string, datanascimento: string) => {
    const {tipo, contrato} = getStateJornada();
    cpf = removeMascara(cpf)
    var cpfjson = removeMascara(contrato.Cliente.DadosCliente.CPF)
    var data = contrato.Cliente.DadosCliente.DataNascimento.substring(0, 10);
    const proposta = contrato.Proposta.NumeroProposta;
    this.setState({loading: true})
    if(tipo == 4){
      if(cpf == cpfjson && datanascimento == data){
        this.setState({loading: false})
        this.props.setLogin(true)
        updateInfoBPO({fase: status.autenticacao_efetuada,situacao: situacao.sucesso});
      }else {
        let mensagem = 'Erro de autenticacao '
        if(cpf != cpfjson){
          mensagem += `CPF Invalido: Esperado ${cpfjson}, no entanto foi digitado ${cpf}`;
        }else if(!datanascimento){
          mensagem += `Data de Nascimento Invalida: Dia, Mês ou Ano não foi digitado`
        } else {
          mensagem += `Data de Nascimento Invalida: Esperado ${formatData(data)}, no entanto foi digitado ${formatData(datanascimento)}`;
        }
        updateInfoBPO({fase: status.autenticacao_efetuada,situacao: situacao.falha, mensagemErro: mensagem});
        this.props.history.push('/erro/login')
      }
    }else{
      detectAdBlock(proposta)
       .then(result => {
        if(result){
          updateInfoBPO({fase: status.autenticacao_efetuada,situacao: situacao.falha, mensagemErro: `Erro ao consultar localização: ${result}`});
          this.setState({loading: false})
          this.props.setLogin(false)
          this.ShowAlert()
        } else if(cpf == cpfjson && datanascimento == data){
          this.setState({loading: false})
          this.props.setLogin(true)
          updateInfoBPO({fase: status.autenticacao_efetuada,situacao: situacao.sucesso});
        }else {
          let mensagem = 'Erro de autenticacao '
          if(cpf != cpfjson){
            mensagem += `CPF Invalido: Esperado ${cpfjson}, no entanto foi digitado ${cpf}`;
          }else if(!datanascimento){
            mensagem += `Data de Nascimento Invalida: Dia, Mês ou Ano não foi digitado`
          } else {
            mensagem += `Data de Nascimento Invalida: Esperado ${formatData(data)}, no entanto foi digitado ${formatData(datanascimento)}`;
          }
          updateInfoBPO({fase: status.autenticacao_efetuada,situacao: situacao.falha, mensagemErro: mensagem});
          this.props.history.push('/erro/login')
        }
      }).catch(e => {
        updateInfoBPO({fase: status.autenticacao_efetuada, situacao: situacao.falha, mensagemErro: `Erro na API de geolocalização: exceção gerada ${e}`});
        this.setState({loading: false});
        this.props.setLogin(false);
        this.ShowAlert();
      })
    }
  }

  ShowAlert() {
    this.setState({ display: true}, () => {
      window.setTimeout(() => {
        this.setState({display: false})
      }, 2000)
    })
  }

  render(){
    const {title, loading, message, display} = this.state;

    if(loading){
      return(
        <Loading />
      )
    }

    return(
      <LoginForm>
        <AlertError message={message} visible={display}/>
        <FadeIn>
          <Header/>
          <Card>
            <CardTitle tag='h5' className='text-center'>{title}</CardTitle>
            <FormLogin handleSubmit={this.handleSubmit}/>
          </Card>
        </FadeIn>
      </LoginForm>
    );
  }
}