import React, { useEffect, MouseEventHandler } from 'react'
import styled from 'styled-components'
import { isBrowser } from 'react-device-detect'
import {Button} from 'reactstrap'
import { Input } from 'reactstrap'

const {REACT_APP_PERFIL, REACT_APP_BUTTON_COLOR, REACT_APP_FONT_COLOR} = process.env
export const videocons = { //somente para desktop
  width: 840,
  height: 840,
  facingMode: 'user'
}

const ImgDiv2 = isBrowser? styled.div`
  margin: 20px auto; 
  text-align: center; 
` : styled.div`
  margin: 20px auto;
  text-align: center;
`

const ImgBanner2 = isBrowser? styled.img`
  width: 260px;
  height: 260px;
` : styled.img` 
  width: 70%; 
  height: auto;
`

const ImgDiv = isBrowser? styled.div`
  margin: 20px auto; 
  text-align: center; 
` : styled.div``

const ImgBanner = isBrowser? styled.img`
  width: 360px;
  height: 230px;
` : styled.img`
  margin-top: 20px; 
  margin-bottom: 20px; 
  width: 100%; 
  height: 220px;
`

export const CheckedBox = ({onChange} : {onChange: React.ChangeEventHandler<HTMLInputElement>}) =>{
  useEffect(() => {
    let element = document.getElementById('assign') as HTMLInputElement
    if(element){
      element.checked = true;
    }
  }, []);

  return(<Input id='assign' type='checkbox' onChange={onChange}/>);
}

export const Banner = ({alt, src} : {alt: string, src: string}) => {
  if(alt == 'Selfie' || alt == 'ProvaVida'){
    return(
      <ImgDiv2>
        <ImgBanner2 alt={alt} src={src}></ImgBanner2>
      </ImgDiv2>
    )
  }
  return(
    <ImgDiv>
      <ImgBanner alt={alt} src={src}></ImgBanner>
    </ImgDiv>
  )
}

const PicDiv = isBrowser? styled.div`
  text-align: center;
  margin-top: 35px;
`:styled.div`
  text-align: center; 
  margin-top: 20px;
`

export const PicButton = ({src, onClick} : {src: string, onClick: MouseEventHandler<HTMLImageElement>}) => {
  return(
    <PicDiv>
      <img src={src} alt='Take Pic' onClick={onClick} height={50}/>
    </PicDiv>
  )
}

export const ButtonsInline = isBrowser? styled.div`
  margin: 20px auto 0px;

  button:first-child {
    width: 375px;
  }

  button {
    width: 375px; 
    margin-left: 4px;
  }

  button: nth-child(3){
    display: block;
    margin: 10px auto 0px;
    width: 760px;
  }
` : styled.div`
  margin: 20px auto;

  button:first-child {
    width: 48%;
  }

  button {
    width: 48%; 
    margin-left: 5px;
  }

  button: nth-child(3){
    margin-top: 10px;
    width: 100%;
  }
`

export const ButtonsInlineCard = styled.div`
  text-align: center;
  display: inline;

  button:first-child {
    width: 48%;
  }

  button {
    width: 48%; 
    margin-left: 3px;
  }
`

export const Command = isBrowser? styled.div`
  text-align: center;
  color: ${REACT_APP_FONT_COLOR};
  font-size: 25px;
`:styled.div`
  text-align: center;
  color: ${REACT_APP_FONT_COLOR};
  font-size: 20px;
` 

export const BoxInfo = isBrowser? styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
  margin: 50px auto 0px;
` : styled.div`
  margin-top: 50px;
  background-color: #f2f2f2;
  width: '100%';
  font-size: '16px';
  padding: 20px;
`

export const FormBox = isBrowser? styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
  margin: 35px auto 20px;

  h5 {
    text-align: center;
  }
  
  .termo {
    margin: 15px 123px;
  }
  
`: styled.div`
  background-color: #f2f2f2;
  width: '100%';
  font-size: '16px';
  padding: 30px;
  height: auto;
  margin: 35px auto 20px;
  text-size-adjust: auto;

  h5 {
    text-align: 'center';
  }

  .termo {
    text-align: center;
    margin-bottom: 10px;
  }

  input[type=checkbox] {
    text-align: left;
  }
`

export const CardInfo = isBrowser? styled.div`
  padding: 20px;
  width: 50%;
  margin: 0 auto;
` : styled.div`
  padding: 20px;
  margin: 0 auto;
  max-width: 'device-width';
`

interface PropsDiv {
  width: string | null
}

const SeguirDiv = isBrowser? styled.div.attrs((props: PropsDiv) => ({
  width: props.width
}))<PropsDiv>`
  text-align: center;

  button { 
    width: ${props=>props.width?props.width:'100%'};
  }
`:styled.div`
  text-align: center;

  button { 
    width: 100%;
  }
  `
;

interface SeguirType {
  unlock: boolean,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  width?: string | null
}

interface SeguirSubType {
  unlock: boolean,
  width?: string | null
}


export const Seguir = ({unlock, onClick, width} : SeguirType) => {
  if(!unlock){
    return(
      <SeguirDiv width={width}>
        <Button color='secondary'>Seguir</Button>
      </SeguirDiv>
    );
  }
    return(
      <SeguirDiv width={width}>
        <Button id={REACT_APP_PERFIL == 'BPO'?'button-bpo': 'button-purple'} onClick={onClick}>Seguir</Button>
      </SeguirDiv>
    )
}

export const SeguirSub = ({unlock, width} : SeguirSubType) => {
  if(!unlock){
    return(
      <SeguirDiv width={width}>
        <Button disabled={true} color='secondary'>Seguir</Button>
      </SeguirDiv>
    );
  }
  return(
    <SeguirDiv width={width}>
      <Button type='submit' id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'}>Seguir</Button>
    </SeguirDiv>
  );
}

export const OpcoesBtn = styled.span`
  display: inline;

  button:nth-child(2) {
    margin-left: 2px;
  }
`

export const ResPic = isBrowser? styled.div`
  text-align: center;

  img { 
    border: 15px solid; 
    border-color: ${REACT_APP_BUTTON_COLOR}; 
    border-radius: 5px; 
    width: 420px; 
    height: 420px;
  }
`: styled.div`
  text-align: center;

  img {
    border: 15px solid; 
    border-radius: 5px;
    border-color: ${REACT_APP_BUTTON_COLOR}; 
    width: ${() => window.screen.width}px;
    height: 324px;
  }
`
