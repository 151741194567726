import Webcam from 'react-webcam';
import mascara from '../images/mask.png'

interface IMobile {
  webcam: React.RefObject<Webcam>,
  facing: string,
  onUserMediaError: ((error: string | DOMException) => void) | undefined
}

export default function WebCamMobile({ webcam, facing = 'user', onUserMediaError = undefined } : IMobile){
  const width = window.innerWidth - 35;
  const height = 648/2;

  if(facing == 'back'){
    return(
      <div style={{width: width + 30, height: height + 30, border: '15px solid', borderColor: '#5A8E91', borderRadius: '5px'}}>
        <Webcam
          className='webcam-mobile'
          ref={webcam}
          audio={false}
          width={width}
          onUserMediaError={onUserMediaError}
          height={height}
          screenshotQuality={1}
          imageSmoothing={true}
          forceScreenshotSourceSize={true}
          videoConstraints={{width: 840, height: 840, facingMode: { exact: 'environment' } }}
          screenshotFormat='image/png'
        />
      </div>
    );
  }else{
    return(
      <div>
      <img style={{position: 'absolute'}} src={mascara} width={width + 30} height='350px' alt='WebCamMascara' />
        <div style={{width: width + 30, height: height + 30, border: '15px solid', borderColor: '#5A8E91', borderRadius: '5px'}}>
          <Webcam
            className='webcam-mobile'
            ref={webcam}
            audio={false}
            onUserMediaError={onUserMediaError}
            width={width}
            height={height}
            screenshotQuality={1}
            imageSmoothing={true}
            forceScreenshotSourceSize={true}
            videoConstraints={{width: width, height: height, facingMode: facing}}
            screenshotFormat='image/png'
          />
        </div>
      </div>
    );
  }
}