import { useEffect } from 'react'
import styled from 'styled-components'
import PDFDoc from '../../components/PDFDoc'
import { isBrowser } from 'react-device-detect'
import { Input } from 'reactstrap'

const DocPDF = isBrowser? styled.div`
  margin: 0px auto 20px; 
  width: 500px; 
  height: 500px;
`:styled.div`
  margin: 0px auto 10px;
  width: 100%;
  height: 470px;
`

export const Termos = styled.div`
  text-align: center; 
  margin-bottom: 20;
`

export const Assinatura = isBrowser? styled.div`

` : styled.div`
  position: fixed;
  max-height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: #FFF;
  overflow-y: scroll;
`

export const CheckedBox = ({onChange} : {onChange: React.ChangeEventHandler<HTMLInputElement>}) =>{
  useEffect(() => {
    (document.getElementById('assign') as HTMLInputElement).checked = true
  }, []);

  return(<Input id='assign' type='checkbox' onChange={onChange}/>);
}

export const Document = ({src, alt} : {src: string, alt: string}) => {
  if(src.substring(5,15) == 'application/pdf'){
    return(
      <DocPDF>
        <PDFDoc pdfsrc={src}/>
      </DocPDF>
    );
  }
  return(<img src={src} width={200} height={200} alt={alt}/>);
}

export const CardResumo = isBrowser? styled.div`
  padding: 20px;
  width: 92%;
  margin: 0 auto;
  
  .termos {
    text-align: justify;
    margin: 15px 400px;
  }

  #check {
    text-align: left;
  }
`: styled.div`
  margin: 10px auto;
  max-width: 'device-width';

  .termos {
    text-align: justify; 
    margin: 20px auto;
  }

  #check {
    text-align: left;
    margin-bottom: 20px;
  }
`

export const CardLocal = styled.div`
  margin-top: 4rem;
  p {
    font-size: 25px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`