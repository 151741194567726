import {isBrowser} from 'react-device-detect'

export const ApresentacaoText = {
  title1: 'Foto do documento',
  text1: isBrowser? 'Agora vamos precisar que você nos envie uma foto da sua CNH ou da sua identidade. Lembre-se que precisa ser do documento original. Fique atento nas dicas de como posicionar seu documento para a foto.'
  : (
    <div>
      <p>Agora tire uma foto da sua CNH ou identidade. Lembre-se que o documento tem que ser original.</p>
      <p>Fique atento as dicas!</p>
    </div>
  ),
  text2: isBrowser? <p>Caso você já tenha uma foto que permita a leitura dos dados do seu documento, você pode escolher entre anexá-la ou tirar uma nova foto do documento.</p> : null
}

export const OpcaoText = {
  title: 'Escolha uma opção de sua preferência'
}

export const AnexarText = {
  title1: 'Se na imagem tem só a frente do seu documento, vamos precisar que você nos envie também o verso',
  title2: 'Envie uma imagem do seu documento',
  title3: 'Envie uma imagem do verso do seu documento',
  error: 'Documentos precisam estar abaixo de 2MB',
  error2: 'Documentos precisam ser imagens ou pdf: .jpg, .jpeg, .png, .bmp, .gif, .pdf'
}

export const TirarFotoText = {
  title1: 'Tire uma foto só da frente do documento ou todo aberto, para aparecer a frente e verso.',
  title2: 'Você consegue ler o documento?',
  title3: 'Se sim, siga para a próxima etapa.',
  title4: 'Agora se você tirou uma foto só da frente do documento, vamos precisar que tire a foto também do verso.',
  title5: 'Tire sua foto em um local bem iluminado',
  title6: 'Se a resposta for sim, você pode seguir para a próxima etapa.',
  error: 'Habilite sua camera, caso queira prosseguir.'
}