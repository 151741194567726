import { useRef } from 'react'
import { Box, Button, ButtonProps, Grid, Icon, Paper, styled } from '@mui/material'
import { Form } from '@unform/web'
import { InputTextField } from '../form'
import { FormHandles } from '@unform/core'
import { grey } from '@mui/material/colors'
import { LoginData } from './FormInterfaces'
import { InputPassowordField } from '../form/InputPasswordField'
import axios from 'axios'
import { User, validationUser } from '../../../ts/interfaces/Manager.interfaces'

const {REACT_APP_SERVER} = process.env;

const webs = REACT_APP_SERVER

const ButtonPropSubmit = styled(Button)<ButtonProps>(({ theme }) => ({
  marginLeft: 105,
  borderColor: theme.palette.getContrastText(grey[300]),
  color: theme.palette.getContrastText(grey[300]),
  '&:click': {
    borderColor:theme.palette.getContrastText(grey[700]),
    color: theme.palette.getContrastText(grey[700]),
  },
  '&:hover': {
    borderColor:theme.palette.getContrastText(grey[200])
  }
}))

export interface ILogin {
  loadOptions: (perfil: number) => void 
  setUser: (usuario: User) => void
}

export const FormLogin = ({ loadOptions, setUser } : ILogin) : JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  const onSubmit = (data: LoginData) => {
    axios.post(webs+"/login", data).then(resp => {
      const result : validationUser = resp.data
      if(result.mensagem == "OK"){
        loadOptions(result.perfil);
        setUser({id_user: result.id_user, login: data.login, senha: data.senha, perfil: result.perfil})
      }else if(result.mensagem == "Senha Inválida"){
        formRef.current?.setFieldError('login', result.mensagem);
      }else {
        formRef.current?.setFieldError('senha', result.mensagem);
      }
    });
  }

  return (
    <Form ref={formRef} onSubmit={onSubmit} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <Box 
        display='flex'
        flexDirection='column'
        maxWidth={400}
        margin={'auto'}
        marginTop={5}
        component={Paper}
        >
        <Grid container padding={0.8} spacing={1}>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <h5>Login ver.2.1 - BPO</h5>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <InputTextField fullWidth name='login' label='Usuario'/>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <InputPassowordField fullWidth name='senha' label='Senha' type='password'/>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2} marginTop={1} justifyContent="end">
            <ButtonPropSubmit type='submit'><Icon>login</Icon></ButtonPropSubmit>
          </Grid>
        </Grid>
      </Box>
    </Form>
  )
}

export default FormLogin