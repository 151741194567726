import { User } from "../../../ts/interfaces/Manager.interfaces"
import { useState } from "react";
import ListaUsuarios from "./ListaUsuarios";
import { FormUpdate } from "../forms";
import Alert, { IAlert } from "../alerts/alert";
import { tela } from '../tela-indice';


interface IConsultaUsuarios {
  screen_tela: number,
  changeScreen: (screen: number) => void
}

export const ConsultaUsuarios = ({screen_tela, changeScreen}: IConsultaUsuarios) : JSX.Element => {
  const [usuario, setUsuario] = useState<User|undefined>(undefined);
  const [alert, setAlert] = useState<IAlert>({message: '', visible: false})
  let screen = <></>;

  const atualizaUsuario = (usuario: User) => {
    changeScreen(tela.gerenciar_usuario_atualizar)
    setUsuario(usuario);
  }

  const retornaConsulta = () => {
    changeScreen(tela.gerenciar_usuario)
  }

  switch(screen_tela){
    case tela.gerenciar_usuario:
      screen = <ListaUsuarios atualizar={atualizaUsuario} setAlert={setAlert} />
      break;
    case tela.gerenciar_usuario_atualizar:
      if(usuario){
         screen = <FormUpdate user={usuario} retornaConsulta={retornaConsulta} setAlert={setAlert} />
      }
      break;
    default:
      break;
  }
  return (
  <>
    <Alert {...alert}/>
    {screen}
  </>)
  ;
}

export default ConsultaUsuarios;