import { Table, TableBody, TableContainer, TableRow, TableCell, Button, Icon } from "@mui/material"
import { PropostaData } from "../../../ts/interfaces/Manager.interfaces"
import { EtapaStr, StatusStr } from "../constants"

interface IDetalhe {
  proposta: PropostaData,
  back: () => void
}

export const DetalheProposta = ({ proposta, back } : IDetalhe) => {
  return(
  <>
  <Button sx={{color: "#000"}} onClick={()=>back()}><Icon>arrow_back_ios</Icon></Button>
  <TableContainer>
    <Table aria-label='tabela-proposta'>
      <TableBody>
        <TableRow>
          <TableCell><b>Id token:</b></TableCell>
          <TableCell>{proposta.id_token}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Id Jornada:</b></TableCell>
          <TableCell>{proposta.idJornada}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Nome do Cliente:</b></TableCell>
          <TableCell>{proposta.nome_cliente}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>CPF:</b></TableCell>
          <TableCell>{proposta.cpf}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Situacao:</b></TableCell>
          <TableCell>{StatusStr(proposta.situacao)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Última etapa realizada:</b></TableCell>
          <TableCell>{EtapaStr(proposta.etapa)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Navegador:</b></TableCell>
          <TableCell>{proposta.navegador.nome}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Versão do Navegador:</b></TableCell>
          <TableCell>{proposta.navegador.versao}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Dispositivo:</b></TableCell>
          <TableCell>{proposta.dispositivo.plataforma}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Sistema Operacional:</b></TableCell>
          <TableCell>{proposta.dispositivo.os}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Versão do Sistema:</b></TableCell>
          <TableCell>{proposta.dispositivo.versao}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  </>
  )
}