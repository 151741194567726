import styled from 'styled-components'
import { isBrowser } from 'react-device-detect'

interface PropMask {
  mask: number
}
const REACT_APP_BUTTON_COLOR = process.env
const buttonColor = REACT_APP_BUTTON_COLOR

export const ModelAnimatedMask = isBrowser? styled.div<PropMask>`

margin: 0 auto;
display: block;
width: 650px;
height: 489px;

${props=>{
  if(!props.mask){
    return(`
      animation-name: open-div;
      animation-duration: 0.25s;
      -webkit-animation-name: open-dv;
      -webkit-animation-duration: 0.25s;
    `);
  }else{
      return(`
        background-color: ${buttonColor};
      `);
  }
}}

video {
  border: 5px solid;
  border-color: ${buttonColor};
  width: 650px;
  height: 489px;
  ${props=>{
    if(props.mask){
      return(`
      clip-path: ellipse(140px 180px at center);
      -webkit-clip-path: ellipse(140px 180px at center);
      `);
    }else{
      return(`
      animation-name: open;
      animation-duration: 0.25s;
      -webkit-animation-name: open;
      -webkit-animation-duration: 0.25s;
      `);
    }
  }}
}

`: styled.div<PropMask>`

margin: 0 auto;
display: block;
width: 100%;
height: 70hv;

${props=>{
  if(!props.mask){
    return(`
      animation-name: open-div;
      animation-duration: 0.25s;
      -webkit-animation-name: open-dv;
      -webkit-animation-duration: 0.25s;
    `);
  }else{
      return(`
        background-color: ${buttonColor};
      `);
  }
}}

video {
  border: 5px solid;
  border-color: ${buttonColor};
  width: ${window.innerWidth-10}px;
  height: ${(34.9 * window.innerHeight)/ 100}px;
  ${props=>{
    if(props.mask){
      return(`
      clip-path: ellipse(30% 35% at center);
      -webkit-clip-path: ellipse(30% 35% at center);
      `);
    }else{
      return(`
        animation-name: open;
        animation-duration: 0.25s;
        -webkit-animation-name: open;
        -webkit-animation-duration: 0.25s;
      `);
    }
  }}
}
`;

export const ModelSampleMask = isBrowser?styled.div<PropMask>`
text-align: center;

img {
  display: ${props => props.mask? 'block':'none'};
  left: 0;
  right: 0;
  margin: auto;
  width: 650px;
  height: 489px;
  position: absolute;
}

video {
  width: 650px,
  height: 489px;
  border: 5px solid;
  border-color: ${buttonColor};
}
`:styled.div<PropMask>`

img {
  display: ${props => props.mask? 'block':'none'};
  width: ${window.screen.width}px;
  height: 325px;
  position: absolute;
}

video {
  width: ${window.screen.width}px;
  height: 325px;
  border: 5px solid;
  border-color: ${buttonColor};
}

`