import { BlobServiceClient } from "@azure/storage-blob";
import axios from 'axios';
import { Buffer } from "buffer";

const webs = process.env.REACT_APP_SERVER

const sasToken = "sp=racwdli&st=2023-02-10T13:35:15Z&se=2050-02-10T21:35:15Z&sv=2021-06-08&sr=c&sig=hoBUPE%2B8HRkU14GGggBsONfsG%2FNW2nRyxGmJMWvyOQk%3D";
const containerName = `documentos`;
const storageAccount = 'bpodigital';


const UploadFile = async (name: string, arquivo: string, proposta: number) => {
  if (!arquivo || !name) return false;
  let b64 = arquivo.substring(arquivo.indexOf(',')+1);
  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  //console.log("recebeu arquivo e gerando blobserviceclient");
  const blobService = new BlobServiceClient(
    `https://${storageAccount}.blob.core.windows.net/?${sasToken}`
  );

  // create blobClient for container
  const container = blobService.getContainerClient(containerName);
  const blobClient = container.getBlockBlobClient(name);
  
  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: name.split('.').pop()}};

  // upload file
  await blobClient.uploadData(Buffer.from(b64, 'base64'), options);
  await blobClient.setMetadata({UserName : `cliente_${proposta}`})
  if(name.includes('prova_vida')){
    name = name.replace("_original", "");
  }
  await axios.get(`${webs}/proposta/confirma-envio`, {params: { name, proposta }});
  return true;
}

export const ClearEtapas = async (id: string) : Promise<void> => {
  try{ 
    await axios.get(`${webs}/proposta/clean-etapas`, {
      params: { 
        id_token: id
      }})
  }catch(e: any){
  } 
}

const Upload = async(name: string, arquivo: string, proposta: number) : Promise<boolean> => {
  const id = name.substring(0, name.indexOf('_'));
  if(name.includes("prova_vida")){
    name = `${id}_prova_vida_original.mp4`
  }
  const ret = await UploadFile(name, arquivo, proposta);
  return ret;
}

export default Upload;