import React from 'react'
import '../App.css';
import FadeIn from 'react-fade-in';
import {Label} from 'reactstrap'
import {tela_fluxo} from '../telas_id';
import {FormBox, Seguir, CheckedBox} from './styles/estilo_telas';
import CCBGen from '../components/doc/ccbgen.component';
import {status, situacao} from '../components/Info'
import './styles/estilo.css'

import { PropAccept } from '../ts/interfaces/PropTelas.interfaces';
import { addInfo, updateInfoBPO } from '../state/actions/Info_actions';
import { getStateJornada } from '../state/actions/jornada_actions';

interface State {
  unlock: boolean,
  checklock: boolean
}

class CCBScreen extends React.Component<PropAccept, State> {
  
  constructor(props : PropAccept){
    super(props)
    this.Aceitar = this.Aceitar.bind(this);
    this.Rejeitar = this.Rejeitar.bind(this);
    this.Status = this.Status.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      unlock: true,
      checklock: false
    }
  }

  componentDidMount(){
    updateInfoBPO({fase: status.tela_ccb, situacao: situacao.sucesso});
    addInfo(tela_fluxo.ccb_screen);
  }

  Aceitar() {
    updateInfoBPO({fase: status.ccb_aceito, situacao: situacao.sucesso});
    this.props.changePasso();
  }

  Rejeitar() {
    const {id} = getStateJornada();
    updateInfoBPO({fase: status.ccb_rejeitado, situacao: situacao.sucesso});
    this.props.history.push('/rejeitar/'+id)
  }

  handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
    const{checklock} = this.state;
    if(e.target?.checked && !checklock){
      this.props.ShowAlert('É necessário ler o documento antes de assinar', 'warning')
    }
    if(!checklock){
      e.target.checked = false
    }
    this.setState({unlock: e.target.checked && checklock})
  }

  Status(e : React.UIEvent<HTMLDivElement>) {
    if(e.currentTarget.scrollTop >= (19 * e.currentTarget.scrollWidth)){
      this.setState({checklock: true})
    }
  }

  render() {
    const {unlock} = this.state;
    const message = 'Li e aceito os termos e condições'
    
    return(
        <FadeIn delay={150}>
            <FormBox>
              <h5>Cédula de Crédito Bancário</h5>
                <div className='termo'>
                  <CCBGen Status={this.Status} />
                </div>
                <CheckedBox onChange={this.handleChange}/>{' '}<Label check>{message}</Label>
                <Seguir unlock={unlock} onClick={this.Aceitar} />
             </FormBox>
          </FadeIn>
        );
  }
}

export default CCBScreen;
