import styled from 'styled-components'
import pic from '../../images/date-image.png';
import DatePicker from 'react-datepicker';

const DateImg = styled.img`
  margin-top: 5px;
  margin-left: 5px;
`;

interface IDateButton {
  onClick : () => void
}

export const DateButton = ({onClick} : IDateButton) => {
  return(
    <DateImg src={pic} alt='DatePic' onClick={onClick} height={30} />
  );
}

const CalendarDiv = styled.div<{show: boolean}>`
  margin-top: 20px;
  text-align: center;
  display: ${props => props.show? 'block': 'none'};
`

interface ICalendar {
  onChange : (date: Date) => void,
  show: boolean
}

export const Calendar = ({onChange, show} : ICalendar) => {
  return(
    <CalendarDiv show={show}>
      <DatePicker
        onChange={onChange}
        inline
      />
    </CalendarDiv>
  );
}