import styled from 'styled-components'
import PDFDoc from './PDFDoc'
import { isBrowser } from 'react-device-detect'
import { Base64Type } from '../ts/types/Types'

const DocPDF = isBrowser? styled.div`
  margin: 20px auto 0px; 
  width: 450px;
  height: 550px;
`:styled.div`
  margin-top: 20px; 
  margin-bottom: 5px; 
  width: 100%; 
  height: 500px;
`

const DocPhoto = isBrowser? styled.img`
  margin-top: 20px; 
  margin-bottom: 20px;
  width: 450px; 
  height: 550px;
`: styled.img`
  margin-top: 20px; 
  margin-bottom: 20px; 
  width: 100%; 
  height: 324px;
`

interface IDocument {
  src: Base64Type,
  alt: string,
  url: string | null
}

export const Document = ({src, alt, url} : IDocument) => {
  if(src instanceof File && src.type == 'application/pdf'){
    return(
      <DocPDF>
        <PDFDoc pdfsrc={src} />
      </DocPDF>
    );
  }
  return <DocPhoto src={url?url:''} alt={alt}/>
}

export default Document