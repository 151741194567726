
import React from 'react'
import { tela_fluxo, tela_fluxo2, documento_id, tela_fluxo_refin } from '../telas_id'
import './styles/anexofoto.component.css'
import Header from '../components/header'
import FadeIn from 'react-fade-in';
import Etapa from '../components/Etapa'

//tela
import {
  Selfie,
  ProvadeVida,
  Identidade,
  Residencia,
  Contracheque,
  SegurandoIdentidade,
  Resumo,
  ValidacaoUltimaJornada,
  CCBScreen,
  Validacao,
  TermosScreen,
  Refin,
  ValidacaoRefin
} from '../telas/index'

//subfluxo
import ProvaVidaFluxo from './prova_vida.fluxo';

import AlertError from '../routes/alerts/alert_error'

import axios from 'axios';

import { UpdateLogMessage, blobToBase64, getExtension } from '../util/Utils';
import { IPropsFluxo } from '../ts/interfaces/Props.interfaces';
import { Base64Type, DocType } from '../ts/types/Types';

import Upload from '../components/Upload.local.component';

import { getStateJornada } from '../state/actions/jornada_actions';
import { updateEventos, updateInfoBPO, situacao, status as stat } from '../state/actions/Info_actions';

const {REACT_APP_SERVER, REACT_APP_SOURCE_FILES} = process.env;

const webs = REACT_APP_SERVER
const source = REACT_APP_SOURCE_FILES
console.log(process.env.REACT_APP_BACKGROUND_COLOR)
interface State {
  dat: string[],
  code: string,
  docs: DocType[],
  status: number,
  link: string,
  step: number,
  coloralert: string,
  enviado: boolean,
  enviado_prova_vida: boolean,
  enviado_cnh_frente: number,
  enviado_cnh_verso: number,
  alerterror: boolean,
  alertmessage: string,
  continuar_ultimajornada: boolean
}

export default class Fluxo extends React.Component<IPropsFluxo, State> {

  constructor(props : IPropsFluxo){
    super(props)
    
    const {ultimaJornada, contrato} = getStateJornada();

    this.changePasso = this.changePasso.bind(this)
    this.InsertScreenShot = this.InsertScreenShot.bind(this)
    this.InsertVideoBlob = this.InsertVideoBlob.bind(this)
    this.ShowAlert = this.ShowAlert.bind(this)
    this.PostScreenShot = this.PostScreenShot.bind(this)
    this.AtualizarEventos = this.AtualizarEventos.bind(this)
    this.continuarJornada = this.continuarJornada.bind(this)
    this.pushDocs = this.pushDocs.bind(this)

    this.state = {
      dat: [],
      code: '',
      docs: [],
      status: 0,
      link: '',
      step: 0,
      coloralert: 'danger',
      enviado: true,
      enviado_prova_vida: false,
      enviado_cnh_frente: 0,
      enviado_cnh_verso: -1,
      alerterror: false,
      alertmessage: '',
      continuar_ultimajornada: (ultimaJornada && ultimaJornada.etapa > 0)
    }

    axios.post(webs+'/document/hash',contrato)
    .then(resp => resp.data)
    .then(txt => this.setState({code: txt}))
    
  }

  continuarJornada = () => {
    this.setState({continuar_ultimajornada: false})
  }

  AtualizarEventos = () => {
    this.setState({enviado: false});
    const {ultimaJornada} = getStateJornada();
    updateEventos(ultimaJornada);
    this.setState({docs: ultimaJornada.docs})
    let data : string[] = [];
    ultimaJornada.docs.forEach(doc => {
      const id = doc.nome.substring(0, doc.nome.indexOf('_'));
      if(!doc.nome.includes('prova_vida')){
        const url = `${source}/${id}/${doc.nome}`
        data.push(url);
      }
    })
    this.setState({dat: data, enviado: true});
  }

  componentDidUpdate(): void {
      const {enviado_cnh_frente, enviado_cnh_verso} = this.state;

      if(enviado_cnh_frente == 1 && enviado_cnh_verso == 1) {
        this.setState({enviado: true, enviado_cnh_frente: 0, enviado_cnh_verso: 0});
      }
  }

  changePasso = (arg?: number) => {
    const {step, enviado_prova_vida} = this.state
    if(step == tela_fluxo.prova_vida && !enviado_prova_vida){
      this.setState({enviado: false});
    }
    arg? this.setState({step: arg}): this.setState({step: step + 1})
  }

  pushDocs = (element : {nome: string, tipo: string}) => {
    const {docs, dat} = this.state;
    if(docs.findIndex(x => x.nome == element.nome) === -1){
      const id = element.nome.substring(0, element.nome.indexOf('_'));
      this.setState({dat: [...dat, `${source}/${id}/${element.nome}`], docs: [...docs, element]})
    }
  }

  PostScreenShot = (screenshot: Base64Type, documento: number, ext: string, wait_verso: boolean) => {
    let element : DocType = {nome: '', tipo: ''};
    const {status} = this.state
    
    const {id, contrato} = getStateJornada();
    let fase = stat.selfie_recebida;

    switch(documento){
      case documento_id.selfie:
        element = {          
            nome: `${id}_photo_face.${ext}`,
            tipo: 'Selfie' 
        }
        break;
      case documento_id.cnh_frente:
        element = {
          nome: `${id}_cnh_frente.${ext}`,
          tipo: 'CNH'
        }
        fase = stat.cnh_frente_recebida;
        break;
      case documento_id.comprovante:
        element = {
          nome: `${id}_comprovante.${ext}`,
          tipo: 'Comprovante_Endereco'
        }
        fase = stat.comprovante_residencia_recebido;
        break;        
      case documento_id.cnh_verso:
        element = {
          nome : `${id}_cnh_verso.${ext}`,
          tipo: 'CNH'
        }
        fase = stat.cnh_verso_recebida;
        break;
      case documento_id.contracheque:
        element = {
          nome: `${id}_contracheque.${ext}`,
          tipo: 'Outros'
        }
        fase = stat.contracheque_recebido;
        break;
      case documento_id.selfie_cartao:
        element = {
          nome: `${id}_photo_face_cartao.${ext}`,
          tipo: 'Selfie'
        }
        fase = stat.selfie_recebida;
        this.setState({status: 7})
        break;
      default:
        break;
    }
    UpdateLogMessage(`Elemento adicionado na lista de documentos`, element, ` Para o id de documento: `, documento)
    this.pushDocs(element)
    
    Upload(element.nome, screenshot as string, contrato.Proposta.NumeroProposta).then(resp=>{
      if(resp == false){
        UpdateLogMessage(`Erro de envio ${element.nome} : response false`)
        updateInfoBPO({fase: fase, situacao: situacao.falha, mensagemErro: 'Erro ao enviar'});
        this.props.history.push('/erro/inesperado')
      }
      if(this.state.status == 7){
        this.setState({enviado: true})
      }else{
        if(wait_verso && documento_id.cnh_frente == documento) {
          this.setState({ status: status+1, enviado_cnh_frente: 1 })
        } else if(documento_id.cnh_verso == documento) {
          this.setState({ status: status+1, enviado_cnh_verso: 1 })
        } else {
          this.setState({ status: status+1, enviado: true })
        }
      }
    }).catch(e => {
      UpdateLogMessage(`Erro de envio ${element.nome}: `, e);
      updateInfoBPO({fase: fase, situacao: situacao.falha, mensagemErro: 'Erro ao enviar:'+e.response.data});
      this.props.history.push('/erro/inesperado')
    })
  }

  InsertScreenShot = async (screenshot: Base64Type, documento: number, wait_verso?: boolean) => {
    const { step } = this.state
    const { id } = getStateJornada();

    var ext = getExtension(screenshot) //Perguntar se e necessario enviar o documento convertido para o front
    this.setState({enviado: false});
    const wait = wait_verso? true: false

    if(ext == 'pdf'){
      ext = 'jpg'
      axios.post(webs+'/convert/pdf-to-jpg', { passo: documento, id_name: id, b64:screenshot })
      .then(async resp => {
        let res = resp.data
        await this.PostScreenShot('data:image/jpg;base64,'+res.base64, documento, ext, wait)
      }).catch(e => {
        UpdateLogMessage(`Erro envio de conversão de pdf: `, e);
        this.setState({step: step, enviado: true});
        this.ShowAlert('Infelizmente não foi possível completar o envio deste documento, de preferência tente anexá-lo em formato de imagem', 'warning', 5000)
      })
    }else{
      await this.PostScreenShot(screenshot, documento, ext, wait)
    }
  }

  InsertVideoBlob = (blob : Blob) => {
    const {id, contrato} = getStateJornada();
    
    blobToBase64(blob).then(
      b64 => {
        let element = {
          nome: `${id}_prova_vida.mp4`,
          tipo: 'Outros'
        }
        Upload(element.nome, b64 as string, contrato.Proposta.NumeroProposta).then(()=>{
          this.setState({docs: [...this.state.docs, element], enviado_prova_vida: true, enviado: true});
          updateInfoBPO({fase: stat.prova_vida_recebida, situacao: situacao.sucesso});
        }).catch(e => {
          UpdateLogMessage(`Erro envio de prova de vida: `, e);
          updateInfoBPO({fase: stat.prova_vida_recebida, situacao: situacao.falha, mensagemErro: e.response.data});
          this.props.history.push('/erro/inesperado');
        });
      }
    ).catch(e => {
      UpdateLogMessage(`Erro envio de conversão de blob prova de vida: `, e);
      this.props.history.push('/erro/inesperado')
    })
    this.setState({ link: URL.createObjectURL(blob) });
  }

  ShowAlert(message: string, color: string='danger', timing: number=2000) {
    this.setState({alertmessage: message, alerterror: true, coloralert: color}, () => {
      window.setTimeout(() => {
        this.setState({alerterror: false})
      }, timing)
    })
  }

  render() {
    const {step, alerterror, enviado, alertmessage, coloralert, continuar_ultimajornada} = this.state
    const {code, docs, link, dat} = this.state;
    const { tipo, contrato } = getStateJornada();

    const PropDocs = {
      InsertScreenShot: this.InsertScreenShot, 
      changePasso: this.changePasso,
      ShowAlert: this.ShowAlert, 
      enviado: enviado
    }

    const PropsEval = {
      history: this.props.history, 
      changePasso: this.changePasso,
      ShowAlert: this.ShowAlert, 
      enviado: enviado
    }

    let etapatag = step > 0? step - 1 : 0
    if(step == tela_fluxo.selfie || step == tela_fluxo.prova_vida){
      etapatag = 1
    }
    if(step >= tela_fluxo.ccb_screen){
      etapatag = 5
    }

    if (contrato.Proposta.TipoProposta == 7) {
      return(
        <div>
        <FadeIn delay={150}>
        <AlertError message={alertmessage} visible={alerterror} color={coloralert}/>
        <Header/>
        <Etapa id={etapatag} />
        {step == tela_fluxo_refin.validacao_refin && <ValidacaoRefin {...PropsEval} />}
        {step == tela_fluxo_refin.selfie && <Selfie {...PropDocs} />}
        {step == tela_fluxo_refin.prova_vida && <ProvadeVida {...PropDocs} history={this.props.history} InsertVideoBlob={this.InsertVideoBlob} />}
        {step == tela_fluxo_refin.documento_id && <Identidade {...PropDocs} />}
        {step == tela_fluxo_refin.documento_residencia && <Residencia {...PropDocs} />}
        {step == tela_fluxo_refin.contracheque && <Contracheque {...PropDocs} />}
        {step == tela_fluxo_refin.refin_screen && <Refin {...PropsEval} />}
        {step == tela_fluxo_refin.ccb_screen && <CCBScreen {...PropsEval} /> }
        {step == tela_fluxo_refin.tela_final && <Resumo history={this.props.history} code={code} link={link} docs={docs} dat={dat} />}
        </FadeIn>
      </div>
      )
    }

    if (tipo == 2) {
      return(
        <div>
          <FadeIn delay={150}>
          <AlertError message={alertmessage} visible={alerterror} color={coloralert}/>
          <Header/>
          {step == tela_fluxo2.selfie_tela && <SegurandoIdentidade {...PropDocs} />}
          {step == tela_fluxo2.tela_final && <Resumo history={this.props.history} docs={docs} dat={dat} />}
          </FadeIn>
        </div>
      )
    }

    if(tipo == 3){
      return(
        <div>
          <FadeIn delay={150}>
            <Header/>
            <ProvaVidaFluxo history={this.props.history} ShowAlert={this.ShowAlert} />
          </FadeIn>
        </div>
      );
    }

    if(step == tela_fluxo.validacao && continuar_ultimajornada){
      return(
        <div>
          <FadeIn delay={150}>
          <Header/>
          <ValidacaoUltimaJornada history={this.props.history} continuarJornada={this.continuarJornada} AtualizarEventos={this.AtualizarEventos} changePasso={this.changePasso} ShowAlert={this.ShowAlert} enviado={enviado}/>
          </FadeIn>
        </div>
      );
    }
    
    return(
      <div>
        <FadeIn delay={150}>
        <AlertError message={alertmessage} visible={alerterror} color={coloralert}/>
        <Header/>
        <Etapa id={etapatag} />
        {step == tela_fluxo.validacao && <Validacao {...PropsEval} />}
        {step == tela_fluxo.selfie && <Selfie {...PropDocs} />}
        {step == tela_fluxo.prova_vida && <ProvadeVida {...PropDocs} history={this.props.history} InsertVideoBlob={this.InsertVideoBlob} />}
        {step == tela_fluxo.documento_id && <Identidade {...PropDocs} />}
        {step == tela_fluxo.documento_residencia && <Residencia {...PropDocs} />}
        {step == tela_fluxo.contracheque && <Contracheque {...PropDocs} />}
        {step == tela_fluxo.termo_screen && <TermosScreen {...PropsEval} />}
        {step == tela_fluxo.ccb_screen && <CCBScreen {...PropsEval} /> }
        {step == tela_fluxo.tela_final && <Resumo history={this.props.history} code={code} link={link} docs={docs} dat={dat} />}
        </FadeIn>
      </div>
    );

  }

}
