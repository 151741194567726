export const tela_fluxo = {
  validacao: 0,
  selfie: 1,
  prova_vida: 2,
  documento_id: 3,
  documento_residencia: 4,
  contracheque: 5,
  termo_screen: 6,
  ccb_screen: 7,
  tela_final: 8
}

export const tela_fluxo_refin = {
  validacao_refin: 0,
  selfie: 1,
  prova_vida: 2,
  documento_id: 3,
  documento_residencia: 4,
  contracheque: 5,
  refin_screen: 6,
  ccb_screen: 7,
  tela_final: 8
}

export const documento_id = {
  selfie: 1,
  cnh_frente: 2,
  cnh_verso: 4,
  comprovante: 3,
  contracheque: 5,
  selfie_cartao: 6,
}

export const tela_fluxo_fgts = {
  termo_screen: 0,
  selfie: 1,
  prova_vida: 2,
  documento_id: 3,
  ccb_screen: 4,
  tela_final: 5
}

export const tela_fluxo3 = {
  inicial: 0,
  selfie: 1,
  prova_vida: 2,
  documento_id: 3,
  documento_residencia: 4,
  contracheque: 5,
  final: 6
}

export const tela_fluxo2 = {
  selfie_tela: 0,
  tela_final: 1
}

export const getPassoById = (id: number, tipo: number) => {
  if(tipo == 1 || tipo == 3 || tipo == 5) {
    switch(id){
      case tela_fluxo.validacao:
        return 'Confimação dos dados e aceitação do serviço e das políticas da empresa';
      case tela_fluxo.prova_vida:
        return 'Prova de vida realizada mediante uma selfie e um video';
      case tela_fluxo.documento_id:
        return 'Processo de confirmação de identidade';
      case tela_fluxo.documento_residencia:
        return 'Processo de envio do comprovante de residencia';
      case tela_fluxo.contracheque:
        return 'Processo de envio do contracheque';
      case tela_fluxo.termo_screen:
        return 'Leitura e aceitação dos termos de adesao do cartão de crédito';
      case tela_fluxo.ccb_screen:
        return 'Leitura e aceitação dos termos apresentados na cédula de crédito bancario';
      default:
        return '';
    }
  }else {
    switch(id){
      case tela_fluxo2.selfie_tela:
        return 'Processo de selfie segurando identidade';
      default:
        return '';
    }
  }
}