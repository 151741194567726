
export const Estado = {
  Pernambuco: 2,
  Maranhao: 3,
  Minas_Gerais: 7,
  Paraiba: 4,
  Tocantins: 32,
  Tocantins_igeprev: 59,
  Paraiba_associacao: 65,
  Piaui: 81,
  Teste: 0,
  celetista: 78,
  Mato_Grosso: 6 //Codigo definido apenas para teste
}

export const getEmpregadorString = (empregador: number) : string => {
  switch(empregador){
    case Estado.Pernambuco:
      return "Governo Pernambuco";
    case Estado.Maranhao:
      return "Governo Maranhão";
    case Estado.Minas_Gerais:
      return "Governo Minas Gerais";
    case Estado.Paraiba:
      return "Governo da Paraíba"; 
  }
  return "";
}

export const getEmpregadorStringCadastro = (empregador: number) : string => {
  switch(empregador){
    case Estado.Pernambuco:
      return "Governo Pernambuco";
    case Estado.Maranhao:
      return "Governo Maranhão";
    case Estado.Minas_Gerais:
      return "Governo Minas Gerais";
    case Estado.Paraiba:
      return "Governo da Paraíba";
    case Estado.Mato_Grosso:
      return "Mato Grosso";
  }
  return "";
}