import { UserSession } from '../data-types'
import { userActions } from '../reducers'
import { store } from '../store'

export const getStateUser = () => {
	return store.getState().Usuario as UserSession
}

export const setStateUser = (state: UserSession) => {
	store.dispatch(userActions.setSession(state))
}

export const userTimedOut = () : boolean => {
	const { timeout } = getStateUser();
	if(timeout) {
		let date = new Date(timeout);
		let expired = Date.now() - date.getTime();
		if(expired >= (1000*60*60)){ //se a sessão estiver aberta por mais de 30 minutos ela fecha
			return true;
		}
	}
	return false;
}