import React from 'react';
import '../telas/styles/estilo.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { isMobile } from 'react-device-detect';
import { Base64Type } from '../ts/types/Types';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IPDFDoc {
  pdfsrc: Base64Type
}

interface State {
  pagenum: number,
  minPage: number,
  maxPage: number,
  setPage: boolean,
  minRage: number,
  maxRage: number
}

export default class PDFDoc extends React.Component<IPDFDoc, State> {
  constructor(props : IPDFDoc){
    super(props)
    this.finalPage = this.finalPage.bind(this)

    this.state = {
      pagenum: 1,
      minPage: 0,
      maxPage: 0,
      setPage: false,
      minRage: 0,
      maxRage: 2
    }

    /*if(setPage && maxPage == 1){
      this.props.UnlockCheck(true)
    }*/

  }

  finalPage(e : React.UIEvent<HTMLDivElement>){
    //const {maxPage} = this.state;

    /*if(e.target.scrollTop >= (maxPage * e.target.scrollWidth)){
      this.props.UnlockCheck(true)
    }*/
    this.setState({pagenum: Math.round(e.currentTarget.scrollTop/470)})
    const {pagenum} = this.state;

    if(pagenum >= 2){
      this.setState({minRage: pagenum-1, maxRage: pagenum+1})
    }else{
      this.setState({minRage: 0, maxRage: 2})
    }
  }

  render(){
    const {minPage, maxPage, setPage, minRage, maxRage} = this.state;
    const wid = isMobile? window.innerWidth : 0
    const {pdfsrc} = this.props;
    //const url = this.props.url;

    if(isMobile){
      return(  
        <div>
        {setPage && maxPage == 1? 
        <Document
          key='1'
          file={pdfsrc}
          >
          <Page key={1} pageNumber={1} width={wid * 0.9} height={470} />
        </Document>
        :
        <Document
          key='1'
          file={pdfsrc}
          onLoadSuccess={({numPages} : {numPages: number}) => !setPage? this.setState({maxPage: numPages}): null}
        >
          <div onScroll={this.finalPage} style={{width: wid * 0.9,height: 470, backgroundColor: '#adb8b3',border:'1px solid #ccc',font:'16px/26px Georgia, Garamond, Serif',overflow:'auto'}}>
            {Array.apply(minPage, Array(maxPage)).map(function (x, i) {
              if((i >= minRage)&&(i <= maxRage)){
                return(
                <div key={i+1} style={{marginTop: '2px'}}>
                  <Page key={i+1} pageNumber={i+1} width={wid * 0.9} height={470} />
                </div>
                )
              }else{
                return(
                  <div key={i+1} style={{display:'block', width: wid * 0.9, height: 470}} />
                );
              }
            })}
          </div>
        </Document>
        }
      </div>
      );
    } else {
      //Pegar o scroll width(tamanho de scroll por pagina) e multiplicar pelo numero maximo de paginas(ativar o botao ao scrollar ate esse ponto)
      return(
        <div>
          {setPage && maxPage == 1?
          <Document
            key='2'
            file={pdfsrc}
          >
            <Page key={1} pageNumber={1} width={500} height={400} />
          </Document> :
          <Document
            key='2'
            file={pdfsrc}
            onLoadSuccess={({numPages} : {numPages: number}) => !setPage? this.setState({maxPage: numPages}): null}
          >
            <div onScroll={this.finalPage} style={{height:'500px',width:'500px', backgroundColor: '#adb8b3',border:'1px solid #ccc',font:'16px/26px Georgia, Garamond, Serif',overflow:'auto'}}>
            {Array.apply(minPage, Array(maxPage)).map(function (x, i) {
              return(
              <div key={i+1} style={{marginTop: '2px'}}>
                <Page key={i+1} pageNumber={i+1} width={500} height={400} />
              </div>
              )
            })}
            </div>
          </Document>
          }
        </div>
      );
    }
  }


}