
import React from 'react'
import { documento_id, tela_fluxo_fgts as tela_fluxo } from '../telas_id'
import './styles/anexofoto.component.css'
import Header from '../components/header'
import FadeIn from 'react-fade-in';
import Etapa from '../components/Etapa'

//tela
import {
  Selfie,
  ProvadeVida,
  Identidade,
  Resumo,
  CCBScreen,
  ValidacaoFGTS
} from '../telas/index'

import AlertError from '../routes/alerts/alert_error'

import axios from 'axios';

import { blobToBase64, getExtension } from '../util/Utils';
import { IPropsFluxo } from '../ts/interfaces/Props.interfaces';
import { Base64Type, DocType } from '../ts/types/Types';

import Upload from '../components/Upload.local.component';
import { getStateJornada } from '../state/actions/jornada_actions';
import { updateEventos, updateInfoBPO, situacao, status as stat } from '../state/actions/Info_actions';

const {REACT_APP_SERVER, REACT_APP_SOURCE_FILES} = process.env;

const webs = REACT_APP_SERVER

const source = REACT_APP_SOURCE_FILES

interface State {
  dat: string[],
  code: string,
  docs: DocType[],
  status: number,
  link: string,
  step: number,
  coloralert: string,
  enviado: boolean,
  enviado_prova_vida: boolean,
  enviado_cnh_frente: number,
  enviado_cnh_verso: number,
  alerterror: boolean,
  alertmessage: string,
  continuar_ultimajornada: boolean
}

export default class FluxoFGTS extends React.Component<IPropsFluxo, State> {
  constructor(props : IPropsFluxo){
    super(props)

    const {ultimaJornada, contrato} = getStateJornada();

    this.changePasso = this.changePasso.bind(this)
    this.InsertScreenShot = this.InsertScreenShot.bind(this)
    this.InsertVideoBlob = this.InsertVideoBlob.bind(this)
    this.ShowAlert = this.ShowAlert.bind(this)
    this.PostScreenShot = this.PostScreenShot.bind(this)
    this.AtualizarEventos = this.AtualizarEventos.bind(this)
    this.continuarJornada = this.continuarJornada.bind(this)

    this.state = {
      dat: [],
      code: '',
      docs: [],
      status: 0,
      link: '',
      step: 0,
      coloralert: 'danger',
      enviado: true,
      enviado_prova_vida: false,
      enviado_cnh_frente: 0,
      enviado_cnh_verso: -1,
      alerterror: false,
      alertmessage: '',
      continuar_ultimajornada: (ultimaJornada && ultimaJornada.etapa > 0)
    }

    axios.post(webs+'/document/hash', contrato)
    .then(resp => resp.data)
    .then(txt => this.setState({code: txt}))
    
  }

  continuarJornada = () => {
    this.setState({continuar_ultimajornada: false})
  }

  AtualizarEventos = () => {
    this.setState({enviado: false});
    const {ultimaJornada} = getStateJornada();

    updateEventos(ultimaJornada);
    this.setState({docs: ultimaJornada.docs})
    let data : string[] = [];
    ultimaJornada.docs.forEach(doc => {
      const id = doc.nome.substring(0, doc.nome.indexOf('_'));
      if(!doc.nome.includes('prova_vida')){
        const url = `${source}/${id}/${doc.nome}`
        data.push(url);
      }
    })
    this.setState({dat: data, enviado: true});
  }

  componentDidUpdate(): void {
    const {enviado_cnh_frente, enviado_cnh_verso} = this.state;

    if(enviado_cnh_frente == 1 && enviado_cnh_verso == 1) {
      this.setState({enviado: true, enviado_cnh_frente: 0, enviado_cnh_verso: 0});
    }
  }

  changePasso = () => {
    const { step, enviado_prova_vida } = this.state;
    if(step == tela_fluxo.prova_vida && !enviado_prova_vida){
      this.setState({enviado: false});
    }
    this.setState({step: step + 1});
  }

  PostScreenShot = (screenshot: Base64Type, documento: number, ext: string, wait_verso: boolean) => {
    let element : DocType = {nome: '', tipo: ''};
    const {status, docs, dat} = this.state
    //const {contrato} = this.props;
    const {id, contrato} = getStateJornada();
    let fase = stat.selfie_recebida;

    switch(documento){
      case documento_id.selfie:
        element = {          
            nome: `${id}_photo_face.${ext}`,
            tipo: 'Selfie' 
        }
        break;
      case documento_id.cnh_frente:
        element = {
          nome: `${id}_cnh_frente.${ext}`,
          tipo: 'CNH'
        }
        
        fase = stat.cnh_frente_recebida;
        break;
      case documento_id.comprovante:
        element = {
          nome: `${id}_comprovante.${ext}`,
          tipo: 'Comprovante_Endereco'
        }
        fase = stat.comprovante_residencia_recebido;
        break;
      case documento_id.cnh_verso:
        element = {
          nome : `${id}_cnh_verso.${ext}`,
          tipo: 'CNH'
        }
        fase = stat.cnh_verso_recebida;
        break;
      case documento_id.contracheque:
        element = {
          nome: `${id}_contracheque.${ext}`,
          tipo: 'Outros'
        }
        fase = stat.contracheque_recebido;
        break;
      case documento_id.selfie_cartao:
        element = {
          nome: `${id}_photo_face_cartao.${ext}`,
          tipo: 'Selfie'
        }
        fase = stat.selfie_recebida;
        this.setState({status: 7})
        break;
      default:
        break;
    }
    this.setState({dat: [...dat, `${source}/${id}/${element.nome}`], docs: [...docs, element]});

    Upload(element.nome, screenshot as string, contrato.Proposta.NumeroProposta).then(resp=>{
      if(resp == false){
        updateInfoBPO({fase: fase,situacao: situacao.falha, mensagemErro: 'Erro ao enviar'});
        this.props.history.push('/erro/inesperado')
      }
      if(this.state.status == 7){
        //this.compareImages2();
        this.setState({enviado: true})
      }else{
        if(wait_verso && documento_id.cnh_frente == documento) {
          this.setState({ status: status+1, enviado_cnh_frente: 1 })
        } else if(documento_id.cnh_verso == documento) {
          this.setState({ status: status+1, enviado_cnh_verso: 1 })
        } else {
          this.setState({status: status+1, enviado: true})
        }
      }
    }).catch(e => {
      console.log(e);
      updateInfoBPO({fase: fase, situacao: situacao.falha, mensagemErro: 'Erro ao enviar:'+e.response.data});
      this.props.history.push('/erro/inesperado')
    })
  }

  InsertScreenShot = async (screenshot: Base64Type, documento: number, wait_verso?: boolean) => {
    const { step } = this.state
    const { id } = getStateJornada();

    var ext = getExtension(screenshot) //Perguntar se e necessario enviar o documento convertido para o front
    this.setState({enviado: false});
    const wait = wait_verso? true: false

    if(ext == 'pdf'){
      ext = 'jpg'
      axios.post(webs+'/convert/pdf-to-jpg', { passo: documento, id_name: id, b64:screenshot })
      .then(resp => {
        let res = resp.data
        this.PostScreenShot('data:image/jpg;base64,'+res.base64, documento, ext, wait)
      }).catch(e => {
        this.setState({step: step, enviado: true});
        this.ShowAlert('Infelizmente não foi possível completar o envio deste documento, de preferência tente anexá-lo em formato de imagem', 'warning', 5000)
      })
    }else{
      await this.PostScreenShot(screenshot, documento, ext, wait)
    }
  }

  InsertVideoBlob = (blob : Blob) => {
    const {id, contrato} = getStateJornada();

    blobToBase64(blob).then(
      b64 => {
        let element = {
          nome: `${id}_prova_vida.mp4`,
          tipo: 'Outros'
        }
        Upload(element.nome, b64 as string, contrato.Proposta.NumeroProposta).then(()=>{
          this.setState({docs: [...this.state.docs, element], enviado_prova_vida: true, enviado: true});
          updateInfoBPO({fase: stat.prova_vida_recebida, situacao: situacao.sucesso});
        }).catch(e => {
          updateInfoBPO({fase: stat.prova_vida_recebida, situacao: situacao.falha, mensagemErro: e.response.data});
          this.props.history.push('/erro/inesperado');
        });
      }
    ).catch(e => this.props.history.push('/erro/inesperado'))
    this.setState({ link: URL.createObjectURL(blob) });
  }

  ShowAlert(message: string, color: string='danger', timing: number=2000) {
    this.setState({alertmessage: message, alerterror: true, coloralert: color}, () => {
      window.setTimeout(() => {
        this.setState({alerterror: false})
      }, timing)
    })
  }

  render() {
    const {step, alerterror, enviado, alertmessage, coloralert} = this.state
    const {code, docs, link, dat} = this.state;

    //const {ultimaJornada} = this.props;

    let etapatag = 1;
    if(step == tela_fluxo.prova_vida){
      etapatag = 2
    }
    if(step == tela_fluxo.documento_id){
      etapatag = 3
    }
    if(step == tela_fluxo.ccb_screen){
      etapatag = 4
    }
    if(step == tela_fluxo.tela_final){
      etapatag = 5
    }

    return(
      <div>
        <FadeIn delay={150}>
        <AlertError message={alertmessage} visible={alerterror} color={coloralert}/>
        <Header/>
        <Etapa type='fgts' id={etapatag} />
        {step == tela_fluxo.termo_screen && <ValidacaoFGTS history={this.props.history} changePasso={this.changePasso} ShowAlert={this.ShowAlert} enviado={enviado}/>}
        {step == tela_fluxo.selfie && <Selfie InsertScreenShot={this.InsertScreenShot} changePasso={this.changePasso} ShowAlert={this.ShowAlert} enviado={enviado}/>}
        {step == tela_fluxo.prova_vida && <ProvadeVida InsertScreenShot={this.InsertScreenShot} changePasso={this.changePasso} InsertVideoBlob={this.InsertVideoBlob} ShowAlert={this.ShowAlert} history={this.props.history} enviado={enviado}/>}
        {step == tela_fluxo.documento_id && <Identidade InsertScreenShot={this.InsertScreenShot} changePasso={this.changePasso} ShowAlert={this.ShowAlert} enviado={enviado}/>}
        {step == tela_fluxo.ccb_screen && <CCBScreen history={this.props.history} changePasso={this.changePasso} ShowAlert={this.ShowAlert} enviado={enviado}/> }
        {step == tela_fluxo.tela_final && <Resumo history={this.props.history} code={code} link={link} docs={docs} dat={dat} />}
        </FadeIn>
      </div>
    );

  }

}
