import { isMobile } from 'react-device-detect';
import etapas from './utils/etapas'
import etapasBpo from './utils/etapas-bpo';
import styled from 'styled-components'
import etapas_fgts from '../images/fluxo_fgts.png';

interface IEtapa {
  id? : number | undefined
  type? : string
}

interface IProgress {
  etapa: number
}

const Progress = styled.img.attrs((props: IProgress) => ({etapa: props.etapa})
)<IProgress>`
  width: ${isMobile? '375px': '479px'}; 
  height: ${isMobile? '45px' : '55px'}; 
  margin: 0px 5px;
  object-fit: cover;
  object-position: ${props=> {
    switch(props.etapa){
      case 1:
        return '0 0%';
      case 2:
        return '0 25%';
      case 3:
        return '0 51.15%';
      case 4:
        return '0 75%';
      default:
        return '0 100%';
    }}}`

export default function Etapa({ id, type } : IEtapa){

  if(type == 'fgts'){
    return(
    <div style={{ textAlign: 'center', marginTop: '35px'}}>
      <Progress src={etapas_fgts} etapa={id}/>
    </div>
    );
  }

  let index : number = id != undefined? id: 6 //insere o último se não receber o id de parametro
  const {REACT_APP_PERFIL} = process.env
  const perfil = REACT_APP_PERFIL 

  if(isMobile){

    return(
      <div style={{ textAlign: 'center', marginTop: '35px'}}>
        <img src={perfil == 'BPO'?etapasBpo[index]: etapas[index]} width='90%' height={25} alt='etapa'/>
      </div>
    );
  }else{
    return(
      <div style={{ textAlign: 'center', marginTop: '35px'}}>
        <img src={perfil == 'BPO'?etapasBpo[index]: etapas[index]} width={960} height={70} alt='etapa'/>
      </div>
    );
  }
}