import React from 'react'
import * as faceapi from "face-api.js";
import Loading from './Loading'
import './styles/facedetect.css'
import Info from './Info'
import mascara from '../images/mask.png';
import {ModelAnimatedMask, ModelSampleMask} from './styles/modelmask'

import {Device, FacePosition} from '../ts/types/Components.types'

interface PropFaceDetect {
  UpdateVal: (v : FacePosition) => void
  InsertVideoBlob: (blob: Blob) => void
  start: boolean,
  stop: boolean,
  showMask: boolean,
  timeRecord: number
  //size?: number
}

interface State {
  canvas: HTMLCanvasElement | null,
  mostrar: boolean,
  started: boolean,
  stopped: boolean,
  recorded: boolean,
  parts: BlobPart[],
  expressions: any
}

export default class FaceDetect extends React.Component<PropFaceDetect, State> {
  mediaRecorder!: MediaRecorder
  mediaStream!: MediaStream
  video: React.RefObject<HTMLVideoElement>
  _isMonted = false;

  constructor(props : PropFaceDetect){
    super(props)
    this.video = React.createRef<HTMLVideoElement>();
    this.stopRecording = this.stopRecording.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.stopRecord = this.stopRecord.bind(this);
    //this.size = this.props.size;
    this.state = {
      canvas: null,
      mostrar: false,
      started: false,
      stopped: false,
      recorded: false,
      parts: [],
      expressions: []
    }
  }
  
  componentDidMount(){
    this._isMonted = true;
    const {recorded} = this.state;
    this.run().then(() => this.setState({mostrar: true}));
    if(this.props.timeRecord > 0){
      window.setTimeout(()=> {
        if(this._isMonted){
          if(!recorded){
            this.stopRecord();
          }
        }
      },this.props.timeRecord)
    }

  }

  componentWillUnmount(){
    const {recorded} = this.state;
    this._isMonted = false;

    if(!recorded){
      this.stopRecording();
    }
  }

  stopRecording = () => {
    const {parts, stopped} = this.state;

    if(!stopped) {
      console.log(this.mediaRecorder);
      try{
        this.mediaRecorder.stop();
      }catch(exception){
        console.log(exception);
      }
    }
    const blob = new Blob(parts, {
      type: 'video/mp4'
    })
    this.props.InsertVideoBlob(blob)

    this.mediaStream.getTracks().forEach(track => {
      track.stop();
    })
    if(this.video.current){
      this.video.current.srcObject = null;
    }
    this.setState({recorded: true, stopped: true});
  }

  run = async () => {
    console.log("run started");
    try {
      await faceapi.nets.tinyFaceDetector.load("/models/");
      await faceapi.loadFaceExpressionModel('/models/');
      await faceapi.loadFaceLandmarkModel('/models/');
      const options = {
        video: {
          width: { ideal: 620, max: 640 },
          height: { ideal: 420, max: 489 },
        },
        audio: false
      };

      const ReceiveStream = (stream: MediaStream) => {
        this.mediaStream = stream;
        if(this.video.current){
          this.video.current.srcObject = this.mediaStream;
        }
        this.mediaRecorder = new MediaRecorder(this.mediaStream, {
          audioBitsPerSecond: 128 * 1000, // 128 kbit/s
          videoBitsPerSecond: 2 * 1000 * 1000, // 2 Mbit/s
        });
      }

      if("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices){
        navigator.mediaDevices.getUserMedia(options).then(ReceiveStream).catch(e => {console.log(`Erro ao abrir a camera: ${e}`)});
      }else{
        const getUserMedia = (
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia).bind(navigator);

        getUserMedia(options, ReceiveStream, (e) => {console.log(`Erro ao abrir a camera: ${e}`)})
      }
    } catch (e) {
      console.log(`Erro ao abrir a camera: ${e}`);
    }
  }

  startRecording = () => {
    //console.log("started recording")
    this.mediaRecorder.start(1000);
    this.setState({started: true})
  }

  stopRecord = () => {
    const {started, stopped} = this.state;
    if(started && !stopped) {
      //console.log('stopping recording')
      try{
        this.mediaRecorder.stop();
      }catch(exception){
        console.log(exception);
      }
      this.setState({stopped: true});
    }
  }

  loadCanvas = () => {
    if(this.video.current){
      const canvas = faceapi.createCanvasFromMedia(this.video.current)
      this.setState({canvas: canvas})
    }
  }

  onPlay = async () => {
    const {started, stopped} = this.state;

    if(this.props.start && !started){
      await this.startRecording();
    }

    if(this.props.stop && !stopped){
      if(!this.state.recorded){
        await this.stopRecord();
      }
      return;
    }
    if(!this._isMonted){
      return;
    }

    /*if(!started && !this.props.showMask){
      this.mediaRecorder.start(1000);
      this.setState({started: true});
    }*/
    
    if (
      this.video.current == null ||
      this.video.current.paused ||
      this.video.current.ended ||
      !faceapi.nets.tinyFaceDetector.params
      ) {
        setTimeout(() => this.onPlay());
        return;
      }
      
    if(this.video.current){
      const {parts} = this.state;
      this.mediaRecorder.ondataavailable = (e) => {
        parts.push(e.data)
        if(this._isMonted){
          this.setState({ parts })
        }
      }
    }

    /*
    if(this.state.canvas == null){
      this.loadCanvas();
    }
    
    const {canvas} = this.state;
    if(canvas != null){
      faceapi.matchDimensions(canvas, displaySize)
    }
    */
    //const {width, height} = this.size
    //const displaySize = { width: width, height: 600 }
    
    const options = new faceapi.TinyFaceDetectorOptions({
      inputSize: 512,
      scoreThreshold: 0.5
    });

    const result = await faceapi
      .detectSingleFace(this.video.current, options)
      .withFaceLandmarks()
      .withFaceExpressions();

    if (result) {
      const expressions = result.expressions
      //const resizedDetections = faceapi.resizeResults(result, displaySize)
      //console.log(resizedDetections)
      //canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
      //faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
      //faceapi.draw.drawFaceExpressions(canvas, resizedDetections)
      //console.log(expressions);
      //console.log('Nose:')
      //console.log(result.landmarks.getNose()[0])
      if(this._isMonted){
        this.setState({ expressions });
        this.props.UpdateVal({felicidade: expressions['happy'], x: result.landmarks.getNose()[0].x, y: result.landmarks.getNose()[0].y})
      }
    }

    if(this._isMonted){
      setTimeout(() => this.onPlay(), 1000);
    }
  };

  render(){
    //const {expressions} = this.state
    const {mostrar} = this.state;
    let os : Device[] = Info.getSystem();

    if(mostrar) {
      if((os[0] == 'Android' && os[1] as number <= 9) || (os[0] == 'IOS' && os[1] as number <= 10) || (os[0] == 'Windows' && os[1] as number <= 7)){
        return(
          <ModelSampleMask mask={this.props.showMask? 1: 0}>
            <img src={mascara} alt='mascara'/>
            <video ref={this.video} onPlay={this.onPlay} autoPlay playsInline muted/>
          </ModelSampleMask>
        );
      }
      return(
        <ModelAnimatedMask mask={this.props.showMask? 1: 0}>
          <video ref={this.video} onPlay={this.onPlay} autoPlay playsInline muted/>
        </ModelAnimatedMask>
      );
    } else {
      return ( 
        <div style={{marginTop: '20px'}}>
          <Loading />
        </div> 
      );
    }
  }

}