export const status = {
	andamento: 1,
	finalizado: 2,
	enviado_bpo: 3,
	token_expirado: 4
}

export const perfil = {
	operador_ti: 1,
	administrador: 2,
	operador: 3
}

export const etapa = {
	inicial: 0,
	photo_face: 1,
	prova_vida: 2,
	cnh_frente: 3,
	cnh_verso: 3,
	comprovante: 4,
	contracheque: 5,
	final: 6
}

export const status_doc = {
	nao_enviado: -1,
	enviado_digital: 0,
	enviado_console: 1
}

export const StatusStr = (stat: number) : string => {
	switch (stat) {
		case status.andamento:
			return 'Andamento';
		case status.finalizado:
			return 'Finalizado';
		case status.enviado_bpo:
			return 'Enviado para a console';
		case status.token_expirado:
			return 'Token Expirado';
		default:
			break;
	}
	return 'Indeterminado';
}

export const EtapaStr = (eta: number) : string => {
	switch(eta){
		case etapa.inicial:
			return 'Tela Inicial'
		case etapa.photo_face:
			return 'Selfie'
		case etapa.prova_vida:
			return 'Prova de Vida'
		case etapa.cnh_frente:
			return 'CNH'
		case etapa.cnh_verso:
			return 'CNH'
		case etapa.comprovante:
			return 'Comprovante'
		case etapa.contracheque:
			return 'Contracheque'
		case etapa.final:
			return 'Finalizado'
		default:
			break;
	}
	return 'Indeterminado';
}

export const getPerfilStr = (perf: number) : string => {
	switch(perf){
		case perfil.operador:
			return 'Operador';
		case perfil.administrador:
			return 'Administrador';
		case perfil.operador_ti:
			return 'Operador TI';
		default:
			break;
	}
	return 'Desconhecido';
}

export const getStatusDocumento = (status: number | undefined) : string => {
	switch(status) {
		case status_doc.nao_enviado:
			return 'Não enviado';
		case status_doc.enviado_digital:
			return 'Enviado para o Digital';
		case status_doc.enviado_console:
			return 'Enviado para a Console';
		default:
			break;
	}
	return 'Indeterminado';
}