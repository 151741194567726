//import { useState } from 'react'
import { Table, TableBody, TableContainer, TableRow, TableCell, Paper, Icon, Button } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import Loading from "../../components/LoadingManager";
import { ConsultaResumo } from "../../ts/interfaces/Manager.interfaces";
import Alert, { IAlert } from './alerts/alert';

const {REACT_APP_SERVER} = process.env;

const webs = REACT_APP_SERVER

const ConsultaResumoDigital = () : JSX.Element => {
    const [resumo, setResumo] = useState<ConsultaResumo|undefined>(undefined)
    const [alert, setAlert] = useState<IAlert>({message: 'enviado', visible: false})

    useEffect(() => {
      axios.get(webs+'/proposta/consulta-resumo').then((resp) => {
        setResumo(resp.data)
      })
    },[])

    const ProcessaResumo = () => {
      axios.get(`${webs}/proposta/processa-resumo`).then((resp) => {
        if (resp.data == 'OK') {
          setAlert({message: 'Requisição enviada com sucesso! Aguarde alguns minutos até o resumo ser re-processado...', visible: true, color: 'info'})
        }
      }).catch(e => {
        console.log(e);
        setAlert({message: 'Erro ao processar resumo', visible: true, color: 'error'})
      })
    }

    if(!resumo){
      return(<Loading />);
    }

    return(
    <>
    <Alert {...alert}/>
    <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
    <Table aria-label='tabela-proposta'>
      <TableBody>
        <TableRow>
          <TableCell><h5>Resumo de operações da última semana <Icon>check</Icon></h5></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Dispositivo Mais Utilizado:</b></TableCell>
          <TableCell>{resumo?.dispositivo_mais_utilizado? resumo?.dispositivo_mais_utilizado: 'Não Identificado'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Navegador Mais Utilizado:</b></TableCell>
          <TableCell>{resumo?.navegador_mais_utilizado? resumo?.navegador_mais_utilizado : 'Não Identificado'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Percentual de conclusão de faceMatch:</b></TableCell>
          <TableCell>{resumo?.percentual_acc_face_match} %</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Percentual de conclusão de movimentos da Prova de Vida:</b></TableCell>
          <TableCell>{resumo?.percentual_acc_prova_vida} %</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Propostas em andamento:</b></TableCell>
          <TableCell>{resumo?.andamento} / {resumo?.andamento > 0? (resumo?.andamento/resumo?.total) * 100 : 0} %</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Propostas concluidas e enviadas:</b></TableCell>
          <TableCell>{resumo?.concluido} / {resumo?.concluido > 0? (resumo?.concluido/resumo?.total) * 100 : 0} %</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>Total de propostas recebidas pelo digital:</b></TableCell>
          <TableCell>{resumo?.total}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center"><Button sx={{borderColor: "#000", color:"#000"}} onClick={() => ProcessaResumo()} variant="outlined"><Icon>sync</Icon> Re-processar Resumo</Button></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  </>
  )

}

export default ConsultaResumoDigital