import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap';
import FadeIn from 'react-fade-in'
import Header from '../components/header'
import error from '../images/error.png'
import { isBrowser } from 'react-device-detect'

const {REACT_APP_NAME_LECCA} = process.env

export default class TelaErroInesperado extends React.Component {
  
  render(){
    if(isBrowser){
      return(
        <div>
            <FadeIn delay={150}>
                <Header/>

                <div className='card-style'>
                  <Card
                    body
                    className="text-center">
                      <img src={error} width='110px' height='105px' style={{margin: '20px auto'}} alt='failed'/>
                      <CardTitle tag="h5">
                        Erro Inesperado
                      </CardTitle>
                      <CardBody>
                        <p><em>Desculpe, houve um erro inesperado em nossos servidores, 
                          por favor tente novamente mais tarde. </em></p>

                        <p><em>Time {REACT_APP_NAME_LECCA}</em></p>
                      </CardBody>
                  </Card>
                </div>
            </FadeIn>
      </div>
      );
    }else{
      return(
        <div>
          <FadeIn delay={150}>
              <Header/>
              
               <div className='card-style-mobile'>
                 <Card
                   body
                   className="text-center">
                     <img src={error} width='90px' height='80px' style={{margin: '20px auto'}} alt='failed'/>
                     <CardTitle tag="h5">
                        Erro Inesperado
                     </CardTitle>
                     <CardBody>

                       <p><em>Desculpe, houve um erro inesperado em nossos servidores, 
                          por favor tente novamente mais tarde.</em></p>
                        
                       <p><em>Time {REACT_APP_NAME_LECCA}</em></p>
                     </CardBody>
                 </Card>
               </div>
            </FadeIn>
      </div>
      );
    }
  } 
}