import React from 'react'
import '../telas/styles/estilo.css'
import FadeIn from 'react-fade-in';
import { Button, Card, CardBody, CardTitle, Input } from 'reactstrap';

import Document from '../components/Document'
import Camera from '../components/CameraFluxo'
import {videocons, CardInfo, ButtonsInlineCard, OpcoesBtn, ResPic, ButtonsInline, Command} from '../telas/styles/estilo_telas'

import { OpcaoText, AnexarText, TirarFotoText} from '../telas/text/identidade.text'
import { FiletoBase64, IsFileUpperBound, IsValidExtension } from '../util/Utils'

import { isBrowser } from 'react-device-detect';

import { PropTelaP, HTMLInputEvent } from '../ts/interfaces/PropTelas.interfaces';
import { Base64Type, Contrato } from '../ts/types/Types';
import Webcam from 'react-webcam';
import Apresentacao from '../components/screen_components/Apresentacao.component';
import { updateInfoBPO, situacao, status } from '../state/actions/Info_actions';
import { documento_id } from '../telas_id';

interface State {
  data: Base64Type,
  data2: Base64Type,
  datab641: Base64Type,
  datab642: Base64Type,
  dataUrl: string | null,
  dataUrl2: string | null,
  step: number,
  verso: boolean,
  refazer: boolean
}

const tela = {
  apresentacao: 0,
  opcao: 1,
  anexar: 2,
  tirarfoto: 3
}

const {REACT_APP_PERFIL} = process.env

export default class IdentidadeTela extends React.Component<PropTelaP, State> {
  webcam: React.RefObject<Webcam>
  contrato!: Contrato

  constructor(props : PropTelaP){
    super(props)
    this.Apresentacao = this.Apresentacao.bind(this)
    this.Opcao = this.Opcao.bind(this)
    this.Anexar = this.Anexar.bind(this)
    this.TirarFoto = this.TirarFoto.bind(this)
    this.Takecapture = this.Takecapture.bind(this)
    this.Takecapture2 = this.Takecapture2.bind(this)
    this.ProximoPasso = this.ProximoPasso.bind(this)
    this.CameraError = this.CameraError.bind(this)
    this.handleImage = this.handleImage.bind(this)
    this.handleImage2 = this.handleImage2.bind(this)
    this.SeguirAnexo = this.SeguirAnexo.bind(this)
    this.SeguirFoto = this.SeguirFoto.bind(this)

    this.webcam = React.createRef<Webcam>();

    this.state = {
      data: null,
      data2: null,
      datab641: null,
      datab642: null,
      dataUrl: null,
      dataUrl2: null,
      step: tela.apresentacao,
      verso: false,
      refazer: true
    }
  }

  componentDidMount() {
    updateInfoBPO({fase: status.tela_cnh, situacao: situacao.sucesso});
  }

  Apresentacao() {

    return(
      <Apresentacao
        id='Identidade'
        onClick={()=>this.setState({step: isBrowser? tela.opcao : tela.tirarfoto})}
      />
    );
  }

  Opcao(){
    return(
      <FadeIn>
          <CardInfo>
            <Card 
              body
              className="text-center">
              <CardBody>
                <CardTitle tag="h5">
                  {OpcaoText.title}
                </CardTitle>

                <OpcoesBtn>
                  <Button color='success' onClick={()=>this.setState({step: tela.anexar})}> Anexar foto</Button>
                  <Button color='info' onClick={()=>this.setState({step: tela.tirarfoto})}> Tirar Foto </Button>
                </OpcoesBtn>
              </CardBody>
            </Card>
          </CardInfo>
        </FadeIn>
    );
  }

  async handleImage(event : HTMLInputEvent) {
    const file : File | null = event.target.files? event.target.files[0] : null
    if(file) {
      if(IsFileUpperBound(file)){
        this.props.ShowAlert(AnexarText.error)
      }else if(!IsValidExtension(file)){
        this.props.ShowAlert(AnexarText.error2)
      }else{
        this.setState({
          data: file,
          dataUrl: URL.createObjectURL(file)
        })
        const datab64 = await FiletoBase64(file)
        this.setState({datab641: datab64})
      }
    }
  }

  async handleImage2(event : HTMLInputEvent){
    const file : File | null = event.target.files? event.target.files[0] : null
    if(file){
      if(IsFileUpperBound(file)){
        this.props.ShowAlert(AnexarText.error)
      }else if(!IsValidExtension(file)){
        this.props.ShowAlert(AnexarText.error2)
      }else{
        this.setState({
          data2: file,
          dataUrl2: URL.createObjectURL(file)
        })

        const datab64 = await FiletoBase64(file)
        this.setState({datab642: datab64})
      }
    }
  }

  SeguirAnexo = async () => {
    const{datab641, datab642} = this.state
    if(datab642) {
      await Promise.all([this.props.InsertScreenShot(datab641, documento_id.cnh_frente, true), this.props.InsertScreenShot(datab642, documento_id.cnh_verso)])
    } else {
      await this.props.InsertScreenShot(datab641, documento_id.cnh_frente)  
    }
    this.ProximoPasso()
  }

  SeguirFoto = async () => {
    const{data, data2} = this.state
    if(data2){
      await Promise.all([this.props.InsertScreenShot(data, documento_id.cnh_frente, true), this.props.InsertScreenShot(data2, documento_id.cnh_verso)])
    } else {
      await this.props.InsertScreenShot(data, documento_id.cnh_frente)
    }
    this.ProximoPasso()
  }

  ProximoPasso(){
    this.props.changePasso()
  }

  Anexar(){
    const {verso} = this.state;

    if(verso == false){
      return(
        <FadeIn>
          <CardInfo>
            <Card
              body
              className="text-center">
                {this.state.data? 
                <CardTitle tag="h5">
                  {AnexarText.title1}  
                </CardTitle> :
                <CardTitle tag="h5">
                  {AnexarText.title2}
                </CardTitle>}
                  
                <CardBody>
                  <Input color='success' type='file' onChange={this.handleImage}/>
                  {this.state.data && <Document alt='Anexo Identidade' src={this.state.data} url={this.state.dataUrl} />}
                  {this.state.data && 
                    <ButtonsInlineCard>
                      <Button color='info' onClick={()=> this.setState({verso: true})}> Anexar Verso </Button>
                      <Button id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} onClick={this.SeguirAnexo}> Seguir </Button>
                    </ButtonsInlineCard>
                  }
                </CardBody>
            </Card>
          </CardInfo>
        </FadeIn>
      );
    }else{
      return(
        <FadeIn>
          <CardInfo>
            <Card
              body
              className="text-center">
                <CardTitle tag="h5">
                  {AnexarText.title3}
                </CardTitle>
                <CardBody>
                  <Input color='success' type='file' onChange={this.handleImage2}/>
                  {this.state.data2 && <Document alt='Anexo Identidade' src={this.state.data2} url={this.state.dataUrl2} />}
                  {this.state.data2 && <Button id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} block={true} onClick={this.SeguirAnexo}> Seguir </Button>}
                </CardBody>
            </Card>
          </CardInfo>
        </FadeIn>
      );
    }
  }

  Takecapture = async () => {
    var screenshot = this.webcam?.current?.getScreenshot();
    if(screenshot){
      this.setState({data: screenshot, refazer: false})
    }else{
      this.props.ShowAlert(TirarFotoText.error)
    }
  }

  Takecapture2 = async() => {
    var screenshot = this.webcam?.current?.getScreenshot();
    if(screenshot){
      this.setState({data2: screenshot, refazer: false})
    }else{
      this.props.ShowAlert(TirarFotoText.error)
    }
  }

  CameraError = () => {
    this.setState({data: null, refazer: true})
    this.props.ShowAlert(TirarFotoText.error)
  }

  TirarFoto() {
    const {verso, refazer} = this.state;

    if(verso == false){
      return(
        <FadeIn delay={50}>
          <div style={{marginTop: '50px', marginBottom: '100px'}}>
            <Command>
              {refazer? 
              <p><b>{TirarFotoText.title1}</b></p>
              :
              <div>
                <p><b>{TirarFotoText.title2}</b></p> 
                <p><b>{TirarFotoText.title3}</b></p>
                <p><b>{TirarFotoText.title4}</b></p> 
              </div>
              }
            </Command>

            {refazer? 
              <Camera webcam={this.webcam} videocons={videocons} CameraError={this.CameraError} onClick={this.Takecapture} />
              : <ResPic>
                
                <img alt='Pic foto' src={this.state.data as string}/>

                {this.state.data && (
                  <ButtonsInline>
                    <Button color='info' onClick={()=>this.setState({refazer: true})}> Tirar Novamente </Button>
                    <Button color='info' onClick={()=> this.setState({verso: true, refazer: true})}> Fotografar Verso </Button>
                    <Button id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple2'} onClick={this.SeguirFoto}> Seguir </Button>
                  </ButtonsInline>
                  )
                  }
                </ResPic>
              }
          </div>
        </FadeIn>
      );

    } else {
      return(
        <FadeIn delay={50}>
        <div style={{marginTop: '50px', marginBottom: '100px'}}>
            <Command>
              {refazer? 
              <p><b>{TirarFotoText.title5}</b></p>
              :
              <div>
              <p><b>{TirarFotoText.title2}</b></p> 
              <p><b>{TirarFotoText.title6}</b></p>
              </div>
              }
            </Command>

            {refazer? 
            <Camera webcam={this.webcam} videocons={videocons} CameraError={this.CameraError} onClick={this.Takecapture2} />
            : <ResPic>
                
              <img alt='Pic foto' src={this.state.data2 as string} />
              
            {this.state.data2 && 
            <ButtonsInline>
              <Button color='info' onClick={()=>this.setState({refazer: true})}> Tirar Novamente </Button>
              <Button id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple2'} onClick={this.SeguirFoto}> Seguir </Button>
            </ButtonsInline>
          }
            </ResPic>}
                
          </div>
        </FadeIn>
      );
     }

  }

  render(){
    const {step} = this.state;

    switch(step){
      case tela.apresentacao:
        return(this.Apresentacao());
      case tela.opcao:
        return(this.Opcao());
      case tela.tirarfoto:
        return(this.TirarFoto());
      case tela.anexar:
        return(this.Anexar());
      default:
        break;
    }
    return null;
  }
  
}