export const ApresentacaoText = {
  title1: 'Foto com documento',
  text1: 'Agora vamos precisar que você nos envie uma foto do seu rosto próximo a sua CNH ou da sua identidade. Lembre-se que precisa ser o documento original. Fique atento nas dicas de como posicionar seu documento para a foto.',
  text2: ''
}

export const OpcaoText = {
  title: 'Escolha uma opção de sua preferência'
}

export const AnexarText = {
  title1: 'Se na imagem tem só a frente do seu documento, vamos precisar que você nos envie também o verso',
  title2: 'Envie uma imagem do seu documento',
  title3: 'Envie uma imagem do verso do seu documento',
  error: 'Documentos precisam estar abaixo de 2MB',
  error2: 'Documentos precisam ser imagens ou pdf: .jpg, .jpeg, .png, .bmp, .gif, .pdf'
}

export const TirarFotoText = {
  title1: 'Posicione o Documento próximo ao seu rosto, em seguida tire a foto.',
  title2: 'Seu Rosto e seu documento estão visíveis?',
  title3: 'Se a resposta for sim, você pode seguir para a próxima etapa.',
  title4: 'Tire sua foto em um local bem iluminado',
  title5: 'Se a resposta for sim, você pode seguir para a próxima etapa. Se você tirou uma foto só da frente, vamos precisar que você tire uma foto também do verso do documento.',
  error: 'Habilite sua camera, caso queira prosseguir.'
}