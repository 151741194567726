import footer from './imgdoc/barfooter.png'
import logo from './imgdoc/logo_termo.jpg'
import { isMobile } from 'react-device-detect'
import { IDocument } from '../../ts/interfaces/Components.interfaces'
import { IStyleProps } from '../../ts/interfaces/Components.interfaces'
import { getStateJornada } from '../../state/actions/jornada_actions'
import { Estado } from './empregador_id'
import logobpo from '../../images-bpo/logo-ccb-2.png'

const {REACT_APP_PERFIL, REACT_APP_NAME, REACT_APP_NAME_LOW, REACT_APP_NAME_CAPS, REACT_APP_NAME_LECCA} = process.env



const TermoGen = ({ Status } : IDocument) => {
  let w = 500
  let h = 550
  if(isMobile){
    w = window.innerWidth * 0.89
    h = 318
  }
  const fontS = '12px/16px'
  const {Proposta} = getStateJornada().contrato

  const styles : IStyleProps = {
    scroll: {width:w, height:h,backgroundColor: '#adb8b3',border:'1px solid #ccc',font:fontS+' Arial, Helvetica, sans-serif',overflow:'auto'},
    paper : {backgroundColor: 'white', height: 'auto', width: w},
    paper_m: {backgroundColor: 'white', height: 'auto', width: w, marginTop: 20},
    text : {width: 0.9*w, textAlign: 'justify', margin:'20px 15px auto'}
  }

  const AddBox = (text : JSX.Element) => {
    return(
    <div style={{border: '1px solid black', marginLeft: '3px', marginTop: '15px', width: w*0.9}}>
      <div style={{marginLeft: 5, marginRight: 10, marginTop: 3}}>
        {text}
      </div>
    </div>);
  }

  const Header = () => {
    return(
      <div style={{textAlign: 'center'}}>
        <img src={REACT_APP_PERFIL == 'BPO'?logobpo :logo} width={w*0.9} height='auto' alt='logo-termo'/>
      </div>
    );
  }

  const Footer = () => {
    return(
      <div>
        <img src={footer} style={{marginLeft: 15}} width={w*0.9} height={5} alt='footer-termo'/>
        <div style={{width: 0.9*w, textAlign: 'justify', margin: '20px 15px auto'}}>
        <p><b>CENTRAL DE ATENDIMENTO:</b></p>
        <p><b>Telefone: (11) 2365-5240 | faleconosco@{REACT_APP_NAME_LOW}.com.br</b></p>
        <p><b>Site: www.{REACT_APP_NAME_LOW}.com.br (via chat)</b></p>
        <p><b><i>Para dúvidas ou outras questões relacionadas ao Cartão {REACT_APP_NAME} e 
          operações de crédito cujos pagamentos serão realizados mediante desconto em folha de pagamento.</i></b></p>
        </div>
        <br/>
      </div>
    );
  }

  const TermoV1 = () => {
    return(
      <div onScroll={Status} style={styles.scroll}>
        <div style={styles.paper}>
          {Header()}
            <div style={styles.text}>
              <p>Por este instrumento particular, de um lado, o “<u>PORTADOR</u>”, identificado e qualificado 
                no Formulário de Adesão; e, de outro lado, <b>{REACT_APP_NAME_CAPS} SERVIÇOS TECNOLÓGICOS E FINANCEIROS LTDA.</b>,
                inscrita no CNPJ sob o nº 29.871.099/0001-89, com sede na Rua Joaquim Floriano, nº 413 - 11º andar
                – Itaim Bibi – São Paulo / SP, CEP 04543-011 (“<u>{REACT_APP_NAME_CAPS}</u>”);  
                têm entre si justo e acordado este Termo de Fornecimento do Cartão {REACT_APP_NAME} (“<u>Termo</u>”), 
                de acordo com as cláusulas e condições abaixo.</p>
              {AddBox(
              <b>
                  <p>Ao aceitar eletronicamente, com a marcação da caixa de diálogo “Eu li e aceito os termos e condições”, 
                  o PORTADOR estará automaticamente aderindo e concordando com as condições deste Termo.</p>
  
                  <p>Mediante a adesão a este Termo, o PORTADOR expressamente autoriza que a Fonte Pagadora
                  compartilhe seus dados pessoais e todas as informações relacionadas aos Créditos.</p>
  
                  <p>O PORTADOR expressamente autoriza a {REACT_APP_NAME_CAPS}, por intermédio da Instituição Financeira, 
                  a ter acesso a informações financeiras a seu respeito obtidas perante o SCR, 
                  e que podem conter dados protegidos por sigilo bancário, nos termos da Lei Complementar nº 105/2001.</p>
  
                  <p>O PORTADOR declara-se ciente e concorda que, o Crédito foi disponibilizado em razão (i) 
                  da sua relação de trabalho com a Fonte Pagadora e (ii) da margem consignável disponível 
                  informada pela Fonte Pagadora, sendo que as Transações apenas poderão ser realizadas de 
                  acordo com o limite e finalidade estabelecidos na legislação aplicável, incluindo as transações 
                  realizadas para a subsistência do PORTADOR, para o custeio de suas atividades vitais básicas, 
                  como moradia, alimentação, educação, saúde, lazer, vestuário, higiene, transporte, 
                  aquisição de bens duráveis, liquidação de dívidas, reorganização de sua situação econômica-financeira.</p>
  
                  <p>A {REACT_APP_NAME_CAPS} poderá periodicamente alterar as condições deste Termo; podendo o 
                  PORTADOR, caso não concorde com a modificação, denunciá-lo sem quaisquer ônus ou 
                  penalidades. A versão atualizada deste Termo poderá ser consultada a qualquer momento 
                  pelo PORTADOR, mediante acesso ao link: www.{REACT_APP_NAME_LOW}.com.br</p>
              </b>)}
              <br/>
              <p><b>1. Definições</b></p>
  
              <p>1.1. As palavras e expressões abaixo, indicadas neste Termo pela primeira letra maiúscula, 
                terão as seguintes definições:</p>
  
              <p>“<u>Cartão {REACT_APP_NAME}</u>”: instrumento de identificação e de pagamento, 
              fornecido pela {REACT_APP_NAME_CAPS}, que possibilita ao PORTADOR realizar 
              Transações de Compra ou Saque.</p>
  
            </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>“<u>Compra</u>”: operação realizada pelo PORTADOR mediante a utilização do Cartão {REACT_APP_NAME}, 
            para o pagamento decorrente da venda de bens e serviços</p>
            <p>“<u>Crédito</u>”: Limite de crédito concedido ao PORTADOR em razão (i) da sua relação de trabalho 
            com a Fonte Pagadora e (ii) considerando as margens de consignação disponíveis informadas pela Fonte Pagadora. 
            Poderá ser disponibilizado ao PORTADOR limite de crédito específico para compra e limite de crédito 
            específico para saque. O Crédito pode ser oriundo de antecipação da remuneração do PORTADOR.</p>
  
            <p>“<u>Domicílio</u>”: conta de livre movimentação de titularidade do PORTADOR, mantida perante 
            instituição bancária ou de pagamento, na qual receberá os pagamentos decorrentes dos Saques.</p>
  
            <p>“<u>Estabelecimento</u>”: pessoa jurídica ou física, fornecedora de bens e serviços que receberá 
            os pagamentos decorrentes das Transações de Compra com o Cartão {REACT_APP_NAME}.</p>
  
            <p>“<u>Fonte Pagadora</u>”: órgão público credenciado ou conveniado com a Instituição Financeira 
            para o fornecimento do Cartão {REACT_APP_NAME_CAPS}, com o qual o PORTADOR, em razão de sua relação de 
            trabalho ou previdenciária, possui Créditos periódicos a receber (vencimentos, remuneração, 
            adicionais, bônus e benefícios entre outros).</p>
  
            <p>“<u>Formulário de Adesão</u>”: formulário eletrônico disponibilizado ao PORTADOR, constando os 
            dados pessoais e demais informações do PORTADOR, e pelo qual haverá a adesão a este Termo.</p>
  
            <p>“<u>Instituição Financeira</u>”: {REACT_APP_NAME_LECCA} – Crédito, Financiamento e Investimento S.A., inscrita 
            no CNPJ sob o nº 29.871.099/0001-89, com sede na Rua São José, nº 20, sala 201 - Rio de Janeiro / RJ, 
            CEP 20010-020; ou outra que venha a celebrar parceria com a {REACT_APP_NAME_CAPS} 
            (conforme indicado na respectiva CCB).</p>
  
            <p>“<u>Plataforma</u>”: site disponível em www,{REACT_APP_NAME_LOW}.com.br e aplicativo para dispositivos 
            móveis, de titularidade da {REACT_APP_NAME_CAPS}, pelo qual o PORTADOR poderá realizar as Transações.</p>
  
            <p>“<u>PORTADOR</u>”: pessoa física, maior e capaz, que aderiu a este Termo por meio do 
            Formulário de Adesão.</p>
  
            <p>“<u>Saque</u>”: operação pela qual o PORTADOR utiliza o Cartão {REACT_APP_NAME} para o resgate 
            de recursos, que serão transferidos para o Domicílio.</p>
  
            <p>“<u>Transação</u>”: operação de Compra, Saque ou outra que venha a ser disponibilizada pela 
            {REACT_APP_NAME_CAPS}, realizada pelo PORTADOR mediante a utilização do Cartão {REACT_APP_NAME}.</p>
            
            <p><b>2. Fornecimento do Cartão {REACT_APP_NAME}</b></p>
  
            <p>2.1. Este Termo tem por objetivo estabelecer as condições pelas quais a {REACT_APP_NAME_CAPS} 
              irá fornecer o Cartão {REACT_APP_NAME}, para que o PORTADOR possa realizar Transações de: 
              (i) Compra; e/ou (ii) Saque, mediante a transferência de recursos para o Domicílio 
              cadastrado.</p>
  
            <p>2.1.1. Em razão da emissão do Cartão {REACT_APP_NAME}, a {REACT_APP_NAME_CAPS} prestará ao PORTADOR 
              os serviços de:</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>(a) Análise das informações e documentos do PORTADOR, com a finalidade de verificar 
            a viabilidade de emissão do Cartão {REACT_APP_NAME};</p>
  
            <p>(b) A disponibilização da fatura ao PORTADOR, contendo: (i) o histórico de Transações 
            realizadas no período de apuração; (ii) o valor total devido pelo PORTADOR, de acordo 
            com as Transações realizadas; e (iii) as demais informações relacionadas ao Cartão {REACT_APP_NAME}; e</p>
  
            <p>(c) O fornecimento da tecnologia necessária para a captura, processamento e liquidação 
            das Transações realizadas com Cartão {REACT_APP_NAME}. </p>
  
            <p>2.1.2. <b>O PORTADOR declara-se ciente e concorda que, diante da natureza da 
              operação decorrente do Fornecimento do Cartão {REACT_APP_NAME}:</b></p>
            <b>
            <p>(a) Com a solicitação do fornecimento do Cartão {REACT_APP_NAME}, 
              haverá disponibilização da função Saque, sendo que na hipótese de realização 
              de Saque haverá a emissão de uma CCB em benefício da Instituição Financeira, 
              formalizando o empréstimo de recursos (mútuo), com a incidência de juros e outros 
              encargos;</p>
  
            <p>(b) Após a emissão da CCB, a mesma será endossada para a {REACT_APP_NAME_CAPS}, que se 
              tornará a legítima credora dos valores devidos pelo PORTADOR em razão da realização 
              das Transações com o Cartão {REACT_APP_NAME};</p>
  
            <p>(c) A Fonte Pagadora é responsável por informar à {REACT_APP_NAME_CAPS}, mensalmente, 
              sobre o valor dos Créditos devidos ao PORTADOR e eventuais alterações aplicáveis;</p>
  
            <p>(d) A Fonte Pagadora irá realizar a retenção do valor integral 
              das faturas (incluindo as tarifas aplicáveis) da remuneração que lhe é devida, 
              para posterior repasse à {REACT_APP_NAME_CAPS};</p>
  
            <p>(e) A Fonte Pagadora poderá, a seu exclusivo critério, determinar restrições 
              para o PORTADOR, em conjunto com os demais servidores, com relação ao valor e/ou 
              natureza das Transações a serem realizadas por meio do Cartão {REACT_APP_NAME}, 
              de acordo com a legislação aplicável;</p>
  
            <p>(f) Caso o seu Crédito seja decorrente de remuneração a ser paga em razão 
              de relação de trabalho com a Fonte Pagadora, as Transações apenas poderão ser realizadas 
              de acordo com o limite estabelecido na legislação aplicável, e as Transações 
              deverão ser necessariamente realizadas com observância às finalidades indicadas 
              na legislação aplicável, incluindo a subsistência do PORTADOR, o custeio de 
              suas atividades vitais básicas, como moradia, alimentação, educação, saúde, 
              lazer, vestuário, higiene e transporte, aquisição de bens duráveis, liquidação 
              de dívidas, reorganização de sua situação econômicafinanceira; e</p>
            </b>
            <p>(g) Sem prejuízo de outras condições adotadas, 
              é condição para a aprovação da solicitação de emissão 
              do Cartão {REACT_APP_NAME}, assim como, para a manutenção 
              das suas funcionalidades de compra e saque ativas, a regularidade do pagamento 
              da remuneração e ou benefícios feitos pela Fonte Pagadora, autorizando, desde já, 
              que a {REACT_APP_NAME_CAPS} bloqueie ou cancele o Cartão {REACT_APP_NAME} na hipótese 
              de suspensão ou interrupção, definitiva ou temporária, dos pagamentos 
              da remuneração ou benefícios pela Fonte Pagadora.</p>
  
            <p><b>3. Credenciamento do PORTADOR</b></p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>3.1. A adesão do PORTADOR a este Termo ocorrerá mediante: (i) 
              o aceite eletrônico deste Termo ; e (ii) a aprovação da solicitação 
              de fornecimento do Cartão {REACT_APP_NAME} feita pela {REACT_APP_NAME_CAPS}.</p>
            <p>3.1.1. A adesão deste Termo, assim como a aprovação da solicitação de fornecimento 
              do Cartão {REACT_APP_NAME} feita pela {REACT_APP_NAME_CAPS} também poderão ocorrer através 
              da central de atendimento da {REACT_APP_NAME_CAPS} (inclusive por telefone), 
              WhatsApp ou quaisquer outros meios que possibilitem a manifestação de vontade do PORTADOR.</p>
            <p>3.1.2. Ao aderir a este Termo, o PORTADOR deverá 
              observar à todas as regras relacionadas com a utilização 
              do Cartão {REACT_APP_NAME}; de acordo com as políticas e diretrizes 
              da {REACT_APP_NAME_CAPS} disponibilizadas na Plataforma.</p>
            <p>3.2. Ao solicitar o fornecimento do Cartão {REACT_APP_NAME}, 
              o PORTADOR: (i) deverá prestar todas as informações solicitadas 
              no Formulário de Adesão; (ii) responsabiliza-se civil e 
              criminalmente pela veracidade das informações prestadas; e 
              (iii) obriga-se a manter seus dados atualizados junto à {REACT_APP_NAME_CAPS}.</p>
            <p>3.2.1. A {REACT_APP_NAME_CAPS} poderá, a qualquer momento e a seu exclusivo critério, 
              solicitar ao PORTADOR o envio de informações adicionais e/ou de documentos 
              que comprovem as informações prestadas.</p>
            <p>3.2.2. Caso a {REACT_APP_NAME_CAPS} constate haver dados incorretos ou inverídicos, 
              ou, ainda, caso o PORTADOR se recuse a enviar as informações e documentos 
              solicitados, a {REACT_APP_NAME_CAPS} poderá negar o fornecimento do Cartão {REACT_APP_NAME} 
              e não liberar o acesso à Plataforma, sem prejuízo de outras medidas que 
              entender necessárias; não assistindo ao PORTADOR qualquer tipo de indenização ou ressarcimento.</p>
            <p>3.2.3. A {REACT_APP_NAME_CAPS} poderá, a seu exclusivo critério, realizar pesquisas, 
              em base de dados públicas ou privadas (inclusive o SCR), com a finalidade 
              de verificar a veracidade dos dados e informações indicadas pelo PORTADOR no 
              Formulário de Adesão e a proceder à análise de crédito do PORTADOR.</p>
            <p>3.2.4. O PORTADOR, quando solicitado pela {REACT_APP_NAME_CAPS}, 
              deverá cadastrar login e senha para utilização da Plataforma. 
              O uso do login e senha são de uso pessoal, exclusivo e intransferível pelo 
              PORTADOR, que deverá mantê-los confidenciais e não permitir seu acesso por terceiros.</p>
            <p>3.2.5. O PORTADOR deverá informar e-mail e número de telefone 
              válidos para comunicação com a {REACT_APP_NAME_CAPS}; sendo que qualquer comunicação 
              enviada por e-mail, SMS, WhatsApp ou pela Plataforma será considerada válida 
              e eficaz entre as Partes.</p>
            <p>3.3. O Cartão {REACT_APP_NAME} será emitido em formato físico (cartão plástico), 
              contendo as informações necessárias para identificação do PORTADOR, podendo 
              ser disponibilizado o cartão virtual ao PORTADOR.</p>
            <p>3.3.1. A entrega do Cartão {REACT_APP_NAME} poderá ser realizada mediante: 
              (i) o envio de correspondência, pelo correio, ao endereço indicado 
              pelo PORTADOR; ou (ii) a entrega presencial ao PORTADOR, 
              nos pontos de atendimento da {REACT_APP_NAME_CAPS}.</p>
            <p>3.3.2. Caberá exclusivamente ao PORTADOR a conferência dos dados pessoais 
              contidos no Cartão {REACT_APP_NAME}, antes de utilizá-lo. A sua utilização ou 
              desbloqueio ensejará na concordância do PORTADOR quanto às informações 
              indicadas no Cartão {REACT_APP_NAME}.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
            <div style={styles.text}>
            <p>3.3.3. O Cartão {REACT_APP_NAME} será emitido juntamente com uma senha previamente definida 
              pela {REACT_APP_NAME_CAPS}, de uso pessoal e intransferível pelo PORTADOR.</p>
            <p>3.4. A {REACT_APP_NAME_CAPS} poderá cancelar a utilização do Cartão {REACT_APP_NAME} sempre que, 
              a seu exclusivo critério, houver o descumprimento das obrigações previstas neste 
              Termo ou da legislação vigente, assim como no caso de determinação judicial ou 
              administrativa.</p>
            <p>3.5. Após o recebimento do Cartão {REACT_APP_NAME}, o PORTADOR será o único responsável 
              pela sua guarda e conservação, obrigando-se a zelar pela sua segurança.</p>
            <p>3.5.1. O PORTADOR será o único e exclusivo responsável pelo uso indevido do 
              Cartão {REACT_APP_NAME} até a data da comunicação à {REACT_APP_NAME_CAPS} para a realização do bloqueio.</p>
            <p>3.6. Caberá ao PORTADOR, por motivos justificáveis e mediante o pagamento 
              de eventuais tarifas incidentes, solicitar um novo Cartão {REACT_APP_NAME} à {REACT_APP_NAME_CAPS}, 
              sendo que a nova emissão ensejará no automático cancelamento do Cartão {REACT_APP_NAME} substituído, 
              ficando impedida sua utilização</p>
            <p><b>4. Desbloqueio do Cartão {REACT_APP_NAME} e Emissão da CCB</b></p>
            <p>4.1. Ao solicitar o Cartão {REACT_APP_NAME}, será concedido ao PORTADOR um limite de crédito 
              para a realização das Transações, levando em consideração as informações e documentos 
              apresentados pelo PORTADOR na solicitação de fornecimento do Cartão {REACT_APP_NAME}.</p>
            <p>4.1.1. O PORTADOR autoriza, desde já, a {REACT_APP_NAME_CAPS}, por si ou por intermédio 
              da Instituição Financeira, a consultar bancos de dados cadastrais públicos ou privados, inclusive o SCR 
              e Serviços de Proteção ao Crédito, para a obtenção de informações cadastrais e de crédito.</p>
            <p>4.1.2. O limite de crédito, assim como qualquer informação sobre a utilização e 
              benefícios do Cartão {REACT_APP_NAME} estarão disponíveis nos Canais de Atendimento da {REACT_APP_NAME}</p>
            <p>4.1.3. A aceitação da solicitação do cartão dependerá ainda: 
              (i) da demonstração da prova de vida, mediante o envio de foto do PORTADOR; 
              (ii) do envio de comprovante de residência; e 
              (iii) da concordância do PORTADOR quanto ao valor, taxa de juros, encargos, 
              datas de vencimento e demais condições financeiras (inclusive o Custo Efetivo da Transação - CET).</p>
            <p>4.1.4. A CCB será emitida para formalizar o empréstimo de recursos 
              ao PORTADOR pela Instituição Financeira, mediante o pagamento de juros e 
              outros encargos previstos no respectivo instrumento</p>
            <p><b>4.2. Para possibilitar a concessão do empréstimo formalizado na CCB, 
              o PORTADOR nomeia a {REACT_APP_NAME_CAPS} como sua bastante procuradora, 
              com poderes especiais para, em seu nome e por sua conta, negociar e obter 
              crédito perante a Instituição Financeira; outorgando-lhe poderes especiais para 
              assinar a CCB, abrir conta para movimentar os valores financiados, negociar prazos, 
              juros e outros encargos, repactuar taxas de juros e emitir títulos representativos do débito 
              perante a Instituição Financeira.</b></p>
            </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
            <div style={styles.text}>
            <p>4.2.1. O PORTADOR desde já autoriza a {REACT_APP_NAME_CAPS} a coletar, tratar e compartilhar 
              os seus dados pessoais e financeiros com a Instituição Financeira, para obtenção do 
              empréstimo decorrente da CCB.</p>
            <p>4.2.2. A {REACT_APP_NAME_CAPS} poderá, em nome da Instituição Financeira, cobrar o PORTADOR 
              dos débitos decorrentes dos empréstimos realizados por meio da CCB</p>
            <p>4.3. O PORTADOR declara-se ciente e concorda que o crédito decorrente da 
              CCB será transferido pela Instituição Financeira para a {REACT_APP_NAME_CAPS} logo após a 
              sua emissão; de modo que os pagamentos devidos pelo PORTADOR em razão da 
              realização de Transações com o Cartão {REACT_APP_NAME} deverão ser realizados diretamente 
              à {REACT_APP_NAME_CAPS} ou a quem esta indicar</p>
            <p><b>5. Realização de Transações</b></p>
            <p>5.1. A aquisição de bens e a negociação da forma de pagamento será 
              feita pelo PORTADOR diretamente com o respectivo Estabelecimento, 
              não sendo a {REACT_APP_NAME_CAPS} responsável por quaisquer assuntos envolvendo a operação de 
              aquisição de produtos que deu origem à Transação.</p>
            <p>5.1.1. A {REACT_APP_NAME_CAPS} não se responsabiliza pelo preço, quantidade, qualidade e/ou 
              garantia dos bens adquiridos, cabendo ao PORTADOR resolver tais questões 
              diretamente com o Estabelecimento respectivo.</p>
            <p>5.2. O PORTADOR também poderá utilizar o Cartão {REACT_APP_NAME} para a realização 
              de Saques, mediante a transferência de recursos para o seu Domicílio.</p>
            <p>5.2.1. O valor do Saque será creditado pela {REACT_APP_NAME_CAPS}, no Domicílio 
              informado pelo PORTADOR. Mediante o recebimento do valor respectivo pelo PORTADOR, 
              fica a {REACT_APP_NAME_CAPS} quitada com relação ao pagamento da Transação, de forma 
              irrevogável e irretratável, não havendo nada a mais a ser reclamado a esse título; 
              valendo o comprovante da TED, PIX ou de outra forma de transferência como documento 
              hábil para comprovação da quitação.</p>
            <p>5.2.2. O PORTADOR é responsável por manter a regularidade do Domicílio. Caso o banco 
              ou instituição de pagamento recebedor declare-se impedido, por qualquer motivo, 
              de dar cumprimento às ordens de crédito emitidas pela {REACT_APP_NAME_CAPS}, deverá 
              o PORTADOR providenciar sua regularização ou, ainda cadastrar novo Domicílio, no prazo de até 2 
              (dois) dias úteis.</p>
            <p>5.2.3. A {REACT_APP_NAME_CAPS} está autorizada a reter o pagamento das Transações até a regularização 
              do Domicílio cadastrado, sem que haja quaisquer ônus, penalidades ou encargos.</p>
            <p>5.2.4. Na hipótese de a data prevista para o Saque ser considerada feriado ou em dia de 
              não funcionamento bancário, a transferência poderá ser realizada no primeiro dia útil subsequente.</p>
            <p>5.3. O PORTADOR terá acesso às Transações realizadas por meio dos Canais de 
              Atendimento da {REACT_APP_NAME}, podendo solicitar o saldo e o extrato das movimentações 
              realizadas nos últimos 12 (doze) meses. A disponibilização do extrato dos pagamentos 
              caracteriza-se como prestação de contas para fins legais.</p>
            <p>5.5.1. O PORTADOR terá o prazo de 90 (noventa) dias, para apontar qualquer 
              divergência ou incorreção em relação a qualquer um dos pagamentos realizados. 
              Após esse prazo, o PORTADOR dará a plena e definitiva quitação à {REACT_APP_NAME_CAPS}, 
              não restando direito de qualquer reclamação.</p>
            </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>5.4. É vedada a utilização do Cartão {REACT_APP_NAME} e a realização das Transações para 
              a celebração de negócios: (i) considerados ilícitos, nos termos da legislação brasileira; 
              (ii) considerados como crimes financeiros, com o intuito de lavagem de dinheiro, financiamento 
              ao terrorismo e corrupção, dentre outros crimes correlatos, ainda que indiretamente; 
              (iii) que não representem um negócio jurídico regular e tenham por intenção a prática de fraudes; 
              ou (iv) que, de qualquer modo, venham a causar prejuízos à {REACT_APP_NAME_CAPS}, seus parceiros, 
              Instituição Financeira ou Fonte Pagadora.</p>
            <p>5.4.1. As Transações com indícios ou suspeitas de fraude estarão sujeitas ao cancelamento, 
              ainda que realizada de forma conivente ou não pelo PORTADOR.</p>
            <p>5.4.2. A {REACT_APP_NAME_CAPS} poderá bloquear o uso do Cartão {REACT_APP_NAME}, 
              suspender o acesso à Plataforma e deixar de realizar as Transações sempre 
              que identificar que a atividade do PORTADOR ou natureza das Transações viola 
              qualquer dispositivo deste Termo ou da legislação vigente, independentemente 
              de qualquer aviso ou notificação prévia; não gerando ao PORTADOR qualquer 
              tipo de indenização ou ressarcimento.</p>
            <p><b>6. Pagamento da Fatura</b></p>
            <p>6.1. As faturas estarão disponibilizadas nos Canais de Atendimento, 
              incluindo o site www.{REACT_APP_NAME_LOW}.com.br.. A cada mês, na respectiva data de vencimento, 
              a {REACT_APP_NAME_CAPS} disponibilzará a fatura contendo o valor total das Transações realizadas 
              no período de apuração, de forma discriminada.</p>
            <p>6.1.1. A fatura poderá ser disponibilizada ao PORTADOR por e-mail, 
              WhatsApp ou na Plataforma, devendo o PORTADOR manter seu e-mail e número de telefone 
              atualizados perante a {REACT_APP_NAME_CAPS}.</p>
            <p>6.1.2. A autenticidade das comunicações recebidas pelo PORTADOR 
              deverá ser previamente verificada perante a {REACT_APP_NAME_CAPS}; sendo que a 
              {REACT_APP_NAME_CAPS} não se utiliza de serviços de terceiros para a comunicação com o PORTADOR.</p>
            <p>6.2. As faturas indicarão todas as informações que possibilitem ao PORTADOR 
              a identificação das Transações, com referência ao respectivo Estabelecimento 
              e os Saques realizados.</p>
            <p>6.3. As faturas serão pagas mediante o repasse dos Créditos, diretamente pela 
              Fonte Pagadora à {REACT_APP_NAME_CAPS}, após o desconto dos valores na folha de pagamento do PORTADOR.</p>
            <p>6.3.1. O PORTADOR reconhece que, uma vez aprovada a Transação, possuirá 
              débito líquido, certo e exigível perante a {REACT_APP_NAME_CAPS}, em relação ao valor 
              da respectiva Transação e de acordo com os termos pactuados na 
              CCB (incluindo a taxa de juros e demais encargos).</p>
            <p>6.3.2. O PORTADOR, neste ato, autoriza que: (i) a {REACT_APP_NAME_CAPS} 
              informe o valor da fatura para a Fonte Pagadora; 
              (ii) a Fonte Pagadora realize a retenção e o repasse 
              dos recursos respectivos diretamente para a {REACT_APP_NAME_CAPS} (ou a quem esta indicar), 
              com a finalidade de quitar o débito constituído pelo meio da CCB emitida 
              pelo PORTADOR ou em razão das compras efetuadas.</p>
  
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>6.3.3. Após a realização da Transação, o PORTADOR concorda 
              que todos e quaisquer recursos oriundos dos Créditos, de acordo com o valor de cada Transação, 
              serão detidos exclusivamente pela {REACT_APP_NAME_CAPS}, inclusive eventuais acréscimos; devendo a 
              Fonte Pagadora efetuar os pagamentos diretamente para a {REACT_APP_NAME_CAPS} ou a quem ela indicar.</p>
            <p>6.4. O atraso no pagamento da fatura, a critério da {REACT_APP_NAME},poderá 
              ensejar no vencimento antecipado da CCB e no pagamento dos encargos moratórios nela previstos.</p>
            <p>6.5. Ainda, a {REACT_APP_NAME_CAPS} poderá, sem a necessidade de aviso prévio ou 
              qualquer formalidade, bloquear o uso do Cartão {REACT_APP_NAME} até a liquidação do 
              valor inadimplido, acrescido dos encargos moratórios incidentes.</p>
            <p>6.5.1. Havendo o cancelamento do Cartão {REACT_APP_NAME}, por qualquer motivo, 
              o acesso à Plataforma será cancelado no prazo de 30 (trinta) dias; 
              cabendo ao PORTADOR obter e imprimir os extratos disponibilizados pela {REACT_APP_NAME_CAPS}.</p>
            <p>6.6. A {REACT_APP_NAME_CAPS} disponibiliza uma central de atendimento, 
              conforme informado na Plataforma, fatura ou Cartão {REACT_APP_NAME}, 
              para que o PORTADOR possa: (i) realizar o bloqueio ou desbloqueio do Cartão {REACT_APP_NAME}; 
              (ii) solicitar a emissão da fatura ou regularizar o pagamento de eventuais débitos; 
              e (iii) dirimir as dúvidas sobre a correta utilização do Cartão {REACT_APP_NAME}.</p>
            <p><b>7. Utilização da Plataforma</b></p>
            <p>7.1. Os serviços decorrentes do fornecimento do Cartão {REACT_APP_NAME} serão 
              prestados de forma remota, mediante a licença de uso da Plataforma ao PORTADOR.</p>
            <p>7.2. O PORTADOR declara-se ciente de que, em se tratando de serviços 
              de tecnologia e que dependem de serviços prestados por terceiros, 
              a {REACT_APP_NAME_CAPS} não poderá ser responsabilizada ou assumirá qualquer responsabilidade por 
              falhas, erros, interrupções, mau funcionamento ou atrasos na utilização da 
              Plataforma ou de seus Canais de Atendimento; não garantindo a utilização do 
              Cartão {REACT_APP_NAME} de forma ininterrupta, sem momentos de indisponibilidade ou lentidão.</p>
            <p>7.3. A {REACT_APP_NAME_CAPS} também não será responsável pela: (i) intermitência ou indisponibilidade 
              de conexão à internet adotada pelo PORTADOR; (ii) incapacidade técnica do 
              dispositivo móvel ou sistema operacional; (iii) indisponibilidade da Plataforma no 
              navegador de internet utilizado pelo PORTADOR; e/ou (iv) atividades de pessoas 
              não autorizadas a utilizar a Plataforma.</p>
            <p>7.4. Não é permitido ao PORTADOR: (i) copiar ou transferir de qualquer forma, 
              total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, 
              provisória ou permanentemente, qualquer conteúdo da Plataforma, quaisquer 
              de suas funcionalidades ou informações nelas contidas; 
              (ii) modificar as características da Plataforma; 
              (iii) criar programas de computador para utilização da Plataforma; 
              e (iv) copiar de qualquer forma dados extraídos da Plataforma, exceto 
              aqueles relativos às Transações realizadas pelo Cartão {REACT_APP_NAME}.</p>
            <p>7.5. O PORTADOR compromete-se a não infringir quaisquer direitos 
              relativos a marcas, patentes, segredo industrial ou, ainda, 
              direito de propriedade, de representação e autoral, responsabilizando-se perante 
              a {REACT_APP_NAME_CAPS} e seus parceiros pelas obrigações ora assumidas.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p><b>8. Remuneração</b></p>
            <p>8.1. Em razão das Transações realizadas pelo Cartão {REACT_APP_NAME}, o PORTADOR deverá pagar 
              as tarifas especificadas no Formulário de Adesão e divulgadas na Plataforma.</p>
            <p>8.2. O valor das tarifas cobradas pela {REACT_APP_NAME_CAPS} é variável de acordo com 
              a natureza e valor da Transação; e poderá ser alterado, a qualquer tempo, 
              conforme informado previamente ao PORTADOR.</p>
            <p>8.2.1. Caso o PORTADOR não concorde com o valor da tarifa 
              aplicável para determinada Transação, poderá encerrar este Termo, 
              a qualquer tempo, mediante comunicação à {REACT_APP_NAME_CAPS}.</p>
            <p><b>9. Vigência</b></p>
            <p>9.1. Este Termo é celebrado por prazo indeterminado, entrando em vigor 
              na data da aprovação da solicitação do Cartão {REACT_APP_NAME} feita pela {REACT_APP_NAME} 
              e comunicada ao PORTADOR pelos meios de comunicação usualmente utilizados. 
              (e-mail ou SMS ou push ou mensagem de whatsapp).</p>
            <p>9.2. Este Termo poderá ser encerrado, sem nenhum ônus, a qualquer tempo 
              e por qualquer das Partes, mediante comunicação com 05 (cinco) dias de antecedência.</p>
            <p>9.3. Este Termo será rescindido imediatamente, de pleno direito, 
              independentemente de qualquer comunicação ou formalidade: (i) 
              com a decretação de falência ou insolvência de qualquer das Partes; 
              (ii) o descumprimento de qualquer das obrigações estabelecidas neste Termo; 
              (iii) a rescisão do contrato do PORTADOR com a Fonte Pagadora; 
              e (iv) rescisão do contrato celebrado entre a {REACT_APP_NAME_CAPS} e a Fonte Pagadora.</p>
            <p>9.4. Em caso de término deste Termo, 
              o PORTADOR continuará a ter acesso à Plataforma, mas 
              não será mais possível a realização de novas Transações 
              no Cartão {REACT_APP_NAME}; permanecendo em vigor todas as 
              condições previstas nas CCBs já emitidas.</p>
            <p>9.5. Caso o PORTADOR encerre seu contrato com a Fonte Pagadora, 
              por qualquer motivo, a Fonte Pagadora irá realizar a retenção e 
              repasse do valor da Transação devido à {REACT_APP_NAME_CAPS}, descontando-o da 
              quantia a ser paga ao PORTADOR em razão da rescisão.</p>
            <p>9.5.1. Caso o desconto do valor não seja 
              suficiente para o pagamento do valor da Transação, 
              a {REACT_APP_NAME_CAPS} irá notificar o PORTADOR para que realize 
              o pagamento do saldo devedor, no prazo de até 02 (dois) dias úteis.</p>
            <p><b>10. Política de Privacidade</b></p>
            <p>10.1. Para fins deste Termo, entende-se por:</p> 
            <p>“<u>Dados Anonimizados</u>”: informações que, isoladamente ou em conjunto 
              com outros dados anonimizados, não permitem a identificação de uma pessoa, 
              considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.</p>
  
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>“<u>Dados de Uso Técnico</u>”: informações que a {REACT_APP_NAME_CAPS} tratar em razão da utilização 
            de dispositivo celular, computador ou outro dispositivo que o PORTADOR utilizar para acessar 
            a Plataforma. Os Dados de Uso Técnico mostram como o PORTADOR utiliza o serviço prestado pela 
            {REACT_APP_NAME_CAPS}, incluindo o endereço IP, estatísticas sobre como as páginas são carregadas ou visualizadas, 
            os sites que o PORTADOR visitou e informações de navegação coletadas por meio de cookies ou tecnologia semelhante.</p>
            <p>“<u>Dados Pessoais</u>”: Dados Pessoais associadas ao PORTADOR como uma pessoa 
            natural identificada ou identificável. Podem incluir nome, autorretrato, endereço, 
            número de telefone, e-mail, número da conta corrente ou poupança, data de nascimento, 
            número ou cópia de documentos oficiais (por exemplo, RG, CNH, CPF, dentre outros). 
            Os Dados de Uso Técnico e as Informações do Dispositivos serão considerados Dados Pessoais 
            quando utilizadas para individualizar o PORTADOR ou sempre que seja possível sua identificação.</p>
            <p>“<u>Informações do Dispositivo</u>”: dados que podem ser coletados automaticamente 
            de qualquer dispositivo utilizado para acessar a Plataforma. Essas informações 
            podem incluir, mas não se limitam ao tipo de dispositivo, conexões de rede do dispositivo, 
            nome do dispositivo, endereço IP do dispositivo, informações sobre o navegador do dispositivo 
            e a conexão de internet usada para acessar a Plataforma.</p>
            <p>“<u>Tratamento</u>”: toda operação realizada com as Dados Pessoais do PORTADOR, 
            em razão da coleta, produção, recepção, classificação, utilização, acesso, 
            reprodução, transmissão, distribuição, processamento, arquivamento, 
            armazenamento, eliminação, avaliação ou controle da informação, modificação, 
            comunicação, transferência, difusão ou extração.</p>
            <p>10.1.1. Esta cláusula tem o objetivo de informar, de forma clara e completa, 
              sobre como haverá o Tratamento das Dados Pessoais do PORTADOR, em decorrência 
              da utilização dos serviços prestados pela {REACT_APP_NAME_CAPS}, com a finalidade de 
              proteger a privacidade do PORTADOR, garantindo que o Tratamento das 
              Dados Pessoais servirá apenas para possibilitar a prestação dos Serviços 
              disponíveis durante a utilização do Cartão {REACT_APP_NAME}.</p>
            <p>10.1.2. Ao utilizar a Plataforma e aderir a este Termo, o PORTADOR dá expresso 
              consentimento para o Tratamento de seus Dados Pessoais pela {REACT_APP_NAME_CAPS} 
              e pela Instituição Financeira. Caso o PORTADOR não concorde com o Tratamento 
              de seus Dados Pessoais na forma prevista neste Termo, deverá se 
              abster de utilizar o Cartão {REACT_APP_NAME}.</p>
            <p>10.2. A {REACT_APP_NAME_CAPS} realiza o Tratamento das Dados Pessoais mínimos, 
              necessários para a utilização, pelo PORTADOR, do conjunto de serviços prestados pela {REACT_APP_NAME_CAPS}.</p>
            <p>10.2.1. Os Dados Pessoais do PORTADOR poderão ser utilizados pela {REACT_APP_NAME_CAPS} 
              para a formação de banco de dados e para aprimorar seus serviços, bem como 
              para elaboração de pesquisas e estatísticas voltadas a analisar eficiência 
              da utilização do Cartão {REACT_APP_NAME} e da Plataforma, preservando-se a individualidade 
              e identificação do PORTADOR de forma anonimizada. As conclusões e resultados 
              dessas pesquisas poderão ser compartilhados ou divulgados pela {REACT_APP_NAME_CAPS} a 
              seu critério, visto se tratar de Dados Anonimizáveis.</p>
            <p>10.2.2. Com a finalidade de prevenir fraudes e garantir a autenticidade das 
              informações fornecidas, poderão ser solicitadas outros Dados Pessoais 
              não contidos no Formulário de Adesão, bem como o envio de documentos 
              adicionais que permitam a confirmação dos dados fornecidos pelo PORTADOR. 
              Neste caso, a {REACT_APP_NAME_CAPS} entrará em contato com PORTADOR diretamente. 
              Essas informações e documentos</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>adicionais poderão ser armazenados pela {REACT_APP_NAME_CAPS} enquanto o PORTADOR mantiver seu cadastro ativo.</p>
            <p>10.2.3. A {REACT_APP_NAME_CAPS} poderá coletar e armazenar outros Dados Pessoais do PORTADOR 
              para o cumprimento de exigência prevista em lei ou emanada das autoridades competentes, 
              bem como para receber e processar comunicações, chamados e exercício de direitos do PORTADOR.</p>
            <p>10.3. As informações do PORTADOR serão obtidas mediante solicitação clara e 
              autorização expressa do PORTADOR, quando do preenchimento ou confirmação dos dados 
              indicados no Formulário de Adesão, sendo processadas com a finalidade de cumprimento 
              dos Serviços oferecidos na Plataforma. Será realizado o Tratamento de informações 
              adicionais do PORTADOR apenas com base no interesse legítimo da {REACT_APP_NAME_CAPS}.</p>
            <p>10.3.1. Tendo em vista que o PORTADOR será indicado pela Fonte Pagadora, seus 
              Dados Pessoais poderão ser compartilhados diretamente pela Fonte Pagadora, 
              com a finalidade de facilitar o cadastro na Plataforma e emissão do Cartão {REACT_APP_NAME}. 
              O compartilhamento dos referidos dados se dará mediante o consentimento prévio 
              e expresso do PORTADOR, de acordo com a legislação aplicável.</p>
            <p>10.4. Os Dados Pessoais do PORTADOR poderão ser compartilhadas pela {REACT_APP_NAME_CAPS} com 
              (i) terceiros contratados pera prover serviços de computação, transferência de dados 
              e hospedagem em nuvem, desde que esses terceiros guardem o mesmo padrão de privacidade 
              e segurança aplicados pela {REACT_APP_NAME_CAPS} e estejam contratualmente obrigados a não 
              acessar o conteúdo, processar ou compartilhar as informações, exceto mediante ordens 
              expressas da {REACT_APP_NAME_CAPS}; (ii) para empresas do grupo da {REACT_APP_NAME_CAPS}, caso existentes; 
              e (iii) à Instituição de Pagamento que irá realizar a emissão da CCB. 
              Esses terceiros somente poderão utilizar as Dados Pessoais para possibilitar 
              a realização dos serviços prestados pela {REACT_APP_NAME_CAPS}.</p>
            <p>10.5. A {REACT_APP_NAME_CAPS} também poderá ser obrigada, por lei ou por determinação 
              das autoridades competentes, a divulgar Dados Pessoais do PORTADOR.</p>
            <p>10.6. O e-mail, número de telefone (WhatsApp) e outras informações de contato 
              do PORTADOR informados no preenchimento do Formulário de Adesão, serão utilizados 
              como meio de comunicação pela {REACT_APP_NAME_CAPS}, apenas para o envio de informações a respeito 
              da Plataforma, solicitação de documentos e de outras informações que vierem a ser 
              necessárias à complementação ou ratificação do Formulário de Adesão.</p>
            <p>10.6.1. A {REACT_APP_NAME_CAPS} não utiliza serviços de terceiros para enviar e-mails em seu nome. 
              Se o PORTADOR receber e-mail ou mensagens pelo WhatsApp que acredita não terem sido 
              enviados pela {REACT_APP_NAME_CAPS}, deverá se abster de adotar qualquer ação e entrar em 
              contato imediatamente com a {REACT_APP_NAME_CAPS} para confirmar sua veracidade.</p>
            <p>10.7. Os Dados Pessoais coletados pela {REACT_APP_NAME_CAPS} são armazenados em servidores seguros, 
              de forma criptografada, com a utilização de medidas de segurança de informação 
              constantemente atualizadas, e serão mantidas confidenciais e 
              submetidas a todas as medidas possíveis contra perda, roubo, uso indevido, 
              alteração e acesso não autorizado.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>10.7.1. Os Dados Pessoais serão armazenados pelo prazo mínimo de 5 (cinco) anos após o 
              cancelamento do cadastro do PORTADOR e encerramento dos Serviços, por qualquer motivo, 
              com a finalidade específica de produção de provas, caso necessário.</p>
            <p>10.7.2. Os Dados de Uso Técnico relacionados às informações colhidas 
              em razão do acesso do PORTADOR ao website e serviços de internet da {REACT_APP_NAME_CAPS}, 
              poderão ser armazenados pelo prazo de 06 (seis) meses, de acordo com a legislação vigente.</p>
            <p>10.7.3. Os Dados Anonimizados, que não identifiquem o PORTADOR, 
              poderão ser armazenados indefinidamente para fins estatísticos.</p>
            <p>10.7.4. Os prazos de armazenamento previstos acima poderão ser alterados pela 
              {REACT_APP_NAME_CAPS} a qualquer momento, de acordo com eventuais alterações a legislação vigente.</p>
            <p>10.8. A {REACT_APP_NAME_CAPS} emprega padrões de segurança avançados, incluindo firewalls, 
              antivírus e outros softwares que auxiliam na proteção de hackers e não vazamento 
              das Dados Pessoais armazenadas, o que não retira a responsabilidade do PORTADOR 
              em proteger e manter a privacidade de seu Formulário de Adesão e Dados Pessoais, 
              isentando a {REACT_APP_NAME_CAPS} de quaisquer vazamentos ocorridos em razão do compartilhamento 
              indevido do PORTADOR com terceiros.</p>
            <p>10.8.1. Na medida da legislação aplicável, a {REACT_APP_NAME_CAPS} não se responsabiliza 
              por violações ilegais de sua Plataforma que venham a comprometer a sua base de dados 
              e as Dados Pessoais do PORTADOR, bem como não se responsabiliza pela utilização 
              indevida das Dados Pessoais obtidas na Plataforma de forma fraudulenta ou ilícita.</p>
            <p>10.8.2. Em caso de suspeita ou confirmação de violação da Plataforma ou de 
              perda de Dados Pessoais do PORTADOR, a {REACT_APP_NAME_CAPS} envidará seus melhores 
              esforços e tomará medidas imediatas para eliminar ou reduzir os riscos de 
              danos ao PORTADOR, bem como informará o PORTADOR potencialmente afetados e as 
              autoridades competentes de tal fato, os riscos envolvidos e 
              as medidas necessárias para evitar tais danos.</p>
            <p>10.9. É permitido ao PORTADOR, a qualquer tempo, nos limites da legislação aplicável, 
              exercer os direitos de: (i) confirmação da existência de Tratamento de seus Dados Pessoais; 
              (ii) acesso às seus Dados Pessoais mantidas perante a {REACT_APP_NAME_CAPS}; 
              (iii) correção de Dados Pessoais incompletas, inexatas ou desatualizadas; 
              (iv) anonimização, bloqueio ou eliminação de Dados Pessoais desnecessárias, 
              excessivas ou tratadas em desconformidade com o disposto na legislação aplicável; 
              (v) portabilidade de Dados Pessoais a outro fornecedor de serviço mediante 
              requisição expressa e observados os segredos comercial e industrial da {REACT_APP_NAME_CAPS}, 
              de acordo com a regulamentação da autoridade competente; 
              (vi) eliminação das Dados Pessoais tratadas com base no consentimento do PORTADOR, 
              salvo nas exceções previstas na legislação aplicável; 
              (vii) a informação das entidades públicas e privadas com as quais 
              a {REACT_APP_NAME_CAPS} realizou uso compartilhado de Dados Pessoais do PORTADOR; 
              (viii) a informação sobre a possibilidade de não fornecer consentimento 
              e sobre as consequências da negativa, quando determinada operação de 
              Tratamento embasar-se no consentimento do PORTADOR; e 
              (ix) a revogação do consentimento, nos termos da legislação aplicável.</p>
            <p>10.9.1. No caso de cancelamento de seu cadastro na Plataforma, 
              o PORTADOR não poderá utilizar a Plataforma.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>10.9.2. Os direitos descritos acima poderão ser exercidos mediante 
              o envio de solicitação por escrito do PORTADOR, acompanhada de prova de sua identidade, 
              ao endereço indicado no preâmbulo deste Termo.</p>
            <p>10.9.3. A {REACT_APP_NAME_CAPS} poderá contatar o PORTADOR para confirmar sua 
              identidade antes do cumprimento da solicitação, que somente será aprovada 
              mediante a confirmação da identidade do PORTADOR.</p>
            <p>10.10. O PORTADOR autoriza que todos os contatos com a {REACT_APP_NAME_CAPS}, 
              independentemente do canal de comunicação utilizado, possam ser gravados 
              e armazenados, visando proporcionar maior segurança àqueles que se utilizam 
              da Plataforma, incluindo o próprio PORTADOR.</p>
            <p>10.11. A {REACT_APP_NAME_CAPS} se compromete a zelar pela segurança de 
              todas as informações fornecidas pelo PORTADOR, comprometendo-se a utilizar de 
              toda tecnologia disponível para evitar o acesso indevido a tais informações. 
              Entretanto, a {REACT_APP_NAME_CAPS} não poderá ser responsabilidade por eventual furto 
              ou roubo de informações que sejam causadas por terceiros mediante o acesso não 
              autorizado de seus sistemas e da Plataforma.</p>
            <p><b>11. Modificações e Revisões</b></p>
            <p>11.1. Este Termo será revisto periodicamente pela 
              {REACT_APP_NAME_CAPS} para adequar a prestação dos serviços que integram 
              o Cartão {REACT_APP_NAME} e a licença de uso da Plataforma. 
              A {REACT_APP_NAME_CAPS} poderá alterar este Termo, excluindo, modificando ou inserindo 
              cláusulas ou condições, ao seu exclusivo critério.</p>
            <p>11.2. As alterações deverão ser comunicadas ao PORTADOR por e-mail, 
              WhatsApp ou mediante comunicação na Plataforma, possibilitando ao PORTADOR 
              seu amplo conhecimento e acesso.</p>
            <p>11.3. Caso o PORTADOR não concorde com as alterações, 
              poderá denunciar este Termo sem qualquer ônus ou penalidade. 
              A continuidade do uso do Cartão {REACT_APP_NAME} ou da Plataforma 
              pelo PORTADOR será interpretada como concordância e aceitação das alterações realizadas, 
              passando essas a serem integralmente aplicáveis.</p>
            <p><b>12. Disposições Finais</b></p>
            <p>12.1. O PORTADOR declara-se ciente e concorda que, independentemente do local 
              de onde esteja utilizando os serviços que integram o Cartão {REACT_APP_NAME}, a relação 
              entre as Partes será sempre regida pela legislação brasileira.</p>
            <p>12.2. O PORTADOR compromete-se a isentar a {REACT_APP_NAME_CAPS} de qualquer litígio decorrente 
              da utilização da Plataforma e da realização de Transações pelo Cartão {REACT_APP_NAME}.</p>
            <p>12.3. O PORTADOR autoriza que a {REACT_APP_NAME_CAPS}, por intermédio da Instituição Financeira, 
              consulte no Sistema de Informações de Crédito (“<u>SCR</u>”) disponibilizado pelo 
              Banco Central do Brasil (“<u>Bacen</u>”), todas e quaisquer informações 
              referentes a quaisquer operações de crédito de sua responsabilidade.</p>
            <p>12.3.1. O SCR tem por finalidade fornecer informações referentes 
              às responsabilidades de clientes em quaisquer operações de crédito, 
              como objetivo de subsidiar decisões de crédito e de negócios.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
          <p>12.3.2. O PORTADOR poderá ter acesso aos dados constantes no SCR 
            por meio do Registro – Extrato do Registro de Informações no Bacen ou 
            da Central de Atendimento ao Público do Bacen, sendo que as manifestações 
            de discordância quanto às informações constantes no SCR e os pedidos de correções 
            e exclusões deverão ser dirigidos às instituições com as quais o PORTADOR 
            contratou operações de crédito, por meio de requerimento escrito e fundamentado.</p>
          <p>12.4. As Partes elegem o Foro da Capital do Estado de São Paulo como único 
            competente para dirimir as questões decorrentes deste Termo, com renúncia expressa 
            a qualquer outro, por mais privilegiado que seja.</p>
          <p>11.4. Qualquer dúvida em relação a este Termo poderá ser enviada 
            à Central de Atendimento da {REACT_APP_NAME_CAPS} conforme indicado no rodapé deste Termo</p>
          <p>     Versão atualizada em 21.1.2022</p>
          <p><b>{REACT_APP_NAME_CAPS} SERVIÇOS TECNOLÓGICOS E FINANCEIROS LTDA.</b></p>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          </div>
          {Footer()}
        </div>
      </div>
    );
  }
/*
  const TermoV2 = () => {
    return(
      <div onScroll={Status} style={styles.scroll}>
        <div style={styles.paper}>
          {Header()}
            <div style={styles.text}>
              <p>Por este instrumento particular, de um lado, o “<u>USUÁRIO</u>”, identificado e qualificado 
                em formulário eletrônico; e, de outro lado, <b>{REACT_APP_NAME_CAPS} SERVIÇOS TECNOLÓGICOS E FINANCEIROS LTDA.</b>, 
                inscrito no CNPJ sob o nº 29.871.099/0001-89, com sede na Rua Joaquim Floriano, nº 413 - 11º andar
                – Itaim Bibi – São Paulo / SP, CEP 04543-011 (“<u>{REACT_APP_NAME_CAPS}</u>”); 
                têm entre si justo e acordado estes Termos e Condições de Uso de Cartão Para Aquisição de 
                Bens e Serviços (“<u>Termo</u>”), de acordo com as cláusulas e condições abaixo.</p>
              {AddBox(
              <b>
                  <p>Ao aceitar eletronicamente, com a marcação da caixa de diálogo “Li e aceito os termos e condições”, 
                    o USUÁRIO estará automaticamente aderindo e concordando com as condições deste Termo.</p>
  
                  <p>Mediante a adesão a este Termo, o USUÁRIO expressamente autoriza que a Fonte Pagadora
                  compartilhe seus dados pessoais e todas as informações relacionadas aos Créditos.</p>
  
                  <p>O USUÁRIO expressamente autoriza a {REACT_APP_NAME_CAPS}, por intermédio da Instituição Financeira, 
                  a ter acesso a informações financeiras a seu respeito obtidas perante o SCR, 
                  e que podem conter dados protegidos por sigilo bancário, nos termos da Lei Complementar nº 105/2001.</p>
  
                  <p>O USUÁRIO declara-se ciente e concorda que, sendo o Crédito decorrente de salário a ser pago 
                  em razão de relação de emprego com a Fonte Pagadora, as Compras apenas poderão ser realizadas de 
                  acordo com o limite e finalidade estabelecidos na legislação aplicável, e devem ser 
                  realizadas para a subsistência do USUÁRIO, para o custeio de suas atividades vitais básicas.</p>
  
                  <p>A {REACT_APP_NAME_CAPS} poderá periodicamente alterar as condições deste Termo; podendo o 
                  USUÁRIO, caso não concorde com a modificação, denunciá-lo sem quaisquer ônus ou 
                  penalidades.</p>
                   
                  <p>A versão atualizada deste Termo poderá ser consultada a qualquer momento 
                  pelo USUÁRIO, mediante acesso ao link: www.{REACT_APP_NAME_LOW}.com.br</p>
              </b>)}
              <br/>
              <p><b>1. Definições</b></p>
  
              <p>1.1. As palavras e expressões abaixo, indicadas neste Termo pela primeira letra maiúscula, 
                terão as seguintes definições:</p>
  
              <p>“<u>Cartão</u>”: instrumento de pagamento pré-pago, emitido pelo Emissor na forma de cartão 
              plástico e com a marca “Bluecard”,que possibilitará ao USUÁRIO realizar a compra de bens e serviços 
              perante Estabelecimentos.</p>
              <p>“<u>CCB</u>”: Cédula de Crédito Bancário emitida pelo USUÁRIO em favor da Instituição Financeira, 
              de acordo com o valor, taxa de juros, quantidade de parcelas, datas de vencimento e demais condições 
              estipuladas em instrumento próprio, cujo crédito será cedido à {REACT_APP_NAME_CAPS} ou à sua ordem.</p>
  
            </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>“<u>Compra</u>”: aquisição de bens e serviços realizada pelo USUÁRIO perante 
            Estabelecimentos mediante a utilização do Cartão.</p>
            <p>“<u>Crédito</u>”: direito creditório de titularidade do USUÁRIO perante a Fonte Pagadora, 
            e que poderá consistir, conforme aplicável, em salário, décimo-terceiro salário, abono 
            pecuniário das férias, benefícios, pensão previdenciária ou outras formas de remuneração 
            passíveis de cessão, de acordo com os limites previstos na legislação vigente.</p>
  
            <p>“<u>Emissor</u>”: Hub Pagamentos S.A., inscrita no CNPJ sob o nº 13.884.775/0001-19, situada 
            na Alameda Arapoema, nº 529, Parte, Tamboré – Barueri / SP, CEP nº 06460-080, ou outra que 
            venha a celebrar parceria com a {REACT_APP_NAME_CAPS} (conforme indicado no respectivo Cartão); e que, 
            na qualidade de instituição de pagamento, possui autorização da respectiva bandeira para 
            realizar a emissão do Cartão.</p>
  
            <p>“<u>Estabelecimento</u>”: determinada pessoa física ou jurídica que comercializa bens e serviços, 
            e que está autorizada pela respectiva bandeira a receber pagamentos decorrentes da compra 
            realizada com o Cartão</p>
  
            <p>“<u>Fonte Pagadora</u>”: órgão público credenciado ou conveniado com a {REACT_APP_NAME_CAPS}, 
            com o qual o USUÁRIO, em razão de sua relação de emprego, possui 
            Créditos periódicos a receber (vencimentos, remuneração, 
            adicionais, bônus e benefícios entre outros).</p>
  
            <p>“<u>Formulário de Adesão</u>”: formulário eletrônico disponível na plataforma, constando os 
            dados pessoais e demais informações do USUÁRIO, e pelo qual haverá a adesão a este Termo.</p>
  
            <p>“<u>Instituição Financeira</u>”: Lecca – Crédito, Financiamento e Investimento S.A., inscrita 
            no CNPJ sob o nº 29.871.099/0001-89, com sede na Rua São José, nº 20, sala 201 - Rio de Janeiro / RJ, 
            CEP 20010-020; ou outra que venha a celebrar parceria com a {REACT_APP_NAME_CAPS} 
            (conforme indicado na respectiva CCB).</p>
  
            <p>“<u>Plataforma</u>”: site disponível em www.{REACT_APP_NAME_LOW}.com.br e aplicativo para dispositivos 
            móveis, de titularidade da {REACT_APP_NAME_CAPS}, pelo qual o USUÁRIO poderá solicitar a emissão do 
            Cartão e utilizar as demais funcionalidades disponíveis.</p>
  
            <p>“<u>USUÁRIO</u>”: pessoa física, maior e capaz, que aderiu a este Termo por meio do 
            Formulário de Adesão.</p>
  
            <p><b>2. Credenciamento do USUÁRIO</b></p>
  
            <p>2.1.	A adesão do USUÁRIO a este Termo ocorrerá mediante o preenchimento do 
              Formulário de Adesão e o aceite eletrônico manifestado na Plataforma.</p>
  
            <p>2.2.	No momento do credenciamento, o USUÁRIO: (i) deverá prestar todas as informações 
              solicitadas no Formulário de Adesão; (ii) responsabiliza-se civil e criminalmente pela 
              veracidade das informações prestadas; e (iii) obriga-se a manter seus dados atualizados 
              junto à {REACT_APP_NAME_CAPS}.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>2.2.1.	A {REACT_APP_NAME_CAPS} poderá, a qualquer momento e a seu exclusivo critério, 
              solicitar ao USUÁRIO o envio de informações adicionais e/ou de documentos que 
              comprovem as informações prestadas.</p>
  
            <p>2.2.2.	Caso a {REACT_APP_NAME_CAPS} constate haver dados incorretos ou inverídicos, ou, 
              ainda, caso o USUÁRIO se recuse a enviar as informações e documentos solicitados, 
              a {REACT_APP_NAME_CAPS} poderá negar a solicitação de emissão do Cartão e não liberar 
              o acesso à Plataforma, sem prejuízo de outras medidas que entender necessárias; 
              não assistindo ao USUÁRIO qualquer tipo de indenização ou ressarcimento.</p>
  
            <p>2.2.3.	A {REACT_APP_NAME_CAPS} poderá, a seu exclusivo critério, realizar pesquisas, 
              em base de dados públicas ou privadas (inclusive o SCR, conforme abaixo definido), 
              com a finalidade de verificar a veracidade dos dados e informações indicadas pelo 
              USUÁRIO no Formulário de Adesão e a proceder à análise de crédito do USUÁRIO. </p>
  
            <p>2.2.4.	O USUÁRIO, quando do preenchimento do Formulário de Adesão, 
              deverá cadastrar login e senha para utilização da Plataforma. 
              O uso do <u>login</u> e senha são de uso pessoal, exclusivo e intransferível pelo USUÁRIO, 
              que deverá mantê-los confidenciais e não permitir seu acesso por terceiros.</p>
            
            <p>2.2.5.	O USUÁRIO deverá informar e-mail e número de telefone válidos para comunicação 
              com a {REACT_APP_NAME_CAPS}; sendo que qualquer comunicação enviada por e-mail, SMS, WhatsApp 
              ou pela Plataforma será considerada válida e eficaz entre as Partes.</p>
  
            <p>2.3.	A {REACT_APP_NAME_CAPS} poderá recusar pedido de credenciamento do USUÁRIO sempre que, 
              a seu exclusivo critério, houver o descumprimento das obrigações previstas 
              neste Termo ou da legislação vigente, assim como no caso de determinação 
              judicial ou administrativa.</p>
  
            <p><b>3. Credenciamento do USUÁRIO</b></p>
            <p>3.1.	Este Termo tem por objetivo estabelecer as condições pelas quais o USUÁRIO 
              poderá utilizar a Plataforma para solicitar a emissão do Cartão e a contratação 
              de empréstimo perante a Instituição Financeira, com a finalidade de realizar a 
              compra de bens e serviços, com vencimento a prazo, perante determinados Estabelecimentos.</p>
  
            <p>3.2.	A {REACT_APP_NAME_CAPS}, por meio da Plataforma, irá: (i) realizar a análise das 
              informações e documentos do USUÁRIO, com a finalidade de verificar a viabilidade 
              de emissão do Cartão perante o Emissor; e (ii) fornecer a tecnologia necessária 
              para possibilitar a concessão de empréstimo pela Instituição Financeira, 
              mediante a emissão da CCB.</p>
  
            <p>3.3.	<b>O USUÁRIO declara-se ciente e concorda que, diante da natureza dos 
              serviços prestados pela {REACT_APP_NAME_CAPS} em razão deste Termo:</b></p>
  
            <p>(a)	<b>Com o credenciamento realizado na Plataforma, haverá a emissão de 
              uma CCB perante a Instituição Financeira, formalizando o empréstimo 
              de recursos (mútuo), com a incidência de juros e outros encargos;</b></p>
            
            <p>(b)	<b>O credenciamento realizado na Plataforma não caracteriza a 
              emissão do Cartão, tendo em vista que o USUÁRIO deverá realizar 
              seu cadastro e o desbloqueio do Cartão perante o Emissor;</b></p>
  
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>(c)	<b>Caso o USUÁRIO deixe de realizar debloqueio do Cartão, ou seu 
              cadastro não seja aprovado pelo Emissor, haverá o cancelamento automático 
              da CCB emitida perante a Instituição Financeira;</b></p>
            <p>(d)	<b>O Cartão possui funcionalidades que podem ser utilizadas 
              a para a compra de bens e serviços perante as lojas físicas 
              e virtuais dos Estabelecimentos;</b></p>
            <p>(e)	<b>Após a emissão da CCB, o respectivo crédito será 
              transferido para a {REACT_APP_NAME_CAPS}, que se tornará a legítima 
              credora dos valores devidos pelo USUÁRIO;</b></p>
            <p>(f)	<b>A Fonte Pagadora é responsável por informar à {REACT_APP_NAME_CAPS} 
              sobre o valor dos Créditos devidos ao USUÁRIO e eventuais 
              alterações aplicáveis;</b></p>
            <p>(g)	<b>A Fonte Pagadora irá proceder com a retenção do valor 
              devido em razão do empréstimo realizado para carregamento do 
              Cartão (incluindo as tarifas aplicáveis), antes do pagamento da 
              remuneração devida ao USUÁRIO, para posterior repasse à {REACT_APP_NAME_CAPS};</b></p>
            <p>(h)	<b>A Fonte Pagadora poderá, a seu exclusivo critério, 
              determinar restrições para o USUÁRIO, em relação ao valor 
              que será utilizado para carregamento do Cartão; e</b></p>
            <p>(i)	<b>Caso o Crédito seja decorrente de salário a ser pago 
              em razão de relação de emprego com a Fonte Pagadora, as Compras 
              apenas poderão ser realizadas de acordo com o limite estabelecido 
              na legislação aplicável, e deverão ser necessariamente realizadas 
              para a subsistência do USUÁRIO.</b></p>
            <p><b>4. Emissão da CCB perante a Instituição Financeira</b></p>
  
            <p>4.1.	Ao realizar o credenciamento na Plataforma, será concedido 
              ao USUÁRIO um limite de crédito para a realização das Compras, 
              levando em consideração as informações e documentos apresentados pelo 
              USUÁRIO no preenchimento do Formulário de Adesão.</p>
            <p>4.1.1.	O USUÁRIO autoriza, desde já, a {REACT_APP_NAME_CAPS}, por si ou 
              por intermédio da Instituição Financeira, a consultar bancos de 
              dados cadastrais públicos ou privados, inclusive o SCR e 
              Serviços de Proteção ao Crédito, para a obtenção de informações 
              cadastrais e de crédito. </p>
            <p>4.1.2.	O limite de crédito será informado na Plataforma, 
              e dependerá, necessariamente, da emissão e assinatura da CCB, 
              pelo USUÁRIO ou na forma prevista na cláusula 4.2. abaixo, 
              perante a Instituição Financeira.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
            <div style={styles.text}>
            <p>4.1.3.	A emissão da CCB, dependerá ainda: (i) da demonstração da prova de vida, 
              mediante o envio de foto do USUÁRIO; (ii) do envio de comprovante de residência; 
              e (iii) da concordância do USUÁRIO quanto ao valor, taxa de juros, encargos, 
              datas de vencimento e demais condições financeiras (inclusive o Custo Efetivo da Transação - CET).</p>
            <p>4.2.	<b>Para possibilitar a concessão do empréstimo formalizado na CCB, o 
              USUÁRIO nomeia a {REACT_APP_NAME_CAPS} como sua bastante procuradora, com poderes especiais para, 
              em seu nome e por sua conta, negociar e obter crédito perante a Instituição Financeira; 
              outorgando-lhe poderes especiais para assinar a CCB, abrir conta para movimentar 
              os valores financiados, negociar prazos, juros e outros encargos, repactuar 
              taxas de juros e emitir títulos representativos do débito perante a Instituição Financeira.</b></p>
            <p>4.2.1.	A USUÁRIO desde já autoriza a {REACT_APP_NAME_CAPS} a coletar, tratar e compartilhar 
              os seus dados pessoais e financeiros com a Instituição Financeira, para obtenção 
              do empréstimo decorrente da CCB.</p>
            <p>4.2.2.	A {REACT_APP_NAME_CAPS} poderá, em nome da Instituição Financeira, cobrar o 
              USUÁRIO dos débitos decorrentes dos empréstimos realizados por meio da CCB. </p>
            <p>4.3.	Após o desembolso do crédito decorrente da CCB, o USUÁRIO 
              declara-se ciente e concorda que os pagamentos devidos pelo USUÁRIO 
              deverão ser realizados diretamente à {REACT_APP_NAME_CAPS} ou a quem ela indicar.</p>
  
            <p><b>5. Emissão do Cartão</b></p>
            <p>5.1.	Após a aprovação do credenciamento e a emissão da CCB na Plataforma, 
              o USUÁRIO será comunicado, para possa retirar o Cartão no endereço indicado 
              pela {REACT_APP_NAME_CAPS}. O Cartão será emitido apenas em formato físico (cartão plástico), 
              e sem identificação do USUÁRIO (cartão não nominal).</p>
            <p>5.1.1.	A entrega do Cartão será ser realizada de forma presencial ao USUÁRIO, 
              nos pontos de atendimento da {REACT_APP_NAME_CAPS} ou pelos Estabelecimentos.</p>
            <p>5.1.2.	No momento da retirada do Cartão, o USUÁRIO deverá comprovar 
              que se trata da pessoa que efetivamente realizou o credenciamento na Plataforma, 
              mediante o fornecimento de documentos e de foto, de acordo com os requisitos 
              de segurança adotados pela {REACT_APP_NAME_CAPS}.</p>
            <p>5.1.3.	O Cartão será entregue juntamente com uma senha previamente definida, 
              de uso pessoal e intransferível pelo USUÁRIO. O USUÁRIO será o único responsável 
              pela sua guarda e conservação, assumindo integral responsabilidade pelo 
              uso indevido do Cartão.</p>
            <p>5.2.	Após a retirada, o USUÁRIO deverá realizar o desbloqueio do Cartão, 
              mediante: (i) a realização do seu cadastro perante o Emissor, com 
              o fornecimento das informações e documentos </p>
            </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
            <div style={styles.text}>
            <p>solicitados em plataforma específica do Emissor; e (ii) a adesão aos 
              Termos e Condições de Uso do Cartão, que consiste num contrato eletrônico 
              disponibilizado na plataforma do Emissor, e que não se confunde com 
              este Termo ou com a CCB.</p>
            <p>5.2.1.	<b>O desbloqueio perante o Emissor é imprescindível para 
              que o USUÁRIO possa utilizar o Cartão. Caso o USUÁRIO deixe de 
              realizar o desbloqueio, ou o Emissor deixe de aprovar o cadastro e desbloqueio, 
              o Cartão permanecerá bloqueado e o USUÁRIO não poderá realizar as Compras.</b></p>
            <p>5.2.2.	<b>O USUÁRIO declara-se ciente e concorda que a {REACT_APP_NAME_CAPS} 
              não terá qualquer interferência perante o Emissor na aprovação 
              de seu cadastro, de modo que o Emissor, por critérios e políticas 
              próprias, poderá negar o desbloqueio do Cartão, sem que o USUÁRIO 
              possa reivindicar qualquer ressarcimento ou indenização.</b></p>
            <p>5.2.3.	<b>Caso o cadastro do USUÁRIO e debloqueio do Cartão seja negado 
              pelo Emissor, o empréstimo formalizado por meio da CCB será automaticamente 
              cancelado, sem quaisquer ônus ao USUÁRIO; e ensejará no 
              encerramento automático deste Termo.</b></p>
            <p>5.3.	Havendo a aprovação do desbloqueio do Cartão, 
              será realizado o carregamento do Cartão, mediante a transferência 
              dos recursos decorrentes do empréstimo contratado pelo USUÁRIO 
              em razão da CCB emitida perante a Instituição Financeira.</p>
            <p>5.4.	Para realização da compra de bens e serviços perante os Estabelecimentos, 
              o USUÁRIO deverá obedecer todas as regras relacionadas com a utilização do Cartão; 
              de acordo com as obrigações, limites e condições previstas nos 
              Termos e Condições de Uso do Cartão celebrado com o Emissor.</p>
            <p>5.4.1.	<b>Os Termos e Condições de Uso do Cartão a ser disponibilizado pelo 
              Emissor consistem num contrato padrão e que indica algumas funcionalidades 
              que não se aplicam ao USUÁRIO em razão dos serviços prestados pela {REACT_APP_NAME_CAPS}; 
              de modo que USUÁRIO reconhece e concorda que:</b></p>
            <p>(a)	<b>O Cartão poderá ser utilizado para a compra de bens e serviços.</b></p>
            <p>(b)	<b>O Cartão permite a realização de Compras nas lojas físicas e pela 
              internet (online) dos Estabelecimentos habilitados perante a bandeira 
              indicada no Cartão., não sendo possível a realização de compras online 
              (pela internet) ou outros meios não presenciais; e </b></p>
            <p>(c)	<b>O Cartão deverá ser utilizado na função crédito à vista e durante o prazo de validade indicado.</b></p>
            <p>5.5.	O Emissor irá disponibilizar, em sua plataforma, todas 
              as informações relacionadas com as Compras realizadas e a utilização do Cartão. </p>
            <p>5.6.	Ainda, o Emissor será responsável pelo atendimento do USUÁRIO, 
              inclusive em caso de dúvidas quanto à utilização do Cartão ou da necessidade 
              de bloqueio do Cartão decorrente de perda, </p>
            </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>roubo ou utilização indevida por terceiros; devendo o USUÁRIO entrar em 
              contato com a central de atendimento, conforme informado na plataforma 
              do Emissor e/ou no verso do Cartão.</p>
            <p>5.6.1.	O USUÁRIO será o único e exclusivo responsável pelo uso indevido do 
              Cartão até a data da comunicação ao Emissor para a realização do bloqueio.</p>
            <p>5.6.2.	Caberá ao USUÁRIO, por motivos justificáveis e mediante o pagamento 
              de eventuais tarifas incidentes, solicitar um novo Cartão, sendo 
              que a nova emissão ensejará no automático cancelamento do Cartão substituído, 
              ficando impedida sua utilização.</p>
            <p><b>6.	Realização de Compras</b></p>
            <p>6.1.	A aquisição de bens e serviços, assim como a negociação da forma de 
              pagamento será feita pelo USUÁRIO diretamente com o respectivo Estabelecimento, 
              não sendo a {REACT_APP_NAME_CAPS} responsável por quaisquer assuntos envolvendo a operação 
              de aquisição de bens ou serviços.</p>
            <p>6.1.1.	A {REACT_APP_NAME_CAPS} não se responsabiliza pelo preço, quantidade, 
              qualidade e/ou garantia dos bens e serviços adquiridos, cabendo ao 
              USUÁRIO resolver tais questões diretamente com o respectivo Estabelecimento.</p>
            <p>6.2.	É vedada a utilização do Cartão e a realização das Compras para 
              a celebração de negócios: (i) considerados ilícitos, nos termos da legislação brasileira; 
              (ii) considerados como crimes financeiros, com o intuito de lavagem de dinheiro, 
              financiamento ao terrorismo e corrupção, dentre outros crimes correlatos, 
              ainda que indiretamente; (iii) que não representem um negócio 
              jurídico regular e tenham por intenção a prática de fraudes; 
              ou (iv) que, de qualquer modo, venham a causar prejuízos à {REACT_APP_NAME_CAPS}, 
              à Instituição Financeira ou ao Emissor.</p>
            
            <p>6.2.1.	As Compras com indícios ou suspeitas de fraude estarão sujeitas ao cancelamento, 
              ainda que realizada de forma conivente ou não pelo USUÁRIO; de acordo com as condições 
              previstas nos Termos de Uso do Cartão celebrado com o Emissor.</p>
  
            <p>6.2.2.	A {REACT_APP_NAME_CAPS} poderá solicitar o bloqueio do Cartão ou suspender o acesso 
              à Plataforma sempre que identificar que a atividade do USUÁRIO ou natureza das 
              Compras viola qualquer dispositivo deste Termo ou da legislação vigente, independentemente 
              de qualquer aviso ou notificação prévia; não gerando ao USUÁRIO qualquer tipo de indenização 
              ou ressarcimento.</p>
            <p><b>7. Pagamento do Empréstimo</b></p>
            
            <p>7.1.	Em razão do carregamento do Cartão, mediante a transferência de 
              recursos ao Emissor para possibilitar a realização da compra de bens duráveis, 
              o USUÁRIO deverá pagar o empréstimo formalizado por meio da CCB, 
              de acordo com o valor, quantidade de parcelas, juros, encargos e demais 
              condições informadas no ato a contratação.</p>
  
            <p>7.2.	As parcelas da CCB deverão ser pagas mediante o repasse automático dos créditos, 
              diretamente pela Fonte Pagadora à {REACT_APP_NAME_CAPS}, após o desconto dos valores na folha 
              de pagamento do USUÁRIO.</p>
  
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>7.2.1.	O USUÁRIO reconhece que, uma vez aprovada a compra, possuirá débito 
              líquido, certo e exigível perante a {REACT_APP_NAME_CAPS}, de acordo com os termos 
              pactuados na CCB (incluindo a taxa de juros e demais encargos aplicáveis).</p>
            <p>7.2.2.	<b>O USUÁRIO, neste ato, autoriza que: (i) a {REACT_APP_NAME_CAPS} 
              informe o valor de seus débitos para a Fonte Pagadora; (ii) a 
              Fonte Pagadora realize a retenção e o repasse dos recursos respectivos 
              diretamente para a {REACT_APP_NAME_CAPS} (ou a quem ela indicar), com a 
              finalidade de quitar o débito constituído pelo meio da CCB emitida pelo USUÁRIO.</b></p>
            <p>7.2.3.	Após a realização da Compra, o USUÁRIO concorda que todos 
              e quaisquer recursos oriundos dos Créditos, de acordo com o respectivo 
              valor, serão detidos exclusivamente pela {REACT_APP_NAME_CAPS}, inclusive eventuais 
              acréscimos; devendo a Fonte Pagadora efetuar os pagamentos diretamente 
              para a {REACT_APP_NAME_CAPS} ou a quem ela indicar.</p>
            <p>7.3.	O atraso no pagamento das parcelas da CCB, por 
              qualquer motivo (inclusive em caso de inexistência de Crédito perante a Fonte Pagadora), 
              ensejará no vencimento antecipado da CCB e no pagamento 
              dos encargos moratórios nela previstos.</p>
            <p>7.4.	Ainda, a {REACT_APP_NAME_CAPS} poderá, sem a necessidade de aviso prévio ou 
              qualquer formalidade, solicitar o bloqueio do Cartão até a liquidação 
              do valor inadimplido, acrescido dos encargos moratórios incidentes.</p>
            <p>7.4.1.	Havendo o cancelamento do Cartão, por qualquer motivo, o acesso 
              à Plataforma será cancelado no prazo de 30 (trinta) dias; cabendo ao 
              USUÁRIO obter e imprimir os extratos disponibilizados pela {REACT_APP_NAME_CAPS}.</p> 
            <p><b>8.	Utilização da Plataforma</b></p>
            <p>8.1.	Os serviços decorrentes deste Termo serão prestados de forma remota, mediante a licença de 
              uso da Plataforma ao USUÁRIO. </p>
            <p>8.2.	<b>O USUÁRIO declara-se ciente de que, em se tratando de serviços de tecnologia 
              e que dependem de serviços prestados por terceiros, a {REACT_APP_NAME_CAPS} não poderá ser 
              responsabilizada ou assumirá qualquer responsabilidade por falhas, erros, interrupções, 
              mau funcionamento ou atrasos na utilização da Plataforma; não garantindo a utilização 
              do Cartão de forma ininterrupta, sem momentos de indisponibilidade ou lentidão.</b></p>
            <p>8.3.	<b>A {REACT_APP_NAME_CAPS} também não será responsável pela: (i) intermitência ou indisponibilidade 
              de conexão à internet adotada pelo USUÁRIO; (ii) incapacidade técnica do dispositivo 
              móvel ou sistema operacional; (iii) indisponibilidade da Plataforma no navegador 
              de internet utilizado pelo USUÁRIO; e/ou (iv) atividades de pessoas não autorizadas 
              a utilizar a Plataforma.</b></p>
            <p>8.4.	Não é permitido ao USUÁRIO: (i) copiar ou transferir de qualquer forma, 
              total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, 
              provisória ou permanentemente, qualquer </p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>conteúdo da Plataforma, quaisquer de suas funcionalidades ou informações nelas contidas; 
              (ii) modificar as características da Plataforma; (iii) criar programas de computador 
              para utilização da Plataforma; e (iv) copiar de qualquer forma dados extraídos da Plataforma, 
              exceto aqueles relativos às às Compras realizadas pelo Cartão.</p>
  
            <p>8.5. O USUÁRIO compromete-se a não infringir quaisquer direitos relativos a marcas, 
              patentes, segredo industrial ou, ainda, direito de propriedade, de representação e autoral, 
              responsabilizando-se perante a {REACT_APP_NAME_CAPS} e seus parceiros pelas obrigações ora assumidas.</p>
  
            <p><b>9.	Remuneração</b></p>
            <p>9.1.	Em razão das Compras realizadas pelo Cartão, o USUÁRIO deverá pagar 
              as tarifas especificadas na plataforma do Emissor.</p>
  
            <p>9.2.	O valor das tarifas poderá ser alterado, a qualquer tempo, 
              mediante comunicação prévia ao USUÁRIO.</p> 
  
            <p>9.2.1.	Caso o USUÁRIO não concorde com a alteração do valor da tarifa, 
              poderá encerrar este Termo e solicitar o cancelamento do Cartão, 
              mediante comunicação à {REACT_APP_NAME_CAPS} e ao Emissor; permanecendo em vigor todas 
              as obrigações do USUÁRIO em razão dos empréstimos já realizados e que 
              deverão ser pagos no tempo e forma devidos.</p>
  
            <p><b>10. Vigência</b></p>
            <p>10.1. Este Termo é celebrado por prazo indeterminado, entrando em vigor na 
              data do aceite ao Formulário de Adesão pelo USUÁRIO.</p> 
            <p>10.2. Este Termo poderá ser encerrado, sem nenhum ônus, a qualquer 
              tempo e por qualquer das Partes, mediante comunicação com 05 (cinco) dias de antecedência.</p>
  
            <p>10.3. Este Termo será rescindido imediatamente, de pleno direito, 
              independentemente de qualquer comunicação ou formalidade: (i) com a decretação 
              de falência ou insolvência de qualquer das Partes; ou (ii) o descumprimento de 
              qualquer das obrigações estabelecidas neste Termo.</p>
  
            <p>10.4. Em caso de término deste Termo, o USUÁRIO continuará a ter acesso à 
              Plataforma, mas não será mais possível a realização de novas Compras no Cartão; 
              permanecendo em vigor todas as condições previstas nas CCBs já emitidas.</p>
  
            <p>10.5. Caso haja o encerramento deste Termo, por qualquer hipótese, 
              o USUÁRIO poderá, a seu exclusivo critério e conveniência, continuar a utilizar 
              o Cartão até que haja o vencimento do prazo de validade; aplicando-se, 
              neste caso, as condições previstas nos Termos e Condições de Uso do Cartão celebrado com o Emissor.</p>
            
            <p><b>11. Política de Privacidade</b></p>
            <p>11.1.	Para fins deste Termo, entende-se por:</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>“<u>Dados Anonimizados</u>”: informações que, isoladamente ou em conjunto com outros dados 
            anonimizados, não permitem a identificação de uma pessoa, considerando a utilização 
            de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.</p>
            <p>“<u>Dados de Uso Técnico</u>”: informações que a {REACT_APP_NAME_CAPS} tratar em razão da 
            utilização de dispositivo celular, computador ou outro dispositivo que o 
            USUÁRIO utilizar para acessar a Plataforma. Os Dados de Uso Técnico mostram como 
            o USUÁRIO utiliza o serviço prestado pela {REACT_APP_NAME_CAPS}, incluindo o endereço IP, 
            estatísticas sobre como as páginas são carregadas ou visualizadas, os sites que 
            o USUÁRIO visitou e informações de navegação coletadas por meio de cookies 
            ou tecnologia semelhante.</p>
            <p>“<u>Dados Pessoais</u>”: Dados Pessoais associadas ao USUÁRIO como uma 
            pessoa natural identificada ou identificável. Podem incluir nome, autorretrato, 
            endereço, número de telefone, e-mail, número da conta corrente ou poupança, 
            data de nascimento, número ou cópia de documentos oficiais 
            (por exemplo, RG, CNH, CPF, dentre outros). Os Dados de Uso Técnico e as Informações 
            do Dispositivos serão considerados Dados Pessoais quando utilizadas para 
            individualizar o USUÁRIO ou sempre que seja possível sua identificação.</p>
            <p>“<u>Informações do Dispositivo</u>”: dados que podem ser coletados automaticamente 
            de qualquer dispositivo utilizado para acessar a Plataforma. Essas informações 
            podem incluir, mas não se limitam ao tipo de dispositivo, conexões de rede 
            do dispositivo, nome do dispositivo, endereço IP do dispositivo, 
            informações sobre o navegador do dispositivo e a conexão de 
            internet usada para acessar a Plataforma.</p>
            <p>“<u>Tratamento</u>”: toda operação realizada com as Dados Pessoais do USUÁRIO, 
            em razão da coleta, produção, recepção, classificação, utilização, 
            acesso, reprodução, transmissão, distribuição, processamento, arquivamento, 
            armazenamento, eliminação, avaliação ou controle da informação, modificação, 
            comunicação, transferência, difusão ou extração.</p>
  
            <p>11.1.1.	Esta cláusula tem o objetivo de informar, de forma clara e completa, 
              sobre como haverá o Tratamento das Dados Pessoais do USUÁRIO, em decorrência 
              da utilização dos serviços prestados pela {REACT_APP_NAME_CAPS}, com a finalidade 
              de proteger a privacidade do USUÁRIO, garantindo que o Tratamento das 
              Dados Pessoais servirá apenas para possibilitar a prestação dos Serviços 
              disponíveis durante a utilização do Cartão.</p>
  
            <p>11.1.2.	Ao utilizar a Plataforma e aderir a este Termo, 
              o USUÁRIO dá expresso consentimento para o Tratamento de seus Dados Pessoais pela 
              {REACT_APP_NAME_CAPS}. Caso o USUÁRIO não concorde com o Tratamento de seus Dados Pessoais 
              na forma prevista neste Termo, deverá se abster de utilizar o Cartão.</p>
  
            <p>11.2.	A {REACT_APP_NAME_CAPS} realiza o Tratamento das Dados Pessoais mínimas, 
              necessárias para a utilização, pelo USUÁRIO, do conjunto de serviços prestados pela {REACT_APP_NAME_CAPS}.</p>
  
            <p>11.2.1.	Os Dados Pessoais do USUÁRIO poderão ser utilizados pela {REACT_APP_NAME_CAPS} 
              para a formação de banco de dados e para aprimorar seus serviços, bem como para elaboração de </p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
          <p>pesquisas e estatísticas voltadas a analisar eficiência da utilização do Cartão e da Plataforma, 
            preservando-se a individualidade e identificação do USUÁRIO de forma anonimizada. 
            As conclusões e resultados dessas pesquisas poderão ser compartilhados ou divulgados 
            pela {REACT_APP_NAME_CAPS} a seu critério, visto se tratar de Dados Anonimizáveis.</p>
  
          <p>11.2.2.	Com a finalidade de prevenir fraudes e garantir a autenticidade das 
            informações fornecidas, poderão ser solicitadas outros Dados Pessoais não contidos 
            no Formulário de Adesão, bem como o envio de documentos adicionais que permitam 
            a confirmação dos dados fornecidos pelo USUÁRIO. Neste caso, 
            a {REACT_APP_NAME_CAPS} entrará em contato com USUÁRIO diretamente. 
            Essas informações e documentos adicionais poderão ser armazenados pela {REACT_APP_NAME_CAPS} 
            enquanto o USUÁRIO mantiver seu cadastro ativo.</p>
  
          <p>11.2.3.	A {REACT_APP_NAME_CAPS} poderá coletar e armazenar outros 
            Dados Pessoais do USUÁRIO para o cumprimento de exigência prevista em 
            lei ou emanada das autoridades competentes, bem como para 
            receber e processar comunicações, chamados e exercício de direitos do USUÁRIO.</p>
  
          <p>11.3.	As informações do USUÁRIO serão obtidas mediante solicitação 
            clara e autorização expressa do USUÁRIO, quando do preenchimento ou confirmação 
            dos dados indicados no Formulário de Adesão, sendo processadas com a 
            finalidade de cumprimento dos Serviços oferecidos na Plataforma. 
            Será realizado o Tratamento de informações adicionais do USUÁRIO apenas 
            com base no interesse legítimo da {REACT_APP_NAME_CAPS}.</p>
  
          <p>11.4.	Os Dados Pessoais do USUÁRIO poderão ser compartilhadas 
            pela {REACT_APP_NAME_CAPS} com (i) terceiros contratados pera prover serviços 
            de computação, transferência de dados e hospedagem em nuvem, desde 
            que esses terceiros guardem o mesmo padrão de privacidade e segurança aplicados 
            pela {REACT_APP_NAME_CAPS} e estejam contratualmente obrigados a não acessar o conteúdo, 
            processar ou compartilhar as informações, exceto mediante ordens 
            expressas da {REACT_APP_NAME_CAPS}; (ii) para empresas do grupo da {REACT_APP_NAME_CAPS}, 
            caso existentes; e (iii) à Instituição de Pagamento que irá realizar a emissão da CCB. 
            Esses terceiros somente poderão utilizar as Dados Pessoais para possibilitar 
            a realização dos serviços prestados pela {REACT_APP_NAME_CAPS}.</p>
  
          <p>11.5.	A {REACT_APP_NAME_CAPS} também poderá ser obrigada, por lei ou 
            por determinação das autoridades competentes, a divulgar Dados Pessoais do USUÁRIO. </p>
  
          <p>11.6.	O e-mail, número de telefone (WhatsApp) e outras informações 
            de contato do USUÁRIO informados no preenchimento do Formulário de Adesão, 
            serão utilizados como meio de comunicação pela {REACT_APP_NAME_CAPS}, 
            apenas para o envio de informações a respeito da Plataforma, solicitação de 
            documentos e de outras informações que vierem a ser necessárias à complementação 
            ou ratificação do Formulário de Adesão.</p>
  
          <p>11.6.1.	A {REACT_APP_NAME_CAPS} não utiliza serviços de terceiros para 
            enviar e-mails em seu nome. Se o USUÁRIO receber e-mail ou 
            mensagens pelo WhatsApp que acredita não terem sido enviados pela {REACT_APP_NAME_CAPS}, 
            deverá se abster de adotar qualquer ação e entrar em contato imediatamente 
            com a {REACT_APP_NAME_CAPS} para confirmar sua veracidade.</p>
  
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
          <p>11.7.	Os Dados Pessoais coletados pela {REACT_APP_NAME_CAPS} são armazenados em servidores seguros, de forma 
            criptografada, com a utilização de medidas de segurança de informação constantemente atualizadas, 
            e serão mantidas confidenciais e submetidas a todas as medidas possíveis contra perda, roubo, 
            uso indevido, alteração e acesso não autorizado.</p>
  
          <p>11.7.1.	Os Dados Pessoais serão armazenados pelo prazo mínimo de 5 (cinco) anos após 
            o cancelamento do cadastro do USUÁRIO e encerramento dos Serviços, por qualquer motivo, 
            com a finalidade específica de produção de provas, caso necessário.</p>
  
          <p>11.7.2.	Os Dados de Uso Técnico relacionados às informações colhidas em 
            razão do acesso do USUÁRIO ao website e serviços de internet da {REACT_APP_NAME_CAPS}, 
            poderão ser armazenados pelo prazo de 06 (seis) meses, de acordo com a legislação vigente.</p>
  
          <p>11.7.3.	Os Dados Anonimizados, que não identifiquem o USUÁRIO, 
            poderão ser armazenados indefinidamente para fins estatísticos.</p>
  
          <p>11.7.4.	Os prazos de armazenamento previstos acima poderão ser alterados 
            pela {REACT_APP_NAME_CAPS} a qualquer momento, de acordo com eventuais alterações a legislação vigente.</p>
  
          <p>11.8.	A {REACT_APP_NAME_CAPS} emprega padrões de segurança avançados, incluindo firewalls, 
            antivírus e outros softwares que auxiliam na proteção de hackers e não vazamento 
            das Dados Pessoais armazenadas, o que não retira a responsabilidade do USUÁRIO 
            em proteger e manter a privacidade de seu Formulário de Adesão e Dados Pessoais, 
            isentando a {REACT_APP_NAME_CAPS} de quaisquer vazamentos ocorridos em razão do compartilhamento 
            indevido do USUÁRIO com terceiros.</p>
  
          <p>11.8.1.	Na medida da legislação aplicável, a {REACT_APP_NAME_CAPS} não se responsabiliza 
            por violações ilegais de sua Plataforma que venham a comprometer a sua base de dados 
            e as Dados Pessoais do USUÁRIO, bem como não se responsabiliza pela utilização indevida 
            das Dados Pessoais obtidas na Plataforma de forma fraudulenta ou ilícita.</p>
  
          <p>11.8.2.	Em caso de suspeita ou confirmação de violação da Plataforma ou de perda 
            de Dados Pessoais do USUÁRIO, a {REACT_APP_NAME_CAPS} envidará seus melhores esforços e tomará 
            medidas imediatas para eliminar ou reduzir os riscos de danos ao USUÁRIO, bem como 
            informará o USUÁRIO potencialmente afetados e as autoridades competentes de tal fato, 
            os riscos envolvidos e as medidas necessárias para evitar tais danos.</p>
  
          <p>11.9.	É permitido ao USUÁRIO, a qualquer tempo, nos limites da legislação aplicável, 
            exercer os direitos de: (i) confirmação da existência de Tratamento de seus Dados Pessoais; 
            (ii) acesso às seus Dados Pessoais mantidas perante a {REACT_APP_NAME_CAPS}; (iii) correção de Dados 
            Pessoais incompletas, inexatas ou desatualizadas; (iv) anonimização, bloqueio ou eliminação 
            de Dados Pessoais desnecessárias, excessivas ou tratadas em desconformidade com 
            o disposto na legislação aplicável; (v) portabilidade de Dados Pessoais a outro 
            fornecedor de serviço mediante requisição expressa e observados os segredos comercial 
            e industrial da {REACT_APP_NAME_CAPS}, de acordo com a regulamentação da autoridade competente; 
            (vi) eliminação das Dados Pessoais tratadas com base no consentimento do USUÁRIO, 
            salvo nas exceções previstas na legislação aplicável; (vii) a informação das 
            entidades públicas e privadas com as quais a {REACT_APP_NAME_CAPS} realizou uso </p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
          <p>compartilhado de Dados Pessoais do USUÁRIO; (viii) a informação sobre a possibilidade 
            de não fornecer consentimento e sobre as consequências da negativa, quando determinada 
            operação de Tratamento embasar-se no consentimento do USUÁRIO; e (ix) a revogação 
            do consentimento, nos termos da legislação aplicável.</p>
  
          <p>11.9.1.	No caso de cancelamento de seu cadastro na Plataforma, o USUÁRIO não poderá 
            utilizar a Plataforma.</p>
  
          <p>11.9.2.	Os direitos descritos acima poderão ser exercidos mediante o envio de 
            solicitação por escrito do USUÁRIO, acompanhada de prova de sua identidade, ao 
            endereço indicado no preâmbulo deste Termo.</p>
  
          <p>11.9.3.	A {REACT_APP_NAME_CAPS} poderá contatar o USUÁRIO para confirmar sua identidade 
            antes do cumprimento da solicitação, que somente será aprovada mediante a confirmação 
            da identidade do USUÁRIO.</p>
  
          <p>11.10.	O USUÁRIO autoriza que todos os contatos com a {REACT_APP_NAME_CAPS}, independentemente 
            do canal de comunicação utilizado, possam ser gravados e armazenados, visando proporcionar 
            maior segurança àqueles que se utilizam da Plataforma, incluindo o próprio USUÁRIO.</p>
  
          <p>11.11.	A {REACT_APP_NAME_CAPS} se compromete a zelar pela segurança de todas as informações 
            fornecidas pelo USUÁRIO, comprometendo-se a utilizar de toda tecnologia disponível 
            para evitar o acesso indevido a tais informações. Entretanto, a {REACT_APP_NAME_CAPS} não poderá 
            ser responsabilidade por eventual furto ou roubo de informações que sejam causadas 
            por terceiros mediante o acesso não autorizado de seus sistemas e da Plataforma.</p>
  
          <p><b>12.	Modificações e Revisões</b></p>
          <p>12.1.	Este Termo será revisto periodicamente pela {REACT_APP_NAME_CAPS} para adequar 
            a prestação dos serviços que integram o Cartão e a licença de uso da Plataforma. 
            A {REACT_APP_NAME_CAPS} poderá alterar este Termo, excluindo, modificando ou inserindo cláusulas 
            ou condições, ao seu exclusivo critério.</p>
  
          <p>12.2.	As alterações deverão ser comunicadas ao USUÁRIO por e-mail, WhatsApp 
            ou mediante comunicação na Plataforma, possibilitando ao USUÁRIO seu 
            amplo conhecimento e acesso.</p>
                    
          <p>12.3.	Caso o USUÁRIO não concorde com as alterações, poderá denunciar 
            este Termo sem qualquer ônus ou penalidade. A continuidade do uso 
            do Cartão ou da Plataforma pelo USUÁRIO será interpretada como concordância 
            e aceitação das alterações realizadas, passando essas a serem integralmente aplicáveis.</p>
          
          <p><b>13.	Disposições Finais</b></p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
          <p>O USUÁRIO declara-se ciente e concorda que, independentemente do local de onde 
            esteja utilizando os serviços que integram o Cartão, a relação entre as Partes 
            será sempre regida pela legislação brasileira.</p>
  
          <p>13.2.	O USUÁRIO compromete-se a isentar a {REACT_APP_NAME_CAPS} de qualquer litígio 
            decorrente da utilização da Plataforma e da realização de Compras pelo Cartão. </p>
  
          <p>13.3.	O USUÁRIO autoriza que a {REACT_APP_NAME_CAPS}, por intermédio da Instituição 
            Financeira, consulte no Sistema de Informações de Crédito (“SCR”) disponibilizado 
            pelo Banco Central do Brasil (“Bacen”), todas e quaisquer informações 
            referentes a quaisquer operações de crédito de sua responsabilidade.</p>
  
          <p>13.3.1.	O SCR tem por finalidade fornecer informações referentes às responsabilidades 
            de clientes em quaisquer operações de crédito, como objetivo de subsidiar 
            decisões de crédito e de negócios.</p>
  
          <p>13.3.2.	O USUÁRIO poderá ter acesso aos dados constantes no SCR 
            por meio do Registro – Extrato do Registro de Informações no Bacen ou 
            da Central de Atendimento ao Público do Bacen, sendo que as manifestações 
            de discordância quanto às informações constantes no SCR e os pedidos 
            de correções e exclusões deverão ser dirigidos às instituições com 
            as quais o USUÁRIO contratou operações de crédito, por meio de 
            requerimento escrito e fundamentado.</p>
  
          <p>13.4.	As Partes elegem o Foro da Capital do Estado de São Paulo 
            como único competente para dirimir as questões decorrentes deste Termo, 
            com renúncia expressa a qualquer outro, por mais privilegiado que seja.</p>
  
          <p>11.4.	Qualquer dúvida em relação a este Termo poderá ser enviada à 
            Central de Atendimento da {REACT_APP_NAME_CAPS}, mediante acesso ao link www.{REACT_APP_NAME_LOW}.com.br, 
            ou pelo telefone (11) 2365-5240.</p>
  
          <p><b>{REACT_APP_NAME_CAPS} SERVIÇOS TECNOLÓGICOS E FINANCEIROS LTDA.</b></p>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          </div>
          {Footer()}
        </div>
      </div>
    );
  }
  */

  const TermoV3 = () => {
    return(
      <div onScroll={Status} style={styles.scroll}>
        <div style={styles.paper}>
          {Header()}
            <div style={styles.text}>
              <p>Por este instrumento particular, de um lado, o “<u>PORTADOR</u>”, identificado e qualificado 
                no formulário de adesão e na assinatura eletrônica aposta no final deste Termo; e, de outro lado, 
                <strong>{REACT_APP_NAME_CAPS} SERVIÇOS TECNOLÓGICOS E FINANCEIROS LTDA.</strong>, inscrita no CNPJ sob o nº
                29.871.099/0001-89, com sede na Rua Joaquim Floriano, nº 413, 11º andar, Bairro Itaim Bibi, São 
                Paulo/SP (“<u>{REACT_APP_NAME_CAPS}</u>”); têm entre si justo e acordado este Termo de Fornecimento do 
                Cartão {REACT_APP_NAME} (“<u>Termo</u>”), de acordo com as cláusulas e condições abaixo.</p>
              {AddBox(
              <b>
                  <p>Ao aceitar eletronicamente, com a marcação da caixa de diálogo “Li e concordo com o Termos de Fornecimento do cartão {REACT_APP_NAME}”, 
                  o PORTADOR estará automaticamente aderindo e concordando com as condições deste Termo.</p>
  
                  <p>Mediante a adesão a este Termo, o PORTADOR expressamente autoriza que a Fonte Pagadora
                  compartilhe seus dados pessoais e todas as informações relacionadas aos Saques e/ou Compras realizados mediante a utilização do Cartão {REACT_APP_NAME}.</p>
  
                  <p>O PORTADOR expressamente autoriza a {REACT_APP_NAME_CAPS}, por intermédio da Instituição Financeira, 
                  a ter acesso a informações financeiras a seu respeito obtidas perante o SCR, 
                  e que podem conter dados protegidos por sigilo bancário, nos termos da Lei Complementar nº 105/2001.</p>
  
                  <p>O PORTADOR declara-se ciente e concorda que, os Saques e/ou Compras realizados mediante a utilização do Cartão {REACT_APP_NAME} foram disponibilizado em razão (i) 
                    da sua relação de trabalho com a Fonte Pagadora e (ii) da margem consignável disponível informada pela Fonte Pagadora para cartão de benefício consignado, 
                    cartão de antecipação de remuneração e cartão de bens duráveis, sendo que as Transações de saque e/ou compras, parceladas ou não, apenas poderão ser realizadas 
                    de acordo com o limite e finalidade estabelecidos na legislação aplicável, incluindo as transações realizadas  para a subsistência do PORTADOR, para o custeio 
                    de suas atividades vitais básicas, como moradia, alimentação, educação, saúde, lazer, vestuário, higiene, transporte, aquisição de bens duráveis, liquidação 
                    de dívidas, reorganização de sua situação econômica-financeira</p>

                  <p>O Cartão {REACT_APP_NAME} poderá ser utilizado como cartão de benefício consignado, cartão de antecipação de remuneração e cartão de bens duráveis conforme 
                    determinado pela legislação aplicável.</p>

                  <p>O PORTADOR declara-se ciente e concorda que a utilização do saque ou compra, parcelado ou não, 
                    derivados ou não  de antecipação de salário e/ou financiamento de bens duráveis, 
                    disponibilizados através do Cartão {REACT_APP_NAME} acarretarão a reserva da margem consignável 
                    destinada ao cartão de benefício consignado, cartão de antecipação de remuneração ou cartão de bens 
                    duráveis disponibilizada pela Fonte Pagadora. A reserva da margem é feita em sua integralidade, mesmo 
                    que o saque e/ou compra realizados não utilizem a integralidade margem, pois a reserva da margem é 
                    feita para o Cartão {REACT_APP_NAME} e não para o saque e/ou compra individualmente considerados.</p>
  
                  <p>A {REACT_APP_NAME_CAPS} poderá periodicamente alterar as condições deste Termo; podendo o 
                  PORTADOR, caso não concorde com a modificação, denunciá-lo sem quaisquer ônus ou 
                  penalidades. A versão atualizada deste Termo poderá ser consultada a qualquer momento 
                  pelo PORTADOR, mediante acesso ao link: www.{REACT_APP_NAME_LOW}.com.br</p>
              </b>)}
              <br/>
              <p><b>1. Definições</b></p>
  
              <p>1.1. As palavras e expressões abaixo, indicadas neste Termo pela primeira letra maiúscula, 
                terão as seguintes definições:</p>
  
              <p>“<u>Cartão {REACT_APP_NAME}</u>”: instrumento de identificação e de pagamento, 
              fornecido pela {REACT_APP_NAME_CAPS}, que possibilita ao PORTADOR realizar Transações 
              de Compra ou Saque, inclusive parcelado, podendo ser derivada da antecipação 
              de remuneração ou para aquisição de bens duráveis, e ainda caracterizado como 
              cartão de benefício consignado, cartão de antecipação de remuneração ou cartão 
              de bens duráveis, conforme legislação aplicável.</p>

              <p>"<u>CCB</u>": Cédula de Crédito Bancário emitida pelo PORTADOR em favor da 
              Instituição Financeira, de acordo com o valor, taxa de juros, quantidade de parcelas, 
              datas de vencimento e demais condições estipuladas em instrumento próprio, cujo crédito 
              será cedido à {REACT_APP_NAME_CAPS} ou à sua ordem. É o instrumento jurídico utilizado para 
              formalização das Transações de Compra ou Saque realizadas através do Cartão {REACT_APP_NAME}.</p>
  
            </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>“<u>Compra</u>”: transação realizada pelo PORTADOR mediante a utilização do Cartão {REACT_APP_NAME}, 
            para o pagamento decorrente da venda de bens e serviços. Esta transação poderá, a exclusivo critério 
            da {REACT_APP_NAME_CAPS}, ser parcelada. As condições de parcelamento, quando disponíveis, serão divulgadas 
            através do aplicativo {REACT_APP_NAME}.</p>

            <p>“<u>Crédito</u>”: Margem consignável disponibilizada ao PORTADOR para utilização das Transações 
            de Saque e Compra relacionadas ao Cartão {REACT_APP_NAME} em razão (i) da sua relação de trabalho com a 
            Fonte Pagadora e (ii) considerando as margens de consignação disponíveis informadas pela Fonte Pagadora 
            para o cartão de benefício consignado, cartão de antecipação de remuneração e cartão de bens duráveis, 
            incluindo as Transações de Compra e Saque. Poderá ser disponibilizado ao PORTADOR limite de crédito 
            específico para compra e limite de crédito específico para saque, porém o Crédito sempre representará 
            a margem consignável total atribuída ao cartão de benefício consignado, cartão de antecipação de 
            remuneração ou cartão de bens duráveis O Crédito pode ser oriundo de antecipação da remuneração ou 
            financiamento da aquisição de bens duráveis do PORTADOR conforme legislação aplicável.</p>
  
            <p>“<u>Domicílio</u>”: conta de livre movimentação de titularidade do PORTADOR, mantida perante 
            instituição bancária ou de pagamento, aceitas pela {REACT_APP_NAME_CAPS}, na qual receberá os pagamentos decorrentes dos Saques.</p>
  
            <p>“<u>Estabelecimento</u>”: pessoa jurídica ou física, fornecedora de bens e serviços que poderá 
            receber os pagamentos decorrentes das Transações realizadas com o Cartão {REACT_APP_NAME}. </p>
  
            <p>“<u>Fonte Pagadora</u>”: órgão público credenciado ou conveniado com a Instituição Financeira 
            ou com a {REACT_APP_NAME_CAPS} para o fornecimento do Cartão {REACT_APP_NAME_CAPS}, com o qual o PORTADOR, 
            em razão de sua relação de trabalho ou previdenciária, possui Créditos periódicos a receber 
            (vencimentos, remuneração, adicionais, bônus e benefícios entre outros).</p>
  
            <p>“<u>Formulário de Adesão</u>”: formulário eletrônico disponibilizado ao PORTADOR, constando os 
            dados pessoais e demais informações do PORTADOR.</p>
  
            <p>“<u>Instituição Financeira</u>”: {REACT_APP_NAME_LECCA} – Crédito, Financiamento e Investimento S.A., 
            inscrita no CNPJ sob o nº 29.871.099/0001-89, com sede na Rua São José, nº 20, sala 201 
            - Rio de Janeiro / RJ, CEP 20010-020; ou outra que venha a celebrar parceria com a {REACT_APP_NAME_CAPS} 
            (conforme indicado na respectiva CCB).</p>
  
            <p>“<u>Plataforma</u>”: site disponível em www,{REACT_APP_NAME_LOW}.com.br e aplicativo para dispositivos 
            móveis {REACT_APP_NAME}  de titularidade da {REACT_APP_NAME_CAPS}, pelo qual o PORTADOR poderá realizar e 
            acompanhar suas Transações feitas através do Cartão {REACT_APP_NAME}, bem como contratar com parceiros 
            {REACT_APP_NAME} os benefícios adicionais disponibilizados e informados pela {REACT_APP_NAME_CAPS}</p>
  
            <p>“<u>PORTADOR</u>”: pessoa física, maior e capaz, que aderiu a este Termo.</p>
  
            <p>“<u>Saque</u>”: transação pela qual o PORTADOR utiliza o Cartão {REACT_APP_NAME} para o resgate 
            de recursos, que serão transferidos para o Domicílio.</p>
  
            <p>“<u>Transação</u>”: Compra, Saque ou outra que venha a ser disponibilizada pela {REACT_APP_NAME_CAPS}, 
            realizada pelo PORTADOR mediante a utilização do Cartão {REACT_APP_NAME}, podendo ser derivada de 
            antecipação de remuneração ou aquisição de bens duráveis.</p>
            
            <p><b>2. Fornecimento do Cartão {REACT_APP_NAME}</b></p>
  
            <p>2.1. Este Termo tem por objetivo estabelecer as condições pelas quais a {REACT_APP_NAME_CAPS} 
              irá fornecer o Cartão {REACT_APP_NAME}, para que o PORTADOR possa realizar Transações de: 
              (i) Compra; e/ou (ii) Saque, mediante a transferência de recursos para o Domicílio 
              cadastrado.</p>
  
            <p>2.1.1. Em razão da emissão do Cartão {REACT_APP_NAME}, a {REACT_APP_NAME_CAPS} prestará ao PORTADOR 
              os serviços de:</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>(a) Análise das informações e documentos do PORTADOR, com a finalidade de verificar 
            a viabilidade de emissão do Cartão {REACT_APP_NAME};</p>
  
            <p>(b) A disponibilização da fatura ao PORTADOR, contendo: (i) o histórico de Transações 
            realizadas no período de apuração; (ii) o valor total devido pelo PORTADOR, de acordo 
            com as Transações realizadas; e (iii) as demais informações relacionadas ao Cartão {REACT_APP_NAME}; e</p>
  
            <p>(c) O fornecimento da tecnologia necessária para a captura, processamento e liquidação 
            das Transações realizadas com Cartão {REACT_APP_NAME}. </p>
  
            <p>2.1.2. <b>O PORTADOR declara-se ciente e concorda que, diante da natureza da 
              operação decorrente do Fornecimento do Cartão {REACT_APP_NAME}:</b></p>
            <b>
            <p>(a) Com a solicitação do fornecimento do Cartão {REACT_APP_NAME}, haverá disponibilização 
              da função Saque, sendo que na hipótese de realização de Saque haverá a emissão de uma 
              CCB em benefício da Instituição Financeira, com a incidência de juros e outros encargos;</p>
  
            <p>(b) Após a emissão da CCB representativa do Saque, a mesma será endossada para a 
              {REACT_APP_NAME_CAPS}, que se tornará a legítima credora dos valores devidos pelo PORTADOR 
              em razão da realização das Transações com o Cartão {REACT_APP_NAME}. A {REACT_APP_NAME_CAPS} poderá, 
              ainda, a seu exclusivo critério, ceder, transferir ou endossar a CCB a favor de 
              fundos de investimentos ou terceiros interessados;</p>
  
            <p>(c) Com a solicitação do fornecimento do Cartão {REACT_APP_NAME}, a Fonte Pagadora 
              efetuará a reserva da margem consignável destinada ao cartão de benefício consignado 
              que será composta tanto do percentual destinado ao Saque quanto do percentual 
              destinado à Compras. Este valor constituirá os Créditos do Cartão {REACT_APP_NAME};</p>
  
            <p>(d) O valor da parcela a ser descontada da folha de pagamento do PORTADOR 
              será aquele indicado na CCB e correspondente ao(s) Saque(s) e/ou Compra(s) 
              realizados individualmente, podendo a reserva de margem consignável ser em 
              valor maior que aquela indicada na CCB já que a reserva da margem consignável 
              se refere ao Cartão {REACT_APP_NAME} e não as transações individualmente consideradas;</p>
  
            <p>(e) Com o recebimento do Cartão {REACT_APP_NAME}, o PORTADOR poderá ativar a função 
              Compras através do aplicativo {REACT_APP_NAME}, sendo que na hipótese de realização 
              de Compras, caso não haja o pagamento da fatura dentro do seu prazo de vencimento, 
              poderá haver a emissão de uma CCB em benefício da Instituição Financeira, 
              com a incidência de juros e outros encargos, com o parcelamento da compra efetivada. 
              A Fonte Pagadora é responsável por informar à {REACT_APP_NAME_CAPS}, mensalmente, sobre o 
              valor dos Créditos devidos ao PORTADOR e eventuais alterações aplicáveis;</p>
  
            <p>(f) Após a emissão da CCB representativa da Compra, a mesma será endossada para 
              a {REACT_APP_NAME_CAPS}, que se tornará a legítima credora dos valores devidos pelo PORTADOR 
              em razão da realização das Transações com o Cartão {REACT_APP_NAME}. A {REACT_APP_NAME_CAPS} poderá, 
              ainda, a seu exclusivo critério, ceder, transferir ou endossar a CCB a favor de fundos 
              de investimentos ou terceiros interessados;</p>

            <p>(g) As CCBs poderão ser assinadas pela {REACT_APP_NAME_CAPS} na qualidade de procurador 
              do PORTADOR conforme cláusula 4.2. deste Termo;</p>

            <p>(h) A reserva de margem consignável do Cartão {REACT_APP_NAME} contemplará sempre 
              os percentuais destinados à Saque e Compras, não sendo possível reserva de margem 
              parcial para uma ou outra Transação, já que a reserva da margem consignável 
              refere-se ao cartão e constitui os Créditos disponibilizados para cartão o servidor público;</p>

            <p>(i) A Fonte Pagadora irá realizar a retenção do valor integral das faturas 
              (incluindo as tarifas aplicáveis) da remuneração que lhe é devida,
               para posterior repasse à {REACT_APP_NAME_CAPS};</p>

            <p>(j) A Fonte Pagadora poderá, a seu exclusivo critério, determinar restrições 
              para o PORTADOR, em conjunto com os demais servidores, com relação ao valor 
              e/ou natureza das Transações a serem realizadas por meio do Cartão {REACT_APP_NAME}, 
              de acordo com a legislação aplicável;</p>

            <p>(k) Caso o seu Crédito seja decorrente de remuneração a ser paga em razão de 
              relação de trabalho com a Fonte Pagadora, as Transações apenas poderão ser realizadas 
              de acordo com o limite estabelecido na legislação aplicável, e as Transações 
              deverão ser necessariamente realizadas com observância às finalidades indicadas na 
              legislação aplicável, incluindo  a subsistência do PORTADOR, o custeio de suas atividades 
              vitais básicas, como moradia, alimentação, educação, saúde, lazer, vestuário, higiene e 
              transporte, aquisição de bens duráveis, liquidação de dívidas, reorganização de sua 
              situação econômica-financeira; e</p>

            <p>(l) Sem prejuízo de outras condições adotadas, é condição para a aprovação da solicitação 
              de emissão do Cartão {REACT_APP_NAME}, assim como, para a manutenção das suas funcionalidades de 
              Ccompra e Caque ativas, a regularidade do pagamento da remuneração e ou benefícios feitos 
              pela Fonte Pagadora, autorizando, desde já, que a {REACT_APP_NAME_CAPS} bloqueie ou cancele o Cartão 
              {REACT_APP_NAME} na hipótese de suspensão ou interrupção, definitiva ou temporária, dos pagamentos 
              da remuneração ou benefícios pela Fonte Pagadora. </p>
            </b>
  
            <p><b>3. Credenciamento do PORTADOR</b></p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>3.1. A adesão do PORTADOR a este Termo ocorrerá mediante: (i) 
              o aceite eletrônico deste Termo ; e (ii) a aprovação da solicitação 
              de fornecimento do Cartão {REACT_APP_NAME} feita pela {REACT_APP_NAME_CAPS}.</p>
            <p>3.1.1. A adesão deste Termo, assim como a aprovação da solicitação 
              de fornecimento do Cartão {REACT_APP_NAME} feita pela {REACT_APP_NAME_CAPS} também 
              poderão ocorrer através da central de atendimento da {REACT_APP_NAME_CAPS} 
              (inclusive por telefone), WhatsApp, aplicativo {REACT_APP_NAME} ou quaisquer 
              outros meios que possibilitem a manifestação de vontade do PORTADOR
               e sejam disponibilizados pela {REACT_APP_NAME_CAPS}.</p>
            <p>3.1.2. Ao aderir a este Termo, o PORTADOR deverá 
              observar à todas as regras relacionadas com a utilização 
              do Cartão {REACT_APP_NAME}; de acordo com as políticas e diretrizes 
              da {REACT_APP_NAME_CAPS} disponibilizadas na Plataforma.</p>
            <p>3.2. Ao solicitar o fornecimento do Cartão {REACT_APP_NAME}, 
              o PORTADOR: (i) deverá prestar todas as informações solicitadas 
              no Formulário de Adesão; (ii) responsabiliza-se civil e 
              criminalmente pela veracidade das informações prestadas; e 
              (iii) obriga-se a manter seus dados atualizados junto à {REACT_APP_NAME_CAPS}.</p>
            <p>3.2.1. A {REACT_APP_NAME_CAPS} poderá, a qualquer momento e a seu exclusivo critério, 
              solicitar ao PORTADOR o envio de informações adicionais e/ou de documentos 
              que comprovem as informações prestadas.</p>
            <p>3.2.2. Caso a {REACT_APP_NAME_CAPS} constate haver dados incorretos ou inverídicos, 
              ou, ainda, caso o PORTADOR se recuse a enviar as informações e documentos 
              solicitados, a {REACT_APP_NAME_CAPS} poderá negar o fornecimento do Cartão {REACT_APP_NAME} 
              e não liberar o acesso à Plataforma, sem prejuízo de outras medidas que 
              entender necessárias; não assistindo ao PORTADOR qualquer tipo de indenização ou ressarcimento.</p>
            <p>3.2.3. A {REACT_APP_NAME_CAPS} poderá, a seu exclusivo critério, realizar pesquisas, 
              em base de dados públicas ou privadas (inclusive o SCR), com a finalidade 
              de verificar a veracidade dos dados e informações indicadas pelo PORTADOR no 
              Formulário de Adesão e a proceder à análise de crédito do PORTADOR.</p>
            <p>3.2.4. O PORTADOR, quando solicitado pela {REACT_APP_NAME_CAPS}, 
              deverá cadastrar login e senha para utilização da Plataforma. 
              O uso do login e senha são de uso pessoal, exclusivo e intransferível pelo 
              PORTADOR, que deverá mantê-los confidenciais e não permitir seu acesso por terceiros.</p>
            <p>3.2.5. O PORTADOR deverá informar e-mail e número de telefone 
              válidos para comunicação com a {REACT_APP_NAME_CAPS}; sendo que qualquer comunicação 
              enviada por e-mail, SMS, WhatsApp ou pela Plataforma será considerada válida 
              e eficaz entre as Partes.</p>
            <p>3.3. O Cartão {REACT_APP_NAME} será emitido em formato virtual, contendo as 
              informações necessárias para identificação do PORTADOR, podendo ser disponibilizado, 
              a exclusivo critério da {REACT_APP_NAME}, o cartão físico ao PORTADOR.</p>
            <p>3.3.1. O Cartão {REACT_APP_NAME} será disponibilizado através do aplicativo da {REACT_APP_NAME_CAPS}, 
              podendo ser utilizado nas carteiras digitais autorizadas e, na hipótese, da 
              {REACT_APP_NAME_CAPS} disponibilizar o cartão físico, a entrega poderá ser realizada mediante: 
              (i) o envio de correspondência, pelo correio, ao endereço indicado pelo PORTADOR; 
              ou (ii) a entrega presencial ao PORTADOR, nos pontos de atendimento da {REACT_APP_NAME_CAPS}..</p>
            <p>3.3.2. Caberá exclusivamente ao PORTADOR a conferência dos dados pessoais 
              contidos no Cartão {REACT_APP_NAME}, antes de utilizá-lo. A sua utilização ou 
              desbloqueio ensejará na concordância do PORTADOR quanto às informações 
              indicadas no Cartão {REACT_APP_NAME}.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
            <div style={styles.text}>
            <p>3.3.3. O Cartão {REACT_APP_NAME} será emitido juntamente com uma senha previamente definida 
              pela {REACT_APP_NAME_CAPS}, de uso pessoal e intransferível pelo PORTADOR.</p>
            <p>3.4. A {REACT_APP_NAME_CAPS} poderá cancelar a utilização do Cartão {REACT_APP_NAME} sempre que, 
              a seu exclusivo critério, houver o descumprimento das obrigações previstas neste 
              Termo ou da legislação vigente, assim como no caso de determinação judicial ou 
              administrativa.</p>
            <p>3.5. Após o recebimento do Cartão {REACT_APP_NAME}, o PORTADOR será o único responsável 
              pela sua guarda e conservação, obrigando-se a zelar pela sua segurança.</p>
            <p>3.5.1. O PORTADOR será o único e exclusivo responsável pelo uso indevido do 
              Cartão {REACT_APP_NAME} até a data da comunicação à {REACT_APP_NAME_CAPS} para a realização do bloqueio.</p>
            <p>3.6. Caberá ao PORTADOR, por motivos justificáveis e mediante o pagamento 
              de eventuais tarifas incidentes, solicitar um novo Cartão {REACT_APP_NAME} à {REACT_APP_NAME_CAPS}, 
              sendo que a nova emissão ensejará no automático cancelamento do Cartão {REACT_APP_NAME} substituído, 
              ficando impedida sua utilização</p>
            <p><b>4. Desbloqueio do Cartão {REACT_APP_NAME} e Emissão da CCB</b></p>
            <p>4.1. Ao solicitar o Cartão {REACT_APP_NAME}, será concedido ao PORTADOR a possibilidade de realizar  Transações de Saque, 
              levando em consideração as informações e documentos apresentados pelo PORTADOR na solicitação de fornecimento do Cartão {REACT_APP_NAME}.</p>
            <p>4.1.1. O PORTADOR autoriza, desde já, a {REACT_APP_NAME_CAPS}, por si ou por intermédio 
              da Instituição Financeira, a consultar bancos de dados cadastrais públicos ou privados, inclusive o SCR 
              e Serviços de Proteção ao Crédito, para a obtenção de informações cadastrais e de crédito.</p>
            <p>4.1.2. A funcionalidade de Compras poderá ser ativada pelo PORTADOR quando ocorrer 
              a disponibilização do Cartão {REACT_APP_NAME} no formato digital.</p>
            <p>4.1.3. O desbloqueio do Cartão {REACT_APP_NAME}, assim como a ativação da funcionalidade Compras, 
              poderão ser feitos pelo PORTADOR na Plataforma ou através de qualquer outro meio disponibilizado 
              pela {REACT_APP_NAME_CAPS}.</p>
            <p>4.1.4. O Crédito ou Limite de Crédito que corresponde à margem consignável para cartão reservada, 
              assim como qualquer informação sobre a utilização e benefícios do Cartão {REACT_APP_NAME} estarão 
              disponíveis nos Canais de Atendimento da {REACT_APP_NAME} e na Plataforma.</p>
            <p>4.1.5. A aceitação da solicitação do cartão poderá, a exclusivo critério da {REACT_APP_NAME_CAPS}, depender ainda: 
              (i) da demonstração da prova de vida do PORTADOR,; (ii) da confirmação do endereço residencial informado 
              pelo PORTADOR; e (iii) da concordância do PORTADOR quanto ao valor, taxa de juros, encargos, datas de vencimento 
              e demais condições financeiras (inclusive o Custo Efetivo da Transação - CET).</p>
            <p>4.1.6. A CCB será emitida para formalizar a Transação de Saque ou Compra do PORTADOR pela Instituição Financeira, 
              mediante o pagamento de juros e outros encargos previstos no respectivo instrumento.</p>
            <p><b>4.2. Para possibilitar a formalização das Transações de Saque e/ou Compra parcelada 
              através da CCB, o PORTADOR nomeia a {REACT_APP_NAME_CAPS} como sua bastante procuradora, com poderes 
              especiais para, em seu nome e por sua conta, negociar e obter crédito perante a Instituição 
              Financeira; outorgando-lhe poderes especiais para assinar a CCB, abrir conta para movimentar 
              os valores financiados, negociar prazos, juros e outros encargos, repactuar taxas de juros e 
              emitir títulos representativos do débito perante a Instituição Financeira, assim como, concordar 
              com endosso ou cessão de direitos creditórios derivados da CCB, seja a favor da {REACT_APP_NAME_CAPS}, seja 
              a favor de fundos de investimentos ou terceiros.</b></p>
            </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
            <div style={styles.text}>
            <p>4.2.1. O PORTADOR desde já autoriza a {REACT_APP_NAME_CAPS} a coletar, tratar e compartilhar os 
              seus dados pessoais e financeiros com a Instituição Financeira, para emissão da CCB..</p>
            <p>4.2.2. A {REACT_APP_NAME_CAPS} poderá, em nome da Instituição Financeira, cobrar o 
              PORTADOR dos débitos decorrentes da CCB.</p>
            <p>4.3. O PORTADOR declara-se ciente e concorda que os direitos creditórios decorrentes 
              da CCB serão transferido pela Instituição Financeira para a {REACT_APP_NAME_CAPS}, mediante endosso 
              ou cessão, logo após a sua emissão; de modo que os pagamentos devidos pelo PORTADOR em 
              razão da realização de Transações de Saque e/ou Compras com o Cartão {REACT_APP_NAME} deverão 
              ser realizados diretamente à {REACT_APP_NAME_CAPS} ou a quem esta indicar.</p>
            <p><b>5. Realização de Transações</b></p>
            <p>5.1. A aquisição de bens e a negociação da forma de pagamento será feita pelo PORTADOR 
              diretamente com o respectivo Estabelecimento, não sendo a {REACT_APP_NAME_CAPS} responsável por 
              quaisquer assuntos envolvendo a operação de aquisição de produtos que deu origem à 
              Transações de Saque ou Compra.</p>
            <p>5.1.1. A {REACT_APP_NAME_CAPS} não se responsabiliza pelo preço, quantidade, qualidade e/ou 
              garantia dos bens adquiridos, cabendo ao PORTADOR resolver tais questões 
              diretamente com o Estabelecimento respectivo.</p>
            <p>5.2. O PORTADOR também poderá utilizar o Cartão {REACT_APP_NAME} para a realização 
              de Saques, mediante a transferência de recursos para o seu Domicílio.</p>
            <p>5.2.1. O valor do Saque será creditado pela {REACT_APP_NAME_CAPS}, no Domicílio 
              informado pelo PORTADOR. Mediante o recebimento do valor respectivo pelo PORTADOR, 
              fica a {REACT_APP_NAME_CAPS} quitada com relação ao pagamento da Transação, de forma 
              irrevogável e irretratável, não havendo nada a mais a ser reclamado a esse título; 
              valendo o comprovante da TED, PIX ou de outra forma de transferência como documento 
              hábil para comprovação da quitação.</p>
            <p>5.2.2. O PORTADOR é responsável por manter a regularidade do Domicílio. Caso o banco 
              ou instituição de pagamento recebedor declare-se impedido, por qualquer motivo, 
              de dar cumprimento às ordens de crédito emitidas pela {REACT_APP_NAME_CAPS}, deverá 
              o PORTADOR providenciar sua regularização ou, ainda cadastrar novo Domicílio, no prazo de até 2 
              (dois) dias úteis.</p>
            <p>5.2.3. A {REACT_APP_NAME_CAPS} está autorizada a reter o pagamento das Transações até a regularização 
              do Domicílio cadastrado, sem que haja quaisquer ônus, penalidades ou encargos.</p>
            <p>5.2.4. Na hipótese de a data prevista para o Saque ser considerada feriado ou em dia de 
              não funcionamento bancário, a transferência poderá ser realizada no primeiro dia útil subsequente.</p>
            <p>5.3. O PORTADOR terá acesso às Transações realizadas por meio dos Canais de 
              Atendimento e da Plataforma da {REACT_APP_NAME}. A disponibilização do extrato dos 
              pagamentos caracteriza-se como prestação de contas para fins legais.</p>
            <p>5.5.1. O PORTADOR poderá contestar qualquer lançamento realizado no Cartão 
              {REACT_APP_NAME} no prazo de 15 (quinze) dias a contar da disponibilização da sua 
              realização na Plataforma. As contestações podem ser realizadas através dos 
              Canais de Atendimento da {REACT_APP_NAME} e serão atendidas no prazo de 15 (quinze) 
              dias, podendo existir contestações que precisam de mais tempo para apuração do ocorrido.</p>
            </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>5.4. É vedada a utilização do Cartão {REACT_APP_NAME} e a realização das Transações para 
              a celebração de negócios: (i) considerados ilícitos, nos termos da legislação brasileira; 
              (ii) considerados como crimes financeiros, com o intuito de lavagem de dinheiro, financiamento 
              ao terrorismo e corrupção, dentre outros crimes correlatos, ainda que indiretamente; 
              (iii) que não representem um negócio jurídico regular e tenham por intenção a prática de fraudes; 
              ou (iv) que, de qualquer modo, venham a causar prejuízos à {REACT_APP_NAME_CAPS}, seus parceiros, 
              Instituição Financeira ou Fonte Pagadora.</p>
            <p>5.4.1. As Transações com indícios ou suspeitas de fraude estarão sujeitas ao cancelamento, 
              ainda que realizada de forma conivente ou não pelo PORTADOR.</p>
            <p>5.4.2. A {REACT_APP_NAME_CAPS} poderá bloquear o uso ou cancelar o Cartão {REACT_APP_NAME}, suspender 
              o acesso à Plataforma e deixar de realizar as Transações sempre que identificar que a 
              atividade do PORTADOR ou natureza das Transações viola qualquer dispositivo deste Termo 
              ou da legislação vigente, independentemente de qualquer aviso ou notificação prévia; 
              não gerando ao PORTADOR qualquer tipo de indenização ou ressarcimento.</p>
            <p>5.4.3. Nas hipóteses de bloqueio de uso ou cancelamento do Cartão {REACT_APP_NAME}, as 
              Transações de Saque e/ou Compra parceladas deverão ser integralmente pagas pelo 
              PORTADOR na forma ajustada na CCB.</p>
            <p><b>6. Pagamento da Fatura</b></p>
            <p>6.1. As faturas estarão disponibilizadas nos Canais de Atendimento e na Plataforma da 
              {REACT_APP_NAME_CAPS}, incluindo o aplicativo {REACT_APP_NAME_CAPS}. A cada mês, na respectiva data de vencimento, 
              a {REACT_APP_NAME_CAPS} disponibilzará a fatura contendo o valor total das Transações realizadas no 
              período de apuração, de forma discriminada e o respectivo boleto para pagamento.</p>
            <p>6.1.1. A fatura poderá ser disponibilizada ao PORTADOR por e-mail, 
              WhatsApp ou na Plataforma, devendo o PORTADOR manter seu e-mail e número de telefone 
              atualizados perante a {REACT_APP_NAME_CAPS}.</p>
            <p>6.1.2. Para as Compras realizadas com o Cartão {REACT_APP_NAME} que constarão da fatura que ficará disponível 
              no aplicativo {REACT_APP_NAME}, é concedida ao PORTADOR duas formas de pagamento: (i) à vista até a 
              data de vencimento indicado na fatura e respectivo boleto de pagamento; ou (ii) parcelado que 
              ocorrerá de forma automática caso o PORTADOR não efetue o pagamento das Compras realizadas na 
              data do seu vencimento. Nos casos em que o PORTADOR optar pelo pagamento parcelado, a {REACT_APP_NAME_CAPS} 
              providenciará a emissão de uma CCB com as condições disponibilizadas no aplicativo {REACT_APP_NAME}.</p>
            <p>6.1.3. A autenticidade das comunicações recebidas pelo PORTADOR deverá ser previamente
               verificada perante a {REACT_APP_NAME_CAPS}; sendo que a {REACT_APP_NAME_CAPS} não se utiliza de serviços 
               de terceiros para a comunicação com o PORTADOR.</p>
            <p>6.2. As faturas indicarão todas as informações que possibilitem ao PORTADOR 
              a identificação das Transações, com referência ao respectivo Estabelecimento 
              e os Saques realizados.</p>
            <p>6.3. As faturas serão pagas mediante o repasse dos Créditos, diretamente pela 
              Fonte Pagadora à {REACT_APP_NAME_CAPS}, após o desconto dos valores na folha de pagamento 
              do PORTADOR, podendo o PORTADOR efetuar o pagamento das Compras nas formas 
              indicadas na claúsula 6.1.2.</p>
            <p>6.3.1. O PORTADOR reconhece que, uma vez aprovada a Transação, possuirá 
              débito líquido, certo e exigível perante a {REACT_APP_NAME_CAPS}, em relação ao valor 
              da respectiva Transação e de acordo com os termos pactuados na 
              CCB (incluindo a taxa de juros e demais encargos).</p>
            <p>6.3.2. O PORTADOR, neste ato, autoriza que: (i) a {REACT_APP_NAME_CAPS} 
              informe o valor da fatura para a Fonte Pagadora, bem como disponibilize 
              qualquer documento, inclusive cópia de CCB e dos documentos pessoais para 
              a Fonte Pagadora; (ii) a Fonte Pagadora realize a retenção e o repasse dos 
              recursos respectivos diretamente para a {REACT_APP_NAME_CAPS} (ou a quem esta indicar), 
              com a finalidade de quitar o débito constituído pelo meio da CCB emitida 
              pelo PORTADOR ou em razão das compras efetuadas.</p>
  
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>6.3.3. Após a realização da Transação, o PORTADOR concorda 
              que todos e quaisquer recursos oriundos dos Créditos, de acordo com o valor de cada Transação, 
              serão detidos exclusivamente pela {REACT_APP_NAME_CAPS}, inclusive eventuais acréscimos; devendo a 
              Fonte Pagadora efetuar os pagamentos diretamente para a {REACT_APP_NAME_CAPS} ou a quem ela indicar.</p>
            <p>6.4. Ainda, a {REACT_APP_NAME_CAPS} poderá, sem a necessidade de aviso prévio ou 
              qualquer formalidade, bloquear o uso do Cartão {REACT_APP_NAME} até a liquidação do 
              valor inadimplido, acrescido dos encargos moratórios incidentes.</p>
            <p>6.4.1. Havendo o cancelamento do Cartão {REACT_APP_NAME}, por qualquer motivo, 
              o acesso à Plataforma será cancelado no prazo de 30 (trinta) dias; cabendo 
              ao PORTADOR obter e imprimir os extratos disponibilizados pela {REACT_APP_NAME_CAPS}.</p>
            <p>6.5. A {REACT_APP_NAME_CAPS} disponibiliza Canais de Atendimento, , para que o PORTADOR 
              possa: (i) realizar o bloqueio ou desbloqueio do Cartão {REACT_APP_NAME}; (ii) solicitar 
              a emissão da fatura ou regularizar o pagamento de eventuais débitos; e (iii) 
              dirimir as dúvidas sobre a correta utilização do Cartão {REACT_APP_NAME}.</p>
            <p><b>7. Utilização da Plataforma</b></p>
            <p>7.1. Os serviços decorrentes do fornecimento do Cartão {REACT_APP_NAME} serão 
              prestados de forma remota, mediante a licença de uso da Plataforma ao PORTADOR.</p>
            <p>7.2. O PORTADOR declara-se ciente de que, em se tratando de serviços 
              de tecnologia e que dependem de serviços prestados por terceiros, 
              a {REACT_APP_NAME_CAPS} não poderá ser responsabilizada ou assumirá qualquer responsabilidade por 
              falhas, erros, interrupções, mau funcionamento ou atrasos na utilização da 
              Plataforma ou de seus Canais de Atendimento; não garantindo a utilização do 
              Cartão {REACT_APP_NAME} de forma ininterrupta, sem momentos de indisponibilidade ou lentidão.</p>
            <p>7.3. A {REACT_APP_NAME_CAPS} também não será responsável pela: (i) intermitência ou indisponibilidade 
              de conexão à internet adotada pelo PORTADOR; (ii) incapacidade técnica do 
              dispositivo móvel ou sistema operacional; (iii) indisponibilidade da Plataforma no 
              navegador de internet utilizado pelo PORTADOR; e/ou (iv) atividades de pessoas 
              não autorizadas a utilizar a Plataforma.</p>
            <p>7.4. Não é permitido ao PORTADOR: (i) copiar ou transferir de qualquer forma, 
              total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, 
              provisória ou permanentemente, qualquer conteúdo da Plataforma, quaisquer 
              de suas funcionalidades ou informações nelas contidas; 
              (ii) modificar as características da Plataforma; 
              (iii) criar programas de computador para utilização da Plataforma; 
              e (iv) copiar de qualquer forma dados extraídos da Plataforma, exceto 
              aqueles relativos às Transações realizadas pelo Cartão {REACT_APP_NAME}.</p>
            <p>7.5. O PORTADOR compromete-se a não infringir quaisquer direitos 
              relativos a marcas, patentes, segredo industrial ou, ainda, 
              direito de propriedade, de representação e autoral, responsabilizando-se perante 
              a {REACT_APP_NAME_CAPS} e seus parceiros pelas obrigações ora assumidas.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p><b>8. Remuneração</b></p>
            <p>8.1. Em razão das Transações realizadas pelo Cartão {REACT_APP_NAME}, o PORTADOR deverá 
              pagar os valores especificados no Formulário de Adesão e divulgadas na Plataforma.</p>
            <p>8.2. O valor de taxas e tarifas que possam vir a ser cobradas pela {REACT_APP_NAME_CAPS} 
              é variável de acordo com a natureza e valor da Transação; e poderá ser alterado, 
              a qualquer tempo, conforme informado previamente ao PORTADOR por meio da Plataforma.</p>
            <p>8.2.1. Caso o PORTADOR não concorde com o valor da tarifa 
              aplicável para determinada Transação, poderá encerrar este Termo, 
              a qualquer tempo, mediante comunicação à {REACT_APP_NAME_CAPS}.</p>
            <p><b>9. Vigência</b></p>
            <p>9.1. Este Termo é celebrado por prazo indeterminado, entrando em vigor 
              na data da aprovação da solicitação do Cartão {REACT_APP_NAME} feita pela {REACT_APP_NAME} 
              e comunicada ao PORTADOR pelos meios de comunicação usualmente utilizados. 
              (e-mail ou SMS ou push ou mensagem de whatsapp).</p>
            <p>9.2. Este Termo poderá ser encerrado, sem nenhum ônus, a qualquer tempo 
              e por qualquer das Partes, mediante comunicação com 05 (cinco) dias de antecedência.</p>
            <p>9.3. Este Termo será rescindido imediatamente, de pleno direito, 
              independentemente de qualquer comunicação ou formalidade: (i) 
              com a decretação de falência ou insolvência de qualquer das Partes; 
              (ii) o descumprimento de qualquer das obrigações estabelecidas neste Termo; 
              (iii) a rescisão do contrato do PORTADOR com a Fonte Pagadora; 
              e (iv) rescisão do contrato celebrado entre a {REACT_APP_NAME_CAPS} e a Fonte Pagadora.</p>
            <p>9.4. Em caso de término deste Termo, 
              o PORTADOR continuará a ter acesso à Plataforma, mas 
              não será mais possível a realização de novas Transações 
              no Cartão {REACT_APP_NAME}; permanecendo em vigor todas as 
              condições previstas nas CCBs já emitidas.</p>
            <p>9.5. Caso o PORTADOR encerre seu contrato com a Fonte Pagadora, 
              por qualquer motivo, a Fonte Pagadora irá realizar a retenção e 
              repasse do valor da Transação devido à {REACT_APP_NAME_CAPS}, descontando-o da 
              quantia a ser paga ao PORTADOR em razão da rescisão.</p>
            <p>9.5.1. Caso o desconto do valor não seja 
              suficiente para o pagamento do valor da Transação, 
              a {REACT_APP_NAME_CAPS} irá notificar o PORTADOR para que realize 
              o pagamento do saldo devedor, no prazo de até 02 (dois) dias úteis.</p>
            <p><b>10. Política de Privacidade</b></p>
            <p>10.1. Para fins deste Termo, entende-se por:</p> 
            <p>“<u>Dados Anonimizados</u>”: informações que, isoladamente ou em conjunto 
              com outros dados anonimizados, não permitem a identificação de uma pessoa, 
              considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.</p>
  
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>“<u>Dados de Uso Técnico</u>”: informações que a {REACT_APP_NAME_CAPS} tratar em razão da utilização 
            de dispositivo celular, computador ou outro dispositivo que o PORTADOR utilizar para acessar 
            a Plataforma. Os Dados de Uso Técnico mostram como o PORTADOR utiliza o serviço prestado pela 
            {REACT_APP_NAME_CAPS}, incluindo o endereço IP, estatísticas sobre como as páginas são carregadas ou visualizadas, 
            os sites que o PORTADOR visitou e informações de navegação coletadas por meio de cookies ou tecnologia semelhante.</p>
            <p>“<u>Dados Pessoais</u>”: Dados Pessoais associadas ao PORTADOR como uma pessoa 
            natural identificada ou identificável. Podem incluir nome, autorretrato, endereço, 
            número de telefone, e-mail, número da conta corrente ou poupança, data de nascimento, 
            número ou cópia de documentos oficiais (por exemplo, RG, CNH, CPF, dentre outros). 
            Os Dados de Uso Técnico e as Informações do Dispositivos serão considerados Dados Pessoais 
            quando utilizadas para individualizar o PORTADOR ou sempre que seja possível sua identificação.</p>
            <p>“<u>Informações do Dispositivo</u>”: dados que podem ser coletados automaticamente 
            de qualquer dispositivo utilizado para acessar a Plataforma. Essas informações 
            podem incluir, mas não se limitam ao tipo de dispositivo, conexões de rede do dispositivo, 
            nome do dispositivo, endereço IP do dispositivo, informações sobre o navegador do dispositivo 
            e a conexão de internet usada para acessar a Plataforma.</p>
            <p>“<u>Tratamento</u>”: toda operação realizada com as Dados Pessoais do PORTADOR, 
            em razão da coleta, produção, recepção, classificação, utilização, acesso, 
            reprodução, transmissão, distribuição, processamento, arquivamento, 
            armazenamento, eliminação, avaliação ou controle da informação, modificação, 
            comunicação, transferência, difusão ou extração.</p>
            <p>10.1.1. Esta cláusula tem o objetivo de informar, de forma clara e completa, 
              sobre como haverá o Tratamento das Dados Pessoais do PORTADOR, em decorrência 
              da utilização dos serviços prestados pela {REACT_APP_NAME_CAPS}, com a finalidade de 
              proteger a privacidade do PORTADOR, garantindo que o Tratamento das 
              Dados Pessoais servirá apenas para possibilitar a prestação dos Serviços 
              disponíveis durante a utilização do Cartão {REACT_APP_NAME}.</p>
            <p>10.1.2. Ao utilizar a Plataforma e aderir a este Termo, o PORTADOR dá expresso 
              consentimento para o Tratamento de seus Dados Pessoais pela {REACT_APP_NAME_CAPS} 
              e pela Instituição Financeira. Caso o PORTADOR não concorde com o Tratamento 
              de seus Dados Pessoais na forma prevista neste Termo, deverá se 
              abster de utilizar o Cartão {REACT_APP_NAME}.</p>
            <p>10.2. A {REACT_APP_NAME_CAPS} realiza o Tratamento das Dados Pessoais mínimos, 
              necessários para a utilização, pelo PORTADOR, do conjunto de serviços prestados pela {REACT_APP_NAME_CAPS}.</p>
            <p>10.2.1. Os Dados Pessoais do PORTADOR poderão ser utilizados pela {REACT_APP_NAME_CAPS} 
              para a formação de banco de dados e para aprimorar seus serviços, bem como 
              para elaboração de pesquisas e estatísticas voltadas a analisar eficiência 
              da utilização do Cartão {REACT_APP_NAME} e da Plataforma, preservando-se a individualidade 
              e identificação do PORTADOR de forma anonimizada. As conclusões e resultados 
              dessas pesquisas poderão ser compartilhados ou divulgados pela {REACT_APP_NAME_CAPS} a 
              seu critério, visto se tratar de Dados Anonimizáveis.</p>
            <p>10.2.2. Com a finalidade de prevenir fraudes e garantir a autenticidade das 
              informações fornecidas, poderão ser solicitadas outros Dados Pessoais 
              não contidos no Formulário de Adesão, bem como o envio de documentos 
              adicionais que permitam a confirmação dos dados fornecidos pelo PORTADOR. 
              Neste caso, a {REACT_APP_NAME_CAPS} entrará em contato com PORTADOR diretamente. 
              Essas informações e documentos</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>adicionais poderão ser armazenados pela {REACT_APP_NAME_CAPS} enquanto o PORTADOR mantiver seu cadastro ativo.</p>
            <p>10.2.3. A {REACT_APP_NAME_CAPS} poderá coletar e armazenar outros Dados Pessoais do PORTADOR 
              para o cumprimento de exigência prevista em lei ou emanada das autoridades competentes, 
              bem como para receber e processar comunicações, chamados e exercício de direitos do PORTADOR.</p>
            <p>10.3. As informações do PORTADOR serão obtidas mediante solicitação clara e 
              autorização expressa do PORTADOR, quando do preenchimento ou confirmação dos dados 
              indicados no Formulário de Adesão, sendo processadas com a finalidade de cumprimento 
              dos Serviços oferecidos na Plataforma. Será realizado o Tratamento de informações 
              adicionais do PORTADOR apenas com base no interesse legítimo da {REACT_APP_NAME_CAPS}.</p>
            <p>10.3.1. Tendo em vista que o PORTADOR será indicado pela Fonte Pagadora, seus 
              Dados Pessoais poderão ser compartilhados diretamente pela Fonte Pagadora, 
              com a finalidade de facilitar o cadastro na Plataforma e emissão do Cartão {REACT_APP_NAME}. 
              O compartilhamento dos referidos dados se dará mediante o consentimento prévio 
              e expresso do PORTADOR, de acordo com a legislação aplicável.</p>
            <p>10.4. Os Dados Pessoais, assim como dados do cartão, de CCBs e pagamentos do 
              PORTADOR poderão ser compartilhadas pela {REACT_APP_NAME_CAPS} com (i) terceiros contratados 
              pera prover serviços de computação, transferência de dados e hospedagem em nuvem, 
              desde que esses terceiros guardem o mesmo padrão de privacidade e segurança aplicados 
              pela {REACT_APP_NAME_CAPS} e estejam contratualmente obrigados a não acessar o conteúdo, 
              processar ou compartilhar as informações, exceto mediante ordens expressas da 
              {REACT_APP_NAME_CAPS}; (ii) para empresas do grupo da {REACT_APP_NAME_CAPS}, caso existentes; (iii) à 
              Instituição Financeira que irá realizar a emissão da CCB; (iv) fundos de investimentos 
              ou terceiros que venham a se titulares dos direitos creditórios derivados das CCBs; 
              (v) órgãos de proteção ao consumidor, incluindo procons, Banco Central do Brasil, 
              Ministério Público, Poder Judiciário, Fonte Pagadora quando se tratar de atendimento 
              à reclamações ou demandas feitas pelo próprio PORTADOR. Esses terceiros somente 
              poderão utilizar as Dados Pessoais para possibilitar a realização dos serviços prestados 
              pela {REACT_APP_NAME_CAPS} ou para solucionar reclamações e demandas feitas pelo próprio PORTADOR.</p>
            <p>10.5. A {REACT_APP_NAME_CAPS} também poderá ser obrigada, por lei ou por determinação 
              das autoridades competentes, a divulgar Dados Pessoais do PORTADOR.</p>
            <p>10.6. O e-mail, número de telefone (WhatsApp) e outras informações de contato 
              do PORTADOR informados no preenchimento do Formulário de Adesão, serão utilizados 
              como meio de comunicação pela {REACT_APP_NAME_CAPS}, apenas para o envio de informações a respeito 
              da Plataforma, solicitação de documentos e de outras informações que vierem a ser 
              necessárias à complementação ou ratificação do Formulário de Adesão  e, 
              ainda, quanto a utilização do Cartão {REACT_APP_NAME}.</p>
            <p>10.6.1. A {REACT_APP_NAME_CAPS} não utiliza serviços de terceiros para enviar e-mails em seu nome. 
              Se o PORTADOR receber e-mail ou mensagens pelo WhatsApp que acredita não terem sido 
              enviados pela {REACT_APP_NAME_CAPS}, deverá se abster de adotar qualquer ação e entrar em 
              contato imediatamente com a {REACT_APP_NAME_CAPS} para confirmar sua veracidade.</p>
            <p>10.7. Os Dados Pessoais coletados pela {REACT_APP_NAME_CAPS} são armazenados em servidores seguros, 
              de forma criptografada, com a utilização de medidas de segurança de informação 
              constantemente atualizadas, e serão mantidas confidenciais e 
              submetidas a todas as medidas possíveis contra perda, roubo, uso indevido, 
              alteração e acesso não autorizado.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>10.7.1. Os Dados Pessoais serão armazenados pelo prazo mínimo de 5 (cinco) anos após o 
              cancelamento do cadastro do PORTADOR e encerramento dos Serviços, por qualquer motivo, 
              com a finalidade específica de produção de provas, caso necessário.</p>
            <p>10.7.2. Os Dados de Uso Técnico relacionados às informações colhidas 
              em razão do acesso do PORTADOR ao website e serviços de internet da {REACT_APP_NAME_CAPS}, 
              poderão ser armazenados pelo prazo de 06 (seis) meses, de acordo com a legislação vigente.</p>
            <p>10.7.3. Os Dados Anonimizados, que não identifiquem o PORTADOR, 
              poderão ser armazenados indefinidamente para fins estatísticos.</p>
            <p>10.7.4. Os prazos de armazenamento previstos acima poderão ser alterados pela 
              {REACT_APP_NAME_CAPS} a qualquer momento, de acordo com eventuais alterações a legislação vigente.</p>
            <p>10.8. A {REACT_APP_NAME_CAPS} emprega padrões de segurança avançados, incluindo firewalls, 
              antivírus e outros softwares que auxiliam na proteção de hackers e não vazamento 
              das Dados Pessoais armazenadas, o que não retira a responsabilidade do PORTADOR 
              em proteger e manter a privacidade de seu Formulário de Adesão e Dados Pessoais, 
              isentando a {REACT_APP_NAME_CAPS} de quaisquer vazamentos ocorridos em razão do compartilhamento 
              indevido do PORTADOR com terceiros.</p>
            <p>10.8.1. Na medida da legislação aplicável, a {REACT_APP_NAME_CAPS} não se responsabiliza 
              por violações ilegais de sua Plataforma que venham a comprometer a sua base de dados 
              e as Dados Pessoais do PORTADOR, bem como não se responsabiliza pela utilização 
              indevida das Dados Pessoais obtidas na Plataforma de forma fraudulenta ou ilícita.</p>
            <p>10.8.2. Em caso de suspeita ou confirmação de violação da Plataforma ou de 
              perda de Dados Pessoais do PORTADOR, a {REACT_APP_NAME_CAPS} envidará seus melhores 
              esforços e tomará medidas imediatas para eliminar ou reduzir os riscos de 
              danos ao PORTADOR, bem como informará o PORTADOR potencialmente afetados e as 
              autoridades competentes de tal fato, os riscos envolvidos e 
              as medidas necessárias para evitar tais danos.</p>
            <p>10.9. É permitido ao PORTADOR, a qualquer tempo, nos limites da legislação aplicável, 
              exercer os direitos de: (i) confirmação da existência de Tratamento de seus Dados Pessoais; 
              (ii) acesso às seus Dados Pessoais mantidas perante a {REACT_APP_NAME_CAPS}; 
              (iii) correção de Dados Pessoais incompletas, inexatas ou desatualizadas; 
              (iv) anonimização, bloqueio ou eliminação de Dados Pessoais desnecessárias, 
              excessivas ou tratadas em desconformidade com o disposto na legislação aplicável; 
              (v) portabilidade de Dados Pessoais a outro fornecedor de serviço mediante requisição 
              expressa e observados os segredos comercial e industrial da {REACT_APP_NAME_CAPS}, de acordo com 
              a regulamentação da autoridade competente; 
              (vi) eliminação das Dados Pessoais tratadas com base no consentimento do PORTADOR, salvo nas exceções previstas na legislação aplicável; 
              (vii) a informação das entidades públicas e privadas com as quais a {REACT_APP_NAME_CAPS} realizou uso compartilhado de Dados Pessoais do PORTADOR; 
              (viii) a informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa, quando determinada operação
               de Tratamento embasar-se no consentimento do PORTADOR; e 
               (ix) a revogação do consentimento, nos termos da legislação aplicável.</p>
            <p>10.9.1. No caso de cancelamento de seu cadastro na Plataforma, 
              o PORTADOR não poderá utilizar a Plataforma.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
            <p>10.9.2. Os direitos descritos acima poderão ser exercidos mediante 
              o envio de solicitação por escrito do PORTADOR, acompanhada de prova de sua identidade, 
              ao endereço indicado no preâmbulo deste Termo.</p>
            <p>10.9.3. A {REACT_APP_NAME_CAPS} poderá contatar o PORTADOR para confirmar sua 
              identidade antes do cumprimento da solicitação, que somente será aprovada 
              mediante a confirmação da identidade do PORTADOR.</p>
            <p>10.10. O PORTADOR autoriza que todos os contatos com a {REACT_APP_NAME_CAPS}, 
              independentemente do canal de comunicação utilizado, possam ser gravados 
              e armazenados, visando proporcionar maior segurança àqueles que se utilizam 
              da Plataforma, incluindo o próprio PORTADOR.</p>
            <p>10.11. A {REACT_APP_NAME_CAPS} se compromete a zelar pela segurança de 
              todas as informações fornecidas pelo PORTADOR, comprometendo-se a utilizar de 
              toda tecnologia disponível para evitar o acesso indevido a tais informações. 
              Entretanto, a {REACT_APP_NAME_CAPS} não poderá ser responsabilidade por eventual furto 
              ou roubo de informações que sejam causadas por terceiros mediante o acesso não 
              autorizado de seus sistemas e da Plataforma.</p>
            <p><b>11. Modificações e Revisões</b></p>
            <p>11.1. Este Termo será revisto periodicamente pela 
              {REACT_APP_NAME_CAPS} para adequar a prestação dos serviços que integram 
              o Cartão {REACT_APP_NAME} e a licença de uso da Plataforma. 
              A {REACT_APP_NAME_CAPS} poderá alterar este Termo, excluindo, modificando ou inserindo 
              cláusulas ou condições, ao seu exclusivo critério.</p>
            <p>11.2. As alterações deverão ser comunicadas ao PORTADOR por e-mail, 
              WhatsApp ou mediante comunicação na Plataforma, possibilitando ao PORTADOR 
              seu amplo conhecimento e acesso.</p>
            <p>11.3. Caso o PORTADOR não concorde com as alterações, 
              poderá denunciar este Termo sem qualquer ônus ou penalidade. 
              A continuidade do uso do Cartão {REACT_APP_NAME} ou da Plataforma 
              pelo PORTADOR será interpretada como concordância e aceitação das alterações realizadas, 
              passando essas a serem integralmente aplicáveis.</p>
            <p><b>12. Disposições Finais</b></p>
            <p>12.1. O PORTADOR declara-se ciente e concorda que, independentemente do local 
              de onde esteja utilizando os serviços que integram o Cartão {REACT_APP_NAME}, a relação 
              entre as Partes será sempre regida pela legislação brasileira.</p>
            <p>12.2. O PORTADOR compromete-se a isentar a {REACT_APP_NAME_CAPS} de qualquer litígio decorrente 
              da utilização da Plataforma e da realização de Transações pelo Cartão {REACT_APP_NAME}.</p>
            <p>12.3. O PORTADOR autoriza que a {REACT_APP_NAME_CAPS}, por intermédio da Instituição Financeira, 
              consulte no Sistema de Informações de Crédito (“<u>SCR</u>”) disponibilizado pelo 
              Banco Central do Brasil (“<u>Bacen</u>”), todas e quaisquer informações 
              referentes a quaisquer operações de crédito de sua responsabilidade.</p>
            <p>12.3.1. O SCR tem por finalidade fornecer informações referentes 
              às responsabilidades de clientes em quaisquer operações de crédito, 
              como objetivo de subsidiar decisões de crédito e de negócios.</p>
          </div>
          {Footer()}
        </div>
        <div style={styles.paper_m}>
          {Header()}
          <div style={styles.text}>
          <p>12.3.2. O PORTADOR poderá ter acesso aos dados constantes no SCR 
            por meio do Registro – Extrato do Registro de Informações no Bacen ou 
            da Central de Atendimento ao Público do Bacen, sendo que as manifestações 
            de discordância quanto às informações constantes no SCR e os pedidos de correções 
            e exclusões deverão ser dirigidos às instituições com as quais o PORTADOR 
            contratou operações de crédito, por meio de requerimento escrito e fundamentado.</p>
          <p>12.4. As Partes elegem o Foro da Capital do Estado de São Paulo como único 
            competente para dirimir as questões decorrentes deste Termo, com renúncia expressa 
            a qualquer outro, por mais privilegiado que seja.</p>
          <p>12.5. Qualquer dúvida em relação a este Termo poderá ser enviada 
            à Central de Atendimento da {REACT_APP_NAME_CAPS} conforme indicado no rodapé deste Termo</p>
          <p>     Versão atualizada em 21.12.2022</p>
          <p><b>{REACT_APP_NAME_CAPS} SERVIÇOS TECNOLÓGICOS E FINANCEIROS LTDA.</b></p>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          </div>
          {Footer()}
        </div>
      </div>
    );
  }
  

  /*if(Proposta.CodigoEmpregador == Estado.Pernambuco){
    console.log("v2")
    return TermoV2()
  }*/

  if(Proposta.CodigoEmpregador == Estado.Paraiba){
    console.log("v1")
    return TermoV1()
  }
  console.log("v3")
  return TermoV3()
}

export default TermoGen