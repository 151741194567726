import React from 'react'
import {tela_fluxo} from '../telas_id'
import {Card, CardTitle, CardBody, Label} from 'reactstrap'
import FadeIn from 'react-fade-in'
import './styles/estilo.css'
import {status, situacao} from '../components/Info'
import termo from '../docs/PoliticaLecca.pdf'
import { Seguir, CheckedBox } from './styles/estilo_telas'

import { PropAccept } from '../ts/interfaces/PropTelas.interfaces';

import { addInfo, updateInfoBPO } from '../state/actions/Info_actions';
import { getStateJornada } from '../state/actions/jornada_actions'

interface State {
  unlock: boolean
}

const {REACT_APP_NAME_LECCA} = process.env

export default class Validacao extends React.Component<PropAccept, State>{
  constructor(props : PropAccept) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.Aceitar = this.Aceitar.bind(this);
    this.Rejeitar = this.Rejeitar.bind(this);
  }

  state = {
    unlock: true
  }

  handleChange(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({unlock: e.target.checked})
  }

  componentDidMount(){
    updateInfoBPO({fase: status.tela_lgpd, situacao: situacao.sucesso});
    //document.getElementsByClassName('modal')[0].display = 'block';
  }

  Aceitar() {
    updateInfoBPO({fase: status.lgpd_aceita, situacao: situacao.sucesso});
    addInfo(tela_fluxo.validacao);
    this.props.changePasso();
  }

  Rejeitar() {
    const {id} = getStateJornada();
    
    updateInfoBPO({fase: status.lgpd_rejeita, situacao: situacao.sucesso});
    this.props.history.push('/rejeitar/'+id);
  }


  render(){

    const {unlock} = this.state;

    return(
      <FadeIn delay={50}>
        
        <div style={{marginTop: '20px'}}>
        <Card
          body
          >
        <CardTitle tag='h5' style={{textAlign: 'center'}}>TERMO DE USO E CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS – LGPD</CardTitle>
        <CardBody>
        <p>Autorizo a {REACT_APP_NAME_LECCA} Financeira a:</p>

        <p>I.	Informar e consultar meus dados no Sistema de Informações de Crédito (SCR) 
          do Banco Central do Brasil (Bacen), conforme Resolução CMN nº. 4.571 de 26 de maio de 2017, 
          bem como a compartilhá-los com prestadores de serviços e parceiros da {REACT_APP_NAME_LECCA} Financeira, 
          para fins de análise e concessão do crédito;</p>
        <p>II.	Consultar o FGTS a fim de identificar a existência de saldo na conta vinculada 
          FGTS de minha titularidade;</p>
        <p>III.	Ofertar e divulgar outros produtos da {REACT_APP_NAME_LECCA} Financeira, inclusive por meio de 
          contatos posteriores a este; </p>
        <p>IV.	Realizar contatos em nossos canais de relacionamento, ficando autorizado o uso do 
          WhatsApp, sem prejuízo de outros meios de comunicação, tal como ligação SMS e e-mail, 
          observando-se o disposto na Lei Geral de Proteção de Dados n° 13.709 de 14 de agosto de 2018.</p>
        <p>Clique <a href={termo} target='_blank' rel="noreferrer">aqui</a> para acessar a Política de Privacidade e Tratamento de Dados Pessoais.</p>
        <p><CheckedBox onChange={this.handleChange} /> <Label check> Aceito os Termos de Uso e Política de Privacidade e Tratamento de Dados Pessoais. </Label></p>
        <Seguir width='30%' unlock={unlock} onClick={this.Aceitar} />
        </CardBody>
        </Card>
        </div>
      </FadeIn>
    );

  }


}