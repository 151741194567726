import React from 'react'
import { documento_id, tela_fluxo3 as tela } from '../telas_id'
import './styles/anexofoto.component.css'
import Header from '../components/header'
import FadeIn from 'react-fade-in';
import { status } from '../components/Info';

//tela
import {
  TelaInicial,
  SelfieTela,
  ProvaVidaTela,
  ComprovanteTela,
  ContrachequeTela,
  IdentidadeTela
} from '../telas_personalizado/index'

import AlertError from '../routes/alerts/alert_error'

import Upload from '../components/Upload.local.component';

import axios from 'axios';

import { UpdateLogMessage, blobToBase64, getExtension } from '../util/Utils';
import { IPropsFluxo } from '../ts/interfaces/Props.interfaces';
import { Base64Type, DocType } from '../ts/types/Types';
import Loading from '../components/Loading';
import { getStateJornada } from '../state/actions/jornada_actions';
import { updateInfoBPO, situacao, log, status as stat } from '../state/actions/Info_actions';

const {REACT_APP_SERVER, REACT_APP_SOURCE_FILES} = process.env;

const webs = REACT_APP_SERVER

const source = REACT_APP_SOURCE_FILES

interface State {
  dat: string[],
  code: string,
  docs: DocType[],
  status: number,
  link: string,
  step: number | undefined,
  coloralert: string,
  enviado: boolean,
  enviado_cnh_frente: number,
  enviado_cnh_verso: number,
  alerterror: boolean,
  alertmessage: string,
  steps: number[]
}

const get_fluxo = () : number[] => {
  const { fluxo } = getStateJornada();
  console.log(fluxo);
  log(fluxo);
  let arr = []
  if(fluxo.Selfie) arr.push(tela.selfie)
  if(fluxo.ProvaVida) arr.push(tela.prova_vida)
  if(fluxo.Identidade) arr.push(tela.documento_id)
  if(fluxo.Comprovante) arr.push(tela.documento_residencia)
  if(fluxo.Contracheque) arr.push(tela.contracheque)

  return arr;
}

export default class FluxoPersonalizado extends React.Component<IPropsFluxo, State> {
  constructor(props : IPropsFluxo){
    super(props)

    this.changePasso = this.changePasso.bind(this)
    this.InsertScreenShot = this.InsertScreenShot.bind(this)
    this.InsertVideoBlob = this.InsertVideoBlob.bind(this)
    this.ShowAlert = this.ShowAlert.bind(this)
    this.PostScreenShot = this.PostScreenShot.bind(this);

    this.state = {
      dat: [],
      code: '',
      docs: [],
      status: 0,
      link: '',
      step: tela.inicial,
      coloralert: 'danger',
      enviado: true,
      alerterror: false,
      enviado_cnh_frente: 0,
      enviado_cnh_verso: -1,
      alertmessage: '',
      steps: get_fluxo()
    }
    
  }

  componentWillUnmount(): void {
      this.setState({});
  }

  componentDidUpdate(): void {
    const {enviado_cnh_frente, enviado_cnh_verso} = this.state;

    if(enviado_cnh_frente == 1 && enviado_cnh_verso == 1) {
      this.setState({enviado: true, enviado_cnh_frente: 0, enviado_cnh_verso: 0});
    }
  }

  changePasso = () => {
    let step = this.state.steps.shift();
    const { id, idJornada, prova_vida } = getStateJornada();
    if(step){
      this.setState({ step });
    }else{
      const {docs} = this.state;
      this.setState({enviado: false});

      axios.post(webs+'/proposta/send-info', {
        id: id,
        idJornada: idJornada,
        documentos: docs,
        eventos: [],
        prova_vida: prova_vida
      }).then(
        async resp => {
          await updateInfoBPO({fase: status.finalizacao, situacao: situacao.sucesso});
          
          this.props.history.push('/agradecimento/3');
        }
        ).catch(
          async err => {
            console.log(err)
            UpdateLogMessage(`Erro de envio send-info: `, err);
            await updateInfoBPO({fase: status.finalizacao, situacao: situacao.falha, mensagemErro: err.response.data});
            this.props.history.push('/agradecimento/3');
          }
        ); 
    }
  }

  PostScreenShot = (screenshot: Base64Type, documento: number, ext: string, wait_verso: boolean) => {
    let element : DocType = {nome: '', tipo: ''};
    const {id, contrato} = getStateJornada();
    const {status, docs, dat} = this.state
    let fase = stat.selfie_recebida;

    switch(documento){
      case documento_id.selfie:
        element = {          
            nome: `${id}_photo_face.${ext}`,
            tipo: 'Selfie' 
        }
        break;
      case documento_id.cnh_frente:
        element = {
          nome: `${id}_cnh_frente.${ext}`,
          tipo: 'CNH'
        }
        
        fase = stat.cnh_frente_recebida;
        break;
      case documento_id.cnh_verso:
        element = {
          nome : `${id}_cnh_verso.${ext}`,
          tipo: 'CNH'
        }

        fase = stat.cnh_verso_recebida;
        break;
      case documento_id.comprovante:
        element = {
          nome: `${id}_comprovante.${ext}`,
          tipo: 'Comprovante_Endereco'
        }
        fase = stat.comprovante_residencia_recebido;
        break;  
      case documento_id.contracheque:
        element = {
          nome: `${id}_contracheque.${ext}`,
          tipo: 'Outros'
        }

        fase = stat.contracheque_recebido;
        break;
      case documento_id.selfie_cartao:
        element = {
          nome: `${id}_photo_face_cartao.${ext}`,
          tipo: 'Selfie'
        }

        fase = stat.selfie_recebida;
        this.setState({status: 7})
        break;
      default:
        break;
    }
    UpdateLogMessage(`Elemento adicionado na lista de documentos`, element, ` Para o id de documento: `, documento)
    this.setState({dat: [...dat, `${source}/${id}/${element.nome}`], docs: [...docs, element]});

    Upload(element.nome, screenshot as string, contrato.Proposta.NumeroProposta).then(resp=>{
      if(resp == false){
        UpdateLogMessage(`Erro de envio ${element.nome} : response false`)
        updateInfoBPO({fase: fase, situacao: situacao.falha, mensagemErro: 'Erro ao enviar'});
        this.props.history.push('/erro/inesperado')
      }
      if(this.state.status == 7){
        //this.compareImages2();
        this.setState({enviado: true})
      }else{
        if(wait_verso && documento_id.cnh_frente == documento) {
          this.setState({ status: status+1, enviado_cnh_frente: 1 })
        } else if(documento_id.cnh_verso == documento) {
          this.setState({ status: status+1, enviado_cnh_verso: 1 })
        } else {
          this.setState({ status: status+1, enviado: true })
        }
      }
    }).catch(e => {
      UpdateLogMessage(`Erro de envio ${element.nome}: `, e);
      updateInfoBPO({fase: fase, situacao: situacao.falha, mensagemErro: 'Erro ao enviar:'+e.response.data});
      this.props.history.push('/erro/inesperado')
    })
  }

  InsertScreenShot = async (screenshot: Base64Type, documento_id: number,  wait_verso?: boolean) => {
    const { step } = this.state
    const { id } = getStateJornada();

    var ext = getExtension(screenshot) //Perguntar se e necessario enviar o documento convertido para o front
    this.setState({enviado: false});
    const wait = wait_verso? true: false

    if(ext == 'pdf'){
      ext = 'jpg'
      axios.post(webs+'/convert/pdf-to-jpg', { passo: documento_id, id_name: id, b64: screenshot })
      .then(resp => {
        let res = resp.data
        this.PostScreenShot('data:image/jpg;base64,'+res.base64, documento_id, ext, wait)
      }).catch(e => {
        UpdateLogMessage('Erro conversão pdf: ', e);
        this.setState({step: step, enviado: true});
        this.ShowAlert('Infelizmente não foi possível completar o envio deste documento, de preferência tente anexá-lo em formato de imagem', 'warning', 5000)
      })
    }else{
      await this.PostScreenShot(screenshot, documento_id, ext, wait)
    }
  }

  InsertVideoBlob = (blob : Blob) => {
    const { id, contrato } = getStateJornada();

    blobToBase64(blob).then(
      b64 => {
        let element = {
          nome: `${id}_prova_vida.mp4`,
          tipo: 'Outros'
        }
        Upload(element.nome, b64 as string, contrato.Proposta.NumeroProposta).then(()=>{
          this.setState({docs: [...this.state.docs, element]});
          updateInfoBPO({fase: stat.prova_vida_recebida, situacao: situacao.sucesso});
        }).catch(e => {
          UpdateLogMessage(`Erro envio de prova de vida: `, e);
          updateInfoBPO({fase: stat.prova_vida_recebida, situacao: situacao.falha, mensagemErro: e.response.data});
          this.props.history.push('/erro/inesperado');
        });
      }
    ).catch(e => {
      UpdateLogMessage(`Erro envio de conversão de blob: `, e);
      updateInfoBPO({fase: stat.prova_vida_recebida, situacao: situacao.falha, mensagemErro: `Erro ao realizar a conversão para base64`});
      this.props.history.push('/erro/inesperado')
    })
    this.setState({ link: URL.createObjectURL(blob) });
  }

  ShowAlert(message: string, color: string='danger', timing: number=2000) {
    this.setState({alertmessage: message, alerterror: true, coloralert: color}, () => {
      window.setTimeout(() => {
        this.setState({alerterror: false})
      }, timing)
    })
  }

  render() {
    const {step, alerterror, enviado, alertmessage, coloralert} = this.state
    const {contrato} = getStateJornada();
    
    const ProperTelas = {
      history: this.props.history,
      ShowAlert: this.ShowAlert, 
      InsertScreenShot: this.InsertScreenShot, 
      changePasso: this.changePasso
    }

    if(!enviado){
      return(
        <div>
        <FadeIn delay={150}>
          <AlertError message={alertmessage} visible={alerterror} color={coloralert}/>
          <Header/>
          <div style={{marginTop: 20}}>
            <Loading text={'Carregando...'}/>
          </div>
        </FadeIn>
      </div>
      )
    }
    
    return(
      <div>
        <FadeIn delay={150}>
          <AlertError message={alertmessage} visible={alerterror} color={coloralert}/>
          <Header/>
          {step == tela.inicial && <TelaInicial NomeCliente={contrato.Cliente.DadosCliente.NomeCliente} onClick={this.changePasso}/>}
          {step == tela.selfie && <SelfieTela {...ProperTelas} />}
          {step == tela.prova_vida && <ProvaVidaTela {...ProperTelas} InsertVideoBlob={this.InsertVideoBlob} history={this.props.history} />}
          {step == tela.documento_id && <IdentidadeTela {...ProperTelas} />}
          {step == tela.documento_residencia && <ComprovanteTela {...ProperTelas} />}
          {step == tela.contracheque && <ContrachequeTela {...ProperTelas} />}
        </FadeIn>
      </div>
    );

  }

}