import React from 'react'
import axios from 'axios'
import Loading from '../../components/LoadingManager'
import { FormBusca, SearchData } from './forms/index'
import { ListaConsulta } from './telas-proposta'
import { PropostaData } from '../../ts/interfaces/Manager.interfaces'
import { DetalheProposta } from './telas-proposta/DetalheProposta'

import { tela } from '../manager-pages/tela-indice';
import { DetalheDocumentos } from './telas-proposta/DetalheDocumentos'

const {REACT_APP_SERVER} = process.env;

const webs = REACT_APP_SERVER

interface State {
    carregar: boolean,
    lista: PropostaData[],
    PropostaId: number
}

const formatData = (data: string) : string => {
  return data.split('/').reverse().join('-');
}

interface IConsultaProposta {
  screen: number,
  changeScreen: (screen: number) => void
}

class ConsultaProposta extends React.Component<IConsultaProposta, State> {
  constructor(props: IConsultaProposta){
    super(props);
    this.consulta = this.consulta.bind(this);
    this.consultaDetalhe = this.consultaDetalhe.bind(this);
    this.Back = this.Back.bind(this);
  }
    
  state = {
    carregar: true,
    lista: [],
    PropostaId: 0
  }

  consulta(data : SearchData) {
    this.setState({carregar: false});
    console.log(data.dataInicio);
    data.dataInicio = formatData(data.dataInicio);
    data.dataFinalizacao = formatData(data.dataFinalizacao);
    axios.post<PropostaData[]>(`${webs}/proposta/consulta`, { data }).then(resp => {
      this.setState({lista: resp.data, carregar: true});
      this.props.changeScreen(tela.consulta_proposta_resultado)
    })
  }

  consultaDetalhe(id: number, screen: number) {
    this.setState({PropostaId: id});
    this.props.changeScreen(screen)
  }

  Back() {
    this.props.changeScreen(tela.consulta_proposta_resultado)
  }

  render() {
    const { carregar, lista, PropostaId } = this.state;
    const {screen} = this.props
    if(!carregar){
      return (
        <Loading />
      );
    }

    switch(screen){
      case tela.consulta_proposta:
        return <FormBusca onSubmit={this.consulta}/>
      case tela.consulta_proposta_resultado:
        return <ListaConsulta listaPropostas={lista} consultaDetalhe={this.consultaDetalhe} />
      case tela.consulta_proposta_detalhe:
        return <DetalheProposta proposta={lista[PropostaId]} back={this.Back}/>
      case tela.consulta_docs_detalhe:
        return <DetalheDocumentos proposta={lista[PropostaId]} back={this.Back}/>
      default:
        break;
    }
    return<></>
  }
}

export default ConsultaProposta