import footer from './imgdoc/barfooter.png'
import logo from './imgdoc/logo.jpg'
import { isMobile } from 'react-device-detect'
import logoMinas from './imgdoc/govMinas.png'
import { Table, DisplayCheck, InputFixed } from './styles/docstyle.component'
import { IDocument, IStyleProps } from '../../ts/interfaces/Components.interfaces'
import { Endereco, Telefone, isPropostaEmprestimo } from '../../ts/types/Types'
import { getStateJornada } from '../../state/actions/jornada_actions'
import { Estado } from './empregador_id'
import logobpo from '../../images-bpo/logo-ccb-2.png'

const {REACT_APP_PERFIL, REACT_APP_NAME, REACT_APP_NAME_LOW, REACT_APP_NAME_CAPS, REACT_APP_NAME_LECCA, REACT_APP_NAME_LECCA_CAPS} = process.env

const CCBGenRefin = ({Status} : IDocument) => {
  let w = 500
  let h = 550
  if(isMobile){
    w = window.innerWidth * 0.89
    h = 318
  }
  const { Cliente, Proposta } = getStateJornada().contrato;

  const getAdr = (arr : Endereco[], type : number) => {
    return arr.find(element => element.TipoEndereco == type)
  }

  const getTel = (arr : Telefone[], type : number) => {
    return arr.find(element => element.TipoTelefone == type)
  }

  const endr = getAdr(Cliente.DadosCliente.Enderecos, 1)
  const celular = getTel(Cliente.DadosCliente.Telefones, 3)
  const tel = getTel(Cliente.DadosCliente.Telefones, 1)
  const CodigoEmpregador = Proposta.CodigoEmpregador

  var telstr = "Nao possui"
  if(tel){
    telstr = '('+tel.DDD+') '+tel.NumeroTelefone;
  }
  var celstr = "Nao possui";
  if(celular){
    celstr = "("+celular.DDD+") "+celular.NumeroTelefone;
  }
  var Orgao = "Nao possui"
  if(Proposta.NomeEmpregador){
    Orgao = Proposta.NomeEmpregador
  }

  const styles : IStyleProps = {
    property: {width: w, height: h, backgroundColor: '#adb8b3', border: '1px solid #ccc',
      font: '12px/16px Arial, Helvetica, sans-serif',
      overflow: 'auto'},
    paper : {backgroundColor: 'white', height: 'auto', width: w},
    title: {textAlign: 'center', marginTop: 5},
    paper_m: {backgroundColor: 'white', height: 'auto', width: w, marginTop: 20},
    text : {width: 0.9*w, textAlign: 'justify', margin:'20px 15px auto'}
  }

  const toStringRS = (value : number) => {
    if(Number.isInteger(value)){
      return 'R$ '+new Intl.NumberFormat('de-DE').format(value) + ',00'
    }
    return 'R$ '+new Intl.NumberFormat('de-DE').format(parseFloat(value.toFixed(2)))
  }

  const FormatData = (str : string) => {
    var sub = str.substring(0,str.lastIndexOf('T'))
    var arr = sub.split('-')
    arr.reverse()
    return arr.join('/')
  }

  const ValorLiquidoCliente = toStringRS(isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.ValorLiquidoCliente : Proposta.ValorLiquidoCliente);
  const ValorIOF =  toStringRS(isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.ValorIOF : Proposta.ValorIOF)
  const ValorPrincipal = toStringRS(isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.ValorLiquidoCliente + Proposta.ValorTarifaCartao + Proposta.PropostaParcelado.ValorIOF : 
  Proposta.ValorLiquidoCliente + Proposta.ValorIOF)
  const ValorTarifaCartao = isPropostaEmprestimo(Proposta)? toStringRS(Proposta.ValorTarifaCartao) : 'Nao possui';
  const TaxaCLMes = isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.TaxaCLMes : Proposta.TaxaCLMes
  const TaxaCLAno = Proposta.TaxaCLAno;
  const Prazo = isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.Prazo : Proposta.Prazo;
  const TaxaCETMes = isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.TaxaCETMes : Proposta.TaxaCETMes
  const TaxaCETAno = isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.TaxaCETAno : Proposta.TaxaCETAno
  const ValorBruto = toStringRS(isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.ValorBruto : Proposta.ValorBruto)
  const ValorParcela = toStringRS(isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.ValorParcela : Proposta.ValorParcela)
  const DataPrimeiroVencimento = FormatData(isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.DataPrimeiroVencimento : Proposta.DataPrimeiroVencimento)
  const DataUltimoVencimento = FormatData(isPropostaEmprestimo(Proposta)? Proposta.PropostaParcelado.DataUltimoVencimento : Proposta.DataUltimoVencimento)
  const saldoRefin = Proposta.DadosRefin[0].saldo;
  let arrContratos = [];

  for(let dadoRefin of Proposta.DadosRefin) {
    arrContratos.push(dadoRefin.NumeroContrato);
  }

  const AddBox = (title : string, text : JSX.Element) => {
    return(
    <div style={{border: '1px solid black', marginLeft: '15px', marginTop: '15px', width: w*0.92}}>
      <b style={{marginLeft: 5, marginTop: 3}}>{title}</b>
      <div style={{marginLeft: 5, marginTop: 3}}>
        {text}
      </div>
    </div>);
  }

  let pgnum = 0;

  const Header = (mostrarLogo : boolean = true) => {
    return(
      <div style={{textAlign: 'center'}}>
        {mostrarLogo && <img src={REACT_APP_PERFIL == 'BPO'?logobpo :logo} width={300} height={35} alt='logo-ccb'/>}
        <div style={{fontSize: 14}}><b>Cédula de Crédito Bancário Refinanciamento</b></div>
        <div style={{marginTop: 2,fontSize: 12}}><b>Numero da CCB: {Proposta.NumeroContrato}</b></div>
      </div>
    );
  }

  const Footer = () => {
    pgnum+=1;
    return(
      <div>
        <img src={footer} style={{marginLeft: 15}} width={w*0.9} height={5} alt='footer-ccb' />
        <p style={{textAlign: 'center'}}><b>VIA DO CREDOR NEGOCIÁVEL</b></p>
        <div style={{width: 0.9*w, textAlign: 'justify', margin: '20px 15px auto'}}>
        <p><b>CENTRAL DE ATENDIMENTO:</b></p>
        <p><b>Telefone: (11) 2365-5240</b></p>
        <p><b>Site: www.{REACT_APP_NAME_LOW}.com.br (via chat)</b></p>
        <p><b><i>Para dúvidas ou outras questões relacionadas ao Cartão {REACT_APP_NAME} e 
          operações de crédito cujos pagamentos serão realizados mediante desconto em folha de pagamento.</i></b></p>
        </div>
        <div style={{textAlign: 'center'}}><b>Página {pgnum} | {CodigoEmpregador == Estado.Maranhao? '10': '11'}</b></div>
        <br/>
      </div>
    );
  }

  if (CodigoEmpregador == Estado.Maranhao){
    return(
      <div onScroll={Status} style={styles.property}>
    <div style={styles.paper}>
      {Header()}

      <p style={styles.title}><b>Preâmbulo</b></p>

      {AddBox('I - Credor', <p>1. {REACT_APP_NAME_LECCA_CAPS} CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A., instituição financeira
      devidamente constituída e existente de acordo com as leis do Brasil, com sede na Cidade de São Paulo, Estado do São Paulo, na Rua José dos Reis 409, sala 51 5º andar, CEP 03.139-040 e
      inscrita no CNPJ sob o nº 29.871.099/0001-89</p>)}

      {AddBox('II –Emitente/Devedor', 
      <div>
      <p>Nome: {Cliente.DadosCliente.NomeCliente}</p>
      <p>CPF: {Cliente.DadosCliente.CPF}</p>
      <p>Doc. de Identidade: {Cliente.DadosCliente.Registro.Numero}  </p>
      <p>Órgão expedidor: {Cliente.DadosCliente.Registro.UFEmissao}</p>
      <p>Endereço: {endr?.Logradouro}</p>
      <p>Nº: {endr?.Numero} Complemento: {endr?.Complemento}</p>
      <p>Bairro: {endr?.Bairro}   Cidade: {endr?.Cidade}      Estado: {endr?.UF}</p>
      <p>CEP: {endr?.CEP}    E-mail: {Cliente.DadosCliente.Email}</p>
      <p>Celular/Whatsapp: {celstr}   Tel.Fixo: {telstr}</p>
      <p>Órgão Público/Fonte  Pagadora: {Orgao}</p>
      <p>Banco n º: {Cliente.ReferenciaBancaria.NumeroBanco}  Agência: {Cliente.ReferenciaBancaria.Agencia}   Conta nº: {Cliente.ReferenciaBancaria.Conta} </p>
      </div>)}

      {AddBox('III - Características da Operação', 
      <div>
        <p>1. Valor Liquido liberado(Valor do saque): {ValorLiquidoCliente} </p>
        <p>2. Tarifa de Saque: {ValorTarifaCartao}    3. Valor do IOF: {ValorIOF} </p>
        <p>3.1. IOF Adicional: {`0`}%</p>
        <p>4. Valor Principal da CCB (1+2+3): {ValorPrincipal} </p>
        <p>5. Taxa de juros: {TaxaCLMes.toFixed(2)} % ao mês (30 dias)  {TaxaCLAno.toFixed(2)} % ao ano (360 dias)</p>
        <p>6. Prazo: {Prazo} meses 7. Nº de Parcelas: {Prazo} </p>
        <p>8.Custo Efetivo Total – CET {TaxaCETMes.toFixed(2)} % ao mês (30 dias)   {TaxaCETAno.toFixed(2)} % ao ano (360 dias)</p>
        <p>9. Valor total a pagar (principal + juros): {ValorBruto}</p>
        <p>10.Valor de cada Parcela: {ValorParcela}</p>
        <p>11. Forma e periodicidade de Pagamento: mensal e mediante desconto em folha de pagamento </p>
        <p>12. Previsão de Vencimento da Primeira Parcela: {DataPrimeiroVencimento}</p>
        <p>13. Previsão de Vencimento Final: {DataUltimoVencimento}</p>
        <p>14. Desembolso da Operação de Crédito:</p>
        <p>14.1. Valor de R$ {saldoRefin} será creditado na conta da {REACT_APP_NAME} Serviços Tecnológicos e Financeiros Ltda – CNPJ nº 29.871.099/0001-89 para liquidação da CCB(s) indicadas no item 15 a seguir.</p>
        <p>14.2. Valor de R$ {ValorLiquidoCliente} será creditado na conta indicada pelo Devedor no Quadro II</p>
        <p>15. Origem da Operação de Crédito: refinanciamento das CCB(s) no.(s): {arrContratos.join(", ")}.</p>
      </div>)}
      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
      {AddBox('IV – Cartão e modalidade de benefício:',
      <div>
      <p>1. Gestor do benefício relacionado com Cartão:</p>
      <p>{REACT_APP_NAME} Serviços Tecnológicos e Financeiros Ltda., sociedade de
      responsabilidade limitada, inscrita no CNPJ sob o nº 29.871.099/0001-89, com
      sede na Cidade e Estado de São Paulo, na Rua Joaquim Floriano, 413 – 11º andar 
      – Itaim Bibi – São Paulo SP, CEP 04543-011, doravante
      denominada apenas “{REACT_APP_NAME}”.</p>
      <p>2. Emissor do Cartão e gestor da conta vinculada:</p>
      <p>Hub Pagamentos S.A., inscrita no CNPJ sob o nº 29.871.099/0001-89, 
      situada na Alameda Arapoema, nº 529, Parte, Tamboré – Barueri / SP, CEP nº 06460-080.</p>
      <p>3. O Cartão é concedido aos servidores públicos de órgãos
      públicos conveniados ou credenciados com o Credor e a {REACT_APP_NAME} e 
      oferece diversos benefícios aos seus titulares. A utilização dos benefícios do
      Cartão depende da prévia solicitação, emissão e desbloqueio do Cartão, da emissão de
      CCB, da aceitação do pedido de utilização do benefício pelo Credor e pelo
      {REACT_APP_NAME}, sendo que o pagamento das parcelas da CCB será realizado
      mediante desconto em folha de pagamento do órgão público (“Fonte Pagadora”).</p>
      <p>4. Modalidade de Benefício do Cartão:</p>
      <p>() Saque parcelado </p>
      <p>() Saque complementar parcelado</p>
      <p>(x) Refinanciamento</p>
      <p>() outros (especificar)</p>
      </div>)}

      <div style={styles.text}>
      <p><b>V - Cláusulas e Condições</b></p>
      <p>Leia com atenção as condições a seguir e consulte o SAC – Serviço de Atendimento ao Cliente em caso de dúvidas. 
      Uso consciente do crédito - Evite o superendividamento, contrate sempre de acordo com as suas condições financeiras.</p>
      <p>1. <b><u>Pagamento:</u></b> Nas datas de vencimento, pagarei por esta Cédula de Crédito Bancário (“CCB”) 
      ao Credor ou à sua ordem, em moeda corrente nacional, na Praça de São Paulo/SP, a quantia 
      líquida, certa e exigível correspondente ao Valor Principal da CCB, acrescido dos juros 
      remuneratórios à taxa conforme item III do Preâmbulo, capitalizados na periodicidade 
      estabelecida, e demais encargos devidos, nos termos desta CCB, mediante desconto em minha 
      remuneração ou em benefício ou pensão previdenciária (“Remuneração”), que, desde já 
      autorizo, de forma irrevogável e irretratável, o órgão público (“Fonte Pagadora”) acima 
      identificado a efetuar e repassar ao Credor, ao {REACT_APP_NAME} ou a terceiro à sua ordem, 
      observada a legislação aplicável</p>
      <p>2. <b><u>Consignação:</u></b> Para viabilizar o pagamento da operação de crédito contratada nesta CCB, 
      autorizo a minha Fonte Pagadora, de forma irrevogável e irretratável, a realizar o desconto 
      mensal em minha Remuneração, em favor do Credor ou à sua ordem, para o pagamento 
      correspondente à quantia necessária à liquidação total das parcelas da operação. As parcelas 
      serão informadas na fatura mensal do Cartão.</p>
      <p>2.1. Autorizo, ainda, minha Fonte Pagadora, de forma irrevogável e irretratável, a (i) se aplicável, 
      descontar o percentual legalmente estabelecido nas minhas verbas rescisórias para o 
      pagamento das obrigações, previstas nesta CCB, repassando o respectivo valor ao Credor ou à 
      sua ordem e; (ii) compartilhar com o Credor e o {REACT_APP_NAME} todas as informações necessárias 
      para realizar o desconto das parcelas desta CCB em minha Remuneração, inclusive enviando 
      cópia desta CCB à Fonte pagadora caso ela assim exija para operacionalizar a averbação do
      pagamento das parcelas desta CCB na minha folha de pagamento.</p>
      </div>
      {Footer()}

    </div>
    <div style={styles.paper_m}>
      {Header()}

      <div style={styles.text}>
      <p>2.2. Estou de acordo que o pagamento das parcelas desta CCB seja realizado por meio de
      descontos mensais em folha de pagamento, no valor necessário à quitação de cada
      parcela, até a quitação total, observada a margem consignável disponível. Se após a averbação da operação, 
      a margem consignável disponível se tornar insuficiente para a consignação integral da parcela 
      contratada, o valor das parcelas a vencer poderá ser consignado parcialmente, readequando o 
      valor das parcelas à margem consignável disponível. Neste caso, o número de parcelas será 
      ajustado para que o Saldo Devedor possa ser quitado mediante o pagamento mensal do novo 
      valor. Caso não seja possível o desconto mensal na folha de pagamento, inclusive, mas não 
      somente, no caso de insuficiência de margem consignável, deverei: (i) pagar as parcelas devidas 
      diretamente ao Credor ou à sua ordem; (ii) verificar com o Credor a possibilidade de reprogramar 
      o pagamento; e/ou (iii) pagar as parcelas mediante boleto bancário ou outro meio expressamente 
      indicado pelo Credor.</p>
      <p>2.3. Declaro estar ciente e de acordo que o valor da fatura do Cartão a ser 
      descontado em minha folha de pagamento poderá ser automaticamente majorado e/ou minorado 
      na mesma proporção de eventuais e futuros aumentos e/ou diminuições em minha margem 
      consignável, manifestando, desde já, concordância com os ajustes realizados para fins de 
      adequação e manutenção dos pagamentos</p>
      <p>2.4. A forma de pagamento das parcelas poderá ser alterada se ocorrer: (a) impossibilidade ou 
      suspensão do desconto do valor das parcelas na minha Remuneração pela Fonte Pagadora, por 
      qualquer motivo, ou (b) início de gozo de benefício previdenciário temporário pelo INSS ou outro 
      órgão de previdência a que eu me vincular; ou (c) término, suspensão ou redução da minha 
      Remuneração; ou (d) a exoneração ou a rescisão do meu contrato de trabalho. Nesses casos, 
      as parcelas serão lançadas nas faturas subsequentes, acrescidas dos respectivos encargos a 
      serem informados, observado o prazo máximo previsto em convênio.</p>
      <p>2.4.1. Caso, ainda assim, o saldo devedor não seja liquidado, as parcelas poderão ser debitadas 
      da conta indicada no item II do Preâmbulo ou em qualquer outra conta de minha titularidade, 
      sendo autorizado, por mim, desde já, este procedimento. Havendo alteração ou transferência da 
      minha conta para outra agência ou outro banco, o Credor e a {REACT_APP_NAME}, ficarão 
      expressamente autorizados a obter os dados da minha nova conta, pelo que, neste ato, outorgo 
      aos mesmos, poderes especiais, em caráter irrevogável e irretratável, nos termos do artigo 684 
      do Código Civil Brasileiro, para praticar todos os atos necessários a tal fim, inclusive encaminhar 
      ofício a minha Fonte Pagadora, para receber os dados da minha nova conta, de modo que o 
      Credor e o {REACT_APP_NAME} possam promover quaisquer débitos decorrentes da CCB, sendo que 
      reconheço que tais procedimentos não configuram, nem configurarão infração ás regras que 
      disciplinam o Sigilo Bancário previstas na Lei Complementar nº 105/2001 e na LGPD – Lei Geral 
      de Proteção de Dados</p>
      <p>2.5. Caso eu venha me aposentar antes de pagar integralmente esta CCB, autorizo que as 
      parcelas passem a ser descontadas de meu benefício ou pensão previdenciária e transferidas 
      para o Credor, caso haja convênio celebrado entre minha nova Fonte Pagadora, para tanto, 
      observada a legislação aplicável. Nesse sentido, todas as autorizações dadas nesta CCB ficam 
      estendidas à nova Fonte Pagadora.</p>
      </div>

      {Footer()}
    </div>
    <div style={styles.paper_m}>
      {Header()}
      
      <div style={styles.text}>
        <p>3. <b><u>Liberação do Crédito: </u></b> Após a aprovação cadastral e creditícia do Credor bem como a 
        confirmação da reserva de margem consignável na minha Remuneração pela Fonte Pagadora 
        (“Averbação”), o Valor do Saque indicado no item III do Preâmbulo será 
        creditado conforme indicado no item III - 14 do Preâmbulo. Declaro para todos os fins de direito que a conta indicada no 
        item II do Preâmbulo é a conta utilizada pela Fonte Pagadora para crédito da minha 
        Remuneração.</p>
        <p>3.1. <b>Averbação do contrato:</b> Esta contratação de empréstimo só será efetivada mediante a confirmação, pelo órgão empregador,
        do pedido de reserva de margem. Caso a averbação não ocorra, por qualquer motivo, a operação e o desembolso não serão efetivados. 
        No caso de eventual liberação de valores na conta corrente do Emitente/Devedor, antes da confirmação de não averbação, o Emitente/Devedor 
        deverá devolver os valores porventura recebidos, sob pena de adoção das medidas judiciais cabíveis. No caso de liquidação de contrato anterior 
        referente ao Cartão {REACT_APP_NAME}, o contrato anterior será reaberto. Caso a averbação ocorra de forma parcial, ou ocorra atraso na averbação, 
        e as condições originalmente contratadas sejam alteradas proporcionalmente ao valor da margem disponibilizada, o Emitente/Devedor se compromete 
        a assinar uma nova CCB, com as novas condições pactuadas.</p>
        <p>3.2. O Emitente/Devedor declara ter ciência de que, na hipótese desta operação de Refinanciamento ser empregada para liquidar outras operações 
        de sua responsabilidade, o valor residual a ser liberado em seu favor, se houver, poderá ser reduzido em virtude da redução da margem consignável.</p>
        <p>4. <b><u>Não Aprovação do Crédito: </u></b> Estou ciente que, em caso de negativa da solicitação de 
        empréstimo (i) o desembolso do crédito não será realizado; (ii) serei notificado acerca da 
        negativa; e (iv) a presente CCB será cancelada e não surtirá quaisquer efeitos, sem a incidência 
        de quaisquer penalidades para as Partes envolvidas.</p>
        <p>5. <b><u>Cartão:</u></b> Declaro ter ciência que: (i) o crédito representado por esta CCB é um 
        benefício concedido aos titulares do Cartão na modalidade indicada no item IV do 
        Preâmbulo e será disponibilizado nas hipótese e conforme legislação aplicável, com observância 
        do Termo de Fornecimento do Cartão; (ii) saques adicionais caracterizando outros 
        benefícios do Cartão poderão ser formalizados mediante a emissão de nova CCB 
        ou outras formas disponibilizadas pelo Credor e pelo {REACT_APP_NAME}, desde que não vedadas 
        pela legislação aplicável; (iii) o valor das parcelas será lançado na fatura do Cartão 
        de minha titularidade, conforme disposto no item III do Preâmbulo, observada a 
        data de vencimento do referido Cartão, e que o valor devido em razão desta CCB comprometerá 
        meu limite de crédito disponibilizado para utilização do Cartão.</p>
        <p>5.1. Tenho ciência, ainda, de que a solicitação de crédito representada nesta CCB configura a 
        ativação do Cartão {REACT_APP_NAME}, assim como a cobrança de tarifas e anuidade, cujos valores 
        estão disponíveis para consulta nos Canais de Atendimento do {REACT_APP_NAME} e serão cobrados 
        na minha fatura.</p>
        <p>6. <b><u>Não Pagamento:</u></b> O não pagamento da parcela implicará em atraso, de forma que sobre o 
        valor da obrigação vencida incidirão: (i) juros remuneratórios previstos nesta CCB; (ii) juros 
        moratórios equivalentes a 1% (um por cento) ao mês, ou fração incidente sobre o valor de 
        principal, acrescido dos encargos previstos nas alíneas anteriores e, (iii) multa não 
        compensatória de 2% (dois por cento) sobre o total devido, além dos demais encargos descritos 
        na fatura a ser enviada a mim. Tenho ciência que o Credor poderá, ainda, promover as medidas 
        extrajudiciais e judiciais cabíveis, cujas despesas passarão a compor o total da dívida.</p>
        <p>6.1. Na hipótese de falta de pagamento das parcelas, o Credor poderá optar pela cobrança 
        somente da parcela vencida, sem que tal ato importe em novação ou alteração dos termos aqui 
        estabelecidos.</p>
        <p>6.2. Para preservar a forma de pagamento inicialmente pactuada nesta CCB, autorizo o Credor 
        e o {REACT_APP_NAME}, observadas as disposições legais aplicáveis, a solicitar à respectiva Fonte 
        Pagadora que efetue o desconto do valor das parcelas, que por qualquer motivo não tenham 
        sido consignadas, por meio da prorrogação do vencimento final das parcelas. Desta forma, a 
        vigência desta CCB ficará automaticamente prorrogada pelo período necessário ao regular 
        pagamento de todas as parcelas mensais.</p>
      </div>

      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}

      <div style={styles.text}>
        <p>6.3. Estou ciente de que se não for possível manter a forma de pagamento nos termos da 
        cláusula acima, deverei pagar o valor das parcelas diretamente ao Credor ou à sua ordem, e 
        que, em caso de inadimplência, o Credor poderá: (i) exigir o ressarcimento de todas as despesas 
        e custos de cobrança extrajudicial e judicial, assim como honorários 
        advocatícios, acrescidos dos encargos previstos na CCB;
        (ii) comunicar os órgãos de proteção de crédito, tais como SPC 
        e SERASA, sistemas de registro (incluindo o SCR) e/ou encaminhar esta CCB para o devido 
        protesto; e (iii) não serei considerado inadimplente na hipótese de a Fonte Pagadora efetuar os 
        descontos das parcelas devidas sob esta CCB e não repassar tais valores ao Credor, sendo 
        vedado neste caso, a adoção pelo Credor ou pelo {REACT_APP_NAME} de quaisquer medidas de 
        cobrança em face de mim relacionado a tais valores.</p>
        <p>7. <b><u>Vencimento Antecipado da Dívida:</u></b> Fica reservado ao Credor, observadas as regras da 
        Fonte Pagadora, o direito de declarar esta CCB antecipadamente vencida e exigir o imediato e 
        integral pagamento do saldo devedor dela decorrente, independentemente de prévia 
        comunicação, na ocorrência das seguintes hipóteses: (i) atraso no pagamento, impossibilidade 
        do desconto em folha de pagamento e/ou falta de pagamento de qualquer valor devido ao Credor; 
        (ii) descumprimento de qualquer obrigação assumida por mim perante o Credor ou no Termo de 
        Emissão do Cartão; (iii) pedido de insolvência ou interdição em meu nome; (iv) 
        meu falecimento; (v) protestos de títulos, distribuição de ação de execução por título extrajudicial 
        ou judicial, emissão de cheque sem fundos ou qualquer outra restrição cadastral ou creditícia em 
        meu nome; (vi) detecção de falsidade e/ou incompletude das declarações feitas por mim nesta 
        CCB; (vii) se for movida, contra mim, medida judicial que possa afetar minha capacidade de 
        cumprimento das obrigações desta CCB; ou (viii) se for iniciado procedimento investigatório em 
        face de mim, para apuração de violação de norma relacionada à prevenção de lavagem de 
        dinheiro e ocultação de bens, prática de atos contra a administração pública.</p>
        <p>8. <b><u>CET:</u></b> Declaro que, previamente à emissão desta CCB, fui informado de forma clara e precisa 
        sobre o CET – Custo Efetivo Total, conforme demonstrativo apresentado no item III do 
        Preâmbulo, sendo cientificado do seu cálculo e possuindo pleno entendimento de que o CET, 
        expresso na forma de taxa percentual anual, corresponde à taxa de juros, tributos, tarifas, bem 
        como outras despesas autorizadas por mim e que a respectiva taxa percentual anual representa 
        as condições vigentes na data do cálculo.</p>
        <p>8.1. Declaro estar ciente que, em razão das características do produto e em função do prazo 
          decorrido entre a simulação da operação, a emissão da CCB e a data da efetiva liberação dos 
          recursos, as condições da operação descritas no preâmbulo poderão sofrer variação de 5% no 
          valor do saque, das parcelas e do desembolso, bem como no CET – Custo Efetivo Total. A qualquer 
          tempo poderei solicitar o cálculo do CET ou novo CET através dos canais de atendimento.</p>
        <p>9. <b><u>Compartilhamento de Dados: </u></b> Conforme definição da lei nº 13.709/2018 – Lei Geral de 
        Proteção de Dados (“LGPD”), autorizo o Credor e o {REACT_APP_NAME} a tratar, compartilhar e 
        guardar todas as informações e dados fornecidos, inclusive pela Fonte Pagadora. A autorização 
        para compartilhamento de dados abrange a Fonte Pagadora, o Credor, o {REACT_APP_NAME}, seus 
        respectivos prestadores de serviços envolvidos na concessão dos benefícios do Cartão 
        . Por fim, ratifico os atos de compartilhamento dos meus dados e informações 
        ocorridos anteriormente a data de emissão desta CCB que tenham sido realizados para a 
        concessão dos benefícios do Cartão.</p>
        <p>9.1. Estou ciente de que o Credor e o {REACT_APP_NAME} realizam tratamento de dados pessoais com 
        finalidades específicas e em conformidade com as bases legais previstas na Lei Geral de 
        Proteção de Dados (“LGPD”), tais como: para a execução dos negócios 
        celebrados com seus clientes, para o devido cumprimento das obrigações legais e regulatórias, para a proteção do 
        crédito, bem como para atender aos interesses legítimos do Credor e do Cartão, 
        dos seus clientes ou de terceiros.</p>
      </div>

      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
        <div style={styles.text}>
          <p>9.2. Autorizo o Credor e o {REACT_APP_NAME} a tratar, coletar, armazenar e compartilhar os dados 
          pessoais e informações financeiras de operações e serviços contratados por mim 
          para: (i) prevenção à fraudes; (ii) assegurar minha identificação, qualificação e 
          autenticação; (iii) prevenção de atos relacionados à lavagem de dinheiro e outros atos ilícitos; (iv) realizar análises 
          de risco de crédito; (v) ofertar produtos e serviços relacionados ao meu perfil, sendo reservado 
          o meu direito de revogar a presente autorização mesmo que a revogação implique no vencimento 
          antecipado das obrigações desta CCB.</p>
          <p>9.3. Autorizo o Credor e o {REACT_APP_NAME}, estritamente para o cumprimento das obrigações 
          previstas neste CCB e no Termo de Fornecimento do Cartão, a compartilhar meus 
          dados pessoais com seus fornecedores e prestadores de serviços, incluindo empresas de 
          telemarketing, de processamento de dados, de tecnologia voltada a meios de pagamento, de 
          tecnologia voltada à prevenção a fraudes, correspondentes bancários, parceiros e empresas ou 
          escritórios especializados em cobrança de dívidas, ou, ainda, para fins de cessão dos direitos 
          creditórios desta CCB, bem como para manter em cadastro ou banco de dados, trocar e incluir 
          informações cadastrais, financeiras e de crédito a respeito dos meus dados pessoais.</p>
          <p>9.4. Estou ciente do meu direito de solicitar a atualização, correção ou exclusão de minhas 
          informações pessoais por meio dos Canais de Atendimento disponibilizados pelo Credor e pelo 
          {REACT_APP_NAME}. Entretanto, o Credor e o {REACT_APP_NAME} poderão manter as informações e dados 
          necessários para o cumprimento de obrigações legais, contratuais e regulatórias.</p>
          <p>10. <b><u>Demais disposições:</u></b> Estou ciente e/ou autorizo: </p>
          <p>(i) o Credor a ceder, transferir, empenhar, alienar, dispor dos direitos e garantias decorrentes 
          desta CCB, inclusive emitir Certificados de Cédula de Crédito Bancário, independente de prévia 
          comunicação, e na hipótese de cessão ou endosso dessa CCB, o novo Credor passará a ter os 
          mesmos direitos aqui previstos.</p>
          <p>(ii) Que a B3 informe minha identidade e meus dados cadastrais ao credor ou adquirente desta 
          CCB.</p>
          <p>(iii) a adesão ao Termo de Fornecimento do Cartão disponível nos Canais de 
          Atendimento do {REACT_APP_NAME} e admito como válida minha assinatura quando esta for capturada 
          de forma eletrônica, por comandos seguros ou gravações de voz, utilizadas nesta CCB.</p>
          <p>(iv) Que a presente CCB possa ser registrada na Brasil Bolsa e Balcão (“B3”) pelo Credor ou 
          pelo {REACT_APP_NAME}, razão pela qual suas cessões poderão ser realizadas de forma eletrônica.</p>
          <p>(v) Que poderei desistir da operação de crédito representada por esta CCB no prazo de até 7 
          (sete) dias úteis contados do recebimento do crédito, devendo para tanto, restituir o Credor o 
          valor total concedido, acrescido de eventuais tributos incidentes.</p>
          <p>(vi) Que a liquidação antecipada total ou parcial da operação, com abatimento proporcional de 
          juros, poderá ser solicitada a qualquer momento nos canais de atendimento disponibilizados pelo 
          {REACT_APP_NAME}. E o valor do débito será calculado com a utilização da taxa de juros pactuada nesta CCB.</p>
        </div>
      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
        <div style={styles.text}>
          <p>(vii) Que a omissão ou tolerância do Credor quanto ao exercício de qualquer direito,
          poder ou privilégio conferido em normas ou nesta CCB não constituirá novação, desistência ou
          renúncia, nem afetará os seus direitos que poderão ser exercidos a qualquer tempo.</p>
          <p>(viii) Que todas as notificações relacionadas a esta CCB serão realizadas por meio eletrônico,
          enviadas para o endereço eletrônico e/ou números de celulares informados por mim no 
          preâmbulo, ou, ainda por meio de correspondência enviada com aviso de recebimento.</p>
          <p>(ix) aos gestores de bancos de dados de que trata a Lei n̊ 12.414/2011, disponibilizarem ao 
          Credor e ao {REACT_APP_NAME} meus históricos de crédito, os quais abrangerão os dados financeiros 
          e de pagamentos relativos às operações de crédito e obrigações de pagamento adimplidas em 
          seus respectivos vencimentos e aquelas a vencer, constantes dos bancos de dados, com a 
          finalidade única e exclusiva de subsidiar a análise e a eventual concessão de crédito, a venda a 
          prazo ou outras transações comerciais e empresariais que impliquem risco financeiro, pelo prazo 
          estabelecido pelas normas vigentes e declaro-me ciente de que poderei revogar, a qualquer 
          tempo, a autorização contida no item anterior perante o gestor do banco de dados.</p>
          <p>(x) Que qualquer dúvida ou reclamação deverá ser tratada exclusivamente nos canais de 
          atendimento do {REACT_APP_NAME}, previsto no rodapé da CCB.</p>
          <p>(xi) Que a {REACT_APP_NAME}, na qualidade de correspondente bancário do Credor, poderá entrar em 
          contato comigo e com minha Fonte Pagadora, assim como solicitar e receber meus documentos, 
          visando a análise e aprovação do crédito;</p>
          <p>(xii) Que a {REACT_APP_NAME} poderá antecipar o valor do crédito, e também enviar cobranças e 
          boletos caso haja algum problema com minha margem consignável.</p>
          <p>(xiii) Que nem o Credor, nem a {REACT_APP_NAME}, solicitam pagamentos para aprovação e/ou 
          liberação de benefícios vinculados ao Cartão. Também não solicitam devoluções 
          ou reembolso de despesas, estornos ou transferências de crédito decorrentes desta CCB em 
          conta de terceiros, nem autoriza que seus intermediários solicitem em seu nome, a qualquer 
          título.</p>
          <p>11. <b><u>Consulta SCR:</u></b> Estou ciente e de acordo que o Credor e a {REACT_APP_NAME}, nos termos da 
          legislação do Banco Central do Brasil (“BCB”) e do Conselho Monetário Nacional (“CMN) 
          vigentes, consultem e registrem informações, decorrentes de operações de crédito de sua 
          responsabilidade junto ao Sistema de Informações de Crédito (“SCR”), para fins de supervisão 
          do risco de crédito e intercâmbio de informações com outras instituições.</p>
          <p>11.1. Tenho conhecimento de que: (a) o SCR tem por finalidade prover informações ao BCB, 
          para fins de monitoramento do crédito no sistema financeiro, para o exercício de suas atividades 
          de fiscalização, e visando propiciar o intercâmbio de informações entre instituições financeiras, 
          conforme § 1º do artigo 1º da Lei Complementar nº 105/2001, sobre o montante de 
          responsabilidades de clientes em operações de crédito; (b) os dados das minhas operações de crédito serão registrados 
          pelo Credor no SCR; (iii) são de exclusiva responsabilidade das 
          instituições remetentes as inclusões de informações no SCR, as correções e exclusões de 
          informações constantes do SCR, a identificação de operações de crédito que se encontrem “sub judice”, o cumprimento de determinações 
          judiciais, o fornecimento de informações sobre essas</p>
        </div>
      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
        <div style={styles.text}>
          <p>determinações e o registro de manifestações de discordância apresentadas pelos contratantes, 
          bem como de outras condições e anotações necessárias a garantir a completude, a fidedignidade 
          e a integridade da informação sobre operações de crédito, e (iv) o procedimento a ser adotado 
          por mim junto ao Credor para correção ou exclusão de informações remetidas pelo Credor ao 
          SCR, o cadastramento de medida judicial e o registro de manifestação de discordância quanto 
          às informações remetidas pelo Credor ao SCR deverá ser verificado por mim junto aos Canais 
          de Atendimento do {REACT_APP_NAME}.</p>
          <p>12. <b><u>Declarações:</u></b> Declaro:</p>
          <p>(i) Que verifiquei que a operação contratada é adequada às minhas necessidades, bem como 
          que possuo condições econômico – financeiras para pagar as obrigações assumidas sem 
          comprometer meu sustento e o de meus dependentes.</p>
          <p>(ii) E assumo o compromisso de respeitar a legislação anticorrupção, atualmente disciplinadas 
          na lei nº 12.846/2013 e no Decreto nº 8.240/2015, sem limitações e informar, imediatamente, ao 
          Credor e ao {REACT_APP_NAME}, qualquer violação e/ou possível descumprimento das obrigações 
          decorrentes destas normas, cabendo ressarcir e indenizar o Credor e o {REACT_APP_NAME}, por 
          qualquer prejuízo que este possa vir a sofrer em razão do descumprimento das normas referidas 
          nesta cláusula.</p>
          <p>(iii) Que os recursos decorrentes desta CCB não serão destinados a atividades ilícitas ou que 
          possam causar danos sociais e/ou ambientais e a projetos que estejam em desacordo com a 
          Política Nacional de Meio ambiente prevista em norma.</p>
          <p>(iv) Que são verdadeiras as informações prestadas por mim sobre a licitude da origem de minha 
          renda, faturamento e patrimônio, e estou ciente do artigo 11, inciso II da lei nª 9.613/1998, com 
          as alterações introduzidas, inclusive pela Lei nº 12.686/2012 (dever das instituições financeiras 
          de comunicação ao Coaf de operações e propostas de operações suspeitas), e dos artigos 297, 
          298 e 299 do Código Penal.</p>
          <p>(v) E admito como válidos e aceitos como meio de comprovação de autoria e de integridade de 
          documentos em forma eletrônica, os métodos de identificação cuja utilização tenha sido 
          solicitada pelo Credor ou pelo {REACT_APP_NAME}, como, por exemplo, certificados emitidos ou não 
          pela ICP-Brasil, senha eletrônica, código de autenticação emitido por dispositivo pessoal ou 
          identificação biométrica;</p>
          <p>(vi) Que manterei as informações constantes do Cadastro atualizadas até a extinção desta CCB, 
          e notificarei imediatamente o Credor ou {REACT_APP_NAME} de qualquer alteração nessas 
          informações.</p>
          <p>(vii) Que renuncio expressamente à faculdade de realizar depósitos, identificados ou não, na 
          conta do Credor ou do {REACT_APP_NAME}, sem que estes tenham expressamente autorizado essa 
          forma de pagamento. Qualquer depósito feito em desacordo com este item não constituirá 
          quitação e, caso identificado, será mim devolvido.</p>
        </div>
      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
        <div style={styles.text}>
          <p>(viii) Que tenho conhecimento que esta CCB é título executivo extrajudicial e representa dívida '+
          em dinheiro líquida, certa e exigível, seja pela soma nela indicada, seja pelo saldo devedor '+
          demonstrado em planilha de cálculo emitida conforme previsto emr lei. E que esta CCB é '+
          negociável, e a via entregue para mim neste ato é a via “NÃO NEGOCIÁVEL”</p>
          <p>(ix) Que, para fins de notificação, citação ou intimação, nos termos dos artigos 190, 246, inciso '+
          V e 513, §2º, inciso III do Código de Processo Civil, seja utilizado o meu endereço eletrônico '+
          indicado no preâmbulo e no Cadastro.</p>
          <p>(x) Estou obrigado por mim meus sucessores a qualquer título ao fiel cumprimento desta CCB, '+
          optando pelo Foro da Comarca do local de emissão desta CCB ou de meu domicílio para '+
          eventual discussão sobre as condições ajustadas.</p>
          <p>(xi) Li previamente esta CCB e não tenho dúvida quanto ao seu conteúdo e nem das '+
          autorizações concedidas.</p>
          <p>Local e data: São Paulo, {FormatData(Proposta.DataCadastro)}</p>
          <p>Emitente: (documento assinado eletronicamente conforme disposto na cláusula 12, inciso “v”
          desta CCB e folha de assinaturas)</p>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      {Footer()}
    </div>
    <div style={styles.paper_m}>
      {Header()}
      <div style={styles.text}>
        <br/>
        <p style={{textAlign: 'center'}}><b>Termo de Aceite do Seguro Prestamista BS2</b></p>
        <p><u>Válido somente para clientes com opção do Seguro prestamista</u></p>
        <p>Pelo presente Termo de Aceitação do Seguro Prestamista BS2, declaro que concordei e solicitei a contratação do Seguro Prestamista BS2, recebi, li e compreendi todas as informações referentes ao seguro contratado, incluindo as condições gerais e específicas do produto.</p>
        <p>Estou ciente do meu direito de arrependimento, que poderá ser exercido no prazo de 7 dias corridos.</p>
        <p>Também afirmo que todas as informações prestadas são verdadeiras, completas e corretas, assumindo total responsabilidade por qualquer omissão ou inexatidão que possa afetar a aceitação do risco ou o pagamento das indenizações decorrentes do seguro contratado.</p>
        <p>Autorizo a cobrança do valor relativo ao Seguro Prestamista BS2 na fatura do Cartão {REACT_APP_NAME} para liquidação total do valor devido em razão da contratação do Seguro Prestamista BS2.</p>
        <p>Assim, aceito formalmente as condições estabelecidas e firmo o presente termo de aceite de contratação e aceite de seguro.</p>
        <p>Por fim, autorizo, aceito e reconheço como válida a assinatura eletrônica aposta neste Termo de Aceitação do Seguro
          Prestamista BS2 nos termos da Medida Provisória nº 2.220-2, de 24 de agosto de 2001, como meio de comprovação de
          autoria e integridade de documentos em forma eletrônica, sendo suficiente para a veracidade, autenticidade,
          integridade, validade e eficácia deste Termo.
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p style={{textAlign: 'center'}}><b>Central de Atendimento:  (11) 2365-5240</b></p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <p style={{textAlign: 'center', fontSize: '9px'}}><b>BS2 SEGUROS S.A. | Código SUSEP 0379-4</b></p>
        <p style={{textAlign: 'center', fontSize: '9px'}}><b>CNPJ nº 29.871.099/0001-89</b></p>
        <p style={{textAlign: 'center', fontSize: '9px'}}>Av. Raja Gabáglia, 1.143 - 16º andar, sala 1604 - Luxemburgo - CEP 30380-403 - Belo Horizonte –</p>
      </div>
      {Footer()}
    </div>
    </div>
    );
  }

  return(
    <div onScroll={Status} style={styles.property}>
    <div style={styles.paper}>
      {Header()}

      <p style={styles.title}><b>Preâmbulo</b></p>
      
      {AddBox('I - Credor', <p>1. {REACT_APP_NAME_LECCA_CAPS} CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A., instituição financeira
      devidamente constituída e existente de acordo com as leis do Brasil, com sede na Cidade de São Paulo, Estado do São Paulo, na Rua José dos Reis 409, sala 51 5º andar, CEP 03.139-040 e
      inscrita no CNPJ sob o nº 29.871.099/0001-89</p>)}

      {AddBox('II –Emitente/Devedor', 
      <div>
      <p>Nome: {Cliente.DadosCliente.NomeCliente}</p>
      <p>CPF: {Cliente.DadosCliente.CPF}</p>
      <p>Doc. de Identidade: {Cliente.DadosCliente.Registro.Numero}  </p>
      <p>Órgão expedidor: {Cliente.DadosCliente.Registro.UFEmissao}</p>
      <p>Endereço: {endr?.Logradouro}</p>
      <p>Nº: {endr?.Numero} Complemento: {endr?.Complemento}</p>
      <p>Bairro: {endr?.Bairro}   Cidade: {endr?.Cidade}      Estado: {endr?.UF}</p>
      <p>CEP: {endr?.CEP}    E-mail: {Cliente.DadosCliente.Email}</p>
      <p>Celular/Whatsapp: {celstr}   Tel.Fixo: {telstr}</p>
      <p>Órgão Público/Fonte  Pagadora: {Orgao}</p>
      <p>Banco n º: {Cliente.ReferenciaBancaria.NumeroBanco}  Agência: {Cliente.ReferenciaBancaria.Agencia}   Conta nº: {Cliente.ReferenciaBancaria.Conta} </p>
      </div>)}

      {AddBox('III - Características da Operação', 
      <div>
        <p>1. Valor Liquido liberado(Valor do saque): {ValorLiquidoCliente} </p>
        <p>2. Tarifa de Saque: {ValorTarifaCartao}    3. Valor do IOF: {ValorIOF} </p>
        <p>3.1. IOF Adicional: {`0`}%</p>
        <p>4. Valor Principal da CCB (1+2+3): {ValorPrincipal} </p>
        <p>5. Taxa de juros: {TaxaCLMes.toFixed(2)} % ao mês (30 dias)  {TaxaCLAno.toFixed(2)} % ao ano (360 dias)</p>
        <p>6. Prazo: {Prazo} meses 7. Nº de Parcelas: {Prazo} </p>
        <p>8.Custo Efetivo Total – CET {TaxaCETMes.toFixed(2)} % ao mês (30 dias)   {TaxaCETAno.toFixed(2)} % ao ano (360 dias)</p>
        <p>9. Valor total a pagar (principal + juros): {ValorBruto}</p>
        <p>10.Valor de cada Parcela: {ValorParcela}</p>
        <p>11. Forma e periodicidade de Pagamento: mensal e mediante desconto em folha de pagamento </p>
        <p>12. Previsão de Vencimento da Primeira Parcela: {DataPrimeiroVencimento}</p>
        <p>13. Previsão de Vencimento Final: {DataUltimoVencimento}</p>
        <p>14. Desembolso da Operação de Crédito:</p>
        <p>14.1. Valor de R$ {saldoRefin} será creditado na conta da {REACT_APP_NAME} Serviços Tecnológicos e Financeiros Ltda – CNPJ nº 29.871.099/0001-89 para liquidação da CCB(s) indicadas no item 15 a seguir.</p>
        <p>14.2. Valor de R$ {ValorLiquidoCliente} será creditado na conta indicada pelo Devedor no Quadro II</p>
        <p>15. Origem da Operação de Crédito: refinanciamento das CCB(s) no.(s): {arrContratos.join(", ")}.</p>
      </div>)}
      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
      {AddBox('IV – Cartão e modalidade de benefício/saque:',
      <div>
      <p>1. Fornecedor do “Cartão {REACT_APP_NAME}” e correspondente:</p>
      <p>{REACT_APP_NAME} Serviços Tecnológicos e Financeiros Ltda., sociedade de
      responsabilidade limitada, inscrita no CNPJ sob o nº 29.871.099/0001-89, com
      sede na Cidade e Estado de São Paulo, na Rua Joaquim Floriano, 413 – 11º andar 
      – Itaim Bibi – São Paulo SP, CEP 04543-011, doravante
      denominada apenas “{REACT_APP_NAME}”.</p>
      <p>2. O Cartão {REACT_APP_NAME} é concedido aos servidores públicos de órgãos
      públicos conveniados ou credenciados com o Credor e o {REACT_APP_NAME} e
      oferece diversos benefícios aos seus titulares. A utilização dos benefícios do
      Cartão {REACT_APP_NAME} depende da prévia solicitação do Cartão, da emissão de
      CCB, da aceitação do pedido de utilização do benefício pelo Credor e pelo
      {REACT_APP_NAME}, sendo que o pagamento das parcelas da CCB será realizado
      mediante desconto em folha de pagamento do órgão público (“Fonte Pagadora”).</p>
      <p>3. Modalidade de Benefício do Cartão {REACT_APP_NAME}:</p>
      <p>() Saque parcelado </p>
      <p>() Saque complementar parcelado</p>
      <p>(x) Refinanciamento</p>
      <p>() outros (especificar)</p>
      </div>)}

      <div style={styles.text}>
      <p><b>V - Cláusulas e Condições</b></p>
      <p>Leia com atenção as condições a seguir e consulte o SAC – Serviço de Atendimento ao Cliente em caso de dúvidas. 
      Uso consciente do crédito - Evite o superendividamento, contrate sempre de acordo com as suas condições financeiras.</p>
      <p>1. <b><u>Pagamento:</u></b> Nas datas de vencimento, pagarei por esta Cédula de Crédito Bancário (“CCB”) 
      ao Credor ou à sua ordem, em moeda corrente nacional, na Praça de São Paulo/SP, a quantia 
      líquida, certa e exigível correspondente ao Valor Principal da CCB, acrescido dos juros 
      remuneratórios à taxa conforme item III do Preâmbulo, capitalizados na periodicidade 
      estabelecida, e demais encargos devidos, nos termos desta CCB, mediante desconto em minha 
      remuneração ou em benefício ou pensão previdenciária (“Remuneração”), que, desde já 
      autorizo, de forma irrevogável e irretratável, o órgão público (“Fonte Pagadora”) acima 
      identificado a efetuar e repassar ao Credor, ao {REACT_APP_NAME} ou a terceiro à sua ordem, 
      observada a legislação aplicável</p>
      <p>2. <b><u>Consignação:</u></b> Para viabilizar o pagamento da operação de crédito contratada nesta CCB, 
      autorizo a minha Fonte Pagadora, de forma irrevogável e irretratável, a realizar o desconto 
      mensal em minha Remuneração, em favor do Credor ou à sua ordem, para o pagamento 
      correspondente à quantia necessária à liquidação total das parcelas da operação. As parcelas 
      serão informadas na fatura mensal do Cartão {REACT_APP_NAME}.</p>
      <p>2.1. Autorizo, ainda, minha Fonte Pagadora, de forma irrevogável e irretratável, a (i) se aplicável, 
      descontar o percentual legalmente estabelecido nas minhas verbas rescisórias para o 
      pagamento das obrigações, previstas nesta CCB, repassando o respectivo valor ao Credor ou à 
      sua ordem e; (ii) compartilhar com o Credor e o {REACT_APP_NAME} todas as informações necessárias 
      para realizar o desconto das parcelas desta CCB em minha Remuneração, inclusive enviando 
      cópia desta CCB à Fonte pagadora caso ela assim exija para operacionalizar a averbação do
      pagamento das parcelas desta CCB na minha folha de pagamento.
      {CodigoEmpregador == Estado.celetista &&
        <a> As verbas rescisórias incluem no seu cálculo, mas não se limitam, a férias, multas, 
          13º salário, bônus pendentes, horas extras, comissões, dentre outras. Em adição autorizo, 
          quando possível, o saque do FGTS - Fundo de Garantia por Tempo de Serviço e de fundos 
          de previdência provenientes da Fonte Pagadora. Para tanto, nomeio e constituo a 
          {REACT_APP_NAME_LECCA} como procuradora, conferindo à {REACT_APP_NAME_LECCA} poderes amplos e especiais para a 
          outorgada agir em meu nome junto ao meu Órgão Empregador, junto à CEF Caixa Econômica 
          Federal, a fundos de previdência e gestores de tais fundos, para levantar e sacar os 
          saldos existentes de verbas rescisórias, FGTS - Fundo de Garantia por Tempo de Serviço, 
          fundos de previdência e quaisquer outras verbas recebíveis em meu nome decorrentes do 
          meu vínculo de trabalho com o Órgão empregador, descontando/abatendo as parcelas vencidas 
          e vincendas desta e de outras CCBs existentes em meu nome, podendo a outorgada fazer 
          requerimentos, receber valores, dar quitação, ingressar com todas as medidas judiciais 
          e administrativas necessárias, substabelecer, enfim, praticar todos os atos necessários 
          ao fiel e bom cumprimento da cláusula mandato.</a>
      }
      </p>
      </div>
      {Footer()}

    </div>
    <div style={styles.paper_m}>
      {Header()}

      <div style={styles.text}>
      <p>2.2. Estou de acordo que o pagamento das parcelas desta CCB seja realizado por meio de
      descontos mensais em folha de pagamento, no valor necessário à quitação de cada
      parcela, até a quitação total, observada a margem consignável disponível. Se após a averbação da operação, 
      a margem consignável disponível se tornar insuficiente para a consignação integral da parcela 
      contratada, o valor das parcelas a vencer poderá ser consignado parcialmente, readequando o 
      valor das parcelas à margem consignável disponível. Neste caso, o número de parcelas será 
      ajustado para que o Saldo Devedor possa ser quitado mediante o pagamento mensal do novo 
      valor. Caso não seja possível o desconto mensal na folha de pagamento, inclusive, mas não 
      somente, no caso de insuficiência de margem consignável, deverei: (i) pagar as parcelas devidas 
      diretamente ao Credor ou à sua ordem; (ii) verificar com o Credor a possibilidade de reprogramar 
      o pagamento; e/ou (iii) pagar as parcelas mediante boleto bancário ou outro meio expressamente 
      indicado pelo Credor.</p>
      <p>2.3. Declaro estar ciente e de acordo que o valor da fatura do Cartão {REACT_APP_NAME} a ser 
      descontado em minha folha de pagamento poderá ser automaticamente majorado e/ou minorado 
      na mesma proporção de eventuais e futuros aumentos e/ou diminuições em minha margem 
      consignável, manifestando, desde já, concordância com os ajustes realizados para fins de 
      adequação e manutenção dos pagamentos</p>
      <p>2.4. A forma de pagamento das parcelas poderá ser alterada se ocorrer: (a) impossibilidade ou 
      suspensão do desconto do valor das parcelas na minha Remuneração pela Fonte Pagadora, por 
      qualquer motivo, ou (b) início de gozo de benefício previdenciário temporário pelo INSS ou outro 
      órgão de previdência a que eu me vincular; ou (c) término, suspensão ou redução da minha 
      Remuneração; ou (d) a exoneração {CodigoEmpregador == Estado.celetista && <a>, suspensão </a>}ou a rescisão do meu contrato de trabalho. Nesses casos, 
      as parcelas serão lançadas nas faturas subsequentes, acrescidas dos respectivos encargos a 
      serem informados, observado o prazo máximo previsto em convênio.</p>
      <p>2.4.1. Caso, ainda assim, o saldo devedor não seja liquidado, as parcelas poderão ser debitadas 
      da conta indicada no item II do Preâmbulo ou em qualquer outra conta de minha titularidade, 
      sendo autorizado, por mim, desde já, este procedimento. Havendo alteração ou transferência da 
      minha conta para outra agência ou outro banco, o Credor e a {REACT_APP_NAME}, ficarão 
      expressamente autorizados a obter os dados da minha nova conta, pelo que, neste ato, outorgo 
      aos mesmos, poderes especiais, em caráter irrevogável e irretratável, nos termos do artigo 684 
      do Código Civil Brasileiro, para praticar todos os atos necessários a tal fim, inclusive encaminhar 
      ofício a minha Fonte Pagadora, para receber os dados da minha nova conta, de modo que o 
      Credor e o {REACT_APP_NAME} possam promover quaisquer débitos decorrentes da CCB, sendo que 
      reconheço que tais procedimentos não configuram, nem configurarão infração ás regras que 
      disciplinam o Sigilo Bancário previstas na Lei Complementar nº 105/2001 e na LGPD – Lei Geral 
      de Proteção de Dados</p>
      <p>2.5. Caso eu venha me aposentar antes de pagar integralmente esta CCB, autorizo que as 
      parcelas passem a ser descontadas de meu benefício ou pensão previdenciária e transferidas 
      para o Credor, caso haja convênio celebrado entre minha nova Fonte Pagadora, para tanto, 
      observada a legislação aplicável.
      {CodigoEmpregador == Estado.celetista && <a> Na hipótese de término do meu contrato 
        com a fonte pagadora, por qualquer motivo, autorizo que as parcelas passem a ser descontadas 
        de nova remuneração que eu venha a receber em razão de novo vínculo estatutário 
        ou celetista. </a>} Nesse sentido, todas as autorizações dadas nesta CCB ficam 
      estendidas à nova Fonte Pagadora.</p>
      </div>

      {Footer()}
    </div>
    <div style={styles.paper_m}>
      {Header()}
      
      <div style={styles.text}>
        <p>3. <b><u>Liberação do Crédito: </u></b> Após a aprovação cadastral e creditícia do Credor bem como a 
        confirmação da reserva de margem consignável na minha Remuneração pela Fonte Pagadora 
        (“Averbação”), o Valor do Saque indicado no item III do Preâmbulo será 
        creditado na conta indicada no item II do Preâmbulo.Declaro para todos os fins de direito que a conta indicada no 
        item II do Preâmbulo é a conta utilizada pela Fonte Pagadora para crédito da minha 
        Remuneração.</p>
        <p>3.1. <b>Averbação do contrato:</b> Esta contratação de empréstimo só será efetivada mediante a confirmação, pelo órgão empregador,
        do pedido de reserva de margem. Caso a averbação não ocorra, por qualquer motivo, a operação e o desembolso não serão efetivados. 
        No caso de eventual liberação de valores na conta corrente do Emitente/Devedor, antes da confirmação de não averbação, o Emitente/Devedor 
        deverá devolver os valores porventura recebidos, sob pena de adoção das medidas judiciais cabíveis. No caso de liquidação de contrato anterior 
        referente ao Cartão {REACT_APP_NAME}, o contrato anterior será reaberto. Caso a averbação ocorra de forma parcial, ou ocorra atraso na averbação, 
        e as condições originalmente contratadas sejam alteradas proporcionalmente ao valor da margem disponibilizada, o Emitente/Devedor se compromete 
        a assinar uma nova CCB, com as novas condições pactuadas.</p>
        <p>3.2. O Emitente/Devedor declara ter ciência de que, na hipótese desta operação de Refinanciamento ser empregada para liquidar outras operações 
        de sua responsabilidade, o valor residual a ser liberado em seu favor, se houver, poderá ser reduzido em virtude da redução da margem consignável.</p>
        <p>4. <b><u>Não Aprovação do Crédito: </u></b> Estou ciente que, em caso de negativa da solicitação de 
        empréstimo (i) o desembolso do crédito não será realizado; (ii) serei notificado acerca da 
        negativa; e (iv) a presente CCB será cancelada e não surtirá quaisquer efeitos, sem a incidência 
        de quaisquer penalidades para as Partes envolvidas.</p>
        <p>5. <b><u>Cartão {REACT_APP_NAME}:</u></b> Declaro ter ciência que: (i) o crédito representado por esta CCB é um 
        benefício concedido aos titulares do Cartão {REACT_APP_NAME} na modalidade indicada no item IV do 
        Preâmbulo e será disponibilizado nas hipótese e conforme legislação aplicável, com observância 
        do Termo de Fornecimento do Cartão {REACT_APP_NAME}; (ii) saques adicionais caracterizando outros 
        benefícios do Cartão {REACT_APP_NAME} poderão ser formalizados mediante a emissão de nova CCB 
        ou outras formas disponibilizadas pelo Credor e pelo {REACT_APP_NAME}, desde que não vedadas 
        pela legislação aplicável; (iii) o valor das parcelas será lançado na fatura do Cartão 
        {REACT_APP_NAME} de minha titularidade, conforme disposto no item III do Preâmbulo, observada a 
        data de vencimento do referido Cartão, e que o valor devido em razão desta CCB comprometerá 
        meu limite de crédito disponibilizado para utilização do Cartão {REACT_APP_NAME}.</p>
        <p>5.1. Tenho ciência, ainda, de que a solicitação de crédito representada nesta CCB configura a 
        ativação do Cartão {REACT_APP_NAME}, assim como a cobrança de tarifas e anuidade, cujos valores 
        estão disponíveis para consulta nos Canais de Atendimento do {REACT_APP_NAME} e serão cobrados 
        na minha fatura.</p>
        <p>6. <b><u>Não Pagamento:</u></b> O não pagamento da parcela implicará em atraso, de forma que sobre o 
        valor da obrigação vencida incidirão: (i) juros remuneratórios previstos nesta CCB; (ii) juros 
        moratórios equivalentes a 1% (um por cento) ao mês, ou fração incidente sobre o valor de 
        principal, acrescido dos encargos previstos nas alíneas anteriores e, (iii) multa não 
        compensatória de 2% (dois por cento) sobre o total devido, além dos demais encargos descritos 
        na fatura a ser enviada a mim. Tenho ciência que o Credor poderá, ainda, promover as medidas 
        extrajudiciais e judiciais cabíveis, cujas despesas passarão a compor o total da dívida.</p>
        <p>6.1. Na hipótese de falta de pagamento das parcelas, o Credor poderá optar pela cobrança 
        somente da parcela vencida, sem que tal ato importe em novação ou alteração dos termos aqui 
        estabelecidos.</p>
        <p>6.2. Para preservar a forma de pagamento inicialmente pactuada nesta CCB, autorizo o Credor 
        e o {REACT_APP_NAME}, observadas as disposições legais aplicáveis, a solicitar à respectiva Fonte 
        Pagadora que efetue o desconto do valor das parcelas, que por qualquer motivo não tenham 
        sido consignadas, por meio da prorrogação do vencimento final das parcelas. Desta forma, a 
        vigência desta CCB ficará automaticamente prorrogada pelo período necessário ao regular 
        pagamento de todas as parcelas mensais.</p>
      </div>

      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}

      <div style={styles.text}>
        <p>6.3. Estou ciente de que se não for possível manter a forma de pagamento nos termos da 
        cláusula acima, deverei pagar o valor das parcelas diretamente ao Credor ou à sua ordem, e 
        que, em caso de inadimplência, o Credor poderá: (i) exigir o ressarcimento de todas as despesas 
        e custos de cobrança extrajudicial e judicial, assim como honorários 
        advocatícios, acrescidos dos encargos previstos na CCB;
        (ii) comunicar os órgãos de proteção de crédito, tais como SPC 
        e SERASA, sistemas de registro (incluindo o SCR) e/ou encaminhar esta CCB para o devido 
        protesto; e (iii) não serei considerado inadimplente na hipótese de a Fonte Pagadora efetuar os 
        descontos das parcelas devidas sob esta CCB e não repassar tais valores ao Credor, sendo 
        vedado neste caso, a adoção pelo Credor ou pelo {REACT_APP_NAME} de quaisquer medidas de 
        cobrança em face de mim relacionado a tais valores.</p>
        <p>7. <b><u>Vencimento Antecipado da Dívida:</u></b> Fica reservado ao Credor, observadas as regras da 
        Fonte Pagadora, o direito de declarar esta CCB antecipadamente vencida e exigir o imediato e 
        integral pagamento do saldo devedor dela decorrente, independentemente de prévia 
        comunicação, na ocorrência das seguintes hipóteses: (i) atraso no pagamento, impossibilidade 
        do desconto em folha de pagamento e/ou falta de pagamento de qualquer valor devido ao Credor; 
        (ii) descumprimento de qualquer obrigação assumida por mim perante o Credor ou no Termo de 
        Emissão do Cartão {REACT_APP_NAME}; (iii) pedido de insolvência ou interdição em meu nome; (iv) 
        meu falecimento; (v) protestos de títulos, distribuição de ação de execução por título extrajudicial 
        ou judicial, emissão de cheque sem fundos ou qualquer outra restrição cadastral ou creditícia em 
        meu nome; (vi) detecção de falsidade e/ou incompletude das declarações feitas por mim nesta 
        CCB; (vii) se for movida, contra mim, medida judicial que possa afetar minha capacidade de 
        cumprimento das obrigações desta CCB; ou (viii) se for iniciado procedimento investigatório em 
        face de mim, para apuração de violação de norma relacionada à prevenção de lavagem de 
        dinheiro e ocultação de bens, prática de atos contra a administração pública{CodigoEmpregador == Estado.celetista && <a>; e (ix) exoneração, suspensão ou rescisão do meu contrato de trabalho por qualquer motivo</a>}.</p>
        <p>8. <b><u>CET:</u></b> Declaro que, previamente à emissão desta CCB, fui informado de forma clara e precisa 
        sobre o CET – Custo Efetivo Total, conforme demonstrativo apresentado no item III do 
        Preâmbulo, sendo cientificado do seu cálculo e possuindo pleno entendimento de que o CET, 
        expresso na forma de taxa percentual anual, corresponde à taxa de juros, tributos, tarifas, bem 
        como outras despesas autorizadas por mim e que a respectiva taxa percentual anual representa 
        as condições vigentes na data do cálculo.</p>
        <p>8.1. Declaro estar ciente que, em razão das características do produto e em função do prazo 
          decorrido entre a simulação da operação, a emissão da CCB e a data da efetiva liberação dos 
          recursos, as condições da operação descritas no preâmbulo poderão sofrer variação de 5% no 
          valor do saque, das parcelas e do desembolso, bem como no CET – Custo Efetivo Total. A qualquer 
          tempo poderei solicitar o cálculo do CET ou novo CET através dos canais de atendimento.</p>
        <p>9. <b><u>Compartilhamento de Dados: </u></b> Conforme definição da lei nº 13.709/2018 – Lei Geral de 
        Proteção de Dados (“LGPD”), autorizo o Credor e o {REACT_APP_NAME} a tratar, compartilhar e 
        guardar todas as informações e dados fornecidos, inclusive pela Fonte Pagadora. A autorização 
        para compartilhamento de dados abrange a Fonte Pagadora, o Credor, o {REACT_APP_NAME}, seus 
        respectivos prestadores de serviços envolvidos na concessão dos benefícios do Cartão 
        {REACT_APP_NAME}. Por fim, ratifico os atos de compartilhamento dos meus dados e informações 
        ocorridos anteriormente a data de emissão desta CCB que tenham sido realizados para a 
        concessão dos benefícios do Cartão {REACT_APP_NAME}.</p>
        <p>9.1. Estou ciente de que o Credor e o {REACT_APP_NAME} realizam tratamento de dados pessoais com 
        finalidades específicas e em conformidade com as bases legais previstas na Lei Geral de 
        Proteção de Dados (“LGPD”), tais como: para a execução dos negócios 
        celebrados com seus clientes, para o devido cumprimento das obrigações legais e regulatórias, para a proteção do 
        crédito, bem como para atender aos interesses legítimos do Credor e do Cartão {REACT_APP_NAME}, 
        dos seus clientes ou de terceiros.</p>
      </div>

      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
        <div style={styles.text}>
          <p>9.2. Autorizo o Credor e o {REACT_APP_NAME} a tratar, coletar, armazenar e compartilhar os dados 
          pessoais e informações financeiras de operações e serviços contratados por mim 
          para: (i) prevenção à fraudes; (ii) assegurar minha identificação, qualificação e 
          autenticação; (iii) prevenção de atos relacionados à lavagem de dinheiro e outros atos ilícitos; (iv) realizar análises 
          de risco de crédito; (v) ofertar produtos e serviços relacionados ao meu perfil, sendo reservado 
          o meu direito de revogar a presente autorização mesmo que a revogação implique no vencimento 
          antecipado das obrigações desta CCB.</p>
          <p>9.3. Autorizo o Credor e o {REACT_APP_NAME}, estritamente para o cumprimento das obrigações 
          previstas neste CCB e no Termo de Fornecimento do Cartão {REACT_APP_NAME}, a compartilhar meus 
          dados pessoais com seus fornecedores e prestadores de serviços, incluindo empresas de 
          telemarketing, de processamento de dados, de tecnologia voltada a meios de pagamento, de 
          tecnologia voltada à prevenção a fraudes, correspondentes bancários, parceiros e empresas ou 
          escritórios especializados em cobrança de dívidas, ou, ainda, para fins de cessão dos direitos 
          creditórios desta CCB, bem como para manter em cadastro ou banco de dados, trocar e incluir 
          informações cadastrais, financeiras e de crédito a respeito dos meus dados pessoais.</p>
          <p>9.4. Estou ciente do meu direito de solicitar a atualização, correção ou exclusão de minhas 
          informações pessoais por meio dos Canais de Atendimento disponibilizados pelo Credor e pelo 
          {REACT_APP_NAME}. Entretanto, o Credor e o {REACT_APP_NAME} poderão manter as informações e dados 
          necessários para o cumprimento de obrigações legais, contratuais e regulatórias.</p>
          <p>10. <b><u>Demais disposições:</u></b> Estou ciente e/ou autorizo: </p>
          <p>(i) o Credor a ceder, transferir, empenhar, alienar, dispor dos direitos e garantias decorrentes 
          desta CCB, inclusive emitir Certificados de Cédula de Crédito Bancário, independente de prévia 
          comunicação, e na hipótese de cessão ou endosso dessa CCB, o novo Credor passará a ter os 
          mesmos direitos aqui previstos.</p>
          <p>(ii) Que a B3 informe minha identidade e meus dados cadastrais ao credor ou adquirente desta 
          CCB.</p>
          <p>(iii) a adesão ao Termo de Fornecimento do Cartão {REACT_APP_NAME} disponível nos Canais de 
          Atendimento do {REACT_APP_NAME} e admito como válida minha assinatura quando esta for capturada 
          de forma eletrônica, por comandos seguros ou gravações de voz, utilizadas nesta CCB.</p>
          <p>(iv) Que a presente CCB possa ser registrada na Brasil Bolsa e Balcão (“B3”) pelo Credor ou 
          pelo {REACT_APP_NAME}, razão pela qual suas cessões poderão ser realizadas de forma eletrônica.</p>
          <p>(v) Que poderei desistir da operação de crédito representada por esta CCB no prazo de até 7 
          (sete) dias úteis contados do recebimento do crédito, devendo para tanto, restituir o Credor o 
          valor total concedido, acrescido de eventuais tributos incidentes.</p>
          <p>(vi) Que a liquidação antecipada total ou parcial da operação, com abatimento proporcional de 
          juros, poderá ser solicitada a qualquer momento nos canais de atendimento disponibilizados pelo 
          {REACT_APP_NAME}. E o valor do débito será calculado com a utilização da taxa de juros pactuada nesta CCB.</p>
          {endr?.Cidade?.includes("Cuiab") &&
            <p><b>“É assegurada ao Contratante a liquidação antecipada do débito, total ou parcialmente, seja das primeiras 
              ou das últimas parcelas, mediante redução proporcional dos juros contratados (desconto comercial) ou taxa SELIC, 
              qual seja menor, fazendo com que o valor para quitação tenha deságio total, atualizando o saldo devedor a 
              valor presente, ou proporcional no caso de liquidação parcial, hipótese em que o saldo devedor deverá ser 
              recalculado e informado ao Poder Público através do sistema informatizado de gestão e controle de 
              empréstimos consignados.”</b></p>}
        </div>
      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
        <div style={styles.text}>
          <p>(vii) Que a omissão ou tolerância do Credor quanto ao exercício de qualquer direito,
          poder ou privilégio conferido em normas ou nesta CCB não constituirá novação, desistência ou
          renúncia, nem afetará os seus direitos que poderão ser exercidos a qualquer tempo.</p>
          <p>(viii) Que todas as notificações relacionadas a esta CCB serão realizadas por meio eletrônico,
          enviadas para o endereço eletrônico e/ou números de celulares informados por mim no 
          preâmbulo, ou, ainda por meio de correspondência enviada com aviso de recebimento.</p>
          <p>(ix) aos gestores de bancos de dados de que trata a Lei n̊ 12.414/2011, disponibilizarem ao 
          Credor e ao {REACT_APP_NAME} meus históricos de crédito, os quais abrangerão os dados financeiros 
          e de pagamentos relativos às operações de crédito e obrigações de pagamento adimplidas em 
          seus respectivos vencimentos e aquelas a vencer, constantes dos bancos de dados, com a 
          finalidade única e exclusiva de subsidiar a análise e a eventual concessão de crédito, a venda a 
          prazo ou outras transações comerciais e empresariais que impliquem risco financeiro, pelo prazo 
          estabelecido pelas normas vigentes e declaro-me ciente de que poderei revogar, a qualquer 
          tempo, a autorização contida no item anterior perante o gestor do banco de dados.</p>
          <p>(x) Que qualquer dúvida ou reclamação deverá ser tratada exclusivamente nos canais de 
          atendimento do {REACT_APP_NAME}, previsto no rodapé da CCB.</p>
          <p>(xi) Que a {REACT_APP_NAME}, na qualidade de correspondente bancário do Credor, poderá entrar em 
          contato comigo e com minha Fonte Pagadora, assim como solicitar e receber meus documentos, 
          visando a análise e aprovação do crédito;</p>
          <p>(xii) Que a {REACT_APP_NAME} poderá antecipar o valor do crédito, e também enviar cobranças e 
          boletos caso haja algum problema com minha margem consignável.</p>
          <p>(xiii) Que nem o Credor, nem a {REACT_APP_NAME}, solicitam pagamentos para aprovação e/ou 
          liberação de benefícios vinculados ao Cartão {REACT_APP_NAME}. Também não solicitam devoluções 
          ou reembolso de despesas, estornos ou transferências de crédito decorrentes desta CCB em 
          conta de terceiros, nem autoriza que seus intermediários solicitem em seu nome, a qualquer 
          título.</p>
          <p>11. <b><u>Consulta SCR:</u></b> Estou ciente e de acordo que o Credor e a {REACT_APP_NAME}, nos termos da 
          legislação do Banco Central do Brasil (“BCB”) e do Conselho Monetário Nacional (“CMN) 
          vigentes, consultem e registrem informações, decorrentes de operações de crédito de sua 
          responsabilidade junto ao Sistema de Informações de Crédito (“SCR”), para fins de supervisão 
          do risco de crédito e intercâmbio de informações com outras instituições.</p>
          <p>11.1. Tenho conhecimento de que: (a) o SCR tem por finalidade prover informações ao BCB, 
          para fins de monitoramento do crédito no sistema financeiro, para o exercício de suas atividades 
          de fiscalização, e visando propiciar o intercâmbio de informações entre instituições financeiras, 
          conforme § 1º do artigo 1º da Lei Complementar nº 105/2001, sobre o montante de 
          responsabilidades de clientes em operações de crédito; (b) os dados das minhas operações de crédito serão registrados 
          pelo Credor no SCR; (iii) são de exclusiva responsabilidade das 
          instituições remetentes as inclusões de informações no SCR, as correções e exclusões de 
          informações constantes do SCR, a identificação de operações de crédito que se encontrem “sub judice”, o cumprimento de determinações 
          judiciais, o fornecimento de informações sobre essas</p>
        </div>
      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
        <div style={styles.text}>
          <p>determinações e o registro de manifestações de discordância apresentadas pelos contratantes, 
          bem como de outras condições e anotações necessárias a garantir a completude, a fidedignidade 
          e a integridade da informação sobre operações de crédito, e (iv) o procedimento a ser adotado 
          por mim junto ao Credor para correção ou exclusão de informações remetidas pelo Credor ao 
          SCR, o cadastramento de medida judicial e o registro de manifestação de discordância quanto 
          às informações remetidas pelo Credor ao SCR deverá ser verificado por mim junto aos Canais 
          de Atendimento do {REACT_APP_NAME}.</p>
          <p>12. <b><u>Declarações:</u></b> Declaro:</p>
          <p>(i) Que verifiquei que a operação contratada é adequada às minhas necessidades, bem como 
          que possuo condições econômico – financeiras para pagar as obrigações assumidas sem 
          comprometer meu sustento e o de meus dependentes.</p>
          <p>(ii) E assumo o compromisso de respeitar a legislação anticorrupção, atualmente disciplinadas 
          na lei nº 12.846/2013 e no Decreto nº 8.240/2015, sem limitações e informar, imediatamente, ao 
          Credor e ao {REACT_APP_NAME}, qualquer violação e/ou possível descumprimento das obrigações 
          decorrentes destas normas, cabendo ressarcir e indenizar o Credor e o {REACT_APP_NAME}, por 
          qualquer prejuízo que este possa vir a sofrer em razão do descumprimento das normas referidas 
          nesta cláusula.</p>
          <p>(iii) Que os recursos decorrentes desta CCB não serão destinados a atividades ilícitas ou que 
          possam causar danos sociais e/ou ambientais e a projetos que estejam em desacordo com a 
          Política Nacional de Meio ambiente prevista em norma.</p>
          <p>(iv) Que são verdadeiras as informações prestadas por mim sobre a licitude da origem de minha 
          renda, faturamento e patrimônio, e estou ciente do artigo 11, inciso II da lei nª 9.613/1998, com 
          as alterações introduzidas, inclusive pela Lei nº 12.686/2012 (dever das instituições financeiras 
          de comunicação ao Coaf de operações e propostas de operações suspeitas), e dos artigos 297, 
          298 e 299 do Código Penal.</p>
          <p>(v) E admito como válidos e aceitos como meio de comprovação de autoria e de integridade de 
          documentos em forma eletrônica, os métodos de identificação cuja utilização tenha sido 
          solicitada pelo Credor ou pelo {REACT_APP_NAME}, como, por exemplo, certificados emitidos ou não 
          pela ICP-Brasil, senha eletrônica, código de autenticação emitido por dispositivo pessoal ou 
          identificação biométrica;</p>
          <p>(vi) Que manterei as informações constantes do Cadastro atualizadas até a extinção desta CCB, 
          e notificarei imediatamente o Credor ou {REACT_APP_NAME} de qualquer alteração nessas 
          informações.</p>
          <p>(vii) Que renuncio expressamente à faculdade de realizar depósitos, identificados ou não, na 
          conta do Credor ou do {REACT_APP_NAME}, sem que estes tenham expressamente autorizado essa 
          forma de pagamento. Qualquer depósito feito em desacordo com este item não constituirá 
          quitação e, caso identificado, será mim devolvido.</p>
        </div>
      {Footer()}
    </div>

    <div style={styles.paper_m}>
      {Header()}
        <div style={styles.text}>
          <p>(viii) Que tenho conhecimento que esta CCB é título executivo extrajudicial e representa dívida '+
          em dinheiro líquida, certa e exigível, seja pela soma nela indicada, seja pelo saldo devedor '+
          demonstrado em planilha de cálculo emitida conforme previsto emr lei. E que esta CCB é '+
          negociável, e a via entregue para mim neste ato é a via “NÃO NEGOCIÁVEL”</p>
          <p>(ix) Que, para fins de notificação, citação ou intimação, nos termos dos artigos 190, 246, inciso '+
          V e 513, §2º, inciso III do Código de Processo Civil, seja utilizado o meu endereço eletrônico '+
          indicado no preâmbulo e no Cadastro.</p>
          <p>(x) Estou obrigado por mim meus sucessores a qualquer título ao fiel cumprimento desta CCB, '+
          optando pelo Foro da Comarca do local de emissão desta CCB ou de meu domicílio para '+
          eventual discussão sobre as condições ajustadas.</p>
          <p>(xi) Li previamente esta CCB e não tenho dúvida quanto ao seu conteúdo e nem das '+
          autorizações concedidas.</p>
          <p>Local e data: São Paulo, {FormatData(Proposta.DataCadastro)}</p>
          <p>Emitente: (documento assinado eletronicamente conforme disposto na cláusula 12, inciso “v”
          desta CCB e folha de assinaturas)</p>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      {Footer()}
    </div>
    {CodigoEmpregador == Estado.Paraiba && (
      <div style={styles.paper_m}>
        {Header()}
        
        <div style={styles.text}>
        <p style={{textAlign: 'center'}}><b>Estado da Paraíba</b><br/>
        <b>Declaração do Emitente/Servidor Público</b></p>

        <p>Em cumprimento às condições estabelecidas nas normas estaduais do Estado da Paraíba, 
          como Emitente da CCB e servidor público do Estado da Paraíba, declaro que os recursos 
          disponibilizados na minha conta corrente serão utilizados para aquisição de:</p>
        <p>(i)	Equipamentos de informática ou eletroeletrônicos, ou;</p>
        <p>(ii)	Se policiais militar ou civil, conforme solicitação de compra apresentada, 
          para aquisição de armamento de uso pessoal, munição, equipamentos de proteção individual 
          e colete balístico.</p> 
        <p>Assumo a obrigação de em 48 (quarenta e oito) horas 
          a contar da solicitação da Lecca Crédito, Financiamento e Investimento S.A., 
          da {REACT_APP_NAME} Serviços Tecnológicos e Financeiros Ltda e/ou outro credor da CCB, 
          apresentar os comprovantes de utilização dos recursos desembolsados em razão da CCB, 
          ora emitida, conforme indicado no item (i) ou (ii) conforme o caso. 
          Tenho ciência e concordo que a falta de apresentação dos comprovantes de 
          utilização dos recursos da CCB na forma determinada pelas normas estaduais 
          do Estado da Paraíba poderá acarretar medidas por parte do Credor e do Estado da Paraíba, 
          inclusive, mas não somente, no vencimento antecipado da CCB. 
          Esta Declaração faz parte integrante e indissociável da CCB como se nela estivesse transcrita.</p>
        <br/>
        <br/>
        <br/>
        <p>Assinatura eletrônica do servidor público/Emitente.</p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        </div>        
      {Footer()}
      </div>
    )}
    {CodigoEmpregador == Estado.Paraiba_associacao && (
      <div style={styles.paper_m}>
        {Header()}
        
        <div style={styles.text}>
        <p style={{textAlign: 'center'}}><b>Autorização de Averbação de Mensalidade de Associado da CEBB Caixa</b><br/>
        <b>Econômica Beneficente do Brasil – CNPJ nº 29.871.099/0001-89</b></p>

        <p>Autorizo o Governo do Estado da Paraíba que realize a consignação/desconto 
          em minha folha de pagamento, sujeito aos reajustes legais, a consignação mensal 
          no valor de R$ 25,00 (vinte e cinco reais), referente à mensalidade associativa 
          da CEBB Caixa Econômica Beneficente do Brasil, associação inscrita no CNPJ 
          nº 29.871.099/0001-89, com sede na Rua Guedes Pereira, nº 55, salas 04 e 06, 
          Centro, João Pessoa – PB – CEP: 58.010-810 (“CEBB”).</p>

        <p>Declaro, para todos os fins e efeitos de direito, ter pleno conhecimento 
          das condições para associação junto à CEBB, as quais tomei ciência e estou de 
          pleno acordo. Declaro, Empregaainda, que tenho ciência de que a realização da operação 
          representada por esta CCB (“Operação”) caracteriza benefício concedido aos 
          associados da CEBB, portanto, o cancelamento da minha inscrição como associado 
          da CEBB durante o prazo da Operação só é possível mediante a liquidação 
          integral de todas as parcelas da CCB.</p>

        <p>Estou ciente e concordo que a incidência da mensalidade associativa é de 
          responsabilidade da CEBB, à qual me associei, de livre e espontânea vontade, 
          na qualidade de servidor público estadual do órgão empregador acima indicado. 
          Tenho ciência que qualquer reclamação, sugestão ou dúvida devo realizar no 
          endereço da CEBB indicado acima.</p>
        
        <p>Autorizo, ainda, o tratamento de meus dados pessoais, nos 
          termos da Lei nº 13.709/2018, para fins de averbação e 
          processamento do desconto da mensalidade associativa.</p>
        <br/>
        </div>        
      {Footer()}
      </div>
    )}
    {CodigoEmpregador == 46 && (
      <div style={styles.paper_m}>
        {Header()}
        
        <div style={styles.text}>
        <p><b style={{textAlign: 'center'}}>Declaração do Emitente/Servidor Público - Teresina-PI</b></p>

        <p>Em virtude das condições estabelecidas na Lei Estadual nº 7.624, de 27 de outubro de 
          2021, que dispõe sobre o procedimento de segurança na contratação de crédito direto ou 
          consignado, para idosos, pensionistas e aposentados, declaro não possuir acompanhante 
          no ato desta contratação.</p>
        <p>Declaro ter ciência quanto às disposições da Lei Estadual nº 7.624/2021.
          Esta Declaração faz parte integrante e indissociável da CCB como se nela estivesse transcrita.</p>
        <br/>
        <br/>
        <br/>
        <p>Assinatura eletrônica do servidor público/Emitente.</p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        </div>        
      {Footer()}
      </div>
    )}

{CodigoEmpregador == Estado.Piaui && (
      <div style={styles.paper_m}>
        {Header()}
        
        <div style={styles.text}>
        <p><b style={{textAlign: 'center'}}>Declaração do Emitente/Servidor Público – Estado do Piauí</b></p>

        <p>Em virtude das condições estabelecidas na Lei Estadual nº 7.624, de 27 de outubro de 
          2021, que dispõe sobre o procedimento de segurança na contratação de crédito direto ou 
          consignado, para idosos, pensionistas e aposentados, declaro não possuir acompanhante 
          no ato desta contratação.</p>
        <p>Declaro ter ciência quanto às disposições da Lei Estadual nº 7.624/2021.
          Esta Declaração faz parte integrante e indissociável da CCB como se nela estivesse transcrita.</p>
        <br/>
        <br/>
        <br/>
        <p>Assinatura eletrônica do servidor público/Emitente.</p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        </div>        
      {Footer()}
      </div>
    )}

    {(CodigoEmpregador >= 6 && CodigoEmpregador < 12) && (
    <div style={styles.paper_m}>
      {Header(false)}
      <div style={{padding: 5, margin: '20px 0px'}}>
      <Table>
      <thead>
      <tr>
        <td><img src={logoMinas} width={70} height={70} alt='logo-minas'/></td>
        <td><b>GOVERNO DO ESTADO DE MINAS</b></td>
        <td colSpan={2}><b>AUTORIZAÇÃO DE RESERVA DE MARGEM CONSIGNÁVEL</b></td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colSpan={4} className='titleID'><b>I - IDENTIFICAÇÃO DO (A) INSTITUIDOR (A) DA PENSÃO DO IPSEMG</b></td>
      </tr>
      <tr>
        <td colSpan={2}><b>01 - NOME:</b> </td>
        <td><b>02 - Nº PROCESSADO:</b> </td>
        <td><b>03 - CPF:</b> </td>
      </tr>
      <tr>
        <td colSpan={4} className='titleID'><b>II - IDENTIFICAÇÃO DO CONSIGNADO</b></td>
      </tr>
      <tr>
        <td colSpan={2}><b>04 - NOME:</b> {Cliente.DadosCliente.NomeCliente}</td>
        <td><b>05 - MATRÍCULA:</b> {Cliente.CodigoCliente}</td>
        <td><b>06 - CPF:</b> {Cliente.DadosCliente.CPF}</td>
      </tr>
      <tr>
        <td colSpan={2}><p><b>07 - CONSIGNADO:</b></p>
        <DisplayCheck type={1}>
        <div><InputFixed /> Servidor Ativo/Inativo</div>
        <div><InputFixed /> Persionista IPSEMG / IPSM</div>
        <div><InputFixed /> Bolsista (Lei 15.790/2005)</div>
        <div><InputFixed /> Pessoal Contratado (Lei 18.185/2009)</div>
        </DisplayCheck>
        </td>
        <td colSpan={2}><p><b>08 - CONSIGNANTE:</b></p>
        <DisplayCheck type={2}>
        <div><InputFixed checked={CodigoEmpregador == 6}/> SEPLAG</div>
        <div><InputFixed checked={CodigoEmpregador == 10}/> IPSEMG</div>
        <div><InputFixed checked={CodigoEmpregador == 11}/> IPSM</div>
        <div><InputFixed checked={CodigoEmpregador == 7}/> CBMMG</div>
        <div><InputFixed checked={CodigoEmpregador == 8}/> PMMG</div>
        <div><InputFixed checked={CodigoEmpregador == 9}/> DPMG</div>
        </DisplayCheck>
        </td>
      </tr>
      <tr>
        <td colSpan={2}><b>09 - LOGRADOURO:</b> {endr?.Logradouro}</td>
        <td><b>10 - Nº/COMPLEM:</b> {endr?.Numero}/{endr?.Complemento}</td>
        <td><b>11 - CEP:</b> {endr?.CEP}</td>
      </tr>
      <tr>
        <td><b>12 - BAIRRO/DISTRITO:</b> {endr?.Bairro}</td>
        <td><b>13 - MUNICÍPIO:</b> {endr?.Cidade}</td>
        <td><b>14 - UF:</b> {endr?.UF}</td>
        <td><b>15 - TEL(DDD):</b> {telstr}</td>
      </tr>
      <tr>
        <td colSpan={4} className='titleID'><b>III - IDENTIFICAÇÃO DO CONSIGNATÁRIO</b></td>
      </tr>
      <tr>
        <td colSpan={2}><b>16 - SIGLA:</b> Lecca Crédito, Financiamento e Investimento S.A.</td>
        <td><b>17 - CNPJ:</b> 29.871.099/0001-89</td>
        <td><b>18 - CÓD. CREDENCTO. SEPLAG:</b> 228</td>
      </tr>
      <tr>
        <td><b>19 - LOGRADOURO:</b> São José</td>
        <td><b>20 - Nº / COMPLEM.:</b> Nº 20, sala 201</td>
        <td><b>21 - CEP:</b> 20.010-020</td>
      </tr>
      <tr>
        <td><b>22 - BAIRRO/DISTRITO:</b> Centro</td>
        <td><b>23 - MUNICÍPIO:</b> Rio de Janeiro</td>
        <td><b>24 - UF:</b> RJ</td>
        <td><b>25 - TEL.(DDD):</b> (21) 3184-8500/ (21) 3184-8547</td>
      </tr>
      <tr>
        <td colSpan={4}><b>26 - Pelo  presente  autorizo  o  Governo  do  Estado  de  Minas  Gerais / Consignante, 
          a  reservar  até  10% (dez  por  cento)  da  margem consignável para quitação de despesas 
          contraídas por meio de cartão benefício, a favor do (a):</b> 
          <p>Lecca Crédito, Financiamento e Investimento S.A</p>
          <hr style={{marginTop: -20}}/>
          <p style={{textAlign: 'center'}}><b>Consignatário (Razão Social/Sigla)</b></p>
          </td>
      </tr>
      <tr>
        <td><b>27 - LOCAL:</b>  São Paulo</td>
        <td><b>28 - DATA:</b> {FormatData(Proposta.DataCadastro)}</td>
        <td colSpan={2}><b>29 - ASSINATURA CONSIGNADO:</b></td>
      </tr>
      <tr>
        <td colSpan={2} className='titleID'><p><b>V - RECONHECIMENTO DE FIRMA OU VALIDAÇÃO DOS DADOS P/ SETOR DE RH. (Do Consignado)</b></p>
        <br/>
        <br/>
        <br/>
        <br/>
        </td>
        <td colSpan={2} className='titleID'><p><b>VI - ABONO DA ASSINATURA (Do Consignado) Obrigatório, independente da condição do item V.</b></p>
        <br/>
        <br/>
        <br/>
        <br/>
        </td>
      </tr>
      <tr>
        <td colSpan={2}><b>30 - RESPONSÁVEL P/ SETOR DE RH (Registrar Nome Completo, CPF e Assinatura)</b></td>
        <td colSpan={2}><b>31 – RESPONSÁVEL P/ CONSIGNATÁRIO (Registrar Nome Completo, CPF e Assinatura)</b></td>
      </tr>
      <tr>
        <td><p>32 - LOCAL:</p></td>
        <td><p>33 - DATA:</p></td>
        <td><p>34 - LOCAL:</p></td>
        <td><p>35 - DATA:</p></td>
      </tr>
      <tr>
        <td colSpan={2}>CÓD. {Proposta.NumeroContrato}</td>
        <td>1ª VIA: CONSIGNATÁRIO</td>
        <td>2ª VIA: CONSIGNADO</td>
      </tr>
      </tbody>
    </Table>
    </div>
    {Footer()}
    </div>
    )}
    <div style={styles.paper_m}>
    {Header()}
      <div style={styles.text}>
        <br/>
        <p style={{textAlign: 'center'}}><b>Termo de Aceite do Seguro Prestamista BS2</b></p>
        <p><u>Válido somente para clientes com opção do Seguro prestamista</u></p>
        <p>Pelo presente Termo de Aceitação do Seguro Prestamista BS2, declaro que concordei e solicitei a contratação do Seguro Prestamista BS2, recebi, li e compreendi todas as informações referentes ao seguro contratado, incluindo as condições gerais e específicas do produto.</p>
        <p>Estou ciente do meu direito de arrependimento, que poderá ser exercido no prazo de 7 dias corridos.</p>
        <p>Também afirmo que todas as informações prestadas são verdadeiras, completas e corretas, assumindo total responsabilidade por qualquer omissão ou inexatidão que possa afetar a aceitação do risco ou o pagamento das indenizações decorrentes do seguro contratado.</p>
        <p>Autorizo a cobrança do valor relativo ao Seguro Prestamista BS2 na fatura do Cartão {REACT_APP_NAME} para liquidação total do valor devido em razão da contratação do Seguro Prestamista BS2.</p>
        <p>Assim, aceito formalmente as condições estabelecidas e firmo o presente termo de aceite de contratação e aceite de seguro.</p>
        <p>Por fim, autorizo, aceito e reconheço como válida a assinatura eletrônica aposta neste Termo de Aceitação do Seguro
          Prestamista BS2 nos termos da Medida Provisória nº 2.220-2, de 24 de agosto de 2001, como meio de comprovação de
          autoria e integridade de documentos em forma eletrônica, sendo suficiente para a veracidade, autenticidade,
          integridade, validade e eficácia deste Termo.
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p style={{textAlign: 'center'}}><b>Central de Atendimento:  (11) 2365-5240</b></p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <p style={{textAlign: 'center', fontSize: '9px'}}><b>BS2 SEGUROS S.A. | Código SUSEP 0379-4</b></p>
        <p style={{textAlign: 'center', fontSize: '9px'}}><b>CNPJ nº 29.871.099/0001-89</b></p>
        <p style={{textAlign: 'center', fontSize: '9px'}}></p>
      </div>
      {Footer()}
    </div>
    </div>
    
  );
}

export default CCBGenRefin