import axios from 'axios';

const webs = process.env.REACT_APP_SERVER
//Verificar se o upload de arquivo tinha tratativa para caso o Id da jornada fosse 0
//Garantir o envio mesmo sem o idJornada

const UploadFile = async (name: string, arquivo: string, proposta: number) => {
  if (!arquivo || !name) return false;
  let b64 = arquivo.substring(arquivo.indexOf(',')+1);
  //Envia para a console
  console.log(`Enviando arquivo ${name} para o servidor`)
  const resp = await axios.post(`${webs}/proposta/upload-arquivo`,{
    nome_arquivo: name,
    base64: b64,
    proposta: proposta
  })
  return (resp.data == 'OK' || resp.data.includes('ja existe'))
}

export const ClearEtapas = async (id: string) : Promise<void> => {
  try{ 
    await axios.get(`${webs}/proposta/clean-etapas`, {
      params: { 
        id_token: id
      }})
  }catch(e: any){
  } 
}

const Upload = async(name: string, arquivo: string, proposta: number) : Promise<boolean> => {
  const id = name.substring(0, name.indexOf('_'));
  if(name.includes("prova_vida")){
    name = `${id}_prova_vida_original.mp4`
  }
  const ret = await UploadFile(name, arquivo, proposta);
  return ret;
}

export default Upload;