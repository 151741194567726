import { Box, Divider, Drawer, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from "@mui/material";

import { IDrawerOption, useDrawerContext } from "../context/MenuContext";
import { User } from "../../ts/interfaces/Manager.interfaces";
import { getPerfilStr } from "../manager-pages/constants";

type Props = {
	usuario: User,
  children: JSX.Element,
	options: IDrawerOption[]
}

type ButtonProps = {
	iconName: string,
	buttonName: string,
	toggleDrawer: (() => void) | undefined
	onClick?: () => void
}

const ListButton : React.FC<ButtonProps> = ({ iconName, buttonName, onClick, toggleDrawer }) => {
	const handleClick = () => {
		toggleDrawer?.();
		onClick?.();
	}

	return (
	<ListItem disablePadding>
		<ListItemButton onClick={handleClick}>
			<ListItemIcon>
				<Icon>{iconName}</Icon>
			</ListItemIcon>
			<ListItemText primary={buttonName} />
		</ListItemButton>
	</ListItem>
	)
}

export const SideMenu : React.FC<Props> = ({ children, usuario, options }) => {
	const theme = useTheme();
	const smDawn = useMediaQuery(theme.breakpoints.down('sm'));//breakpoint para telas mobile
	const { isDrawerOpen, toggleDrawerOpen } = useDrawerContext();
	const { login, perfil } = usuario;

	const toggleDrawer = smDawn? toggleDrawerOpen: undefined;

	return(
	<>
		<Drawer open={isDrawerOpen} onClose={toggleDrawerOpen} variant={smDawn? "temporary" : "permanent"}>
		 <Box style={{padding: '10px'}}>  
		   <p><b>Usuario</b>: {login}</p>
		   <p><b>Perfil</b>: {getPerfilStr(perfil)}</p>
		  </Box>
		<Divider/>
		<Box flex={1} sx={{maxWidth: theme.spacing(32)}}>
			<List component="nav">
				{options.map(option => 
					<ListButton
						key={option.iconName}
						iconName={option.iconName}
						buttonName={option.buttonName}
						onClick={option.onClick}
						toggleDrawer={smDawn? toggleDrawer: undefined}
					/>
				)}
			</List>
		</Box>
		</Drawer>
		<Box height="100vh" marginLeft={smDawn? 0 : theme.spacing(32)}>
		  {children}
		</Box>
		</>
)
}

export default SideMenu;