import etapa1 from '../../images/etapa1.png'
import etapa2 from '../../images/etapa2.png'
import etapa3 from '../../images/etapa3.png'
import etapa4 from '../../images/etapa4.png'
import etapa5 from '../../images/etapa5.png'
import etapa6 from '../../images/etapa6.png'
import etapa7 from '../../images/etapa7.png'

export const etapas = [etapa1, etapa2, etapa3, etapa4, etapa5, etapa6, etapa7]

export default etapas