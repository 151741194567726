import { Table, TableBody, TableContainer, TableRow, TableCell, useTheme, Button, Icon, TableHead } from "@mui/material"
import { PropostaData } from "../../../ts/interfaces/Manager.interfaces"
import { getStatusDocumento } from "../constants"
import { Anexo } from "../../../ts/types/Types"

const source = process.env.REACT_APP_SOURCE_FILES

interface IDetalhe {
  proposta: PropostaData,
  back: () => void
}

const FilterDocs = (id: string, docs: Anexo[]) => {
  if(!docs.some((anexo) => anexo.nome.includes("photo_face"))) {
    docs.push({
      nome: `${id}_photo_face.png`,
      tipo: `Selfie`,
      status: -1
    })
  }
  if(!docs.some((anexo) => anexo.nome.includes("prova_vida"))) {
    docs.push({
      nome: `${id}_prova_vida.mp4`,
      tipo: `Outros`,
      status: -1
    })
  }
  if(docs.some((anexo) => anexo.nome.includes("cnh_verso"))){
    if(!docs.some((anexo) => anexo.nome.includes("cnh_frente"))) {
      docs.push({
        nome: `${id}_cnh_frente.png`,
        tipo: `CNH`,
        status: -1
      })
    }
  } else {
    if(!docs.some((anexo) => anexo.nome.includes("cnh_frente"))) {
      docs.push({
        nome: `${id}_cnh_frente.png`,
        tipo: `CNH`,
        status: -1
      })
    }
  }
  if(!docs.some((anexo) => anexo.nome.includes("comprovante"))) {
    docs.push({
      nome: `${id}_comprovante.png`,
      tipo: `Comprovante_Endereco`,
      status: -1
    })
  }
  if(!docs.some((anexo) => anexo.nome.includes("_contracheque"))) {
    docs.push({
      nome: `${id}_contracheque.png`,
      tipo: `Outros`,
      status: -1
    })
  }
  return docs;
}

export const DetalheDocumentos = ({ proposta, back } : IDetalhe) => {
  const theme = useTheme();
  const docs = FilterDocs(proposta.id_token, proposta.documentos);
  
  return(
  <>
  <Button sx={{color: "#000"}} onClick={()=>back()}><Icon>arrow_back_ios</Icon></Button>
  <TableContainer>
    <Table aria-label='tabela-documentos'>
      <TableHead>
        <TableRow>
          <TableCell><b>Documento</b></TableCell>
          <TableCell><b>Status</b></TableCell>
          <TableCell><b>Download</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {docs.map((doc, index) => (
          <TableRow key={index}>
            <TableCell>{doc.nome}</TableCell>
            <TableCell>{getStatusDocumento(doc.status)}</TableCell>
            <TableCell>{doc.status == -1? <Button style={{marginLeft: theme.spacing(4)}} sx={{color: "#696969"}}><Icon>file_download_off</Icon></Button> : <Button style={{marginLeft: theme.spacing(4)}} href={`${source}/${doc.nome}`} target="_blank" download><Icon>download</Icon></Button>}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  </>
  )
}