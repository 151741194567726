import FadeIn from 'react-fade-in';
import { Button, Card, CardText, CardTitle } from 'reactstrap';
import { Banner, BoxInfo } from '../../telas/styles/estilo_telas'
import { getBanner } from './BannerScreen.component'

interface PropApresentacao {
  id: string,
  onClick : () => void
}

const {REACT_APP_PERFIL} = process.env


export const Apresentacao = ({id, onClick} : PropApresentacao) => 
    {
      const ban = getBanner(id);
      return(<FadeIn delay={50}>
        <BoxInfo>
          <Card
            body
            className="text-center"
          >
            <CardTitle tag="h5">
              {ban.title}
            </CardTitle>
            <CardText>
              {ban.text}
            </CardText>
          </Card>
          
          <Banner alt={id} src={ban.src} />
          
          <div>{ban.obs}</div>
          <Button block={true} id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} onClick={onClick}> Seguir </Button>
        </BoxInfo>
      </FadeIn>)
    };

export default Apresentacao