import React, {Component} from 'react'
import {Form, FormGroup, Label, Input, InputGroup} from 'reactstrap';
import InputMask from 'react-input-mask';
import {DateButton, Calendar} from './styles/form-login-style';

import "./styles/react-date-picker.css";

const {REACT_APP_BUTTON_COLOR, REACT_APP_PERFIL} = process.env

const buttonColor = REACT_APP_BUTTON_COLOR
const perfil = REACT_APP_PERFIL

interface IForm {
  handleSubmit: (cpf: string, datanascimento: string) => void
}

interface State {
  datanascimento: string,
  cpf: string,
  showcalendar: boolean
}

export default class FormLogin extends Component<IForm, State> {
  constructor(props : IForm) {
    super(props);
    this.onChangeCPF = this.onChangeCPF.bind(this)
    this.parseData = this.parseData.bind(this)
    this.onChangeDataNascimento = this.onChangeDataNascimento.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChangeDatePicker = this.onChangeDatePicker.bind(this);

    this.state = {
      datanascimento: '',
      cpf: '',
      showcalendar: false
    }
  }

  onChangeCPF(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({
      cpf: e.target.value
    })
  }

  onChangeDataNascimento(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({
      datanascimento: e.target.value
    })
  }

  parseData(data : string){
    let datarr = data.split('/');
    if(datarr.length > 1){
      return datarr[2]+'-'+datarr[1]+'-'+datarr[0];
    }
    return '';
  }

  onSubmit = (e : React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    const { cpf, datanascimento } = this.state;
    this.props.handleSubmit(cpf, this.parseData(datanascimento))

    this.setState({cpf: '', datanascimento: ''})
  }

  onChangeDatePicker = (date : Date) => {
    var day = parseInt(date.getDate().toString());
    var month = parseInt(date.getMonth().toString());
    var year = date.getFullYear().toString();

    this.setState({datanascimento: `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`});
  }

  render() {
    const {cpf, datanascimento, showcalendar} = this.state;
    return(
      <Form onSubmit={this.onSubmit}>
        <FormGroup>
          <Label>
            CPF
          </Label>
          <Input 
            id="cpf"
            name="cpf"
            placeholder="CPF"
            type="text"
            onChange={this.onChangeCPF}
            value={cpf}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Data de Nascimento
          </Label>
          <InputGroup>
          <Input 
            id="datanascimento"
            name="datanascimento"
            mask='99/99/9999'
            placeholder="Data de Nascimento"
            type="text"
            onChange={this.onChangeDataNascimento}
            value={datanascimento}
            tag={InputMask}
          />
          
          <DateButton onClick={()=> this.setState({showcalendar: showcalendar? false: true})} />
          </InputGroup>
          <Calendar onChange={this.onChangeDatePicker} show={showcalendar}/>
        </FormGroup>
        <Input type='submit' style={{marginTop:'15px', backgroundColor: `${buttonColor}`, color: '#FFF'}} value='Logar' />
      </Form>
      );
  }

}