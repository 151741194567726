import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { infoBPOreducer, infoReducer, JornadaReducer, UserReducer } from "./reducers";
import { persistStore, persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage';
import thunk from 'redux-thunk';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	whitelist: ["infoBPO", "info", "Jornada"],
	blacklist: ["userSession"]
}

const reducer = combineReducers({
  InfoBPO: infoBPOreducer,
  Info: infoReducer,
  Jornada: JornadaReducer,
  Usuario: UserReducer
})

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
	middleware: [thunk]
})

export type RootState = ReturnType<typeof store.getState>

export const persistor = persistStore(store)
export default store;