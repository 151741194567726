
import { SelectProps, Select, FormControl, InputLabel } from '@mui/material';
import { useField } from '@unform/core';
import React, { useEffect, useState } from 'react';

type InputSelectProps = SelectProps & {
	name: string,
  children: React.ReactNode,
	defaultVal?: number | string
}

export const SelectField : React.FC<InputSelectProps> = ({ name, children, label, defaultVal, ...rest }) => {
	const { fieldName, registerField, defaultValue, error } = useField(name);

	const [value, setValue] = useState(defaultValue || defaultVal != undefined? defaultVal : '');

	useEffect(()=>{
		registerField({
			name : fieldName,
			getValue : () => value,
			setValue: (_, newValue) => setValue(newValue)
		});
	},[registerField, fieldName, value])

	return(
    <FormControl fullWidth>
      <InputLabel id={`label-id-${label}`}>{label}</InputLabel>
		  <Select
		  	{...rest}
        label={label}
        id={`label-id-${label}`}
		  	error={!!error}
		  	defaultValue={defaultValue}
		  	value={value}
		  	onChange={e => setValue(e.target.value)}
		  >
        {children}
      </Select>
    </FormControl>
	)
} 

export default SelectField;