import React from 'react'
import FormCadastro from '../forms/FormCadastro'
import FadeIn from 'react-fade-in';
import { Card, CardTitle } from 'reactstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import AlertError from './alerts/alert_error'
import Header from '../components/header'
import '../App.css';
import { FluxoType } from '../ts/types/Types';
import axios from 'axios';
import ErrorComponent from '../components/Erro'
import { Estado, getEmpregadorStringCadastro } from '../components/doc/empregador_id';

const {REACT_APP_SERVER} = process.env;

const webs = REACT_APP_SERVER

interface State {
  resp: string,
  visible: boolean,
  color: string
}

const isProduction = webs?.toString() == 'http://services.bpoconsig.com.br/bpo-consig-server'

const generateNumeroProposta = () : number => Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000;

export default class Cadastro extends React.Component<any, State> {
  constructor(props : any){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      resp: '',
      visible: false,
      color: 'success'
    }
  }

  handleSubmit = (nome : string, email : string, celular : string, cpf :string, datanascimento : string, estado : number, tipo : number, fluxo?: FluxoType) => {
    const n_proposta = isProduction? 2056 : generateNumeroProposta();

    console.log(`Proposta gerada: ${n_proposta}`)

    axios.post(webs+'/v1/integration/createProposal',{
      Tipo: tipo,
          Contrato:{
            Proposta:{
              NumeroProposta: n_proposta,
              CodigoCliente:1054,
              CodigoProponente:2064,
              TipoPessoa:1,
              CPFCliente:"000.000.003-53",
              NomeCliente: nome,
              Matricula:"1235478",
              DataNascimento:`${datanascimento}T00:00:00`,
              ValorSolicitado:500.0,
              ValorFinanciado:0.0,
              ValorIOF:0.0,
              ValorCompra:0.0,
              ValorRefin:0.0,
              ValorDespesas:0.0,
              ValorComissao:0.0,
              ValorAnuidade:0.0,
              Coeficiente:0.0,
              Prazo:0,
              ValorParcela:500.0,
              ValorBruto:0.0,
              ValorLiquidoCliente:0.0,
              ValorSaqueComplementar:500.0,
              ValorTarifaCartao:0.0,
              ValorLimiteSaqueComplementar:500.0,
              ValorLimiteCompra:0.0,
              TaxaCLMes:3.99,
              TaxaCLAno:59.9186,
              TaxaAPMes:0.0,
              TaxaAPAno:0.0,
              TaxaNominalMes:0.0,
              TaxaNominalAno:0.0,
              TaxaCETMes:0.0,
              TaxaCETAno:0.0,
              CodigoBanco:1,
              NomeBanco:"BPO BANCO",
              CodigoMatriz:1,
              NomeMatriz:"MATRIZ 1",
              CodigoGerente:1,
              NomeGerente:"SUPERVISOR 1",
              CodigoPromotora:1,
              NomePromotora:"PROMOTORA 1",
              CodigoOperador:1,
              NomeOperador:"OPERADOR 1",
              CodigoLoja:1,
              NomeLoja:"PV000001",
              CodigoEmpregador: estado,
              NomeEmpregador: getEmpregadorStringCadastro(estado),
              CodigoOrgao:4,
              NomeOrgao:"ORGAO 1",
              CodigoSindicato:0,
              NomeSindicato:"",
              ValorTC:0.0,
              IDTabelaFinanciamento:18,
              NumeroTabela:"000233",
              NomeTabela:"CARTAO + SAQUE",
              DataPrimeiroVencimento:"2079-02-02T00:00:00",
              DataUltimoVencimento:"2079-02-02T00:00:00",
              NumeroContrato:"88-12345678",
              NumeroOperacaoConsignada:"",
              PropostaCadastradaNoBanco:false,
              CodigoProduto:1,
              CodigoEsteira:1,
              DataBase:"2021-11-09T00:00:00",
              DataCadastro:"2021-11-09T22:20:45.767",
              CodigoUsuario:"2",
              LoginUsuario:null,
              Beneficiario:{
                TipoBeneficiario:2,
                NomeBeneficiario: nome,
                CGCBeneficiario:"000.000.003-53",
                TipoConta:1,
                NumeroBanco:"",
                NumeroAgencia:"",
                NumeroDVAgencia:"",
                NumeroConta:"",
                NumeroDVConta:""
              },
              TipoProposta:1,
              DadosTransfer:null,
              DadosRefin:[
                {
                  NumeroProposta: 0,
                  CodigoCliente: "1234567890",
                  CPF: "000.000.003-53",
                  Matricula: "112345",
                  NumeroContrato: "123456",
                  Saldo: 7232.31,
                  ValorParcela: 250,
                  QuantidadeDeParcelas: 72,
                  QuantidadeParcAbertas: 72,
                  QuantidadeParcVencidas: 8,
                  QuantidadeParcAVencer: 64,
                  ParcelasRefin: [
                    {
                      NumeroContrato: "1234567890",
                      NumeroParcela: 1,
                      ValorParcela: 250,
                      SaldoParcela: 250,
                      DataDeVencimento: "2022-12-15T00:00:00",
                      ValorConfirmado: 0
                    },
                  ]
                }],
              PropostaParcelado:{
                 NumeroProposta:2056,
                 ValorSolicitado:11879.84,
                 ValorFinanciado:12222.42,
                 ValorIOF:342.58,
                 Prazo:96,
                 ValorParcela:500.0,
                 ValorBruto:48000.0,
                 ValorLiquidoCliente:11879.84,
                 TaxaCLMes:3.99,
                 TaxaCLAno:59.9186,
                 TaxaAPMes:3.9358,
                 TaxaAPAno:58.9205,
                 TaxaNominalMes:3.99,
                 TaxaNominalAno:59.9186,
                 TaxaCETMes:4.0598,
                 TaxaCETAno:62.2836,
                 IDTabelaFinanciamento:18,
                 NumeroTabela:"000233",
                 NomeTabela:"CARTAO + SAQUE",
                 DataPrimeiroVencimento:"2021-12-10T00:00:00",
                 DataUltimoVencimento:"2029-11-10T00:00:00"
              }
          }, 
          Cliente:{
             CodigoCliente:1054,
             CodigoHistoricoCliente:0,
             CodigoProponente:0,
             DadosCliente:{
                NomeCliente: nome,
                NomeClienteCartao:"",
                TipoPessoa:1,
                CPF: cpf,
                DataNascimento: datanascimento,
                Registro:{
                   Numero:"",
                   DataEmissao:"2079-02-02T00:00:00",
                   LocalEmissao:"",
                   UFEmissao:""
                },
                CidadeNatal:"DF",
                UFNatal:"DF",
                Sexo:0,
                EstadoCivil:2,
                TipoResidencia:0,
                TempoResidencia:"00",
                NomePai:"",
                NomeMae:"",
                DadosEmpresa:{
                   CNPJ:"",
                   NomeEmpresa:"",
                   DataAdmissao:"2079-02-02T00:00:00",
                   CodigoProfissao:0,
                   DescricaoProfissao:null,
                   Cargo:"",
                   Renda:0.0,
                   OutrasRendas:0.0,
                   NumeroBeneficio:"1235478",
                   IdentificadorMargem:"",
                   MesAno_ContraCheque:"",
                   NaturezaOcupacao:4
                },
                Enderecos:[
                   {
                     TipoEndereco:1, //Residencial
                     CEP:"07050000",
                     Logradouro:"Av Emilio Ribas",
                     Numero:"1",
                     Complemento:"CMPLTO",
                     Bairro:"Jardim Eugenia",
                     Cidade:estado == Estado.Mato_Grosso? "Cuiabá" : "Guarulhos",
                     UF:estado == Estado.Mato_Grosso? "MG": "SP",
                     Latitude:"",
                     Longitude:""
                   },{
                     TipoEndereco:3,
                     CEP:"07050000",
                     Logradouro:"Av Emilio Ribas",
                     Numero:"1",
                     Complemento:"CMPLTO",
                     Bairro:"Jardim Eugenia",
                     Cidade:estado == Estado.Mato_Grosso? "Cuiabá" : "Guarulhos",
                     UF:estado == Estado.Mato_Grosso? "MG": "SP",
                     Latitude:"",
                     Longitude:""
                   }
                ],
                Telefones:[
                   {
                     TipoTelefone:3, //Telefone Celular
                     DDD:celular.substring(0,2),
                     NumeroTelefone:celular.substring(2,celular.length),
                     Operadora:0
                   }
                ],
                DadosCadastro:{
                   DataDeCadastro:"2021-11-09T22:21:00",
                   UsuarioInclusao:"MASTER",
                   DataDeAlteracao:"2021-11-09T22:21:00",
                   UsuarioAlteracao:"MASTER"
                },
                Email: email,
                RamoAtividade:"",
                TipoInstalacao:4,
                Socios:[

                ],
                Faturamento:[

                ],
                PossuiCNH:false
             },
             NomeRef1:"",
             DDDRef1:"",
             FoneRef1:"",
             NomeRef2:"",
             DDDRef2:"",
             FoneRef2:"",
             ReferenciaBancaria:{
                TipoConta:1,
                NumeroBanco:"",
                Agencia:"",
                Conta:"",
                DVConta:""
             },
             DadosConjuge:{
                NomeConjuge:"",
                CPFConjuge:"",
                DataNascimentoConjuge:"2079-02-02T00:00:00",
                RGConjuge:"",
                RendaConjuge:0.0
             }
          },
          Fluxo: fluxo
        }
    }).then((resp)=>{
      let data = resp.data;
      console.log(data);
      if(data.success){
        this.setState({resp: 'Link gerado com sucesso!', visible: true, color: 'success'}, ()=>{
          window.setTimeout(() => {
            this.setState({visible: false})
          }, 2000)
        })
      }else{
        this.setState({resp: 'Erro ao gerar link, tente gerar novamente ou espere um pouco antes de gerar um novo.', visible: true, color: 'danger'}, ()=>{
          window.setTimeout(() => {
            this.setState({visible: false})
          }, 2000)
        })
      }
    }).catch((e)=>{
      this.setState({resp: 'Erro ao gerar link, tente gerar novamente ou espere um pouco antes de gerar um novo.', visible: true, color: 'danger'}, ()=>{
        window.setTimeout(() => {
          this.setState({visible: false})
        }, 2000)
      })
    })
  }

  render(){

    const {resp, visible, color} = this.state

    if(isProduction){
      return(
        <ErrorComponent title='Link Inválido' message='Este caminho é inválido, é necessario ter um token para o acesso da jornada.'/>
      );
    }

    return(
      <div className='cadastro'>
      <BrowserView>
        <AlertError message={resp} visible={visible} color={color} />
        <FadeIn>
          <Header/>
          <Card style={{width: '50%', padding: '20px', margin: '35px auto'}}>
            <CardTitle tag='h5' className='text-center'>Preencha os seus dados</CardTitle>
            <FormCadastro handleSubmit={this.handleSubmit}/>
          </Card>
        </FadeIn>
      
      </BrowserView>
      <MobileView>
        <AlertError message={resp} visible={visible} color={color} />
        <FadeIn>
          <Header/>
          <Card style={{width: '100%', padding: '20px', margin: '35px auto'}}>
            <CardTitle tag='h5' className='text-center'>Preencha os seus dados</CardTitle>
            <FormCadastro handleSubmit={this.handleSubmit}/>
          </Card>
        </FadeIn>
      
      </MobileView>
      </div>
    );
  }
}