export const tela = {
	inicial: 0,
	consulta_proposta: 1,
	consulta_proposta_resultado: 2,
	consulta_proposta_detalhe: 3,
	consulta_docs_detalhe: 4,
	consulta_resumo: 5,
	consulta_logs: 6,
	registrar: 7,
	gerenciar_usuario: 8,
	gerenciar_usuario_atualizar: 9,
	consulta_thread: 11,
	sair: 10
}