import { Button, ButtonProps, styled } from '@mui/material'
import { grey } from '@mui/material/colors'

export const ButtonPropSubmit = styled(Button)<ButtonProps>(({ theme }) => ({
  borderColor: theme.palette.getContrastText(grey[300]),
  color: theme.palette.getContrastText(grey[300]),
  '&:click': {
    borderColor:theme.palette.getContrastText(grey[700]),
    color: theme.palette.getContrastText(grey[700]),
  },
  '&:hover': {
    borderColor:theme.palette.getContrastText(grey[200])
  }
}))