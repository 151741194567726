
import React from 'react'
import './styles/manager.style.css';
import { IPropsRota } from '../ts/interfaces/Props.interfaces';
import SideMenu from './side-menu/SideMenu';
import { DrawerProvider, IDrawerOption } from './context/MenuContext';
import { ButtonMenu } from './side-menu/ButtonMenu';
import { tela } from './manager-pages/tela-indice';
import ConsultaProposta from './manager-pages/consulta-proposta';
import ConsultaResumoDigital from './manager-pages/consulta-resumo';
import { FormBuscaLog, FormLogin, FormConsultaThread, FormRegistro } from './manager-pages/forms';
import { perfil as PerfilOptions } from './manager-pages/constants';
import { ConsultaUsuarios } from './manager-pages/telas-usuario/ConsultaUsuarios';
import { User } from '../ts/interfaces/Manager.interfaces';
import { UserSession } from '../state/data-types';
import { setStateUser, getStateUser, userTimedOut } from '../state/actions/user_actions';

interface State {
  carregar: boolean,
  options: IDrawerOption[],
  usuario: undefined|User,
  screen: number
}

const LoadRoute = (rota: string) : number => {
  switch(rota) {
    case "inicial":
      return tela.inicial
    case "registrar":
      return tela.registrar
    case "consulta-proposta":
      return tela.consulta_proposta
    case "gerencia-usuario":
      return tela.gerenciar_usuario
    case "consulta-resumo":
      return tela.consulta_resumo
  }
  return -1
}

class Manager extends React.Component<IPropsRota, State> {

  constructor(props: IPropsRota){
    super(props);
    this.changeScreen = this.changeScreen.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.updateUser = this.updateUser.bind(this);
  }

  state = {
    carregar: true,
    isLogged: false,
    options: [],
    usuario: undefined,
    screen: tela.inicial
  }

  componentDidMount(){
    document.body.style.backgroundColor = "#FFF";
    let user: UserSession = getStateUser();
    
    if(userTimedOut()){
      this.setState({usuario: undefined})
      setStateUser({usuario: undefined})
    } else if(user.usuario) {
      this.setState({usuario: user.usuario, screen: LoadRoute(this.props.match.params.rota)})
      this.loadOptions(user.usuario.perfil);
    }
  }

  loadOptions(perfil: number){
    let options: IDrawerOption[] = [];
    if(perfil == PerfilOptions.operador){
      options = [
        {
          iconName: 'home',
          buttonName: 'Principal',
          onClick: () => this.changeScreen(tela.inicial)
        },
        {
          iconName: 'description',
          buttonName: 'Consulta Propostas',
          onClick: () => { this.changeScreen(tela.consulta_proposta); }
        },
        {
          iconName: 'logout',
          buttonName: 'Sair',
          onClick: () => { this.setState({usuario: undefined, screen: tela.inicial}); setStateUser({ usuario: undefined }); }
        }
      ]
    } else if(perfil == PerfilOptions.operador_ti) {
      options = [
        {
          iconName: 'home',
          buttonName: 'Principal',
          onClick: () => this.changeScreen(tela.inicial)
        },
        {
          iconName: 'description',
          buttonName: 'Consulta Propostas',
          onClick: () => { this.changeScreen(tela.consulta_proposta); }
        },
        {
          iconName: 'history',
          buttonName: 'Consultar Logs',
          onClick: () => this.changeScreen(tela.consulta_logs)
        },
        {
          iconName: 'settings',
          buttonName: 'Configurações Threads',
          onClick: () => this.changeScreen(tela.consulta_thread)
        },
        {
          iconName: 'logout',
          buttonName: 'Sair',
          onClick: () => { this.setState({usuario: undefined, screen: tela.inicial}); setStateUser({ usuario: undefined }); }
        }
      ]
    }else{
      options = [
        {
          iconName: 'home',
          buttonName: 'Principal',
          onClick: () => this.changeScreen(tela.inicial)
        },
        {
          iconName: 'description',
          buttonName: 'Consulta Propostas',
          onClick: () => {this.changeScreen(tela.consulta_proposta); }
        },
        {
          iconName: 'app_registration',
          buttonName: 'Registrar Novo Usuario',
          onClick: () => this.changeScreen(tela.registrar)
        },
        {
          iconName: 'how_to_reg',
          buttonName: 'Gerenciar Usuarios',
          onClick: () => this.changeScreen(tela.gerenciar_usuario)
        },
        {
          iconName: 'history',
          buttonName: 'Consultar Logs',
          onClick: () => this.changeScreen(tela.consulta_logs)
        },
        {
          iconName: 'settings',
          buttonName: 'Configurações Threads',
          onClick: () => this.changeScreen(tela.consulta_thread)
        },
        {
          iconName: 'logout',
          buttonName: 'Sair',
          onClick: () => { this.setState({usuario: undefined, screen: tela.inicial}); setStateUser({ usuario: undefined }); }
        }
      ]
    }
    this.setState({ options })
  }

  updateUser(usuario: User){
    this.setState({ usuario })
    setStateUser({ usuario, timeout: new Date()});
  }

  changeScreen(screen: number) {
    this.setState({ screen });
  }

  render() {
    const { options, screen, usuario } = this.state;

    if(!usuario){
      return(<FormLogin loadOptions={this.loadOptions} setUser={this.updateUser}/>);
    }

    return (
    <DrawerProvider>
      <SideMenu options={options} usuario={usuario}>
        <div>
          <ButtonMenu />
          {screen == tela.inicial && <ConsultaResumoDigital />}
          {screen == tela.registrar && <FormRegistro />}
          {screen >= tela.gerenciar_usuario && screen <= tela.gerenciar_usuario_atualizar && <ConsultaUsuarios screen_tela={screen} changeScreen={this.changeScreen}/>}
          {screen >= tela.consulta_proposta && screen <= tela.consulta_docs_detalhe && <ConsultaProposta screen={screen} changeScreen={this.changeScreen} />}
          {screen == tela.consulta_logs && <FormBuscaLog />}
          {screen == tela.consulta_thread && <FormConsultaThread />}
        </div>
      </SideMenu>
    </DrawerProvider>
    );
    }
}

export default Manager;