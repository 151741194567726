import React from 'react'
import ident from '../images/foto_segurando_identidade.png'
import './styles/estilo.css'
import FadeIn from 'react-fade-in';
import { Button, Card, CardText, CardTitle } from 'reactstrap';
import { documento_id, tela_fluxo2 } from '../telas_id';

import { Banner, ResPic } from './styles/estilo_telas2'
import { videocons, ButtonsInline, Command, BoxInfo } from './styles/estilo_telas'
import Camera from '../components/CameraFluxo2'

import Loading from '../components/Loading'
import {ApresentacaoText, TirarFotoText} from './text/segurando_identidade.text'

import { status, situacao } from '../components/Info';

import { PropTelaDoc } from '../ts/interfaces/PropTelas.interfaces';
import { Base64Type, Contrato } from '../ts/types/Types';
import Webcam from 'react-webcam';
import { updateInfoBPO, addInfo } from '../state/actions/Info_actions';

interface State {
  data: Base64Type,
  dataUrl: string | null,
  step: number,
  verso: boolean,
  refazer: boolean
}

const tela = {
  apresentacao: 0,
  tirarfoto: 1
} 

const {REACT_APP_PERFIL} = process.env
export default class SegurandoIdentidade extends React.Component<PropTelaDoc, State> {
  webcam: React.RefObject<Webcam>
  contrato!: Contrato

  constructor(props : PropTelaDoc){
    super(props)
    this.Apresentacao = this.Apresentacao.bind(this)
    this.TirarFoto = this.TirarFoto.bind(this)
    this.Takecapture = this.Takecapture.bind(this)
    this.CameraError = this.CameraError.bind(this)
    this.SeguirFoto = this.SeguirFoto.bind(this)

    this.webcam = React.createRef<Webcam>();

    this.state = {
      data: null,
      dataUrl: null,
      step: tela.apresentacao,
      verso: true,
      refazer: true
    }
  }

  componentDidMount(){
    updateInfoBPO({fase: status.tela_cnh, situacao: situacao.sucesso});
    addInfo(tela_fluxo2.selfie_tela);
  }

  Apresentacao(){

    return(
      <FadeIn>
          <BoxInfo>
            <Card
              body
              className="text-center"
            >
              <CardTitle tag="h5">
                {ApresentacaoText.title1}
              </CardTitle>
              <CardText>
                {ApresentacaoText.text1}
              </CardText>
            </Card>
            <Banner alt='Intro Identidade' src={ident} />
            <p>{ApresentacaoText.text2}
            </p>
            <Button block={true} id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} onClick={()=>{this.setState({step: tela.tirarfoto})}}> Tirar Foto </Button>
          </BoxInfo>
        </FadeIn>
    );
  }

  SeguirFoto = () => {
    const{data} = this.state
    this.props.InsertScreenShot(data, documento_id.selfie_cartao)
    this.ProximoPasso()
  }

  ProximoPasso(){
    updateInfoBPO({fase: status.tela_resumo, situacao: situacao.sucesso});
    this.props.changePasso(tela_fluxo2.tela_final)
  }

  Takecapture = async () => {
    var screenshot = this.webcam?.current?.getScreenshot();
    if(screenshot){
      this.setState({data: screenshot, refazer: false})
    }else{
      this.props.ShowAlert(TirarFotoText.error)
    }
  }

  CameraError = (e: string | DOMException) => {
    this.setState({data: null, refazer: true})
    updateInfoBPO({fase: status.cnh_frente_capturada, situacao: situacao.falha, mensagemErro: `Nao foi possivel acessar a camera:${e}`});
    this.props.ShowAlert(TirarFotoText.error)
  }

  TirarFoto(){
    const {refazer} = this.state;
      return(
        <FadeIn delay={50}>
          <div style={{marginTop: '50px', marginBottom: '100px'}}>
            <Command>
              {refazer? 
              <b>{TirarFotoText.title1}</b>
              :
              <div>
                <p><b>{TirarFotoText.title2}</b></p> 
                <p><b>{TirarFotoText.title3}</b></p>
              </div>  
              }
            </Command>

            {refazer? 
              <Camera webcam={this.webcam} videocons={videocons} onClick={this.Takecapture}/>
              : <ResPic>
                
                <img alt='Pic foto' src={this.state.data as string} />
                
                {this.state.data && (
                  <ButtonsInline>
                    <Button color='info' onClick={()=>this.setState({refazer: true})}> Tirar Novamente </Button>
                    <Button id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple2'} onClick={this.SeguirFoto}> Seguir </Button>
                  </ButtonsInline>
                  )
                  }
                </ResPic>
              }
          </div>
        </FadeIn>
      );

  }

  render(){
    const {step} = this.state;
    const {enviado} = this.props;

    if(!enviado){
      return(
        <div style={{marginTop: 20}}>
          <Loading text={'Carregando...'}/>
        </div>
      );
    }

    switch(step){
      case tela.apresentacao:
        return(this.Apresentacao());
      case tela.tirarfoto:
        return(this.TirarFoto());
      default:
        break;
    }
    return null;
  }
  
}