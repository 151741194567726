
import React from 'react'
import FadeIn from 'react-fade-in';
import { Button, Card, CardBody, CardText, CardTitle, Label } from 'reactstrap';
import './styles/estilo.css'
import { tela_fluxo_refin } from '../telas_id';
//import Loading from '../components/Loading'

import {CardInfo, BoxInfo, CheckedBox, Seguir} from './styles/estilo_telas'

import {ApresentacaoText} from './text/comprovate.text'

import { status, situacao } from '../components/Info';

import { PropTelaRes } from '../ts/interfaces/PropTelas.interfaces';
import { Base64Type } from '../ts/types/Types';
import { addInfo, updateInfoBPO } from '../state/actions/Info_actions';
import RefinInfo from '../components/refin_info.component';

const tela = {
  apresentacao: 0,
  opcao: 1
}

interface State {
  data: Base64Type,
  datab64: Base64Type,
  dataUrl: string | null,
  step: number,
  checklock: boolean,
  unlock: boolean
}
const {REACT_APP_PERFIL} = process.env
export default class Refin extends React.Component<PropTelaRes, State> {
  constructor(props: PropTelaRes){
    super(props)
    this.Aceitar = this.Aceitar.bind(this);
    this.Apresentacao = this.Apresentacao.bind(this)
    this.Opcao = this.Opcao.bind(this)
    this.ProximoPasso = this.ProximoPasso.bind(this)
    this.Status = this.Status.bind(this)
    this.handleChange = this.handleChange.bind(this)
    
    
    this.state = {
      data: null,
      datab64: null,
      dataUrl: null,
      step: tela.opcao,
      checklock: false,
      unlock: true
      
    }
    
    
    
  }

  
  handleSubmit = (cep : string, uf : string, cidade : string, endereco : string) => {
    //this.props.changePasso();
  }

  handleChange(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({unlock: e.target.checked})
  }

  Aceitar() {
    updateInfoBPO({fase: status.tela_refin, situacao: situacao.sucesso});
    addInfo(tela_fluxo_refin.refin_screen);
    this.props.changePasso();
  }
  
  componentDidMount(){
    updateInfoBPO({fase: status.tela_refin, situacao: situacao.sucesso});
    addInfo(tela_fluxo_refin.refin_screen);
  }

  Status(e : React.ChangeEvent<any>) {
    if(e.target.scrollTop >= (30 * e.target.scrollWidth)){
      this.setState({checklock: true})
    }
  }



  Apresentacao(){
    return(
      <FadeIn>
          <BoxInfo>
            <Card
              body
              className="text-center"
            >
              <CardTitle tag="h5">
                {ApresentacaoText.title1}
              </CardTitle>
              <CardText>
                {ApresentacaoText.text1}
              </CardText>
            </Card>
            {/*<Banner src={comprovante} alt='intro-comprovante' />
            {ApresentacaoText.text2}*/}
            <Button block={true} id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} onClick={()=> this.setState({step: tela.opcao})}> Seguir </Button>
          </BoxInfo>
      </FadeIn>
    );
  }



  Opcao(){
    const {unlock} = this.state
    
    return(
      <FadeIn>
        <CardInfo>
          <Card body>
            <CardBody>
              <h6 className="text-center"><b>Características da Operação de Refinanciamento:</b></h6>
              <RefinInfo Status={this.Status}/>
              <div style={{marginBottom: '1rem'}}>
              <CheckedBox onChange={this.handleChange} /> <Label check> Eu li e aceito o termo </Label>
              </div>
                <Seguir width='30%' unlock={unlock} onClick={this.Aceitar} />
            </CardBody>
          </Card>
        </CardInfo>
      </FadeIn>
    );
  }


  ProximoPasso(){
    //this.props.changePasso()
  }



  render(){
    const {step} = this.state;

    switch(step){
      case tela.apresentacao:
        return(this.Apresentacao());
        case tela.opcao:
          return(this.Opcao());
      default:
        break;
    }
    return null;
  }
  
}