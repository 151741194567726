import { useEffect, useRef } from 'react'
import { Box, Grid, Icon, MenuItem, Paper } from '@mui/material'
import { Form } from '@unform/web'
import { InputDataField, InputTextField, SelectField } from '../form'
import { FormHandles } from '@unform/core'
import { ButtonPropSubmit } from './FormComponents'

import { LogData, SearchData } from './FormInterfaces'

const web = process.env.REACT_APP_SERVER

export const FormBusca = ({ onSubmit } : { onSubmit: (data: SearchData) => void}) : JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  useEffect(()=>{
    formRef.current?.setFieldValue('dataInicio', (new Date()).toLocaleDateString())
  },[])

  return (
    <Form ref={formRef} onSubmit={onSubmit} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <Box sx={{ m: 2 }} display='flex' flexDirection='column' component={Paper} variant='outlined'>
        <Grid container direction={'column'} padding={2} spacing={2}>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <h5>Informe os Critérios de Pesquisa</h5>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputTextField fullWidth name='id_token' label='Token id da proposta' />
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputTextField fullWidth name='numero_proposta' label='Número da proposta' />
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputTextField fullWidth name='cpf' label='CPF' />
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={8} sm={4} lg={4}>
              <InputDataField fullWidth name='dataInicio' label='Data de Inicio da Formalização da Jornada' />
            </Grid>
            <Grid item xs={8} sm={4} lg={4}>
              <InputDataField fullWidth name='dataFinalizacao' label='Data de Final da Formalização da Jornada' />
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField fullWidth name='tipo' label='Tipo de Jornada' >
                <MenuItem value={1}>Empréstimo</MenuItem>
                <MenuItem value={5}>FGTS</MenuItem>
              </SelectField>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField fullWidth name='situacao' label='Situação da Jornada' defaultVal={0}>
                <MenuItem value={0}>Todas</MenuItem>
                <MenuItem value={1}>Andamento</MenuItem>
                <MenuItem value={2}>Finalizada</MenuItem>
                <MenuItem value={3}>Enviado para console</MenuItem>
              </SelectField>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <ButtonPropSubmit fullWidth type='submit'><Icon>search</Icon> Buscar</ButtonPropSubmit>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  )
}

export const FormBuscaLog = () : JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  useEffect(()=>{
    formRef.current?.setFieldValue('data', (new Date()).toLocaleDateString())
  },[])

  return (
    <Form ref={formRef} onSubmit={(formv: LogData) => window.location.replace(`${web}/thread/download-logs?datastr=${formv.data}`)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <Box sx={{ m: 2 }} display='flex' flexDirection='column' component={Paper} variant='outlined'>
        <Grid container direction={'column'} padding={2} spacing={2}>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <h5>Informe a data para baixar os logs:</h5>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={8} sm={4} lg={4}>
              <InputDataField fullWidth name='data' label='Data' />
            </Grid>
            <Grid item xs={8} sm={4} lg={4}>
              <ButtonPropSubmit fullWidth type='submit'><Icon>download</Icon> Baixar</ButtonPropSubmit>
            </Grid>
          </Grid>
          
        </Grid>
      </Box>
    </Form>
  )
}

export default FormBusca