import React, { useState } from 'react';
import { InputAdornment, IconButton, Icon, TextFieldProps } from '@mui/material';
import InputTextField from './InputTextField';

type InputTextProps = TextFieldProps & {
	name: string
}

export const InputPassowordField : React.FC<InputTextProps> = ({ name, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputTextField
      name={name}
      {...rest}
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleToggleShowPassword}>
              {showPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}