import logo from '../images/logo-mcard.png';
import logo2 from '../images-bpo/logo-BPO.png'
import { isMobile } from 'react-device-detect';
import images_bpo from './utils/logo-bpo';
import { perfil } from '../manager/manager-pages/constants';

export default function Header() {
  const {REACT_APP_PERFIL} = process.env
  const perfil = REACT_APP_PERFIL


  if(isMobile){
    return(
      <img className='logotipo-mobile' src={perfil == 'BPO'?images_bpo[0]: logo} width="auto" height="80" alt='logo'/>
    );
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center'}}>
      <img className='logotipo' src={perfil == 'BPO'?images_bpo[0]: logo} width="auto" height="100" alt='logo'/>
    </div>
  );
}