import React, { useEffect, useState } from 'react';
import {status, situacao} from '../components/Info';
import {CardResumo, Termos, Assinatura, CheckedBox} from './styles/estilo_resumo'
import axios from 'axios';

import CCBGen from '../components/doc/ccbgenemprestimo.component'
import TermoGen from '../components/doc/termogen.component'

import Loading from '../components/Loading'
import FadeIn from 'react-fade-in';
import { Button, Card, CardBody, CardTitle, Table, Label } from 'reactstrap';

import {isBrowser} from 'react-device-detect'
import { Base64Type } from '../ts/types/Types';
import { PropResumo } from '../ts/interfaces/PropTelas.interfaces';
import { updateInfoBPO, getInfoList } from '../state/actions/Info_actions';
import { getStateJornada } from '../state/actions/jornada_actions';
import RefinInfo from '../components/refin_info.component';

import imglogo from '../images-bpo/logo-BPO.png'

const {REACT_APP_SERVER, REACT_APP_SOURCE_FILES} = process.env;


const webs = REACT_APP_SERVER
const source = REACT_APP_SOURCE_FILES

const {id} = getStateJornada();

const RenderDocuments = ({cnh_frente, cnh_verso, dat, tipo} : {cnh_frente: string, cnh_verso: string, dat: Base64Type[], tipo: number}) => {
  if(cnh_frente && cnh_verso){
    return(
    <div>
      <p><b>Identidade Frente:</b></p>
      <img src={cnh_frente} width={200} height={200} alt='identidadefrente'/>
      <p><b>Identidade Verso:</b></p>
      <img src={cnh_verso} width={200} height={200} alt='identidadetras' />
      {/*tipo == 1 &&
      <>
      <p><b>Comprovante:</b></p>
      <img src={dat[3] as string} width={200} height={200} alt='comprovante' />
    </>*/}
    </div>
    );
  }else{
    return (
    <div>
      <p><b>Identidade:</b></p>
      <img src={cnh_frente} width={200} height={200} alt='identidade'></img>
      {/*tipo == 1 &&
      <>
      <p><b>Comprovante:</b></p>
      <img src={dat[2] as string} width={200} height={200} alt='comprovante'></img>
    </>*/}
    </div>
    );
  }
}

const escolheTermo = () => {
  const {contrato} = getStateJornada()
  const tipo = contrato.Proposta.TipoProposta
  if(tipo == 7){
    return(
      <Termos>
      <p><b>Características da Operação de Refinanciamento:</b></p>
      <div className='termos'>
        <RefinInfo />
      </div>
    </Termos>
    )
  }else{
    return(
      <Termos>
      <p><b>Termo de Adesão:</b></p>
      <div className='termos'>
        <TermoGen />
      </div>
    </Termos>
    )
  }
}

const RenderTerms = () => {
  return(
    <div style={isBrowser?{}:{marginBottom: '100px'}}>
      {escolheTermo()}
      <Termos>
        <p><b>CCB:</b></p>
        <div className='termos'>
          <CCBGen />
        </div>
      </Termos>
    </div>
  );
}

interface State {
  selfie: string,
  cnh_frente: string,
  cnh_verso: string,
  valid: boolean,
  confirma: boolean,
  textc: string,
  messagef: string,
}

function removerAcentos(texto: string) {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}


function comparaCidade(cidadeWeb: string, cidadeContrato: string){
  if (removerAcentos(cidadeWeb.toLowerCase()) == removerAcentos(cidadeContrato.toLowerCase())) {
    return true 
  } else {
    return false
  }
}

function getGeo(cidadeWeb: string, cidadeContrato: string, estadoWeb: string){
  const geo = (comparaCidade(cidadeWeb, cidadeContrato))? `${cidadeWeb}/${estadoWeb}`: `Região ${cidadeWeb}/${estadoWeb} não confere com os dados registrados.`
  return geo
}


export default class Resumo extends React.Component<PropResumo, State>{
  constructor(props : PropResumo){
    super(props);
    this.state = {
      selfie: "",
      cnh_frente: "",
      cnh_verso: "",
      valid: true,
      confirma: true,
      textc: 'Li e aceito todos os termos e condições',
      messagef: 'Carregando...'
    }
    this.handleChange = this.handleChange.bind(this);
    this.enviar_resumo = this.enviar_resumo.bind(this);
  }
  
  
  async componentDidMount(){
    updateInfoBPO({fase: status.tela_resumo, situacao: situacao.sucesso});
    const { id } = getStateJornada();
    const selfie = await this.getimage(id, '1');
    this.setState({ selfie: selfie });
    const cnh_frente = await this.getimage(id, '2');
    this.setState({ cnh_frente: cnh_frente });
    const cnh_verso = await this.getimage(id, '3');
    this.setState({ cnh_verso: cnh_verso });
  }

  handleChange(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({confirma: e.target.checked})
  }

  enviar_resumo(){
    //POST das CCBs com resumo

    this.setState({valid: false, messagef: 'Este processo pode demorar alguns minutos...'})

    const list = getInfoList();
    const {contrato} = getStateJornada();
    const {city, estado} = list[0];
    const {id, idJornada, tipo, prova_vida, endereco_comprovante} = getStateJornada();
    const cityContrato = contrato.Cliente.DadosCliente.Enderecos[1].Cidade
    const eventos = getInfoList();
    const {docs} = this.props;
    const geo = getGeo(city, cityContrato, estado);

    //Resumo com a CCB para o front

    //let cur = 'Acuracia: '+this.props.acc;
    
    //Manda as informacoes para o front
    this.setState({messagef: 'Finalizando...'})

    axios.post(webs+'/proposta/send-info', {
      id: id,
      idJornada: idJornada,
      documentos: docs,
      geolocalizacao: geo,
      endereco_comprovante: endereco_comprovante,
      eventos: eventos,
      prova_vida: prova_vida
    }).then(
      resp => {
        updateInfoBPO({fase: status.finalizacao, situacao: situacao.sucesso});
        this.props.history.push('/agradecimento/'+tipo);
      }
    ).catch(
      err => {
        updateInfoBPO({fase: status.finalizacao, situacao: situacao.falha, mensagemErro: err.response.data});
        this.props.history.push('/agradecimento/'+tipo);
      }
    );
  }


  async getimage(id: string, type: string){
    try {
      const resp = await axios.get(`${webs}/get-imagem`, {params: {id: id, type: type}});
      return resp.data

    } catch (error) {
      // handle the error, e.g., return a default image or throw an error
      console.error(error);
      return ''; // default value or appropriate handling
    }
  }
  

  render(){


    

    const {id, tipo, contrato, nome} = getStateJornada();
    const list = getInfoList();
    const {code, link, dat} = this.props;
    const {valid, confirma, textc, messagef, selfie, cnh_frente, cnh_verso} = this.state;

    console.log(selfie);

    if(valid){
      return(
        <FadeIn delay={50}>
          <CardResumo>
            <Card
              body
              className="text-center">
                <CardTitle tag="h5">
                  Resumo do Processo
                </CardTitle>
                <CardBody>
                  <img src={selfie} width={200} height={200} alt='selfie' />
                  <p><b>Nome do cliente:</b> {nome}</p>
                  <p><b>CPF:</b> {contrato.Cliente.DadosCliente.CPF}</p>
                  <p><b>Produto:</b> {tipo == 5? 'Saque Aniversário FGTS' : 'Consignado'}</p>
                  {tipo == 1? <p><b>Codigo CCB:</b> {code}</p> : null}
                  <div className='table-responsive'>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Eventos</th>
                        <th>Data e Hora</th>
                        <th>ID do dispositivo</th>
                        <th>Geolocalizacao</th>
                        <th>Sistema Operacional</th>
                        <th>Navegador</th>
                        <th>IP</th>
                        <th>porta</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, index) => {
                        return(
                        <tr key={index}>
                          <td>{item.passo as string}</td>
                          <td>{item.timeOpened as string}</td>
                          <td>{item.ID}</td>
                          {(item.city && item.estado)? <td>{item.city}/{item.estado}</td>: <td>Não encontrado</td>} 
                          <td>{item.os as string}</td>
                          <td>{item.browserName as string}</td>
                          <td>{item.ip as string}</td>
                          <td>3000</td>
                        </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  </div>
                  
                  
                  {tipo == 1 || tipo == 5? <RenderDocuments cnh_frente={cnh_frente} cnh_verso={cnh_verso} dat={dat} tipo={tipo} /> : null}

                  {tipo == 1 || tipo == 5? <p><a target='_blank' href={link?link:`${webs}/public/${id}_prova_vida.mp4`} rel='noreferrer'>Gravação de video</a></p> : null}
                  {tipo == 1? <RenderTerms /> : null}
                  
                  <Assinatura>
                    <div id='check'>
                      <CheckedBox onChange={this.handleChange} />{' '}<Label check>{textc}</Label>
                    </div>
                      
                    {(confirma)? <Button color='info' block={true} onClick={()=> this.enviar_resumo() }> Assinar </Button> : 
                      <Button color='secondary' block={true}> Assinar </Button>
                    }
                  </Assinatura>
              </CardBody>
            </Card>
          </CardResumo>
        </FadeIn>
        );
    } else {
      return(
        <div style={{marginTop: 20}}>
          <Loading text={messagef}/>
        </div>
      );
    }
  }
}