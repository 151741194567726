import styled from 'styled-components'
import Webcam from 'react-webcam'
import WebCamMobile from './webcammobile'
import { isBrowser } from 'react-device-detect'
import photo from '../images/screenshot.png'
import photoBpo from '../images-bpo/screenshot.png'
import mascara from '../images/mask.png'
import mascaraBpo from '../images-bpo/mask.png'

const PicDiv = isBrowser? styled.div`
  text-align: center;
  margin-top: 35px;
`:styled.div`
  text-align: center; 
  margin-top: 20px;
`
const {REACT_APP_PERFIL, REACT_APP_BUTTON_COLOR} = process.env

const color = REACT_APP_BUTTON_COLOR

const perfil = REACT_APP_PERFIL

const PicButton = ({ onClick } : { onClick: React.MouseEventHandler<HTMLImageElement> }) => {
  return(
    <PicDiv>
      <img src={perfil == 'BPO'?photoBpo: photo} alt='Take Pic' onClick={onClick} height={50}/>
    </PicDiv>
  )
}




const CameraDiv = isBrowser? styled.div`
  text-align: center;  

  video {
    margin: 0 auto; 
    width: 430px; 
    height: 425px; 
    border: 15px solid; 
    border-color: ${REACT_APP_BUTTON_COLOR}
  }

`:styled.div`
  
`

interface ICam {
  webcam: React.RefObject<Webcam>
  videocons: boolean | MediaTrackConstraints | undefined,
  CameraError: (error: string | DOMException) => void,
  onClick: React.MouseEventHandler<HTMLImageElement>
}

export const Camera = ({ webcam, videocons, CameraError, onClick } : ICam) => {
  const Cam = isBrowser?(
    <Webcam
      ref={webcam}
      audio={false}
      height={420}
      width={400}
      videoConstraints={videocons}
      forceScreenshotSourceSize={true}
      screenshotFormat='image/png'
    />
  ):(
    <WebCamMobile
      webcam={webcam}
      facing='back'
      onUserMediaError={CameraError}
    />
  )
  
  return(
    <CameraDiv> 
      {Cam}
      <PicButton onClick={onClick} />
    </CameraDiv>
  );
  
}

const SelfieDiv = isBrowser? styled.div`
  margin: 0 auto; 
  width: 490px; 
  height: 490px; 
  border: 35px solid; 
  border-color: ${REACT_APP_BUTTON_COLOR}; 
  border-radius: 185px;
  
  img:first-child {
    position: absolute;
    border-radius: 150px;
    width: 420px;
    height: 420px;
  }

  video {
    border-radius: 150px;
  }

`:styled.div``




export const Selfie = ({webcam, videocons, onClick, CameraError} : ICam) => {
  const Cam = isBrowser?(
      <SelfieDiv>
        <img alt='Selfie' src={perfil == 'BPO'?mascaraBpo: mascara} />
        <Webcam
          id='inputVideo'
          ref={webcam}
          audio={false}
          height={420}
          width={420}
          onUserMediaError={CameraError}
          videoConstraints={videocons}
          screenshotFormat='image/png'
          forceScreenshotSourceSize={true}
        />
      </SelfieDiv>
    ) : (
      <WebCamMobile
        webcam={webcam}
        facing='user'
        onUserMediaError={CameraError}
      />
    );

    return(
      <div>
        {Cam}
        <PicButton onClick={onClick} />
      </div>
    )

}


export default Camera;