
export const ApresentacaoText = {
  title1: 'Contracheque',
  text1: 'Por fim, você também precisa nos enviar um contracheque/holerite. Fique atento nas dicas de como posicionar seu documento para a foto.',
  text2: <p>Caso você já tenha uma foto que permita a leitura dos dados do seu documento, você pode escolher entre anexá-la ou tirar uma nova foto do documento.</p>
}

export const OpcaoText = {
  title: 'Escolha uma opção de sua preferência'
}

export const AnexarText = {
  title1: 'Envie uma foto do seu contracheque/holerite',
  title2: 'Você consegue ler o documento? Então você pode seguir para a próxima etapa.',
  error: 'Documentos precisam estar abaixo de 2MB',
  error2: 'Documentos precisam ser imagens ou pdf: .jpg, .jpeg, .png, .bmp, .gif, .pdf'
}

export const TirarFotoText = {
  title1: 'Tire sua foto em um local bem iluminado e centralize o seu contracheque/holerite',
  title2: 'Você consegue ler o documento? Então você pode seguir para a próxima etapa.',
  error: 'Habilite sua camera, caso queira prosseguir.'
}