import styled from 'styled-components'
import { isBrowser } from 'react-device-detect'
import { Button } from 'reactstrap';

const ImgDiv = isBrowser? styled.div`
  margin: 20px auto; 
  text-align: center; 
` : styled.div`
  margin: 20px auto;
  text-align: center;
`

const ImgBanner = isBrowser? styled.img`
  width: 260px;
  height: 260px;
` : styled.img` 
  width: 70%; 
  height: auto;
`
const {REACT_APP_BUTTON_COLOR, REACT_APP_FONT_COLOR} = process.env
const buttonColor = REACT_APP_BUTTON_COLOR
export const Banner = ({alt, src} : {alt: string, src: string}) => {
  return(
    <ImgDiv>
      <ImgBanner alt={alt} src={src}></ImgBanner>
    </ImgDiv>
  )
}

export const ButtonsInline = isBrowser? styled.div`
  margin: 20px auto;
  text-align: center;

  button:first-child {
    width: 280px;
  }

  button {
    width: 280px; 
    margin-left: 2px;
  }
` : styled.div`
  margin: 20px auto;

  button:first-child {
    width: 48%;
  }

  button {
    width: 48%; 
    margin-left: 3px;
  }
`

export const Command = isBrowser? styled.div`
  text-align: center;
  color: ${REACT_APP_FONT_COLOR};
  font-size: 25px;
  margin-bottom: 20px;
`:styled.div`
  text-align: center;
  color: ${REACT_APP_FONT_COLOR};
  font-size: 20px;
` 

const SeguirDiv = isBrowser? styled.div `
  margin-top: 20px; 
  text-align: center;

  button {
    width: 650px; 
    margin-bottom: 20px;
  }
` : styled.div`
  margin-top: 20px;

  button {
    width: 100%; 
    margin-bottom: 20px;
  }
`

export const SeguirBtn = ({onClick} : {onClick: React.MouseEventHandler<HTMLButtonElement>}) => {
  return(
    <SeguirDiv>
        <Button color='info' onClick={onClick}> Seguir </Button>
    </SeguirDiv>
  );
}

export const ResPic = isBrowser? styled.div`
  text-align: center;

  img { 
    border-radius: 150px;
    border: 35px solid;
    border-color: ${buttonColor}; 
    width: 490px; 
    height: 490px;
  }
`: styled.div`
  text-align: center;

  img {
    border: 15px solid; 
    border-radius: 5px;
    border-color: ${buttonColor}; 
    width: ${() => window.screen.width}px;
    height: 324px;
  }
`

export const LayoutSelfie = isBrowser? styled.div`
  margin-top: 50px; 
  margin-bottom: 100px;
`:styled.div`
  margin-top: 25px; 
  margin-bottom: 100px;
`
