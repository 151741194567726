import { Paper, TableContainer, Table, Button, TableHead, TableRow, Icon, TableCell, TableBody } from "@mui/material";
import { User } from "../../../ts/interfaces/Manager.interfaces"
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../../components/LoadingManager";
import { IAlert } from '../alerts/alert'
import { getPerfilStr } from "../constants";

const {REACT_APP_SERVER} = process.env;

const webs = REACT_APP_SERVER

interface IListaDetalhe {
  atualizar: (usuario: User) => void
  setAlert: React.Dispatch<React.SetStateAction<IAlert>>
}

export const ListaUsuarios = ({ atualizar, setAlert } : IListaDetalhe) => {
  const [users, setUsers] = useState<User[]>([]);
  const [carregando, setCarregando] = useState<boolean>(false);
  
  const removeUsuario = (usuario: User) => {
    setCarregando(true);
    axios.post(webs+'/remover-usuario', usuario).then(resp => {
      setCarregando(false);
      if(resp.data){
        setAlert({message: 'Usuario removido com sucesso!', visible: true, color:'success'})
      }else{
        setAlert({message: 'Erro não foi possível remover o Usuario!', visible: true, color:'error'})
      }
    })
  }

  useEffect(() => {
    axios.get(webs+'/usuarios')
    .then(resp => {
      setUsers(resp.data);
    })
  }, [users]);

  useEffect(() => {
    return () => {
      setUsers([])
    }
  }, []);

  if(!users.length || carregando){
    return(<Loading />);
  }

  return (
    <>
    <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
      <Table aria-label='tabela-proposta'>
        <TableHead>
          <TableRow>
            <TableCell><b>id</b></TableCell>
            <TableCell><b>Usuario</b></TableCell>
            <TableCell><b>Perfil</b></TableCell>
            <TableCell><b>Editar</b></TableCell>
            <TableCell><b>Excluir</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            users.map((usuario : User, index: number) => {
              return(
                <TableRow key={index}>
                  <TableCell>{usuario.id_user}</TableCell>
                  <TableCell>{usuario.login}</TableCell>
                  <TableCell>{getPerfilStr(usuario.perfil)}</TableCell>
                  <TableCell><Button onClick={() => atualizar(usuario)}><Icon>edit</Icon></Button></TableCell>
                  <TableCell><Button onClick={() => removeUsuario(usuario)}><Icon>delete</Icon></Button></TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

export default ListaUsuarios;