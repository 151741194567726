import React from 'react'
import Apresentacao from '../components/screen_components/Apresentacao.component'
import { RastreioText } from '../telas/text/prova_vida.text'
import FadeIn from 'react-fade-in'
import FaceDetect from '../components/FaceDetect'
import { SeguirBtn } from '../telas/styles/estilo_prova_vida'
import { PropTelaProvaVidaP } from '../ts/interfaces/PropTelas.interfaces'
import Countdown from "react-countdown";
import ErroIdentificacao from '../components/screen_components/ErroIdentificacao.component'
import { PPVida } from '../ts/types/Types'
import { setStateJornada } from '../state/actions/jornada_actions'
import { updateInfoBPO } from '../state/actions/Info_actions'
import {status, situacao} from '../components/Info'

const tela = {
  inicial: 0,
  captura_prova_vida: 1,
  erro_de_captura: 2
}

interface State {
  movimento: number,
  resumo: PPVida,
  blob: Blob | null,
  px: number,
  py: number,
  step: number,
  tentativa: number
}

export default class ProvaVidaTela extends React.Component<PropTelaProvaVidaP, State> {
  constructor(props : PropTelaProvaVidaP){
    super(props)

    this.Apresentacao = this.Apresentacao.bind(this)
    this.onUpdatePos = this.onUpdatePos.bind(this)
    this.onUpdatePosSave = this.onUpdatePosSave.bind(this)
    this.onRecord = this.onRecord.bind(this)
    this.sendRecord = this.sendRecord.bind(this)
    this.ErroCaptura = this.ErroCaptura.bind(this)
    this.onUpdateFace = this.onUpdateFace.bind(this)
    this.onUpdateCounter = this.onUpdateCounter.bind(this)
    this.onFinishCount = this.onFinishCount.bind(this)
    
    this.state = {
      movimento : 0,
      resumo: {movimento_concluido: 0, prova_vida_suspeita: false, etapas: {sorriso: false, rosto_direita: false, rosto_esquerda: false}},
      blob: null,
      px: 0,
      py: 0,
      step: 0,
      tentativa: 3
    }
  }

  Apresentacao() {
    return(<Apresentacao
      id='ProvaVida'
      onClick={()=>this.setState({step: tela.captura_prova_vida})}
      />)
  }

  onUpdatePos(value : number, x : number =0, y : number =0, size: number =0){
    //console.log(value)
    if(x != 0 && y != 0 && size != 0){
      this.setState({px: x, py: y})
    }
    this.setState({movimento: value})
  }

  onUpdatePosSave(value : number){
    this.setState({movimento: value})
  }

  onRecord(blob : Blob){
    const {movimento_concluido} = this.state.resumo;
    this.props.InsertVideoBlob(blob as Blob);

    if(movimento_concluido > 0){
      updateInfoBPO({fase: status.prova_vida_capturada, situacao: situacao.sucesso});
    }
  }

  sendRecord(){
    const {tentativa} = this.state;
    let {resumo} = this.state;

    if(resumo.movimento_concluido > 0){
      setStateJornada({ prova_vida: resumo });
      this.props.changePasso()
    }else if(tentativa > 0){
      this.setState({step: tela.erro_de_captura});
    }else{
      resumo.prova_vida_suspeita = true;
      setStateJornada({ prova_vida: resumo });
      this.props.changePasso()
    }
  }

  onUpdateFace(value : {x: number, y: number, felicidade: number}){
    const {movimento, px} = this.state
    let p = value.x/px;
    const width = window.innerWidth;
    let { resumo } = this.state;

    if(resumo.movimento_concluido == 0 && value.felicidade > 0.5){
      resumo.movimento_concluido += 1;
      resumo.etapas.sorriso = true;
      this.setState({ resumo })
    }else if(resumo.movimento_concluido == 1 && p < 0.8) {
      resumo.movimento_concluido += 1;
      resumo.etapas.rosto_direita = true;
      this.setState({ resumo });
    }else if(resumo.movimento_concluido == 2 && p > 1.2) {
      resumo.movimento_concluido += 1;
      resumo.etapas.rosto_esquerda = true;
      this.setState({ resumo });
    }

    if(movimento == -1 && (value.x >= width/2 + 10 || value.x <= width/2 - 10)){
      this.setState({movimento: 0, px: value.x })
    }else if((movimento == 0) && (value.felicidade > 0.5)){
      this.setState({movimento: 1 })
    }else if((movimento == 1) && (p < 0.8)){
      this.setState({movimento: 2 })
    }else if((movimento == 2) && (p > 1.2)){
      this.setState({movimento: 3 })
    }
  }

  onUpdateCounter({seconds}: {seconds: number}){
    return(<span>{seconds}</span>)
  }

  onFinishCount() {
    const {movimento} = this.state;
    if(movimento < 3){
      this.setState({movimento: movimento + 1})
    }
  }

  RastreioPersonalizado() { 
    const {movimento} = this.state
  
    const showMask = (movimento == -1)

    const PropCount = {
      date: Date.now()+5000,
      intervalDelay: 0,
      precision: 3,
      renderer: this.onUpdateCounter,
      onComplete: this.onFinishCount
    }

    return(
      <FadeIn delay={50}>
        <div style={{marginTop: '50px', marginBottom: '100px'}}>
              {movimento != null?(
              <p id='font-command-mobile'>
                {movimento == -1 && (<b>{RastreioText.movimento1}</b>)}
                {movimento == 0 && (<b>{RastreioText.movimento2} &#128512; ... (<Countdown {...PropCount} />)</b>)}
                {movimento == 1 && (<b>{RastreioText.movimento3} ... (<Countdown {...PropCount} />)</b>)}
                {movimento == 2 && (<b>{RastreioText.movimento4} ... (<Countdown {...PropCount} />)</b>)}
                {movimento == 3 && (<b>{RastreioText.movimento5}</b>)}
              </p>
              ): null }

              <FaceDetect UpdateVal={this.onUpdateFace} InsertVideoBlob={this.onRecord} start={(movimento >= 0)} stop={(movimento >= 3)} showMask={showMask} timeRecord={0} />

              {movimento == 3 && (
                <SeguirBtn onClick={() => this.sendRecord()}/>
              )
              }
        </div>
      </FadeIn>
    );
  }

  ErroCaptura() {
    const {tentativa} = this.state;

    return(
      <ErroIdentificacao id='ProvaVida' 
        ReiniciarTentativa={()=>{ this.setState({
          step: tela.captura_prova_vida, 
          movimento: -1, 
          resumo: {
            movimento_concluido: 0, 
            prova_vida_suspeita: false, 
            etapas: {
              sorriso: false, 
              rosto_direita: false, 
              rosto_esquerda: false
            }},
            tentativa: tentativa - 1}) 
          }}
        tentativa={tentativa}
      />
    );
  }

  render() {
    const {step} = this.state;

    switch(step){
      case tela.inicial:
        return(this.Apresentacao());
      case tela.captura_prova_vida:
        return(this.RastreioPersonalizado());
      case tela.erro_de_captura:
        return(this.ErroCaptura());
      default:
        break;
    }
    return(<></>)
  }


}