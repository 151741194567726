import React from "react"

//Interfaces
export type InfoType = {
  [key: string] : string | number | boolean | Storage
}

export type Address = {
  endereco: string,
  cep: string,
  cidade: string,
  uf: string,
}

export type FluxoType = {
  Selfie: boolean,
  ProvaVida: boolean,
  Identidade: boolean,
  Comprovante: boolean,
  Contracheque: boolean
}

export type BannerType = {
  title: string,
  text: string | React.ReactElement | null,
  obs?: string | React.ReactElement | null,
  src: string
}

export type Proposal = {
  success: boolean, 
  proposalId: string, 
  proposalNumber: string
}

export type Endereco = {
  TipoEndereco: number, 
  CEP: string,
  Logradouro: string,
  Numero: string,
  Complemento:string,
  Bairro:string,
  Cidade:string,
  UF:string,
  Latitude:string,
  Longitude:string
}

export type Telefone = {
  TipoTelefone:number, //Telefone Celular
  DDD:string,
  NumeroTelefone:string,
  Operadora:number
}

export interface DataRefin {
  NumeroProposta: number
  CodigoCliente: string
  CPF: string
  Matricula: string
  NumeroContrato: string
  Saldo: number
  ValorParcela: number
  QuantidadeDeParcelas: number
  QuantidadeParcAbertas: number
  QuantidadeParcVencidas: number
  QuantidadeParcAVencer: number
  ParcelasRefin: ParcelasRefin[]
}

export interface ParcelasRefin {
  NumeroContrato: string
  NumeroParcela: number
  ValorParcela: number
  SaldoParcela: number
  DataDeVencimento: string
  ValorConfirmado: number
}

export type PropostaEmprestimo = {
  NumeroProposta: number,
  CodigoCliente: number,
  CodigoProponente: number,
  TipoPessoa: number,
  CPFCliente: string,
  NomeCliente: string,
  Matricula: string,
  DataNascimento: string,
  ValorSolicitado: number,
  ValorFinanciado: number,
  ValorIOF: number,
  ValorCompra: number,
  ValorRefin: number,
  ValorDespesas: number,
  ValorComissao: number,
  ValorAnuidade: number,
  Coeficiente: number,
  Prazo: number,
  ValorParcela: number,
  ValorBruto: number,
  ValorLiquidoCliente: number,
  ValorSaqueComplementar: number,
  ValorTarifaCartao: number,
  ValorLimiteSaqueComplementar: number,
  ValorLimiteCompra: number,
  TaxaCLMes: number,
  TaxaCLAno: number,
  TaxaAPMes: number,
  TaxaAPAno: number,
  TaxaNominalMes: number,
  TaxaNominalAno: number,
  TaxaCETMes: number,
  TaxaCETAno: number,
  CodigoBanco: number,
  NomeBanco: string,
  CodigoMatriz: number,
  NomeMatriz: string,
  CodigoGerente: number,
  NomeGerente: string,
  CodigoPromotora: number,
  NomePromotora: string,
  CodigoOperador: number,
  NomeOperador: string,
  CodigoLoja: number,
  NomeLoja: string,
  CodigoEmpregador: number,
  NomeEmpregador:string,
  CodigoOrgao: number,
  NomeOrgao: string,
  CodigoSindicato: number,
  NomeSindicato: string,
  ValorTC: number,
  IDTabelaFinanciamento: number,
  NumeroTabela: string,
  NomeTabela: string,
  DataPrimeiroVencimento: string,
  DataUltimoVencimento: string,
  NumeroContrato: string,
  NumeroOperacaoConsignada: string,
  PropostaCadastradaNoBanco: boolean,
  CodigoProduto: number,
  CodigoEsteira: number,
  DataBase: string,
  DataCadastro: string,
  CodigoUsuario: string,
  LoginUsuario: any,
  Beneficiario:{
    TipoBeneficiario: number,
    NomeBeneficiario: string,
    CGCBeneficiario: string,
    TipoConta: number,
    NumeroBanco: string,
    NumeroAgencia: string,
    NumeroDVAgencia: string,
    NumeroConta: string,
    NumeroDVConta: string
  },
  TipoProposta: number,
  DadosTransfer: any,
  DadosRefin: DataRefin[],
  PropostaParcelado:{
     NumeroProposta: number,
     ValorSolicitado: number,
     ValorFinanciado: number,
     ValorIOF: number,
     Prazo: number,
     ValorParcela: number,
     ValorBruto: number,
     ValorLiquidoCliente: number,
     TaxaCLMes: number,
     TaxaCLAno: number,
     TaxaAPMes: number,
     TaxaAPAno: number,
     TaxaNominalMes: number,
     TaxaNominalAno: number,
     TaxaCETMes: number,
     TaxaCETAno: number,
     IDTabelaFinanciamento: number,
     NumeroTabela: string,
     NomeTabela: string,
     DataPrimeiroVencimento: string,
     DataUltimoVencimento: string
  }
};

export interface Beneficiario {
  TipoBeneficiario: number
  NomeBeneficiario: string
  CGCBeneficiario: string
  TipoConta: number
  NumeroBanco: string
  NumeroAgencia: string
  NumeroDVAgencia: string
  NumeroConta: string
  NumeroDVConta: string
}

export interface PropostaParcela {
  NumeroParcela: number
  ValorParcela: number
  DataVencimento: string
  ValorPrincipal: number
  ValorJuros: number
  TaxaCLMes: number
  TaxaCLAno: number
  TaxaAPMes: number
  TaxaAPAno: number
  TaxaNominalMes: number
  TaxaNominalAno: number
  TaxaCETMes: number
  TaxaCETAno: number
}

export interface MatrizPromotora {
  Codigo: string
  NomeMatriz: any
  CodigoEDescricao: string
  CNPJ: any
  EnderecoComercial: EnderecoComercial
  TelefoneComercial: TelefoneComercial
  TelefoneCelular: TelefoneCelular
  Email: any
  Ativo: boolean
  SeparaProdutosPorLoja: boolean
  PossuiCampanhas: boolean
  DadosCadastro: DadosCadastro
}

export interface EnderecoComercial {
  TipoEndereco: number
  CEP: any
  Logradouro: any
  Numero: any
  Complemento: any
  Bairro: any
  Cidade: any
  UF: string
  Latitude: any
  Longitude: any
}

export interface TelefoneComercial {
  TipoTelefone: number
  DDD: any
  NumeroTelefone: any
  Operadora: number
  NaoPerturbe: boolean
}

export interface TelefoneCelular {
  TipoTelefone: number
  DDD: any
  NumeroTelefone: any
  Operadora: number
  NaoPerturbe: boolean
}

export interface DadosCadastro {
  DataDeCadastro: string
  UsuarioInclusao: any
  DataDeAlteracao: string
  UsuarioAlteracao: any
}

export interface EnderecoComercial2 {
  TipoEndereco: number
  CEP: string
  Logradouro: string
  Numero: string
  Complemento: string
  Bairro: string
  Cidade: string
  UF: string
  Latitude: any
  Longitude: any
}

export interface TelefoneComercial2 {
  TipoTelefone: number
  DDD: string
  NumeroTelefone: string
  Operadora: number
  NaoPerturbe: boolean
}

export interface TelefoneCelular2 {
  TipoTelefone: number
  DDD: string
  NumeroTelefone: string
  Operadora: number
  NaoPerturbe: boolean
}

export interface DadosCadastro2 {
  DataDeCadastro: string
  UsuarioInclusao: string
  DataDeAlteracao: string
  UsuarioAlteracao: string
}

export interface DadosPromotora {
  MatrizPromotora: MatrizPromotora
  Codigo: string
  NomePromotora: string
  CodigoEDescricao: string
  CNPJ: string
  EnderecoComercial: EnderecoComercial2
  TelefoneComercial: TelefoneComercial2
  TelefoneCelular: TelefoneCelular2
  Email: string[]
  Ativo: boolean
  ValorMeta: number
  CodigoPlano: number
  DadosCadastro: DadosCadastro2
  CodigoBanco: string
  CodigoBancoRetencao: string
  PromotoraRetencao: boolean
  CodigoPVRetencao: number
}

export type PropostaFGTS = {
  NumeroProposta: number
  CodigoCliente: number
  CodigoProponente: number
  TipoPessoa: number
  CPFCliente: string
  NomeCliente: string
  Matricula: string
  ValorSolicitado: number
  ValorFinanciado: number
  ValorIOF: number
  ValorCompra: number
  ValorRefin: number
  ValorDespesas: number
  ValorComissao: number
  Coeficiente: number
  Prazo: number
  ValorParcela: number
  ValorBruto: number
  ValorLiquidoCliente: number
  TaxaCLMes: number
  TaxaCLAno: number
  TaxaAPMes: number
  TaxaAPAno: number
  TaxaNominalMes: number
  TaxaNominalAno: number
  TaxaCETMes: number
  TaxaCETAno: number
  CodigoBanco: number
  NomeBanco: string
  CodigoMatriz: number
  NomeMatriz: string
  CodigoGerente: number
  NomeGerente: string
  CodigoPromotora: number
  NomePromotora: string
  CodigoOperador: number
  NomeOperador: string
  CodigoLoja: number
  NomeLoja: string
  CodigoEmpregador: number
  NomeEmpregador: string
  CodigoOrgao: number
  NomeOrgao: string
  ValorTC: number
  IDTabelaFinanciamento: number
  NumeroTabela: string
  NomeTabela: string
  DataPrimeiroVencimento: string
  DataUltimoVencimento: string
  NumeroContrato: string
  NumeroOperacaoConsignada: string
  NumeroADE: string
  NumeroPropostaRefinPort: number
  PropostaCadastradaNoBanco: boolean
  DataBase: string
  TipoPropostaBack: number
  DataCadastro: string
  CodigoUsuario: string
  Beneficiario: Beneficiario
  TipoProposta: number
  CodigoProduto: number
  DadosTransfer: any
  DadosRefin: any
  PropostaSaqueTotalFGTS: boolean
  IDFgts: string
  DadosPromotora: DadosPromotora
};

export const isPropostaEmprestimo = (x: any): x is PropostaEmprestimo => 'PropostaParcelado' in x;

export type Contrato = {
Proposta: PropostaEmprestimo | PropostaFGTS,
PropostaParcelas?: PropostaParcela[],
Cliente:{
  CodigoCliente: number,
  CodigoHistoricoCliente: number,
  CodigoProponente: number,
  DadosCliente:{
     NomeCliente: string,
     NomeClienteCartao: string,
     TipoPessoa: number,
     CPF: string,
     DataNascimento: string,
     Registro:{
        Numero: string,
        DataEmissao: string,
        LocalEmissao: string,
        UFEmissao: string
     },
     CidadeNatal:string,
     UFNatal:string,
     Sexo: number,
     EstadoCivil:number,
     TipoResidencia: number,
     TempoResidencia:string,
     NomePai:string,
     NomeMae:string,
     DadosEmpresa:{
        CNPJ:string,
        NomeEmpresa:string,
        DataAdmissao:string,
        CodigoProfissao:number,
        DescricaoProfissao: any,
        Cargo: string,
        Renda: number,
        OutrasRendas: number,
        NumeroBeneficio: string,
        IdentificadorMargem: string,
        MesAno_ContraCheque: string,
        NaturezaOcupacao: number
     },
     Enderecos: Endereco[],
     Telefones: Telefone[],
     DadosCadastro:{
        DataDeCadastro: string,
        UsuarioInclusao: string,
        DataDeAlteracao: string,
        UsuarioAlteracao: string
     },
     Email: string,
     RamoAtividade: string,
     TipoInstalacao: number,
     Socios: any[],
     Faturamento: any[],
     PossuiCNH:boolean
  },
  NomeRef1: string,
  DDDRef1: string,
  FoneRef1: string,
  NomeRef2: string,
  DDDRef2: string,
  FoneRef2: string,
  ReferenciaBancaria:{
     TipoConta: number,
     NumeroBanco: string,
     Agencia: string,
     Conta: string,
     DVConta: string
  },
  DadosConjuge:{
     NomeConjuge: string,
     CPFConjuge: string,
     DataNascimentoConjuge: string,
     RGConjuge: string,
     RendaConjuge: number
  }
}
}

export type Anexo = {
  nome: string,
  tipo: string,
	status?: number
}

export type Etapa = { 
  etapa: number,
  docs: Anexo[]
}

export type ValidationReturn = {
  success: boolean,
  nome: string,
  tipojornada: number,
  idjornada: string,
  ultimaJornada: Etapa,
  situacao: number,
  contrato: Contrato[],
  fluxo: FluxoType
}

export type PPVida = {
	movimento_concluido: number,
	prova_vida_suspeita: boolean,
	etapas: EtapasProvaVida
}

export type EtapasProvaVida = {
  sorriso: boolean,
  rosto_direita: boolean,
  rosto_esquerda: boolean
}

export type ValidationID = {
  [key: string] : boolean | string | number | Etapa | Contrato | null | any
}

export type DocType = {
  nome: string,
  tipo: string
}

export type Base64Type =  string | ArrayBuffer | File | null;