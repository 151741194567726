import React, {Component} from 'react'
import {Form, FormGroup, Label, Input, InputGroup} from 'reactstrap';
import { FluxoType } from '../ts/types/Types';
import InputMask from 'react-input-mask';
import {DateButton, Calendar} from './styles/form-login-style';

import "./styles/react-date-picker.css";
const {REACT_APP_BUTTON_COLOR} = process.env

const corBotao = REACT_APP_BUTTON_COLOR 
interface IForm {
  handleSubmit: (
    nome : string, 
    email : string, 
    celular : string, 
    cpf : string, 
    datanascimento : string, 
    estado : number, 
    tipo : number,
    fluxo?: FluxoType) => void
}


interface State {
  nome: string,
  email: string,
  celular: string,
  datanascimento: string,
  cpf: string,
  estado: number,
  tipo: number,
  Selfie: boolean, 
  Identidade: boolean, 
  ProvaVida: boolean, 
  Contracheque: boolean, 
  Comprovante: boolean,
  showcalendar: boolean
}

const parseData = (data : string) => {
  let datarr = data.split('/');
  if(datarr.length > 1){
    return datarr[2]+'-'+datarr[1]+'-'+datarr[0];
  }
  return '';
}

export default class FormCadastro extends Component<IForm, State> {
  constructor(props : IForm) {
    super(props);
    this.onChangeNome = this.onChangeNome.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.onChangeCelular = this.onChangeCelular.bind(this)
    this.onChangeCPF = this.onChangeCPF.bind(this)
    this.onChangeDataNascimento = this.onChangeDataNascimento.bind(this)
    this.onChangeEstado = this.onChangeEstado.bind(this)
    this.onChangeTipo = this.onChangeTipo.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      nome: '',
      email: '',
      celular: '',
      datanascimento: '',
      cpf: '',
      estado: 2,
      tipo: 1,
      Selfie: false, 
      Identidade: false, 
      ProvaVida: false, 
      Contracheque: false, 
      Comprovante: false,
      showcalendar: false
    }
  }

  onChangeEstado(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({estado: parseInt(e.target.value,10)})
  }

  onChangeTipo(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({tipo: parseInt(e.target.value,10)})
  }

  onChangeNome(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({
      nome: e.target.value
    })
  }

  onChangeEmail(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({
      email: e.target.value
    })
  }

  onChangeCelular(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({
      celular: e.target.value
    })
  }

  onChangeCPF(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({
      cpf: e.target.value
    })
  }

  onChangeDataNascimento(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({
      datanascimento: e.target.value
    })
  }

  onChangeDatePicker = (date : Date) => {
    var day = parseInt(date.getDate().toString());
    var month = parseInt(date.getMonth().toString());
    var year = date.getFullYear().toString();

    this.setState({datanascimento: `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`});
  }

  onSubmit = (e : React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { nome, email, celular, cpf, datanascimento, estado, tipo } = this.state;

    const fluxo : FluxoType = {
      Selfie: this.state.Selfie,
      ProvaVida: this.state.ProvaVida,
      Identidade: this.state.Identidade,
      Comprovante: this.state.Comprovante,
      Contracheque: this.state.Contracheque
    }

    this.props.handleSubmit(nome, email, celular, cpf, parseData(datanascimento), estado, tipo, fluxo)

    this.setState({nome: '', email: '', celular: '', cpf: '', datanascimento: ''})
  }

  render() {
    const {nome, email, celular, cpf, datanascimento, showcalendar, tipo} = this.state;
    return(
      <Form onSubmit={this.onSubmit}>
        <FormGroup>
          <Label>
            Nome
          </Label>
          <Input 
            id="nome"
            name="nome"
            placeholder="Nome"
            type="text"
            onChange={this.onChangeNome}
            value={nome}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Email
          </Label>
          <Input 
            id="email"
            name="email"
            placeholder="Email"
            type="email"
            onChange={this.onChangeEmail}
            value={email}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Celular
          </Label>
          <Input 
            id="celular"
            name="email"
            placeholder="Telefone"
            type="text"
            onChange={this.onChangeCelular}
            value={celular}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            CPF
          </Label>
          <Input 
            id="cpf"
            name="cpf"
            placeholder="CPF"
            type="text"
            onChange={this.onChangeCPF}
            value={cpf}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Selecione o tipo de Jornada
          </Label>
          <Input 
            id="tipo"
            name="tipo"
            type="select"
            onChange={this.onChangeTipo}
          >
            <option value={1}>Empréstimo/Cartão</option>
            <option value={3}>Prova de Vida</option>
          </Input>
        </FormGroup>
        {tipo == 1 && <FormGroup>
          <Label>
            Selecione um Convênio
          </Label>
          <Input 
            id="estado"
            name="estado"
            type="select"
            onChange={this.onChangeEstado}
          >
            <option value={2}>Governo de Pernambuco</option>
            <option value={3}>Governo do Maranhão</option>
            <option value={4}>Governo da Paraíba</option>
            <option value={7}>Governo de Minas Gerais</option>
          </Input>
        </FormGroup>}
        {tipo == 4 &&
        <FormGroup>
          <p><Label>
            <strong>Escolha as opções</strong>
          </Label></p>
          <Input 
            type="checkbox"
            onChange={(e)=> this.setState({Selfie: e.target.checked})}
          />{' '}Selfie{'   '}
          <Input 
            type="checkbox"
            onChange={(e) => this.setState({ProvaVida: e.target.checked})}
          />{' '}Prova de Vida{'   '}
          <Input 
            type="checkbox"
            onChange={(e)=>this.setState({Identidade: e.target.checked})}
          />{' '}Identidade{'   '}
          <Input 
            type="checkbox"
            onChange={(e)=>this.setState({Comprovante: e.target.checked})}
          />{' '}Comprovante{'   '}
          <Input 
            type="checkbox"
            onChange={(e)=>this.setState({Contracheque: e.target.checked})}
          />{' '}Contracheque
        </FormGroup>}
        <FormGroup>
          <Label>
            Data de Nascimento
          </Label>
          <InputGroup>
            <Input 
              id="datanascimento"
              name="datanascimento"
              mask='99/99/9999'
              placeholder="Data de Nascimento"
              type="text"
              onChange={this.onChangeDataNascimento}
              value={datanascimento}
              tag={InputMask}
            />
          
            <DateButton onClick={()=> this.setState({showcalendar: showcalendar? false: true})} />
          </InputGroup>
          <Calendar onChange={this.onChangeDatePicker} show={showcalendar}/>
        </FormGroup>
        <Input type='submit' style={{marginTop:'15px', backgroundColor:corBotao, color: '#FFF'}} value='Gerar link' />
      </Form>
      );
  }

}