import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Box, Grid, Icon, Paper} from '@mui/material'
import { Form } from '@unform/web'
import { InputTextField, CheckBoxField } from '../form'
import { FormHandles } from '@unform/core'
import Loading from '../../../components/LoadingManager'
import axios from 'axios'
import { Thread } from './FormInterfaces';
import { ButtonPropSubmit } from './FormComponents';
import Alert, { IAlert } from '../alerts/alert'

const web = process.env.REACT_APP_SERVER

const FormThreadEnvio = ( { thread, setAlert }: { thread: Thread, setAlert: Dispatch<SetStateAction<IAlert>> }) : JSX.Element => {
  const formRef = useRef<FormHandles>(null);
  useEffect(()=>{
    formRef.current?.setFieldValue('nome_thread', thread.nome_thread)
    formRef.current?.setFieldValue('cron_tab', thread.cron_tab)
  },[thread])

  return (
    <Form ref={formRef} style={{ margin: 15 }} onSubmit={(formv: Thread) => onSubmit(formv, setAlert)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <Grid container item direction='row' spacing={2}>
        <Grid item xs={8} sm={4} lg={4}>
          <InputTextField hidden name='nome_thread' label={thread.nome_thread} />
          <InputTextField fullWidth name='cron_tab' label={thread.nome_thread} />
        </Grid>
        <Grid item xs={8} sm={4} lg={4}>
          <CheckBoxField name='habilita' defaultChecked={thread.habilita}/>
          <ButtonPropSubmit size='medium' type='submit'><Icon>update</Icon></ButtonPropSubmit>
        </Grid>
      </Grid>
    </Form>)
}

const onSubmit = (formv: Thread, setAlert: Dispatch<SetStateAction<IAlert>>) => {
  axios.post(`${web}/thread/atualiza-thread`, formv).then((resp) => {
    setAlert({message: resp.data, visible: true, color: 'success'})
  }).catch(e => {
    console.log(e)
    setAlert({message: `Erro ao atualizar configuracoes`, visible: true, color: 'error'})
  })
}

export const FormConsultaThread = () : JSX.Element => {
  const [configuracoes, setConfiguracoes] = useState<Thread[]>([]);
  const [alert, setAlert] = useState<IAlert>({message: 'enviado', visible: false})

  useEffect(()=>{
    axios.get<Thread[]>(`${web}/thread/consulta-configuracao`).then(res => {
      setConfiguracoes(res.data)
    }).catch(e => {
      console.log(e)
      setAlert({message: `Erro ao carregar configuracoes`, visible: true, color: 'error'})
    })
  },[])

  if (configuracoes.length > 0) {
    return (
      <>
        <Alert {...alert}/>
        <Box sx={{ m: 2 }} display='flex' flexDirection='column' component={Paper} variant='outlined'>
          <Grid container direction={'column'} padding={2} spacing={2}>
            <Grid container item direction='row' spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <h5>Configuração de Threads:</h5>
              </Grid>
            </Grid>
            {configuracoes.map((conf: Thread, index: number) => {
              return <FormThreadEnvio key={index} thread={conf} setAlert={setAlert} />}
            )}
          </Grid>
        </Box>
      </>
    )
  } else {
    return (
      <Loading />
    );
  }
}

export default FormConsultaThread;