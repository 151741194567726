import React from 'react'
import {tela_fluxo} from '../telas_id'
import {Card, CardTitle, CardBody, Label} from 'reactstrap'
import FadeIn from 'react-fade-in'
import './styles/estilo.css'
import {status, situacao} from '../components/Info'
import { Seguir, CheckedBox } from './styles/estilo_telas'

import { PropAccept } from '../ts/interfaces/PropTelas.interfaces';
import { addInfo, updateInfoBPO } from '../state/actions/Info_actions'
import { getStateJornada } from '../state/actions/jornada_actions'
import img from '../images/comprovante2.png'

interface State {
  unlock: boolean
}

const {REACT_APP_NAME_LECCA} = process.env

export default class ValidacaoRefin extends React.Component<PropAccept, State>{
  constructor(props : PropAccept) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.Aceitar = this.Aceitar.bind(this);
    this.Rejeitar = this.Rejeitar.bind(this);
  }

  state = {
    unlock: true
  }

  handleChange(e : React.ChangeEvent<HTMLInputElement>){
    this.setState({unlock: e.target.checked})
  }

  componentDidMount(){
    updateInfoBPO({fase: status.tela_lgpd, situacao: situacao.sucesso});
    //document.getElementsByClassName('modal')[0].display = 'block';
  }

  Aceitar() {
    updateInfoBPO({fase: status.lgpd_aceita, situacao: situacao.sucesso});
    addInfo(tela_fluxo.validacao);
    this.props.changePasso();
  }

  Rejeitar() {
    const {id} = getStateJornada();
    updateInfoBPO({fase: status.lgpd_rejeita, situacao: situacao.sucesso});
    this.props.history.push('/rejeitar/'+id)
  }


  render(){

    const {unlock} = this.state;

    return(
      <FadeIn delay={50}>
        
        <div className='corpo' style={{margin: '20px', display: 'flex', justifyContent: 'center'}}>
        <Card 
          body
          >
        <CardTitle tag='h5' style={{textAlign: 'center'}}>Refinanciamento - Refin</CardTitle>
        <CardBody>
        <p style={{textAlign: 'center', textJustify: 'inter-word'}}>
          Você aceitou fazer um refinanciamento do seu empréstimo do cartão de benefícios {REACT_APP_NAME_LECCA}?
        </p>
        <br/>
        <p style={{textAlign: 'center', textJustify: 'inter-word'}}>
          Uma alternativa para se reorganizar financeiramente!
        </p>
        <div style={{margin: "2rem", width: 'auto',display: 'flex', justifyContent: 'center'}}>
          <img width={'200px'} src={img} alt="doc" />

        </div>
        <div>
          <CheckedBox onChange={this.handleChange} /> <Label check> <b>Sim, quero seguir a formalização</b> </Label>
        </div>
        <br/>
        <Seguir width='30%' unlock={unlock} onClick={this.Aceitar} />
        </CardBody>
        </Card>
        </div>
      </FadeIn>
    );

  }


}