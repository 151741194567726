import { createSlice } from '@reduxjs/toolkit'
import { UserSession } from '../data-types'

const initialState : UserSession = {
	usuario: undefined
}

interface Action {
  type: string,
  payload: UserSession
}

const UserSlice = createSlice({
  name: 'userSession',
  initialState: initialState,
  reducers: {
    setSession: (state: UserSession, action: Action) => {
				state = action.payload;
				return state
    }
  }
})


export const userActions = UserSlice.actions

export default UserSlice.reducer;