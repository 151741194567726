import React from 'react'
import { Base64Type } from '../ts/types/Types'
import Webcam from 'react-webcam'
import { PropTelaDoc } from '../ts/interfaces/PropTelas.interfaces'
import FadeIn from 'react-fade-in'
import { Button } from 'reactstrap'
import { ResPic, ButtonsInline, Command, LayoutSelfie } from '../telas/styles/estilo_prova_vida'
import {Selfie} from '../components/CameraFluxo'
import Apresentacao from '../components/screen_components/Apresentacao.component'
import { TirarFotoText } from '../telas/text/prova_vida.text'
import { documento_id, tela_fluxo } from '../telas_id'
import { addInfo, updateInfoBPO } from '../state/actions/Info_actions'
import {status, situacao} from '../components/Info'
import Loading from '../components/Loading'

const tela = {
  inicial: 0,
  foto: 1
}

const videocons = {
  width: 420,
  height: 420,
  facingMode: 'user'
}

interface State {
  refazer: boolean,
  data: Base64Type,
  error: string,
  step: number
}

const {REACT_APP_PERFIL} = process.env
const perfil = REACT_APP_PERFIL

export default class SelfieTela extends React.Component<PropTelaDoc, State> {
  webcam: React.RefObject<Webcam>
  
  constructor(props : PropTelaDoc) {
    super(props)

    this.webcam = React.createRef<Webcam>();

    this.Takecapture = this.Takecapture.bind(this)
    this.CameraError = this.CameraError.bind(this)
    this.Seguir = this.Seguir.bind(this)

    this.state = {
      refazer: true,
      data: null,
      error: 'Habilite sua camera, caso queira prosseguir.',
      step: tela.inicial
    }
  }

  componentDidMount(): void {
    updateInfoBPO({fase: status.tela_selfie, situacao: situacao.sucesso});
    addInfo(tela_fluxo.selfie);
  }

  Takecapture() {
    const {error} = this.state
    var screenshot = this.webcam?.current?.getScreenshot();
    if(screenshot){
      this.setState({data: screenshot, refazer: false})
    }else{
      this.props.ShowAlert(error);
    }
  }
  
  CameraError(e: string | DOMException) {
    const { error } = this.state
    this.setState({data: null, refazer: true})
    updateInfoBPO({fase: status.selfie_capturada, situacao: situacao.falha, mensagemErro: `Nao foi possivel acessar a camera: ${e}`});
    this.props.ShowAlert(error)
  }
  
  Seguir() {
    this.props.InsertScreenShot(this.state.data, documento_id.selfie)
    updateInfoBPO({fase: status.selfie_capturada, situacao: situacao.sucesso});
    this.props.changePasso();
  }
  
  Apresentacao() {
    return(
    <Apresentacao 
      id='Selfie'
      onClick={()=>this.setState({step: tela.foto})}
      />
    );
  }
  
  TelaFoto() {
    const {refazer} = this.state;
    let id = ''
    if(perfil == "BPO"){
      id = 'button-bpo'
    }

    return(
    <FadeIn delay={50}>
        <LayoutSelfie>
          <Command>
          {refazer? 
            <div>
              <p><b>{TirarFotoText.title1}</b></p> 
              <p><b>{TirarFotoText.title2}</b></p>
              <p><b>{TirarFotoText.title3}</b></p>
            </div>
            : 
            <p><b>{TirarFotoText.title4}</b></p>
          }
          </Command>

          
          {refazer? (
            <Selfie webcam={this.webcam} videocons={videocons} onClick={this.Takecapture} CameraError={this.CameraError} />
          ) : (
            <ResPic>
              <img alt='Selfie tirada' src={this.state.data as string}/>
              <ButtonsInline>
                <Button id={id} onClick={()=>this.setState({refazer: true})} >Tirar novamente</Button>
                <Button color='info' onClick={this.Seguir}> Seguir </Button>
              </ButtonsInline>
            </ResPic>
          )}
              
        </LayoutSelfie>
      </FadeIn>
    );
  }

  render(){
    const {step} = this.state;
    const {enviado} = this.props;

    if(!enviado){
      return(
        <div style={{marginTop: 20}}>
          <Loading text={'Carregando...'}/>
        </div>
      );
    }

    switch(step){
      case tela.inicial:
        return(this.Apresentacao());
      case tela.foto:
        return(this.TelaFoto());
      default:
        break;
    }
    return <></>;
  }

}