
import {isBrowser} from 'react-device-detect'

export const ApresentacaoText = {
  title1: 'Comprovante de Residência',
  text1:'Na tela a seguir verifique se o endereço informado confere com o seu endereço atual, caso não, altere as informações apresentadas.',
  text2: isBrowser? <p>Caso você já tenha uma foto que permita a leitura dos dados do seu documento, você pode escolher entre anexá-la ou tirar uma nova foto do documento.</p> : null
}

export const OpcaoText = {
  title: 'Escolha uma opção de sua preferência'
}

export const AnexarText = {
  title1: 'Envie uma foto do seu comprovante de residência',
  title2: 'Você consegue ler o documento? Então você pode seguir para a próxima etapa.',
  error: 'Documentos precisam estar abaixo de 2MB',
  error2: 'Documentos precisam ser imagens ou pdf: .jpg, .jpeg, .png, .bmp, .gif, .pdf'
}

export const TirarFotoText = {
  title1: 'Tire sua foto em um local bem iluminado e centralize o seu comprovante',
  title2: 'Você consegue ler o documento? Então você pode seguir para a próxima etapa.',
  error: 'Habilite sua camera, caso queira prosseguir.'
}