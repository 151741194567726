import { getStateJornada } from "../state/actions/jornada_actions"
import { IDocument, IScrollProps } from "../ts/interfaces/Components.interfaces"
import { PropostaEmprestimo } from "../ts/types/Types"

const RefinInfo = ({ Status } : IDocument) => {

  let w = '100hw'
  let h = '65vh'

  const fontS = '14px/16px'

  const styles : IScrollProps = {
    scroll: {width:w, height:h,backgroundColor: '#ffff',border:'1px solid #ccc',font:fontS+' Arial, Helvetica, sans-serif',overflow:'auto', borderRadius: '5px', marginBottom: '1rem'},
  }

  const {contrato} = getStateJornada()
  const Proposta = contrato.Proposta as PropostaEmprestimo

  const ContratosOld = () => {
    return(
      <div>
        <h5 style={{textAlign: 'center'}}> <b>II – Informações do(s) Contrato(s) refinanciados(s):</b> </h5>
        {Proposta? Proposta.DadosRefin.map((data, index) =>
          <div className="infoContrato" key={index}>
            <ul>
              <li><h5><b>Número do Contrato: </b>{data.NumeroContrato}</h5> 
              </li>
                <ul>
                  <li><b>Valor da Parcela: </b>R$ {data.ValorParcela}</li>
                  <li><b>Prazo:</b> {data.QuantidadeDeParcelas} meses</li>
                  <li><b>Qt. Parcelas Pagas:</b> {data.QuantidadeDeParcelas - data.QuantidadeParcAbertas}</li>
                  <li><b>Valor do Saldo Devedor:</b> {data.Saldo}</li>
                </ul>
            </ul>
          </div>
        ): ''}
      </div>
    )
    
  }

  const ContratoRefin = () => {

    
    return(
        <div>
          <h5 style={{textAlign: 'center'}}> <b>I – Informações do Novo Contrato:</b> </h5>
          <div className="infoContrato">
          <ul>
            <li>Valor Líquido Liberado: R$ {Proposta?Proposta.PropostaParcelado.ValorLiquidoCliente: 0}</li>
            <li>Valor da Tarifa: R$ </li>
            <li>Valor do IOF: R$ {Proposta?Proposta.PropostaParcelado.ValorIOF: 0}</li>
            <li>Valor Principal da CCB: R$ {contrato.PropostaParcelas? contrato.PropostaParcelas[0].ValorPrincipal: '0'}</li>
            <li>Taxa de Juros: 
              <ul>
                <li>{Proposta?Proposta.PropostaParcelado.TaxaAPMes: "0"}%</li>
                <li>{Proposta?Proposta.PropostaParcelado.TaxaAPAno: "0"}%</li>
              </ul>
            </li>
            <li>Prazo: {Proposta? Proposta.PropostaParcelado.Prazo: ''} meses</li>
            <li>Valor de cada parcela: R$ {Proposta? Proposta.PropostaParcelado.ValorParcela: 0}</li>
          </ul>
          

          </div>
        </div>
    )
    
  }
  const RefinBox = () => {
    return(
      <div onScroll={Status} style={styles.scroll}>
        <ContratoRefin/>
        <ContratosOld/>
      </div>
    )
  }

  return RefinBox()
}

export default RefinInfo