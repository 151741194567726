import { JornadaKey } from '../data-types/index'
import { createSlice } from '@reduxjs/toolkit'

const initialState : JornadaKey = {
  id: '',
  idJornada: '',
  nome: '',
  tipo: 0,
  contrato: {
    Proposta:{
      NumeroProposta:0,
      CodigoCliente:1054,
      CodigoProponente:2064,
      TipoPessoa:1,
      CPFCliente:"000.000.003-53",
      NomeCliente: 'default',
      Matricula:"1235478",
      ValorSolicitado:500.0,
      ValorFinanciado:0.0,
      ValorIOF:0.0,
      ValorCompra:0.0,
      ValorRefin:0.0,
      ValorDespesas:0.0,
      ValorComissao:0.0,
      Coeficiente:0.0,
      Prazo:20,
      ValorParcela:500.0,
      ValorBruto:0.0,
      ValorLiquidoCliente:0.0,
      TaxaCLMes:3.99,
      TaxaCLAno:59.9186,
      TaxaAPMes:0.0,
      TaxaAPAno:0.0,
      TaxaNominalMes:0.0,
      TaxaNominalAno:0.0,
      TaxaCETMes:0.0,
      TaxaCETAno:0.0,
      CodigoBanco:1,
      NomeBanco:"BPO BANCO",
      CodigoMatriz:1,
      NomeMatriz:"MATRIZ 1",
      CodigoGerente:1,
      NomeGerente:"SUPERVISOR 1",
      CodigoPromotora:1,
      NomePromotora:"PROMOTORA 1",
      CodigoOperador:1,
      NomeOperador:"OPERADOR 1",
      CodigoLoja:1,
      NomeLoja:"PV000001",
      CodigoEmpregador: 81,
      NomeEmpregador:"Governo Maranhão",
      CodigoOrgao:4,
      NomeOrgao:"ORGAO 1",
      ValorTC:0.0,
      IDTabelaFinanciamento:18,
      NumeroTabela:"000233",
      NomeTabela:"CARTAO + SAQUE",
      DataPrimeiroVencimento:"2079-02-02T00:00:00",
      DataUltimoVencimento:"2079-02-02T00:00:00",
      NumeroContrato:"88-12345678",
      NumeroOperacaoConsignada:"",
      PropostaCadastradaNoBanco:false,
      CodigoProduto:1,
      DataBase:"2021-11-09T00:00:00",
      DataCadastro:"2021-11-09T22:20:45.767",
      CodigoUsuario:"2",
      Beneficiario:{
        TipoBeneficiario:2,
        NomeBeneficiario: 'default',
        CGCBeneficiario:"000.000.003-53",
        TipoConta:1,
        NumeroBanco:"",
        NumeroAgencia:"",
        NumeroDVAgencia:"",
        NumeroConta:"",
        NumeroDVConta:""
      },
      TipoProposta:1,
      DadosTransfer:null,
      DadosRefin:[
        {
          NumeroProposta: 0,
          CodigoCliente: "3582",
          CPF: "390.920.618-24",
          Matricula: "112345",
          NumeroContrato: "7000035693",
          Saldo: 7232.31,
          ValorParcela: 250,
          QuantidadeDeParcelas: 72,
          QuantidadeParcAbertas: 72,
          QuantidadeParcVencidas: 8,
          QuantidadeParcAVencer: 64,
          ParcelasRefin: [
            {
              NumeroContrato: "7000035693",
              NumeroParcela: 1,
              ValorParcela: 250,
              SaldoParcela: 250,
              DataDeVencimento: "2022-12-15T00:00:00",
              ValorConfirmado: 0
            },
          ]
        },
        {
          NumeroProposta: 0,
          CodigoCliente: "3582-2",
          CPF: "390.920.618-24",
          Matricula: "112345",
          NumeroContrato: "7000035693-2",
          Saldo: 8232.31,
          ValorParcela: 250,
          QuantidadeDeParcelas: 72,
          QuantidadeParcAbertas: 72,
          QuantidadeParcVencidas: 8,
          QuantidadeParcAVencer: 64,
          ParcelasRefin: [
            {
              NumeroContrato: "7000035693-2",
              NumeroParcela: 1,
              ValorParcela: 250,
              SaldoParcela: 250,
              DataDeVencimento: "2022-12-15T00:00:00",
              ValorConfirmado: 0
            },
          ]
        }
      ],
      NumeroADE: "",
      NumeroPropostaRefinPort: 0,
      TipoPropostaBack: 0,
      PropostaSaqueTotalFGTS: false,
      IDFgts: ""
  }, 
  Cliente:{
     CodigoCliente:1054,
     ContaDigital: {
      TipoConta: 0,
      NumeroBanco: "",
      Agencia: "",
      Conta: "",
      DVConta: ""
     },
     CodigoProponente:0,
     DadosCliente:{
        NomeCliente: 'default',
        TipoPessoa:1,
        CPF: 'default',
        DataNascimento: 'default',
        Registro:{
           Numero:"",
           DataEmissao:"2079-02-02T00:00:00",
           LocalEmissao:"",
           UFEmissao:""
        },
        DadosEmpresa: {
         CNPJ: "",
         NomeEmpresa: "",
         DataAdmissao: "",
         CodigoProfissao: 0,
         DescricaoProfissao: {},
         Cargo: "",
         Renda: 0,
         OutrasRendas: 0,
         NumeroBeneficio: "",
         MatriculaInstituidor: "",
         IdentificadorMargem: "",
         IdentificadorMargem2: "",
         IdentificadorMargem3: "",
         IdentificadorMargem4: "",
         CodigoAverbacao: "",
         MesAno_ContraCheque: "",
         NaturezaOcupacao: 0
        },
        CidadeNatal:"DF",
        UFNatal:"DF",
        Sexo:0,
        EstadoCivil:2,
        TipoResidencia:0,
        TempoResidencia:"00",
        NomePai:"",
        NomeMae:"",
        Enderecos:[
           {
             TipoEndereco:1, //Residencial
             CEP:"07050000",
             Logradouro:"Av Emilio Ribas",
             Numero:"1",
             Complemento:"CMPLTO",
             Bairro:"Jardim Eugenia",
             Cidade:"Guarulhos",
             UF:"SP",
             Latitude:"",
             Longitude:""
           },{
             TipoEndereco:3,
             CEP:"07050000",
             Logradouro:"Av Emilio Ribas",
             Numero:"1",
             Complemento:"CMPLTO",
             Bairro:"Jardim Eugenia",
             Cidade:"Guarulhos",
             UF:"SP",
             Latitude:"",
             Longitude:""
           }
        ],
        Telefones:[
           {
             TipoTelefone:3, //Telefone Celular
             DDD:'default',
             NumeroTelefone:'default',
             Operadora:0,
             NaoPerturbe: false
           }
        ],
        DadosCadastro:{
           DataDeCadastro:"2021-11-09T22:21:00",
           UsuarioInclusao:"MASTER",
           DataDeAlteracao:"2021-11-09T22:21:00",
           UsuarioAlteracao:"MASTER"
        },
        Email: 'default',
        RamoAtividade:"",
        TipoInstalacao:4,
        PossuiCNH:false
     },
     NomeRef1:"",
     DDDRef1:"",
     FoneRef1:"",
     NomeRef2:"",
     DDDRef2:"",
     FoneRef2:"",
     ReferenciaBancaria:{
        TipoConta:1,
        NumeroBanco:"",
        Agencia:"",
        Conta:"",
        DVConta:""
     },
     DadosConjuge:{
        NomeConjuge:"",
        CPFConjuge:"",
        DataNascimentoConjuge:"2079-02-02T00:00:00",
        RGConjuge:"",
        RendaConjuge:0.0
     }
  }
  },
  ultimaJornada: null
}

type Action = {
  type: string,
  payload: JornadaKey
}

const jornadaSlice = createSlice({
  name: 'Jornada',
  initialState: initialState,
  reducers: {
    setJornada: (state: JornadaKey, action: Action) => {
      Object.keys(action.payload).map(x=>state[x] = action.payload[x]);
			return state;
    }
  }
})

export const jornadaActions = jornadaSlice.actions

export default jornadaSlice.reducer;