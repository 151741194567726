import { infoBPOKey } from '../data-types/index'
import { createSlice } from '@reduxjs/toolkit'

const initialState : infoBPOKey = {
  idJornada: '0',
  fase: 0,
  situacao: 1, //1 sucesso 2 falha
  sistemaOperacional: "",
  navegador: "",
  mensagemErro: ''
}

type Action = {
  type: string,
  payload: infoBPOKey
}

const infoBPOSlice = createSlice({
  name: 'infoBPO',
  initialState: initialState,
  reducers: {
    setInfo: (state: infoBPOKey, action: Action) => {
      Object.keys(action.payload).map(x=> state[x] = action.payload[x]);
			return state;
    }
  }
})

export const infoBPOActions = infoBPOSlice.actions;

export default infoBPOSlice.reducer

/*

const reducer = async (state: infoBPO = initialState, action : Action) => {
  switch(action.type) {
    case InfoActionType.SET:
      return action.info;
    case InfoActionType.UPDATE:
      try{
        await axios.post(`${webs}/atualiza-jornada`, action.info);
      }catch(e){}
      return action.info;
    default:
      return state;
  }
}

export default reducer;
*/