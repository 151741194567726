import { Alert, Button, Icon, AlertColor, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

export interface IAlert {
  message: string,
  visible: boolean,
  color?: AlertColor,
  setAlert?: React.Dispatch<React.SetStateAction<IAlert>>
}

export const AlertManager = ({ message, visible, color="success", setAlert} : IAlert) : JSX.Element => {
  const [open, setOpen] = useState<boolean>(false || visible);

  useEffect(()=>{
    setOpen(visible);
  }, [visible])
  
  if(open){
    return(
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}} open={open} autoHideDuration={5000} onClose={() => {setOpen(false); if (typeof setAlert!== 'undefined') {setAlert({message: '',visible: false})} }}>
        <Alert action={
          <Button color="inherit" onClick={() => {setOpen(false); if(typeof setAlert!== 'undefined'){ setAlert({message: '',visible: false})} }}><Icon fontSize="inherit">close</Icon></Button>
        } severity={color} sx={{ width: '100%'}}>
          {message}
        </Alert>
      </Snackbar>
    )
  }
  return(<></>)
}

export default AlertManager