import React from 'react'
import './styles/prova_vida.css'
import './styles/estilo.css'
import { Button, Card, CardText, CardTitle, CardBody} from 'reactstrap';
import FadeIn from 'react-fade-in';
import selfie from '../images/selfie.png'
import { tela_fluxo } from '../telas_id'
import Loading from '../components/Loading'

import FaceDetect from '../components/FaceDetect'

import Countdown from "react-countdown";

import { blobToBase64 } from '../util/Utils';
import axios from 'axios';


import {ApresentacaoText, RastreioText} from '../telas/text/prova_vida.text'

import { BoxInfo } from '../telas/styles/estilo_telas'
import { Banner, SeguirBtn } from '../telas/styles/estilo_prova_vida'
import {status, situacao} from '../components/Info'

import Webcam from 'react-webcam';
import { PropFluxoProvaVida } from '../ts/interfaces/PropTelas.interfaces';

import Upload from '../components/Upload.local.component';
import { addInfo, getInfoList, updateInfoBPO } from '../state/actions/Info_actions';
import { getStateJornada, setStateJornada } from '../state/actions/jornada_actions';
import ErroIdentificacao from '../components/screen_components/ErroIdentificacao.component';
import { PPVida } from '../ts/types/Types';


const {REACT_APP_SERVER, REACT_APP_PERFIL} = process.env;

const webs = REACT_APP_SERVER

const tela = {
  validacao: 0,
  inicial: 1,
  rastreamento: 2,
  erro_de_captura: 3
}

interface State {
  px: number,
  py: number,
  size: number,
  movimento: number,
  refazer: boolean,
  step: number,
  stopRecord: boolean,
  enviado: boolean,
  blob: Blob | null,
  resumo: PPVida,
  tentativa: number,
  aceita: boolean,
  seconds: number
}

export default class ProvadeVidaFluxo extends React.Component<PropFluxoProvaVida, State> {
  webcam: React.RefObject<Webcam>

  constructor(props : PropFluxoProvaVida){
    super(props)
    // props: insertScreenshot, AddEvento, ChangePasso
    //this.Takecapture = this.Takecapture.bind(this)
    this.onUpdatePos = this.onUpdatePos.bind(this)
    this.onUpdatePosSave = this.onUpdatePosSave.bind(this)
    this.onRecord = this.onRecord.bind(this)
    this.onUpdateFace = this.onUpdateFace.bind(this)
    this.onUpdateCounter = this.onUpdateCounter.bind(this)
    this.onFinishCount = this.onFinishCount.bind(this)
    this.sendInfo = this.sendInfo.bind(this)
    this.sendRecord = this.sendRecord.bind(this)

    this.webcam = React.createRef<Webcam>();

    this.state = {
      px: 0,
      py: 0,
      size: 0,
      movimento: -1,
      refazer: true,
      step: tela.validacao,
      stopRecord: false,
      enviado: true,
      tentativa: 3,
      aceita: false,
      resumo: {movimento_concluido: 0, prova_vida_suspeita: false, etapas: {sorriso: false, rosto_direita: false, rosto_esquerda: false}},
      blob: null,
      seconds: -1
    }

  }

  componentDidMount() {
    updateInfoBPO({fase: status.tela_prova_vida, situacao: situacao.sucesso});
    addInfo(tela_fluxo.prova_vida);
  }

  Validacao() {
    const {contrato} = getStateJornada();

    return(
      <FadeIn delay={50}>
        <div style={{marginTop: '20px'}}>
        <Card
          body
          >
        <CardBody>
        <p>Olá <b>{contrato.Cliente.DadosCliente.NomeCliente}</b>,</p>
        <p style={{textAlign: 'justify', textJustify: 'inter-word'}}>Para seguirmos com a análise da sua operação, precisamos que você faça novamente a etapa a seguir.</p>
        <Button block={true} id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} onClick={()=>{this.setState({step: tela.inicial})}}> Seguir </Button>
        </CardBody>
        </Card>
        </div>
      </FadeIn>
    );
  }

  Apresentacao() {
    return(
      <FadeIn delay={50}>
        <BoxInfo>
          <Card
            body
            className="text-center"
          >
            <CardTitle tag="h5">
              Prova de Vida
            </CardTitle>
            <CardText>
            Faremos um pequeno vídeo seu, tudo bem? Para capturarmos bem o seu rosto, siga as instruções apresentadas.
            </CardText>
          </Card>
          
          <Banner alt='Intro Identidade' src={selfie} />
          
          <p>{ApresentacaoText.text2}</p>
          <Button block={true} id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} onClick={()=>{this.setState({step: tela.rastreamento})}}> Seguir </Button>
        </BoxInfo>
      </FadeIn>
    );
  }

  onUpdatePos(value : number, x : number =0, y : number=0, size : number=0){
    //console.log(value)
    if(x != 0 && y != 0 && size != 0){
      this.setState({px: x, py: y, size: size})
    }
    this.setState({movimento: value})
  }

  onUpdatePosSave(value : number){
    this.setState({movimento: value})
  }

  onRecord(blob : Blob){
    const {movimento_concluido} = this.state.resumo;
    
    if(movimento_concluido > 1) { 
      updateInfoBPO({fase: status.prova_vida_capturada, situacao: situacao.sucesso});
      this.setState({ blob, aceita: true});
    }else{
      this.setState({ blob, aceita: false });
    }
  }

  sendRecord(){
    const {tentativa, aceita} = this.state;
    let {resumo} = this.state;

    if(aceita){
      setStateJornada({ prova_vida: resumo });
      this.sendInfo();
    }else if(tentativa > 0){
      this.setState({step: tela.erro_de_captura});
    }else{
      resumo.prova_vida_suspeita = true;
      setStateJornada({ prova_vida: resumo });
      this.sendInfo();
    }
  }

  sendInfo = () => {
    const {blob} = this.state;
    const {id, idJornada, contrato, prova_vida} = getStateJornada();
    const list = getInfoList();
    this.setState({stopRecord: true});

    let element = {
      nome: `${id}_prova_vida.mp4`,
      tipo: 'Outros'
    }
    this.setState({enviado: false});
    blobToBase64(blob as Blob).then(
      b64 => {
        Upload(`${id}_prova_vida.mp4`, b64 as string, contrato.Proposta.NumeroProposta).then(()=>{
          updateInfoBPO({fase: status.prova_vida_recebida, situacao: situacao.sucesso});
          axios.post(webs+'/proposta/send-info', {
            id: id,
            idJornada: idJornada,
            obs: "",
            prova_vida: prova_vida,
            documentos: [element],
            eventos: list
          }).then(
            resp => {
              updateInfoBPO({fase: status.finalizacao, situacao: situacao.sucesso});
              this.props.history.push('/agradecimento/3');
            }
          ).catch(
            err => {
              updateInfoBPO({fase: status.finalizacao, situacao: situacao.falha, mensagemErro: err.response});
              this.props.history.push('/agradecimento/3');
            }
          );
        }).catch(e => {
          updateInfoBPO({fase: status.prova_vida_recebida, situacao: situacao.falha, mensagemErro: e.response.data});
          this.props.history.push('/erro/inesperado');
        });
      }
    ).catch(e => this.props.history.push('/erro/inesperado'));
  }

  onUpdateFace(value : {x: number, y: number, felicidade: number}){
    const {movimento, px} = this.state
    let { resumo } = this.state;
    let p = value.x/px;
    const width = window.innerWidth;

    if(value.felicidade > 0.5){
      resumo.movimento_concluido += 1;
      resumo.etapas.sorriso = true;
    }else if(p < 0.8) {
      resumo.movimento_concluido += 1;
      resumo.etapas.rosto_direita = true;
    }else if(p > 1.2) {
      resumo.movimento_concluido += 1;
      resumo.etapas.rosto_esquerda = true;
    }

    /*console.log(value)
    if(px != 0){
      console.log(px)
    }*/
    if(movimento == -1 && (value.x >= width/2 + 10 || value.x <= width/2 - 10)){
      this.setState({movimento: 0, px: value.x, resumo })
    }else if((movimento == 0) && (value.felicidade > 0.5)){
      this.setState({movimento: 1, resumo })
    }else if((movimento == 1) && (p < 0.8)){
      this.setState({movimento: 2, resumo })
    }else if((movimento == 2) && (p > 1.2)){
      this.setState({movimento: 3, resumo })
    }
  }

  onUpdateCounter({seconds} : {seconds: number}){
    return(<span>{seconds}</span>)
  }

  onFinishCount() {
    const {movimento} = this.state;
    if(movimento < 3){
      this.setState({movimento: movimento + 1})
    }
  }

  RastreioPersonalizado() { 
    const {movimento} = this.state
  
    const showMask = (movimento == -1)

    return(
      <FadeIn delay={50}>
        <div style={{marginTop: '50px', marginBottom: '100px'}}>
              {movimento != null?(
              <p id='font-command-mobile'>
                {movimento == -1 && (<b>{RastreioText.movimento1}</b>)}
                {movimento == 0 && (<b>{RastreioText.movimento2} &#128512; ... (<Countdown date={Date.now()+5000} intervalDelay={0} precision={3} renderer={this.onUpdateCounter} onComplete={this.onFinishCount}/>)</b>)}
                {movimento == 1 && (<b>{RastreioText.movimento3} ... (<Countdown date={Date.now()+5000} intervalDelay={0} precision={3} renderer={this.onUpdateCounter} onComplete={this.onFinishCount} />)</b>)}
                {movimento == 2 && (<b>{RastreioText.movimento4} ... (<Countdown date={Date.now()+5000} intervalDelay={0} precision={3} renderer={this.onUpdateCounter} onComplete={this.onFinishCount} />)</b>)}
                {movimento == 3 && (<b>{RastreioText.movimento5}</b>)}
              </p>
              ): null }

              <FaceDetect UpdateVal={this.onUpdateFace} InsertVideoBlob={this.onRecord} showMask={showMask} start={(movimento >= 0)} stop={(movimento >= 3)} timeRecord={0} />

              {movimento == 3 && (
                <SeguirBtn onClick={this.sendRecord}/>
              )
              }
        </div>
      </FadeIn>
    );
  }

  ErroCaptura() {
    const {tentativa} = this.state;

    return(
      <ErroIdentificacao 
        id='ProvaVida' 
        ReiniciarTentativa={()=>{ this.setState({
          step: tela.rastreamento, 
          movimento: -1, 
          resumo: {
            movimento_concluido: 0, 
            prova_vida_suspeita: false, 
            etapas: {
              sorriso: false, 
              rosto_direita: false, 
              rosto_esquerda: false
            }},
            tentativa: tentativa - 1}) 
          }}
        tentativa={tentativa}
      />
    );
  }

  render(){
    const {step, enviado} = this.state;
    
    if(!enviado){
      return(
        <div style={{marginTop: 20}}>
          <Loading text={'Carregando...'}/>
        </div>
      );
    }

    switch(step){
      case tela.validacao:
        return(this.Validacao());
      case tela.inicial:
        return(this.Apresentacao());
      case tela.rastreamento:
        return(this.RastreioPersonalizado());
      case tela.erro_de_captura:
        return(this.ErroCaptura());
      default:
        break;
    }
    return <></>;
  }
}