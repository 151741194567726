import axios from 'axios'
import { infoBPO, infoBPOKey, Info } from '../data-types/index'
import { isMobile } from 'react-device-detect';
import { infoBPOActions, infoActions } from '../reducers/index';
import { Etapa } from '../../ts/types/Types';
import { getPassoById } from '../../telas_id';
import { SHA256 } from 'crypto-js';
import DeviceDetector, { DeviceDetectorResult } from "device-detector-js";
import store from '../store'
import { getStateJornada } from './jornada_actions';

const {REACT_APP_SERVER, REACT_APP_API_LOCATION} = process.env;

const webs = REACT_APP_SERVER
const api_location = REACT_APP_API_LOCATION

export const status = {
  jornada_iniciada: 0,
  tela_autenticacao: 1,
  autenticacao_efetuada: 2,
  tela_lgpd: 3,
  lgpd_aceita: 4,
  lgpd_rejeita: 5,
  tela_selfie: 6,
  selfie_capturada: 7,
  selfie_recebida: 8,
  tela_prova_vida: 9,
  prova_vida_recebida: 10,
  prova_vida_capturada: 11,
  tela_cnh: 12,
  cnh_frente_capturada: 13,
  cnh_frente_recebida: 14,
  cnh_verso_capturada: 15,
  cnh_verso_recebida: 16,
  tela_comprovante_residencia: 17,
  comprovante_residencia_capturado: 18,
  comprovante_residencia_recebido: 19,
  tela_contracheque: 20,
  contracheque_recebido: 21,
  contracheque_capturado: 22,
  tela_termo: 23,
  termo_aceito: 24,
  termo_rejeitado: 25,
  tela_ccb: 26,
  ccb_aceito: 27,
  ccb_rejeitado: 28,
  tela_resumo: 29,
  finalizacao: 30
}

export const situacao = {
  sucesso: 1,
  falha: 2
}

export const setInfoBPO = (data: infoBPOKey) => {
  store.dispatch(infoBPOActions.setInfo(data))
}

export const getInfoBPO = () => {
  return store.getState().InfoBPO as infoBPO;
}

export const getIdJornada = () => {
	return (store.getState().InfoBPO as infoBPO).idJornada;
}

export const updateInfoBPO = async (data: infoBPOKey) => {
  store.dispatch(infoBPOActions.setInfo(data));
	const { id } = getStateJornada()
  try{
    await axios.post(`${webs}/atualiza-jornada`, {id, ...getInfoBPO()});
  }catch(e){}
}

export const log = async (message: any) => {
  const {id, contrato} = getStateJornada();
  try{
    await axios.get(`${webs}/log-info`, {params: {
      proposta: contrato.Proposta.NumeroProposta,
      token: id,
      msg: message
    }});
  }catch(e){}
}

export const getInfoList = () => {
  const listInfo: Info[] = store.getState().Info.map((x: string) => JSON.parse(x) as Info);
  return listInfo;
}

export const addInfo = async (index: number) => {
  const {tipo} = getStateJornada();
	const passo = getPassoById(index, tipo);
  try{
    let v1 = await axios(`${api_location}`)
    const res = v1.data;

    var data : Info = {
      timeOpened: new Date().toString(),
      timezone: new Date().getTimezoneOffset() / 60,
      pageon: window.location.pathname,
      referrer: document.referrer,
      previousSites: window.history.length,
      browserName: getBrowser(),
      os: getOS(),
      browserVersion1a: window.navigator.appVersion,
      browserVersion1b: navigator.userAgent,
      browserLanguage: navigator.language,
      browserOnline: navigator.onLine,
      browserPlatform: navigator.platform,
      javaEnabled: navigator.javaEnabled(),
      dataCookiesEnabled: navigator.cookieEnabled,
      dataCookies1: document.cookie,
      dataCookies2: decodeURIComponent(document.cookie.split(';').join()),
      dataStorage: localStorage,
      sizeScreenW: window.screen.width,
      sizeScreenH: window.screen.height,
      sizeDocW: window.innerWidth,
      sizeDocH: window.innerHeight,
      sizeInW: window.innerWidth,
      sizeInH: window.innerHeight,
      sizeAvailW: window.screen.availWidth,
      sizeAvailH: window.screen.availHeight,
      scrColorDepth: window.screen.colorDepth,
      scrPixelDepth: window.screen.pixelDepth,
      latitude: res.latitude.toString().toLowerCase() == 'not found'? 0: res.latitude,
      longitude: res.longitude.toString().toLowerCase() == 'not found'? 0: res.longitude,
      ip: res.ip,
      country: res.country,
      city: res.city,
      estado: res.subdivisionIso,
      passo: passo,
      ID: SHA256(navigator.language + navigator.onLine + window.navigator.appVersion + navigator.userAgent).toString()
    }
  }catch(e) {
    console.log(e)
    return false;
  }
	if(passo){
  	store.dispatch(infoActions.pushInfo(JSON.stringify(data)));
	}
	return true;
}

export const updateEventos = async (ultimaJornada: Etapa) => {
  const {tipo} = getStateJornada();
  try{
    let v1 = await axios(`${api_location}`)
    const res = v1.data;

    for(var i=0; i < ultimaJornada.etapa; i++){
      const passo = getPassoById(i, tipo);

      var data : Info = {
        timeOpened: new Date().toString(),
        timezone: new Date().getTimezoneOffset() / 60,
        pageon: window.location.pathname,
        referrer: document.referrer,
        previousSites: window.history.length,
        browserName: getBrowser(),
        os: getOS(),
        browserVersion1a: window.navigator.appVersion,
        browserVersion1b: navigator.userAgent,
        browserLanguage: navigator.language,
        browserOnline: navigator.onLine,
        browserPlatform: navigator.platform,
        javaEnabled: navigator.javaEnabled(),
        dataCookiesEnabled: navigator.cookieEnabled,
        dataCookies1: document.cookie,
        dataCookies2: decodeURIComponent(document.cookie.split(';').join()),
        dataStorage: localStorage,
        sizeScreenW: window.screen.width,
        sizeScreenH: window.screen.height,
        sizeDocW: window.innerWidth,
        sizeDocH: window.innerHeight,
        sizeInW: window.innerWidth,
        sizeInH: window.innerHeight,
        sizeAvailW: window.screen.availWidth,
        sizeAvailH: window.screen.availHeight,
        scrColorDepth: window.screen.colorDepth,
        scrPixelDepth: window.screen.pixelDepth,
        latitude: res.latitude.toString().toLowerCase() == 'not found'? 0: res.latitude,
        longitude: res.longitude.toString().toLowerCase() == 'not found'? 0: res.longitude,
        ip: res.ip,
        country: res.country,
        city: res.city,
        estado: res.subdivisionIso,
        passo: passo,
        ID: SHA256(navigator.language + navigator.onLine + window.navigator.appVersion + navigator.userAgent).toString()
      }
      if(passo) {
      	store.dispatch(infoActions.pushInfo(JSON.stringify(data)));
			}
  }

  }catch(e){
    console.log(e)
  } 
}

export const getBrowser = () => {
  const deviceDetector : DeviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);

  return device?.client?.name + ' v.' + device?.client?.version;
}

export const getBrowserInfo = () =>{
	const deviceDetector : DeviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);

	return {
		nome: device?.client?.name,
		versao: device?.client?.version
	}
}

export const getOS = () => {
  const deviceDetector : DeviceDetector = new DeviceDetector();
  const device : DeviceDetectorResult = deviceDetector.parse(navigator.userAgent);
  let OSName = device?.os?.name + ' ' + device?.os?.version;
  if(!isMobile){
    if(device?.os?.platform == "x64"){
      OSName += ' 64 bits'
    }else{
      OSName += ' 32 bits'
    }
  }
  return OSName;
}

export const getOSInfo = () => {
  const deviceDetector : DeviceDetector = new DeviceDetector();
  const device : DeviceDetectorResult = deviceDetector.parse(navigator.userAgent);
  return {
		os: device?.os?.name,
		versao: device?.os?.version,
		plataforma: device?.os?.platform
	};
}