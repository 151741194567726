import React from 'react'
import FadeIn from 'react-fade-in'
import { Button, Card, CardBody} from 'reactstrap';
import '../telas/styles/estilo.css'

interface Prop {
  NomeCliente: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const {REACT_APP_PERFIL} = process.env

export const TelaInicial = ({NomeCliente, onClick} : Prop) => {
  return(
    <FadeIn delay={50}>
      <div style={{marginTop: '20px'}}>
      <Card
        body
        >
      <CardBody>
      <p>Olá <b>{NomeCliente}</b>,</p>
      <p style={{textAlign: 'justify', textJustify: 'inter-word'}}>Para seguirmos com a análise da sua operação, precisamos que você faça novamente a(s) etapa(s) a seguir.</p>
      <Button block={true} id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple'} onClick={onClick}> Seguir </Button>
      </CardBody>
      </Card>
      </div>
    </FadeIn>
  )
}

export default TelaInicial