import CCBGenEmprestimo from './ccbgenemprestimo.component'
import CCBGenFGTS from './ccbgenfgts.component';
import { IDocument } from '../../ts/interfaces/Components.interfaces'
import { getStateJornada } from '../../state/actions/jornada_actions';
import CCBGenRefin from './ccbgenrefin.component';

const CCBGen = ({Status} : IDocument) => {
  const {tipo, contrato} = getStateJornada();
  const tipoProposta = contrato.Proposta.TipoProposta
  if(tipo == 5){
    return <CCBGenFGTS Status={Status} />
  }else if(tipoProposta == 7){
    return <CCBGenRefin Status={Status}/>
  }else{
    return <CCBGenEmprestimo Status={Status}/>
  }
}

export default CCBGen