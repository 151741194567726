import { TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

type InputTextProps = TextFieldProps & {
	name: string
}

export const InputDataField : React.FC<InputTextProps> = ({ name, ...rest }) => {
	const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

	const [value, setValue] = useState(defaultValue || '');

	useEffect(()=>{
		registerField({
			name : fieldName,
			getValue : () => value,
			setValue: (_, newValue) => setValue(newValue)
		});
	},[registerField, fieldName, value])
	return(
    <InputMask
      mask='99/99/9999'
      value={value}
      disabled={false}
      onChange={(e) => setValue(e.target.value)}
    >
      <TextField
			  {...rest}
        
			  error={!!error}
			  helperText={error}
			  defaultValue={defaultValue}
			  onKeyDown={()=> error? clearError(): undefined}
		  />
    </InputMask>
	)
} 

export default InputDataField;