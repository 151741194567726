import { ApresentacaoText as text_selfie, ApresentacaoText2 as text_provavida } from "../../telas/text/prova_vida.text";
import { ApresentacaoText as text_identidade } from "../../telas/text/identidade.text";
import { ApresentacaoText as text_comprovante } from "../../telas/text/comprovate.text";
import { ApresentacaoText as text_contracheque } from "../../telas/text/contracheque.text";

import selfie from '../../images/selfie.png'
import identidade from '../../images/foto_identidade.png'
import comprovante from '../../images/foto_comprovante.png'
import contracheque from '../../images/foto_contracheque.png'
import alerta from '../../images/Alerta.png'
import { BannerType } from "../../ts/types/Types";

export const getBanner = (id : string) : BannerType => {
  switch(id){
    case 'Selfie':
      return { title:text_selfie.title1 , text: text_selfie.text1, obs: text_selfie.text2, src: selfie}
    case 'ProvaVida':
      return { title:text_provavida.title1, text: text_provavida.text1, src: selfie}
    case 'Identidade':
      return {title:text_identidade.title1, text: text_identidade.text1, obs: text_identidade.text2, src: identidade}
    case 'Comprovante':
      return {title: text_comprovante.title1, text: text_comprovante.text1, obs: text_comprovante.text2, src: comprovante}
    default:
      break
    }
  return {title:text_contracheque.title1, text: text_contracheque.text1, obs:text_contracheque.text2, src:contracheque}
}

export const getAviso = (id : string) : BannerType => {
  switch(id){
    case 'ProvaVida':
      return { title: "Atenção!", text: "É preciso realizar todos os movimentos na tela para avançar", src: alerta}
    default:
      break
    }
  return {title:text_contracheque.title1, text: text_contracheque.text1, obs:text_contracheque.text2, src:contracheque}
}