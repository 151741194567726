import { SHA256 } from 'crypto-js';
import { isMobile } from 'react-device-detect';
import DeviceDetector, { DeviceDetectorResult } from "device-detector-js";
import { getPassoById } from '../telas_id';
import axios from 'axios';
import { InfoType, Etapa } from '../ts/types/Types';
import { getStateJornada } from '../state/actions/jornada_actions';

const {REACT_APP_SERVER, REACT_APP_API_LOCATION} = process.env;

const webs = REACT_APP_SERVER
const api_location = REACT_APP_API_LOCATION

export const status = {
  jornada_iniciada: 0,
  tela_autenticacao: 1,
  autenticacao_efetuada: 2,
  tela_lgpd: 3,
  lgpd_aceita: 4,
  lgpd_rejeita: 5,
  tela_selfie: 6,
  selfie_capturada: 7,
  selfie_recebida: 8,
  tela_prova_vida: 9,
  prova_vida_recebida: 10,
  prova_vida_capturada: 11,
  tela_cnh: 12,
  cnh_frente_capturada: 13,
  cnh_frente_recebida: 14,
  cnh_verso_capturada: 15,
  cnh_verso_recebida: 16,
  tela_comprovante_residencia: 17,
  comprovante_residencia_capturado: 18,
  comprovante_residencia_recebido: 19,
  tela_contracheque: 20,
  contracheque_recebido: 21,
  contracheque_capturado: 22,
  tela_termo: 23,
  termo_aceito: 24,
  termo_rejeitado: 25,
  tela_ccb: 26,
  ccb_aceito: 27,
  ccb_rejeitado: 28,
  tela_resumo: 29,
  finalizacao: 30,
  tela_refin: 31
}

export const situacao = {
  sucesso: 1,
  falha: 2
}

export class InfoBPO{
  public info : InfoType

  constructor(){
    this.info = {
      idJornada: '0',
      fase: status.jornada_iniciada,
      situacao: situacao.sucesso, //1 sucesso 2 falha
      sistemaOperacional: Info.getOS(),
      navegador: Info.getBrowser(),
      mensagemErro: ''
    }
    this.setInfo = this.setInfo.bind(this);
    this.setInfoUpdate = this.setInfoUpdate.bind(this);
  }

  setInfo(values : InfoType){
    for(var key in values){
      this.info[key] = values[key];
    }
  }

  setInfoUpdate(values :  InfoType){
    for(var key in values){
      this.info[key] = values[key];
    }
    if(this.info.idJornada != '0'){
      this.update();
    }
  }

  async update(){
		const { id } = getStateJornada();
    try{
      await axios.post(`${webs}/atualiza-jornada`, {id, ...this.info})
    }catch(e){
      console.log('erro atualizacao');
    }
  }
}

export class Info{
  list : InfoType[]

  constructor(){
    this.list = []
  }

  static getBrowser(){
    const deviceDetector : DeviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);
    return device?.client?.name + ' v.' + device?.client?.version;
    /*
    let str = window.navigator.appVersion;
    let browser = null;
    let version = null;
    let verOffset = null;
    let nameOffset = null;
    // Opera
    if ((verOffset = str.indexOf('Opera')) != -1) {
      browser = 'Opera';
      version = str.substring(verOffset + 6);
      if ((verOffset = str.indexOf('Version')) != -1) {
          version = str.substring(verOffset + 8);
      }
  }
  // Opera Next
  if ((verOffset = str.indexOf('OPR')) != -1) {
      browser = 'Opera';
      version = str.substring(verOffset + 4);
  }
  // Legacy Edge
  else if ((verOffset = str.indexOf('Edge')) != -1) {
      browser = 'Microsoft Legacy Edge';
      version = str.substring(verOffset + 5);
  } 
  // Edge (Chromium)
  else if ((verOffset = str.indexOf('Edg')) != -1) {
      browser = 'Microsoft Edge';
      version = str.substring(verOffset + 4);
  }
  // MSIE
  else if ((verOffset = str.indexOf('MSIE')) != -1) {
      browser = 'Microsoft Internet Explorer';
      version = str.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = str.indexOf('Chrome')) != -1) {
      browser = 'Chrome';
      version = str.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = str.indexOf('Safari')) != -1) {
      browser = 'Safari';
      version = str.substring(verOffset + 7);
      if ((verOffset = str.indexOf('Version')) != -1) {
          version = str.substring(verOffset + 8);
      }
  }
  // Firefox
  else if ((verOffset = str.indexOf('Firefox')) != -1) {
      browser = 'Firefox';
      version = str.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (str.indexOf('Trident/') != -1) {
      browser = 'Microsoft Internet Explorer';
      version = str.substring(str.indexOf('rv:') + 3);
  }
  // Other browsers
  else if ((nameOffset = str.lastIndexOf(' ') + 1) < (verOffset = str.lastIndexOf('/'))) {
      browser = str.substring(nameOffset, verOffset);
      version = str.substring(verOffset + 1);
      if (browser.toLowerCase() == browser.toUpperCase()) {
          browser = navigator.appName;
      }
  }
   return browser+' v.'+version.substring(0, version.indexOf(' '));
   */
  }

  static getOS(){
    // system
    const deviceDetector : DeviceDetector = new DeviceDetector();
    const device : DeviceDetectorResult = deviceDetector.parse(navigator.userAgent);
    let OSName = device?.os?.name + ' ' + device?.os?.version;
    if(!isMobile){
      if(device?.os?.platform == "x64"){
        OSName += ' 64 bits'
      }else{
        OSName += ' 32 bits'
      }
    }
    return OSName;
    /*
    let str = window.navigator.userAgent;
    let OSName = null;
    
    if (str.indexOf("Windows NT 10.0")!= -1) OSName="Windows 10";
    if (str.indexOf("Windows NT 6.3") != -1) OSName="Windows 8.1";
    if (str.indexOf("Windows NT 6.2") != -1) OSName="Windows 8";
    if (str.indexOf("Windows NT 6.1") != -1) OSName="Windows 7";
    if (str.indexOf("Windows NT 6.0") != -1) OSName="Windows Vista";
    if (str.indexOf("Windows NT 5.1") != -1) OSName="Windows XP";
    if (str.indexOf("Windows NT 5.0") != -1) OSName="Windows 2000";
    if (str.indexOf("Mac")            != -1) OSName="Mac/iOS";
    if (str.indexOf("X11")            != -1) OSName="UNIX";
    if (str.indexOf("Linux")          != -1) OSName="Linux";

    if(str.indexOf('x64')!= null){
      OSName += ' 64 bits'
    }else{
      OSName += ' 32 bits'
    }

    if (isMobile){
      if(str.indexOf("Android") != -1){
        OSName = str.match(/Android\s([0-9]*)/i)[0]
      }else if(str.indexOf("iPhone") != -1 || str.indexOf("iPad") != -1 || str.indexOf("iPod") != -1){
        OSName = 'I' + str.match(/OS (\d)?\d_\d(_\d)?/i)[0]
      }else{
        OSName = 'Indefinido'
      }
    }

    return OSName;*/
  }

  static getSystem(){
    const deviceDetector : DeviceDetector = new DeviceDetector();
    const device : DeviceDetectorResult = deviceDetector.parse(navigator.userAgent);
    if(device && device.os){
      return [device.os.name, parseFloat(device.os.version)]
    }
    return ['undefined', 0]
  }

  async addInfo(index: number, tipo: number) {
    const passo = getPassoById(index, tipo);

    try{
      let v1 = await axios(`${api_location}`)
      const res = v1.data;

      var info = {
        timeOpened: new Date().toString(),
        timezone: new Date().getTimezoneOffset() / 60,
        pageon: window.location.pathname,
        referrer: document.referrer,
        previousSites: window.history.length,
        browserName: Info.getBrowser(),
        os: Info.getOS(),
        browserVersion1a: window.navigator.appVersion,
        browserVersion1b: navigator.userAgent,
        browserLanguage: navigator.language,
        browserOnline: navigator.onLine,
        browserPlatform: navigator.platform,
        javaEnabled: navigator.javaEnabled(),
        dataCookiesEnabled: navigator.cookieEnabled,
        dataCookies1: document.cookie,
        dataCookies2: decodeURIComponent(document.cookie.split(';').join()),
        dataStorage: localStorage,
        sizeScreenW: window.screen.width,
        sizeScreenH: window.screen.height,
        sizeDocW: window.innerWidth,
        sizeDocH: window.innerHeight,
        sizeInW: window.innerWidth,
        sizeInH: window.innerHeight,
        sizeAvailW: window.screen.availWidth,
        sizeAvailH: window.screen.availHeight,
        scrColorDepth: window.screen.colorDepth,
        scrPixelDepth: window.screen.pixelDepth,
        latitude: res.latitude.toString().toLowerCase() == 'not found'? 0: res.latitude,
        longitude: res.longitude.toString().toLowerCase() == 'not found'? 0: res.longitude,
        ip: res.ip,
        country: res.country,
        city: res.city,
        estado: res.subdivisionIso,
        passo: passo,
        ID: SHA256(navigator.language + navigator.onLine + window.navigator.appVersion + navigator.userAgent).toString()
      }
      //console.log(info)
    }catch(e) {
      console.log(e)
      return false;
    }
    if(index >= this.list.length && passo){
      this.list.push(info)
    }
    return true;
  }

  async updateEventos(ultimaJornada: Etapa, tipo: number) {
    try{
      let v1 = await axios(`${api_location}`)
      const res = v1.data;

      for(var i=0; i < ultimaJornada.etapa; i++){
        const passo = getPassoById(i, tipo);

				if(passo){
        	var info = {
        	  timeOpened: new Date().toString(),
        	  timezone: new Date().getTimezoneOffset() / 60,
        	  pageon: window.location.pathname,
        	  referrer: document.referrer,
        	  previousSites: window.history.length,
        	  browserName: Info.getBrowser(),
        	  os: Info.getOS(),
        	  browserVersion1a: window.navigator.appVersion,
        	  browserVersion1b: navigator.userAgent,
        	  browserLanguage: navigator.language,
        	  browserOnline: navigator.onLine,
        	  browserPlatform: navigator.platform,
        	  javaEnabled: navigator.javaEnabled(),
        	  dataCookiesEnabled: navigator.cookieEnabled,
        	  dataCookies1: document.cookie,
        	  dataCookies2: decodeURIComponent(document.cookie.split(';').join()),
        	  dataStorage: localStorage,
        	  sizeScreenW: window.screen.width,
        	  sizeScreenH: window.screen.height,
        	  sizeDocW: window.innerWidth,
        	  sizeDocH: window.innerHeight,
        	  sizeInW: window.innerWidth,
        	  sizeInH: window.innerHeight,
        	  sizeAvailW: window.screen.availWidth,
        	  sizeAvailH: window.screen.availHeight,
        	  scrColorDepth: window.screen.colorDepth,
        	  scrPixelDepth: window.screen.pixelDepth,
        	  latitude: res.latitude.toString().toLowerCase() == 'not found'? 0: res.latitude,
        	  longitude: res.longitude.toString().toLowerCase() == 'not found'? 0: res.longitude,
        	  ip: res.ip,
        	  country: res.country,
        	  city: res.city,
        	  estado: res.subdivisionIso,
        	  passo: passo,
        	  ID: SHA256(navigator.language + navigator.onLine + window.navigator.appVersion + navigator.userAgent).toString()
        	}
				
        	this.list.push(info)
				}
    }

    }catch(e){
      console.log(e)
    }
  }

  getList(){
    return this.list
  }
}

export default Info;