import store from '../store'
import { Jornada, JornadaKey } from '../data-types';
import { jornadaActions } from '../reducers';

export const getStateJornada = () => {
  return store.getState().Jornada as Jornada;
}

export const setStateJornada = (state: JornadaKey) => {
  store.dispatch(jornadaActions.setJornada(state))
}