import { useEffect, useRef } from 'react'
import { Box, Button, ButtonProps, Grid, Icon, MenuItem, Paper, styled } from '@mui/material'
import { Form } from '@unform/web'
import { InputTextField, SelectField } from '../form'
import { FormHandles } from '@unform/core'
import { grey } from '@mui/material/colors'

import { AtualizaData } from './FormInterfaces'
import { InputPassowordField } from '../form/InputPasswordField'
import axios from 'axios'
import { User, validationUser } from '../../../ts/interfaces/Manager.interfaces'
import { IAlert } from '../alerts/alert'

const {REACT_APP_SERVER} = process.env;

const webs = REACT_APP_SERVER

const ButtonPropSubmit = styled(Button)<ButtonProps>(({ theme }) => ({
  borderColor: theme.palette.getContrastText(grey[300]),
  color: theme.palette.getContrastText(grey[300]),
  '&:click': {
    borderColor:theme.palette.getContrastText(grey[700]),
    color: theme.palette.getContrastText(grey[700]),
  },
  '&:hover': {
    borderColor:theme.palette.getContrastText(grey[200])
  }
}))

interface IUpdate {
  user: User,
  retornaConsulta: () => void  
  setAlert: React.Dispatch<React.SetStateAction<IAlert>>
}

export const FormUpdate = ({ user, retornaConsulta, setAlert } : IUpdate) : JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  const onSubmit = (data: AtualizaData) => {
    axios.post(webs+'/atualizar', data).then((resp) => {
      const result: validationUser = resp.data;
      if(result.perfil == -1){
        setAlert({message: 'Não foi possivel atualizar o usuario: '+result.mensagem, visible: true, color: 'error'});
      } else {
        setAlert({message: 'Usuário atualizado com Sucesso!', visible: true, color: 'success'})
      }
      retornaConsulta()
    })
  }

  useEffect(() => {
    formRef.current?.setData({
      id_user: user.id_user,
      login: user.login,
      senha: '',
      perfil: user.perfil
    })
  }, [user])

  return (
    <>
    <Button sx={{color: "#000"}} onClick={()=>retornaConsulta()}><Icon>arrow_back_ios</Icon></Button>
    <Form ref={formRef} onSubmit={onSubmit} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <Box margin={2} display='flex' flexDirection='column' component={Paper} variant='outlined'>
        <Grid container direction={'column'} padding={2} spacing={2}>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <h5>Atualizar</h5>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputTextField fullWidth name='login' label='Usuario'/>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputPassowordField fullWidth name='senha' label='Nova Senha' type='password'/>
            </Grid>
          </Grid>
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField fullWidth name='perfil' label='Tipo Perfil'>
                <MenuItem value={1}>Operador TI</MenuItem>
                <MenuItem value={2}>Administrador</MenuItem>
                <MenuItem value={3}>Operador</MenuItem>
              </SelectField>
            </Grid>
          </Grid>
          <InputTextField name='id_user' label='Id Usuario' hidden />
          <Grid container item direction='row' spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <ButtonPropSubmit fullWidth type='submit'><Icon>group_add_icon</Icon>Atualizar</ButtonPropSubmit>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  </>
  )
}

export default FormUpdate