import React from 'react'
import { Base64Type } from '../ts/types/Types'
import Webcam from 'react-webcam'
import { PropTelaP } from '../ts/interfaces/PropTelas.interfaces'
import FadeIn from 'react-fade-in'
import { Button } from 'reactstrap'
import { ResPic, ButtonsInline, Command, LayoutSelfie } from '../telas/styles/estilo_prova_vida'
import {Selfie} from '../components/CameraFluxo'
import Apresentacao from '../components/screen_components/Apresentacao.component'
import { TirarFotoText } from '../telas/text/prova_vida.text'
import { documento_id } from '../telas_id'

const tela = {
  inicial: 0,
  foto: 1
}

const videocons = {
  width: 420,
  height: 420,
  facingMode: 'user'
}

interface State {
  refazer: boolean,
  data: Base64Type,
  error: string,
  step: number
}

const {REACT_APP_PERFIL} = process.env

export default class SelfieTela extends React.Component<PropTelaP, State> {
  webcam: React.RefObject<Webcam>
  
  constructor(props : PropTelaP) {
    super(props)

    this.webcam = React.createRef<Webcam>();

    this.Takecapture = this.Takecapture.bind(this)
    this.CameraError = this.CameraError.bind(this)
    this.Seguir = this.Seguir.bind(this)

    this.state = {
      refazer: true,
      data: null,
      error: 'Habilite sua camera, caso queira prosseguir.',
      step: tela.inicial
    }
  }

  Takecapture() {
    const {error} = this.state
    var screenshot = this.webcam?.current?.getScreenshot();
    if(screenshot){
      this.setState({data: screenshot, refazer: false})
    }else{
      this.props.ShowAlert(error);
    }
  }
  
  CameraError() {
    const { error } = this.state
    this.setState({data: null, refazer: true})
    this.props.ShowAlert(error)
  }
  
  Seguir() {
    this.props.InsertScreenShot(this.state.data, documento_id.selfie)
    this.props.changePasso()
    //this.props.InfoUpdate({fase: status.tela_prova_vida, situacao: situacao.sucesso});
  }
  
  Apresentacao() {
    return(
    <Apresentacao 
      id='Selfie'
      onClick={()=>this.setState({step: tela.foto})}
      />
    );
  }
  
  TelaFoto() {
    const {refazer} = this.state;

    return(
    <FadeIn delay={50}>
        <LayoutSelfie>
          <Command>
          {refazer? 
            <div>
              <p><b>{TirarFotoText.title1}</b></p> 
              <p><b>{TirarFotoText.title2}</b></p>
              <p><b>{TirarFotoText.title3}</b></p>
            </div>
            : 
            <p><b>{TirarFotoText.title4}</b></p>
          }
          </Command>

          {refazer? (
            <Selfie webcam={this.webcam} videocons={videocons} onClick={this.Takecapture} CameraError={this.CameraError} />
          ) : (
            <ResPic>
              <img alt='Selfie tirada' src={this.state.data as string}/>
              <ButtonsInline>
                <Button id={REACT_APP_PERFIL == 'BPO'?'button-bpo':'button-purple2'} onClick={()=>this.setState({refazer: true})} >Tirar novamente</Button>
                <Button color='info' onClick={this.Seguir}> Seguir </Button>
              </ButtonsInline>
            </ResPic>
          )}
              
        </LayoutSelfie>
      </FadeIn>
    );
  }

  render(){
    const {step} = this.state;

    switch(step){
      case tela.inicial:
        return(this.Apresentacao());
      case tela.foto:
        return(this.TelaFoto());
      default:
        break;
    }
    return <></>;
  }

}