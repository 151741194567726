import { Paper, TableContainer, Table, Button, TableHead, TableRow, Icon, TableCell, TableBody, useTheme } from "@mui/material";
import { PropostaData } from "../../../ts/interfaces/Manager.interfaces"
import { StatusStr, perfil } from "../constants";
import axios from "axios";
import Alert, { IAlert } from '../alerts/alert'
import React from "react";
import { getStateUser } from "../../../state/actions/user_actions";
import { tela } from '../../manager-pages/tela-indice'

const web = process.env.REACT_APP_SERVER;

interface IListaConsulta {
  listaPropostas : PropostaData[],
  consultaDetalhe: (id: number, screen: number) => void
}

export const ListaConsulta = ({ listaPropostas, consultaDetalhe } : IListaConsulta) => {
  const theme = useTheme();
  const { usuario } = getStateUser();
  const [alert, setAlert] = React.useState<IAlert>({message: `${listaPropostas.length} propostas encontradas!`, visible: true, color: 'info'})
  
  const UploadCmd = async (id: string) => {
    const resp = await axios.get(`${web}/proposta/reupload?id=${id}`);
    if(resp.data == "OK"){
      setAlert({message: 'Proposta Enviada com sucesso!', visible: true})
    }else{
      setAlert({message: 'Erro:'+resp.data, visible: true, color:'error'})
    }
  }

  const formatDate = (data: string | null) => {
    if (data) {
      let date = new Date(data)
      return date.toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'}).replace(",","")
    }
    return 'Não registrado'
  }

  return (
    <>
    <Alert {...alert} setAlert={setAlert}/>
    <TableContainer component={Paper}>
      <Table aria-label='tabela-proposta'>
        <TableHead>
          <TableRow>
            <TableCell><b>idToken</b></TableCell>
            <TableCell><b>Número da Proposta</b></TableCell>
            <TableCell><b>Nome do Cliente</b></TableCell>
            <TableCell><b>Situacao</b></TableCell>
            <TableCell><b>Data Inicio Jornada</b></TableCell>
            <TableCell><b>Data Finalização Jornada</b></TableCell>
            <TableCell><b>Enviar Proposta</b></TableCell>
            {usuario?.perfil != perfil.operador? <TableCell><b>Baixar Logs</b></TableCell> : <></>}
            <TableCell><b>Baixar Documentos</b></TableCell>
            <TableCell><b>Detalhe Documentos</b></TableCell>
            <TableCell><b>Detalhe</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            listaPropostas.map((proposta : PropostaData, index: number) => {
              return(
                <TableRow key={index}>
                  <TableCell>{proposta.id_token}</TableCell>
                  <TableCell>{proposta.numero_proposta}</TableCell>
                  <TableCell>{proposta.nome_cliente}</TableCell>
                  <TableCell>{StatusStr(proposta.situacao)}</TableCell>
                  <TableCell>{formatDate(proposta.dataInicio)}</TableCell>
                  <TableCell>{formatDate(proposta.dataFinalizacao)}</TableCell>
                  <TableCell><Button style={{marginLeft: theme.spacing(4)}} onClick={() => UploadCmd(proposta.id_token)}><Icon>upload</Icon></Button></TableCell>
                  {usuario?.perfil != perfil.operador? <TableCell><Button style={{marginLeft: theme.spacing(4)}} href={`${web}/download/log?id=${proposta.id_token}`}><Icon>sim_card_download</Icon></Button></TableCell> : <></>}
                  <TableCell><Button style={{marginLeft: theme.spacing(4)}} href={`${web}/download/zip?id=${proposta.id_token}`}><Icon>folder_zip</Icon></Button></TableCell>
                  <TableCell><Button style={{marginLeft: theme.spacing(4)}} onClick={() => consultaDetalhe(index, tela.consulta_docs_detalhe)}><Icon>difference</Icon></Button></TableCell>
                  <TableCell><Button onClick={() => consultaDetalhe(index, tela.consulta_proposta_detalhe)}><Icon>description</Icon></Button></TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

export default ListaConsulta;