import styled from 'styled-components'
import { isBrowser } from 'react-device-detect'

export const LoginForm = isBrowser? styled.div`
  .card {
    width: 50%; 
    padding: 20px; 
    margin: 35px auto;
  }
` : styled.div`
  .card {
    width: 100%; 
    padding: 20px; 
    margin: 35px auto;
  }
`