import { createSlice } from '@reduxjs/toolkit'

const initialState : string[] = []

type Action = {
  type: string,
  payload: string
}

const infoSlice = createSlice({
  name: 'info',
  initialState: initialState,
  reducers: {
    pushInfo: (state: string[], action: Action) => {
      state.push(action.payload);
			return state;
    }
  }
})

export const infoActions = infoSlice.actions

export default infoSlice.reducer;