export const ApresentacaoText = {
  title1: 'Selfie',
  text1: 'Vamos tirar uma foto sua, tudo bem? Para sua foto ficar bem definida, siga as instruções apresentadas.',
  text2: 'Importante: você precisa permitir a utilização da câmera do seu celular ou do seu computador.'
}

export const ApresentacaoText2 = {
  title1: 'Prova de Vida',
  text1: 'Faremos um pequeno vídeo seu, tudo bem? Para capturarmos bem o seu rosto, siga as instruções apresentadas.'
}

export const TirarFotoText = {
  title1: '1º Escolha um local bem iluminado',
  title2: '2º Centralize seu rosto no espaço indicado',
  title3: '3º Se você usa óculos ou chapeu tire do rosto antes de fazer a selfie',
  title4: 'Foto tirada com sucesso!',
  error: 'Habilite sua camera, caso queira prosseguir.'
}

export const RastreioText = {
  movimento1: 'Centralize o seu rosto',
  movimento2: 'Sorria para a camera',
  movimento3: 'Otimo! agora vire seu rosto para a direita',
  movimento4: 'Legal! agora vire o seu rosto para a esquerda',
  movimento5: 'Perfeito!'
}