import React from 'react'
import Etapa from '../components/Etapa'
import FadeIn from 'react-fade-in';
import { Card, CardBody } from 'reactstrap';
import Header from '../components/header'
import trophy from '../images/shaking-hands.png'
import { CardInfo } from './styles/tela-agradecimento-style';
import { IPropsTipo } from '../ts/interfaces/Props.interfaces';

const {REACT_APP_NAME_LECCA} = process.env

export default class TelaAgradecimento extends React.Component<IPropsTipo,any> {

  render(){
    const {tipo} = this.props.match.params
  
    return(
        <div>
            <FadeIn delay={150}>
                <Header/>
                {tipo == '1' && <Etapa />}
                <CardInfo>
                  <Card
                    body
                    className="text-center">
                      <img src={trophy} width='150px' height='120px' style={{margin: '0 auto'}} alt='trophy'/>
                      <CardBody>
                      {tipo == '3'? <h4>Processo realizado com sucesso!</h4> : <h4>Proposta recebida com sucesso!</h4> }
                        <p><em>Recebemos seu processo e vamos analisar !</em></p>
                          {/*<img src={gif} style={{marginTop: '20px', marginBottom: '20px'}} />*/}
                          <p><em> Fique atento ao seu email ou celular que, em caso de alguma dúvida, podemos te chamar.</em></p>

                        <p><em>Em breve, daremos retorno !</em></p>
                        <p><em>{REACT_APP_NAME_LECCA}</em></p>
                      </CardBody>
                  </Card>
                </CardInfo>
            </FadeIn>
      </div>
    );
  }
}