import { profileEnd } from "console";
import Loader from "../ts/declarations/Components.d";

const {REACT_APP_BUTTON_COLOR} = process.env
const buttonColor = REACT_APP_BUTTON_COLOR

export default function Loading({text = "Carregando..."}) {
  return(
    <Loader type="bubble-scale" bgColor={buttonColor} title={text} color={buttonColor} size={100} />
  );
}