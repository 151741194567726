import etapa1 from '../../images-bpo/etapa1.png'
import etapa2 from '../../images-bpo/etapa2.png'
import etapa3 from '../../images-bpo/etapa3.png'
import etapa4 from '../../images-bpo/etapa4.png'
import etapa5 from '../../images-bpo/etapa5.png'
import etapa6 from '../../images-bpo/etapa6.png'
import etapa7 from '../../images-bpo/etapa7.png'

export const etapasBpo = [etapa1, etapa2, etapa3, etapa4, etapa5, etapa6, etapa7]

export default etapasBpo